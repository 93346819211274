import PropTypes, { InferProps } from 'prop-types'
import { useMemo } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { ColorSwatchesContext, ColorSwatchLabelPositions } from './color-swatches.context'

const propTypes = {
  /**
   * The size of the color swatches
   * @default standard
   */
  size: PropTypes.oneOf(['standard', 'super'] as const),
  /**
   * Where to position the label tooltips relative to its swatch
   * @default top
   */
  labelPosition: PropTypes.oneOf(ColorSwatchLabelPositions),
}

export type ColorSwatchesProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const ColorSwatches = renderWithRef<MinNativeRef, ColorSwatchesProps>('ColorSwatches', null, ({ children, labelPosition = 'top', size = 'standard', ...props }, ref) => {
  const classNameSet = new Set<string>(['swan-color-swatches'])
  if (size != 'standard') classNameSet.add(`swan-color-swatches-${size}`)

  const ctxValue = useMemo(
    () => ({
      labelPosition,
    }),
    [labelPosition],
  )

  return (
    <ColorSwatchesContext.Provider value={ctxValue}>
      <RenderComp root="div" classNames={classNameSet} forwardedRef={ref} props={props}>
        {children}
      </RenderComp>
    </ColorSwatchesContext.Provider>
  )
})
