import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type AspectRatioContentProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent AspectRatio
 */
export const AspectRatioContent = renderWithRef<MinNativeRef, AspectRatioContentProps>('AspectRatioContent', null, ({ children, ...rest }, ref) => {
  return (
    <RenderComp root="span" forwardedRef={ref} classNames={['swan-aspect-ratio-content']} props={rest}>
      {children}
    </RenderComp>
  )
})
