import { ComponentProps } from 'react'

import { processStyleProps } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, filterProps, renderWithRef } from '~/react/components/core'

import { ProgressBarContainer } from './progress-bar-container.component'
import { ProgressOutput } from './progress-output.component'
import { Progress } from './progress.component'

type NativeProps = ComponentProps<typeof Progress>

export type ProgressBarProps = CoreProps<NativeProps, MinNativeRef>

export const ProgressBar = renderWithRef<MinNativeRef, ProgressBarProps>('ProgressBar', undefined, (props, ref) => {
  const className = Array.from(processStyleProps(props, props.className ? [props.className] : undefined)).join(' ')
  const progressProps = {
    ...filterProps(props, null, null),
    className: undefined,
    as: props.as,
    component: props.component,
    render: props.render,
  }
  return (
    <ProgressBarContainer className={className}>
      <Progress ref={ref} {...progressProps}>
        {props.children}
      </Progress>
      {props.value === null || props.value === undefined ? null : <ProgressOutput>{props.value}%</ProgressOutput>}
    </ProgressBarContainer>
  )
})
