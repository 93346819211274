import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * Visual style of the StandardTile.
   * Available options: "standard", "product", "circular"
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'product', 'circular'] as const),
  /**
   * Specifies whether the tile is vertically or horizontally oriented
   * Available options: "vertical", "horizontal"
   * @default vertical
   */
  layout: PropTypes.oneOf(['vertical', 'horizontal'] as const),
  /**
   * Width setting for the image on the tile
   * Available options: "standard", "full-bleed"
   * @default standard
   */
  imageWidth: PropTypes.oneOf(['standard', 'full-bleed'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type StandardTileProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const StandardTile = renderWithRef<MinNativeRef, StandardTileProps>('StandardTile', propTypes, (props, ref) => {
  useComponentStylesLoaded('StandardTile', SWAN_STYLE_KEY_MAP.standardTile)

  const { skin = 'standard', layout = 'vertical', imageWidth = 'standard' } = props

  const classNames = new Set(['swan-standard-tile'])
  if (skin !== 'standard') classNames.add(`swan-standard-tile-skin-${skin}`)
  if (layout === 'horizontal') classNames.add('swan-standard-tile-horizontal')
  if (imageWidth === 'full-bleed' && skin === 'product') classNames.add('swan-standard-tile-full-bleed')

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
      {props.children}
    </RenderComp>
  )
})
