import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export const FileInputContext = createContext<
  | {
      inputId?: string | null
      disabled?: boolean
    }
  | undefined
  | null
>(undefined)

export const useFileInputContext = () => {
  return useNonNullishContext(FileInputContext)
}
