export const SWAN_BASE_CDN_URL = 'https://swan.prod.merch.vpsvc.com/'
export const SWAN_BASE_URL_MAP = {
  default: {
    cdn: SWAN_BASE_CDN_URL,
    s3: 'https://vds-swan-prod.s3-eu-west-1.amazonaws.com/',
  },
  relative: '/swan/',
  vistaprint: {
    au: 'https://www.vistaprint.com.au/swan/',
    at: 'https://www.vistaprint.at/swan/',
    be: 'https://www.vistaprint.be/swan/',
    ca: 'https://www.vistaprint.ca/swan/',
    ch: 'https://www.vistaprint.ch/swan/',
    com: 'https://www.vistaprint.com/swan/',
    de: 'https://www.vistaprint.de/swan/',
    dk: 'https://www.vistaprint.dk/swan/',
    es: 'https://www.vistaprint.es/swan/',
    fi: 'https://www.vistaprint.fi/swan/',
    fr: 'https://www.vistaprint.fr/swan/',
    ie: 'https://www.vistaprint.ie/swan/',
    in: 'https://www.vistaprint.in/swan/',
    it: 'https://www.vistaprint.it/swan/',
    nl: 'https://www.vistaprint.nl/swan/',
    no: 'https://www.vistaprint.no/swan/',
    nz: 'https://www.vistaprint.co.nz/swan/',
    pt: 'https://www.vistaprint.pt/swan/',
    se: 'https://www.vistaprint.se/swan/',
    sg: 'https://www.vistaprint.sg/swan/',
    uk: 'https://www.vistaprint.co.uk/swan/',
  },
}

export const SWAN_BASE_URL_LIST = [...Object.values(SWAN_BASE_URL_MAP.default), ...Object.values(SWAN_BASE_URL_MAP.vistaprint)]
