import { MutableRefObject, useEffect, useRef } from 'react'

/**
 * https://github.com/akiran/react-slick/issues/1240#issuecomment-1180985599
 * Disable window scrolling while swiping horizontally
 * @param ref HTML element
 * @param dragThreshold indicates that the user needs to swipe horizontally a greater distance before vertical scrolling is disabled.
 */
export function usePreventVerticalScroll<T>(ref: MutableRefObject<T>, dragThreshold = 5) {
  const firstClientX = useRef<number>(0)
  const clientX = useRef<number>(0)

  useEffect(() => {
    const preventTouch = (e: TouchEvent) => {
      clientX.current = e.touches[0].clientX - firstClientX.current

      // Vertical scrolling does not work when you start swiping horizontally.
      if (Math.abs(clientX.current) > dragThreshold) {
        e.preventDefault()
        return false
      }

      return true
    }

    const touchStart = (e: TouchEvent) => {
      firstClientX.current = e.touches[0].clientX
    }

    const element = ref?.current as HTMLElement

    if (element) {
      element.addEventListener('touchstart', touchStart)
      element.addEventListener('touchmove', preventTouch, { passive: false })
    }
    return () => {
      if (element) {
        element.removeEventListener('touchstart', touchStart)
        element.removeEventListener('touchmove', preventTouch)
      }
    }
  })
}
