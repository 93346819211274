import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type LinkListHeadingProps = CoreProps<JSX.IntrinsicElements['h3'], MinNativeRef>

/**
 * @subcomponent List
 */

export const LinkListHeading = renderWithRef<MinNativeRef, LinkListHeadingProps>('LinkListHeading', null, (props, ref) => (
  <RenderComp root="h3" forwardedRef={ref} classNames={['swan-link-list-heading']} props={props}>
    {props.children}
  </RenderComp>
))
