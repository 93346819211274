import { Section } from '@react-stately/collections'
import type { SectionProps } from '@react-types/shared'

export type ListboxSectionProps = SectionProps<Record<string, never>>

/**
 * @subcomponent Listbox
 */
export const ListboxSection = (props: ListboxSectionProps) => {
  // this never actually gets rendered below, it's only defined for the typescript custom prop hints
  // any prop logic or hooks need to go in the internal option component
  return <Section {...props} />
}

// the Section component has some functions on it for the react-aria collection behavior.
// This will copy the props over
Object.assign(ListboxSection, Section)

ListboxSection.displayName = 'ListboxSection'
