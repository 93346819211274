import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type StandardTileFooterProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardTile
 */
export const StandardTileFooter = renderWithRef<MinNativeRef, StandardTileFooterProps>('StandardTileFooter', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-standard-tile-footer']} props={props}>
    {props.children}
  </RenderComp>
))
