import type { Node } from '@react-types/shared'
import { useMemo } from 'react'

import { useSwanListboxListContext } from './listbox-list.context'

export const useSelectedItems = () => {
  const { listState } = useSwanListboxListContext()

  const { selectionManager, collection } = listState

  const { selectedKeys } = selectionManager

  const selectedItems = useMemo(() => [...selectedKeys].map(key => collection.getItem(key)).filter(Boolean), [selectedKeys, collection])

  // if the first key is an item use it
  // otherwise, it's a section so iterate over the keys to find the first item
  // we could skip the firstKey check, but it might be slightly more performant
  const firstItem = useMemo(() => {
    const firstKey = collection.getFirstKey()
    let first: Node<object> | undefined | null
    if (firstKey != null) {
      first = collection.getItem(firstKey)
    }

    if (first?.type !== 'item') {
      for (const k of collection.getKeys()) {
        const item = collection.getItem(k)
        if (item?.type === 'item') {
          first = item
          break
        }
      }
    }

    return first
  }, [collection])

  return useMemo(
    () => ({
      selectedItems,
      selectedItemsOrFirst: selectedItems.length > 0 || !firstItem ? selectedItems : [firstItem],
    }),
    [selectedItems, firstItem],
  )
}
