import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { useProgressBarId } from '~/react/components/progress-bar/progress-bar.context'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

export type ProgressProps = CoreProps<JSX.IntrinsicElements['progress'], MinNativeRef>

/**
 * @subcomponent ProgressBar
 */
export const Progress = renderWithRef<MinNativeRef, ProgressProps>('Progress', undefined, (props, ref) => {
  useComponentStylesLoaded('Progress', SWAN_STYLE_KEY_MAP.progressBar)
  const id = useProgressBarId(props.id)
  return (
    <RenderComp root="progress" classNames={['swan-progress']} forwardedRef={ref} props={{ max: 100, ...props, id }}>
      {props.children}
    </RenderComp>
  )
})
