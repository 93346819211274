import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

type CarouselSlideTypes = {
  /**
   * Provide distinct label for the slide.
   * As an alternative, number and set size can serve as a meaningful label. eg "3 of 10"
   */
  'aria-label'?: JSX.IntrinsicElements['div']['aria-label'] // TODO: v4 make either one or the other required (aria-label or aria-labelledby)
  /**
   * References of the element that labels the slide.
   */
  'aria-labelledby'?: JSX.IntrinsicElements['div']['aria-labelledby'] // TODO: v4 make either one or the other required (aria-label or aria-labelledby)
  /**
   * Localized label for assistive tech to identify this element as a "slide"
   * @default slide
   */
  'aria-roledescription'?: JSX.IntrinsicElements['div']['aria-roledescription'] // TODO: v4 remove the default value and make it require
}

export type CarouselSlideProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, CarouselSlideTypes>

/**
 * @subcomponent Carousel
 */
export const CarouselSlide = renderWithRef<MinNativeRef, CarouselSlideProps>('CarouselSlide', null, (props, ref) => {
  const { children, 'aria-roledescription': ariaRoleDescription, ...rest } = props
  const classNames = ['swan-carousel-slide']
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={{ role: 'group', ...rest, 'aria-roledescription': ariaRoleDescription || 'slide' }}>
      {children}
    </RenderComp>
  )
})
