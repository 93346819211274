import PropTypes, { InferProps } from 'prop-types'
import { useRef } from 'react'

import { assignRefs } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { PopoverDialog, useSwanPopover } from '~/react/components/popover'
import { PopoverContentInternal, PopoverContentInternalProps } from '~/react/components/popover/popover-content-internal.component'

import { useIsomorphicLayoutEffect } from '~/react/hooks'

const propTypes = {
  /**
   * Set to "true" if the Listbox Popover is rendering inside a Modal.
   */
  inModal: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type ListboxPopoverProps = CoreProps<
  PopoverContentInternalProps,
  HTMLDivElement,
  InferProps<typeof propTypes> & {
    // Redeclaring the props so as to capture proper default value
    /**
     * The placement of the popover. eg: "bottom start", "top end", "top", "bottom"
     * @default bottom start
     */
    placement?: PopoverContentInternalProps['placement']
    /**
     * Specifies whether to hide the arrow of the popover.
     * @default true
     */
    hideArrow?: PopoverContentInternalProps['hideArrow']
  },
  PopoverContentInternalProps
>

/**
 * @subcomponent Listbox
 */
export const ListboxPopover = renderWithRef<MinNativeRef, ListboxPopoverProps>('ListboxPopover', {}, (props, ref) => {
  const { children, className, isNonModal, placement = 'bottom start', hideArrow = true } = props
  const { overlayState, triggerRef, fullWidth } = useSwanPopover()
  const popoverRef = useRef<HTMLDivElement>(null)
  const combinedRef = assignRefs(ref, popoverRef)

  const classes = new Set(['swan-listbox-popover'])
  if (className) classes.add(className)

  useIsomorphicLayoutEffect(() => {
    function handleResize() {
      const popoverEl = popoverRef.current
      const triggerEl = triggerRef.current
      if (overlayState.isOpen && fullWidth && triggerEl && popoverEl) {
        const { width } = triggerEl.getBoundingClientRect()
        popoverEl.style.width = `${width}px`
        popoverEl.style.minWidth = `${width}px`
        popoverEl.style.maxWidth = `${width}px`
      }
    }
    handleResize()

    if (typeof window !== 'object') return
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [overlayState.isOpen, fullWidth, triggerRef])

  return overlayState.isOpen ? (
    <RenderComp root={PopoverContentInternal} forwardedRef={combinedRef} propKeysToRemove={propKeysToRemove} classNames={classes} props={{ placement, hideArrow, ...props }}>
      {/* combobox, which uses this component, should not have a dialog */}
      {isNonModal ? <>{children}</> : <PopoverDialog>{children}</PopoverDialog>}
    </RenderComp>
  ) : (
    /* the listbox list needs to be rendered in the dom for the items to be registered with the parent listbox */
    <div aria-hidden="true" className="swan-display-none">
      {children}
    </div>
  )
})
