import { useButton } from '@react-aria/button'
import PropTypes, { InferProps } from 'prop-types'
import { Fragment, ReactNode } from 'react'

import { assignRefs } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { useSwanPopover } from '~/react/components/popover'

import { useFloatingLabelFormElement } from '~/react/contexts/internal/floating-label'

import { useSwanListboxButtonContext } from './listbox-button.context'
import { ListboxOptionProps } from './listbox-option.component'
import { useSelectedItems } from './use-selected-items.hook'

const propTypes = {
  /**
   * Content to be displayed before the selected items.
   */
  labelPrefix: PropTypes.node,

  /**
   * Placeholder Text to display when no item is selected, instead of the first item.
   */
  placeholder: PropTypes.node,
}

const propKeysToRemove = Object.keys(propTypes)

export type ListboxButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef, InferProps<typeof propTypes>>

const SelectedOptions = ({ className, placeholder }: { className?: string; placeholder?: ReactNode | string }) => {
  const { selectedItems, selectedItemsOrFirst } = useSelectedItems()

  let selectedText: ReactNode | string = ''
  if (selectedItems.length === 0 && placeholder != null) {
    selectedText = placeholder
  } else if (selectedItemsOrFirst.length === 1) {
    const item = selectedItemsOrFirst[0]
    selectedText = item?.props?.label ?? item?.rendered ?? item?.textValue ?? ''
  } else {
    // concatenate and flatten the JSX for every selected item
    selectedText = selectedItemsOrFirst.reduce<Array<ReactNode>>((arr, item, i) => {
      // if the item has a "label" prop, use that, otherwise fallback to the rendered jsx
      const props = item?.props as ListboxOptionProps
      const key = item?.value?.toString() ?? i
      const itemText = <Fragment key={key}>{(props.label ?? item?.rendered ?? item?.textValue) as ReactNode}</Fragment>
      const items = [...arr, itemText]
      if (i < selectedItemsOrFirst.length - 1) {
        items.push(<Fragment key={`${key},`}>{', '}</Fragment>)
      }
      return items
    }, [])
  }

  return <span className={className}>{selectedText}</span>
}

/**
 * @subcomponent Listbox
 */
export const ListboxButton = renderWithRef<HTMLButtonElement, ListboxButtonProps>('ListboxButton', {}, ({ className, labelPrefix, ...props }, ref) => {
  const { triggerRef } = useSwanPopover()
  const { triggerProps, triggerDisabled } = useSwanListboxButtonContext()
  const combinedRef = assignRefs(ref, triggerRef)
  const { buttonProps } = useButton(triggerProps, triggerRef)
  const id = buttonProps.id

  // make the button's id available to the context, so that a floating label can use it
  useFloatingLabelFormElement({ ...props, placeholder: undefined, id })

  const classNames = new Set(['swan-listbox-button'])
  if (labelPrefix) classNames.add('swan-listbox-button-with-label')
  if (className) classNames.add(className)

  return (
    <RenderComp
      root="button"
      forwardedRef={combinedRef}
      classNames={classNames}
      props={{ ...props, ...buttonProps, disabled: triggerDisabled }}
      propKeysToRemove={propKeysToRemove}
    >
      {labelPrefix ? <span className="swan-listbox-button-label">{labelPrefix as ReactNode}</span> : null}
      <SelectedOptions placeholder={props.placeholder as ReactNode} />
    </RenderComp>
  )
})
