export const MANIFEST_JS = {
  toggleSwitch: {
    hashed: 'toggle-switch.b959553da98ffd9165e17db01e5b22d5.min.js',
    versioned: 'toggle-switch.min.js',
  },
  tabs: {
    hashed: 'tabs.9b00075085a9d21ac770004da98cec57.min.js',
    versioned: 'tabs.min.js',
  },
  progressiveImage: {
    hashed: 'progressive-image.7d0f0578c98a6ce7cd6262dfb964717d.min.js',
    versioned: 'progressive-image.min.js',
  },
  progress: {
    hashed: 'progress.950a9e90782b06532045448a3d4a7fb5.min.js',
    versioned: 'progress.min.js',
  },
  popover: {
    hashed: 'popover.ac0ec2645f6bfde1aa8601202184aed9.min.js',
    versioned: 'popover.min.js',
  },
  modalDialog: {
    hashed: 'modal-dialog.43dc15e13cfa0dc2558adf2ea810b0c7.min.js',
    versioned: 'modal-dialog.min.js',
  },
  core: {
    hashed: 'core.0e98d3cc41a6962a52d8a6f66bc9b976.min.js',
    versioned: 'core.min.js',
  },
  alertBox: {
    hashed: 'alert-box.59c5c15fc3f789a64155ba4d88a701c3.min.js',
    versioned: 'alert-box.min.js',
  },
  accordion: {
    hashed: 'accordion.a203a93efcff16ce628eeee8d6e39cae.min.js',
    versioned: 'accordion.min.js',
  },
} as const;
