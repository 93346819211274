import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type FluidImageProps = CoreProps<JSX.IntrinsicElements['img'], MinNativeRef>

export const FluidImage = renderWithRef<MinNativeRef, FluidImageProps>('FluidImage', null, ({ children, ...props }, ref) => (
  <RenderComp root="img" forwardedRef={ref} props={props} classNames={['swan-fluid-image']}>
    {children}
  </RenderComp>
))
