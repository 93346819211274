import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BreadcrumbsContainerProps = CoreProps<JSX.IntrinsicElements['nav'], MinNativeRef>

/**
 * @subcomponent Breadcrumbs
 */
export const BreadcrumbsContainer = renderWithRef<MinNativeRef, BreadcrumbsContainerProps>('BreadcrumbsContainer', null, ({ children, ...props }, ref) => (
  <RenderComp root="nav" classNames={['swan-breadcrumbs']} forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
