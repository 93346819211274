import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type FieldSetLegendProps = CoreProps<JSX.IntrinsicElements['legend'], MinNativeRef>

/**
 * @subcomponent FieldSet
 */
export const FieldSetLegend = renderWithRef<MinNativeRef, FieldSetLegendProps>('FieldSetLegend', null, ({ children, ...props }, ref) => (
  <RenderComp root="legend" forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
