import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ModalDialogButtonsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Dialog
 */
export const ModalDialogButtons = renderWithRef<MinNativeRef, ModalDialogButtonsProps>('ModalDialogButtons', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" forwardedRef={ref} props={props} classNames={['swan-modal-dialog-buttons']}>
    {children}
  </RenderComp>
))
