import PropTypes, { InferProps } from 'prop-types'
import type { ComponentProps } from 'react'

import { CoreInputProps, MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'
import { composeEventHandlersUtil } from '~/react/utilities'

import { ButtonbarButtonContext, useButtonbarContext } from './buttonbar.context'

const propTypes = {
  /**
   * The value of the button
   */
  value: PropTypes.string.isRequired,
}

const propKeysToRemove: string[] = []

export type ButtonbarButtonProps = CoreProps<Omit<CoreInputProps, 'value'>, MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Buttonbar
 */
export const ButtonbarButton = renderWithRef<MinNativeRef, ButtonbarButtonProps>('ButtonbarButton', propTypes, (props, ref) => {
  const { value, children, id: customId, onChange: customOnChange } = props
  const { variant, selectedValues, selectedValue, onSelectedValuesChange, onSelectedValueChange, name } = useButtonbarContext()
  const id = useId(customId)
  const isMultiSelect = variant === 'multi-select'
  const classNames = [isMultiSelect ? 'swan-checkbox' : 'swan-radio']

  const checked = isMultiSelect ? !!selectedValues?.includes(value) : selectedValue === value

  const onChange: ComponentProps<'input'>['onChange'] = isMultiSelect
    ? event => {
        if (event.target.checked) {
          onSelectedValuesChange([...(selectedValues || []), value], event)
        } else {
          onSelectedValuesChange(
            (selectedValues || []).filter(x => x !== value),
            event,
          )
        }
      }
    : event => {
        if (event.target.checked) {
          onSelectedValueChange(value, event)
        }
      }

  const forwardedProps = {
    type: isMultiSelect ? 'checkbox' : 'radio',
    ...props,
    id,
    name,
    value: value,
    checked,
    onChange: composeEventHandlersUtil(customOnChange, onChange),
  }
  return (
    <>
      <RenderComp root="input" forwardedRef={ref} propKeysToRemove={propKeysToRemove} props={forwardedProps} classNames={classNames} />
      <ButtonbarButtonContext.Provider value={{ inputId: id }}>{children}</ButtonbarButtonContext.Provider>
    </>
  )
})
