import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Position of the button.
   * Available options: 'left', 'right'
   *
   * @default right
   */
  buttonPosition: PropTypes.oneOf(['left', 'right'] as const),
}

export type TextInputWithButtonInsetProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent TextInput
 */
export const TextInputWithButtonInset = renderWithRef<MinNativeRef, TextInputWithButtonInsetProps>('TextInputWithButtonInset', null, (props, ref) => {
  const { buttonPosition = 'right', children, ...rest } = props

  const classNames = new Set(['swan-vanilla-ignore', 'swan-input-with-button-inset'])
  if (buttonPosition === 'left') classNames.add(`swan-input-with-button-left`)

  return (
    <RenderComp root="div" classNames={classNames} forwardedRef={ref} props={{ role: 'group', ...rest }}>
      {children}
    </RenderComp>
  )
})
