import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ListboxOptionThumbnailContainerProps = CoreProps<JSX.IntrinsicElements['div']>

/**
 * @subcomponent Listbox
 */

export const ListboxOptionThumbnailContainer = renderWithRef<MinNativeRef, ListboxOptionThumbnailContainerProps>(
  'ListboxOptionThumbnailContainer',
  null,
  ({ children, ...props }, ref) => {
    return (
      <RenderComp root="div" classNames={['swan-listbox-option-thumbnail-container']} forwardedRef={ref} props={props}>
        {children}
      </RenderComp>
    )
  },
)
