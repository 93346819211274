export const ModalDialogVariantEnum = {
  Standard: 'standard',
  PanelRight: 'panel-right',
  PanelLeft: 'panel-left',
  PanelBottom: 'panel-bottom',
  PanelTop: 'panel-top',
  Menu: 'menu',
} as const

export type ModalDialogVariant = (typeof ModalDialogVariantEnum)[keyof typeof ModalDialogVariantEnum]

export const ModalDialogBodyWidths = ['standard', 'capped', 'grow'] as const
