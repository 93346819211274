import PropTypes, { InferProps } from 'prop-types'
import { ChangeEvent } from 'react'
import warning from 'tiny-warning'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useControllableValue, useDidImmutableValueChange, useId, useMap } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { TabsContext } from './tabs.context'

const propTypes = {
  /**
   * Whether or not the headers are centered horizontally in their container
   *
   * @default false
   */
  centerHeaders: PropTypes.bool,
  /**
   * Whether or not the headers are sticky.
   * Also hides the dividing line between the tab headers and content
   *
   * @default false
   */
  stickyHeaders: PropTypes.bool,
  /**
   * @deprecated
   * This is deprecated without a replacement. Tabs will be scrollable.
   *
   * Whether or not the headers will wrap
   *
   * @default false
   */
  wrapHeaders: deprecatedProp(PropTypes.bool, 'Use the default scrolling behaviour of Tabs instead.'),
  /**
   * Whether or not there is a dividing line between headers and contents
   * @default true
   */
  showDividingLine: PropTypes.bool,
  /**
   * The id of the tab which should be selected initially, used for uncontrolled tabs
   */
  defaultSelectedTabId: PropTypes.string,
  /**
   * The id of the tab which should be selected. Used when controlling the state of the tabs
   */
  selectedTabId: PropTypes.string,
  /**
   * Callback which is fired when the user requests a tab-change
   */
  onRequestTabChange: PropTypes.func as PropTypes.Requireable<(tabId: string | null, event: ChangeEvent<HTMLInputElement>) => void>,
}
const propKeysToRemove = Object.keys(propTypes)

export type TabsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const Tabs = renderWithRef<MinNativeRef, TabsProps>('Tabs', propTypes, (props, ref) => {
  useComponentStylesLoaded('Tabs', SWAN_STYLE_KEY_MAP.tabs)

  const {
    children,
    centerHeaders = false,
    stickyHeaders = false,
    wrapHeaders = false,
    showDividingLine = true,
    defaultSelectedTabId,
    selectedTabId: controlledSelectedTabId,
    onRequestTabChange: controlledOnRequestTabChange,
  } = props
  const classNames = new Set(['swan-vanilla-ignore', 'swan-tabs'])
  if (centerHeaders) classNames.add('swan-tabs-center-headers')
  if (stickyHeaders) classNames.add('swan-tabs-sticky-headers')
  if (wrapHeaders) classNames.add('swan-tabs-wrap-headers')
  if (showDividingLine === false) classNames.add('swan-tabs-no-dividing-line')

  const isControlled = controlledSelectedTabId !== undefined

  const didIsControlledChange = useDidImmutableValueChange(isControlled)

  warning(
    !didIsControlledChange,
    isControlled
      ? 'The `Tabs` component switched from "Uncontrolled" to "Controlled". This is an unsupported pattern. This can happen if `selectedTabId` was not initially defined, but was defined in a subsequent render'
      : 'The `Tabs` component switched from "Controlled" to "Uncontrolled". This is an unsupported pattern. This can happen if `selectedTabId` was initially defined, but not defined in a subsequent render. If you\'re trying to make it so that _no_ tab is selected, you should pass `null` for `selectedTabId`',
  )

  warning(
    isControlled || defaultSelectedTabId !== undefined,
    '`defaultSelectedTabId` is required if `selectedTabId` is not provided. If you really want there to be _no_ tab selected initially, pass `null` for `defaultSelectedTabId`',
  )

  const [headerIds, { set: registerHeader, remove: unregisterHeader }] = useMap<string | undefined | null>()
  const [labelIds, { set: registerLabel, remove: unregisterLabel }] = useMap<string | undefined | null>()
  const [contentIds, { set: registerContent, remove: unregisterContent }] = useMap<string | undefined | null>()

  const [selectedTabId, requestTabChange] = useControllableValue<string | null, [string | null, ChangeEvent<HTMLInputElement>]>({
    value: controlledSelectedTabId,
    onChange: controlledOnRequestTabChange || undefined,
    // if defaultSelectedTabId is not defined, we'll use `null` (no value selected by default)
    // when the Tabs are controlled, this value is just ignored, and when the Tabs are uncontrolled, we warn the user that this is happening implicitly.
    // the only reason we're doing this defaulting here rather than in the props destructuring at the top (where defaulting usually happens)
    // is so that we can catch the case where we're implicitly defaulting to null and we can warn the user
    defaultValue: defaultSelectedTabId ?? null,
    defaultOnChange: (setUncontrolledState, ...onChangeArgs) => {
      const [newTabId] = onChangeArgs
      setUncontrolledState(newTabId)
    },
  })

  const tabsName = useId()
  return (
    <TabsContext.Provider
      value={{
        tabsName,
        selectedTabId,
        onRequestTabChange: requestTabChange,
        headerIds,
        registerHeader,
        unregisterHeader,
        labelIds,
        registerLabel,
        unregisterLabel,
        contentIds,
        registerContent,
        unregisterContent,
      }}
    >
      <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
        {children}
      </RenderComp>
    </TabsContext.Provider>
  )
})
