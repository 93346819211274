import { forwardRef, ForwardRefRenderFunction, PropsWithoutRef, RefAttributes, WeakValidationMap } from 'react'

import { isNotProduction } from '~/core/utilities'

export function renderWithRef<T, P>(
  displayName: string,
  propTypes: WeakValidationMap<PropsWithoutRef<P> & RefAttributes<T>> | undefined | null,
  renderFun: ForwardRefRenderFunction<T, PropsWithoutRef<P>>,
) {
  const component = forwardRef(renderFun)
  component.displayName = `WithRef(${displayName})`
  if (propTypes && isNotProduction()) {
    // Add propTypes only for non-production
    component.propTypes = propTypes
  }
  return component
}
