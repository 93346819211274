import { polyfillResolve } from './manifest.utils'

type SwanPolyfill = {
  loadCondition: () => boolean
  scriptFileName?: string
}

const polyfillConfigs: Array<SwanPolyfill> = [
  {
    loadCondition: () => typeof window !== 'undefined' && typeof window.HTMLDialogElement === 'undefined',
    scriptFileName: 'modal-dialog.js',
  },
  // TODO (browser): once our supported browsers all support `:focus-visible` we can remove this polyfill and all `.focus-visible` selectors from SWAN
  {
    loadCondition: () => typeof window !== 'undefined' && window.CSS && CSS.supports && CSS.supports('selector(:focus-visible)') === false,
    scriptFileName: 'focus-visible.js',
  },
]

export function getSwanScriptPolyfillUrl(name: string, base?: string) {
  return polyfillResolve(name, base)
}

function generatePolyfillElements(swanBaseUrl: string) {
  const scriptFragment = document.createDocumentFragment()

  polyfillConfigs.forEach(polyfill => {
    const { loadCondition, scriptFileName } = polyfill

    if (!loadCondition()) {
      return
    }

    if (scriptFileName) {
      const url = getSwanScriptPolyfillUrl(scriptFileName, swanBaseUrl)
      if (!document.querySelector(`script[src="${url}"]`)) {
        const script = document.createElement('script')
        script.src = url
        script.type = 'text/javascript'
        script.defer = true
        scriptFragment.appendChild(script)
      }
    }
  })

  return { scriptFragment }
}

export const loadSwanPolyfill = (swanBaseUrl: string) => {
  const { scriptFragment } = generatePolyfillElements(swanBaseUrl)
  document.head.appendChild(scriptFragment)
}
