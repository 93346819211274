import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

export type TableProps = CoreProps<JSX.IntrinsicElements['table'], MinNativeRef>

export const Table = renderWithRef<MinNativeRef, TableProps>('Table', null, ({ children, ...props }, ref) => {
  useComponentStylesLoaded('Table', SWAN_STYLE_KEY_MAP.table)
  return (
    <RenderComp root="table" classNames={['swan-table']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
