import { MANIFEST_JS } from '~/core/manifest/js'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types'

import { getSameKeyValueMapForManifest, manifestResolve } from './manifest.utils'

export type SwanCoreJsKey = keyof typeof MANIFEST_JS
export const SWAN_JS_KEY_MAP = getSameKeyValueMapForManifest(MANIFEST_JS)

export function getSwanJsUrl(key: SwanCoreJsKey, pathType?: SwanPathTypeEnum, swanAssetBaseUrl?: string) {
  return manifestResolve(MANIFEST_JS, key, SwanAssetFolders.JS, pathType, swanAssetBaseUrl)
}
