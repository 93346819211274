import { RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

import { Button, ButtonProps } from '~/react/components/button'

export type PriceBarMainButtonProps = Omit<ButtonProps, 'skin'> & {
  /**
   * The visual style of the Button
   * @default primary
   */
  skin?: ButtonProps['skin'] // Redeclaring the prop so as to capture proper default value
}

const propTypes = {}

const propKeysToRemove = Object.keys(propTypes)

/**
 * @subcomponent PriceBar
 */
export const PriceBarMainButton = renderWithRef<MinNativeRef, PriceBarMainButtonProps>('PriceBarMainButton', null, (props, ref) => {
  const { skin = 'primary', children } = props

  return (
    <RenderComp root={Button} forwardedRef={ref} propKeysToRemove={propKeysToRemove} props={{ skin, ...props }}>
      {children}
    </RenderComp>
  )
})
