import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * Provide the text that should be used for screen readers.
   */
  accessibleText: PropTypes.string,
}

export type PaginationProps = CoreProps<JSX.IntrinsicElements['nav'], HTMLElement, InferProps<typeof propTypes>>

export const Pagination = renderWithRef<MinNativeRef, PaginationProps>('Pagination', null, ({ children, accessibleText, ...props }, ref) => {
  useComponentStylesLoaded('Pagination', SWAN_STYLE_KEY_MAP.pagination)

  return (
    <RenderComp root="nav" classNames={['swan-pagination']} forwardedRef={ref} props={{ 'aria-label': accessibleText ?? undefined, ...props, role: 'navigation' }}>
      <ul>{children}</ul>
    </RenderComp>
  )
})
