export function getEnv() {
  try {
    return process.env.NODE_ENV || 'production'
  } catch {
    return 'production'
  }
}

export function isTestEnv() {
  return process.env.NODE_ENV === 'test'
}

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export function isNotProduction() {
  return process.env.NODE_ENV !== 'production'
}

export function isNotTestOrProduction() {
  const env = process.env.NODE_ENV
  return env !== 'production' && env !== 'test'
}
