import { createContext, useContext } from 'react'

export const ColorSwatchLabelPositions = ['top', 'bottom', 'left', 'right'] as const

export const ColorSwatchGradientTypes = ['linear', 'radial'] as const

type ColorSwatchesContextProps = {
  labelPosition?: (typeof ColorSwatchLabelPositions)[number] | null
}

export const ColorSwatchesContext = createContext<ColorSwatchesContextProps | undefined | null>(undefined)

export function useColorSwatchesContext() {
  return useContext(ColorSwatchesContext)
}
