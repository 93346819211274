import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type StandardTileSwatchesProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardTile
 */

export const StandardTileSwatches = renderWithRef<MinNativeRef, StandardTileSwatchesProps>('StandardTileSwatches', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-standard-tile-swatches']} props={props}>
    {props.children}
  </RenderComp>
))
