import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type LinkListProps = CoreProps<JSX.IntrinsicElements['ul'], MinNativeRef>

/**
 * @subcomponent List
 */

export const LinkList = renderWithRef<MinNativeRef, LinkListProps>('LinkList', null, (props, ref) => {
  return (
    <RenderComp root="ul" forwardedRef={ref} classNames={['swan-link-list']} props={props}>
      {props.children}
    </RenderComp>
  )
})
