import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type PriceBarCouponCalloutProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent PriceBar
 */
export const PriceBarCouponCallout = renderWithRef<MinNativeRef, PriceBarCouponCalloutProps>('PriceBarCouponCallout`', null, (props, ref) => {
  const { children } = props
  const classNames = new Set(['swan-price-bar-coupon-callout'])

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
