import { Dispatch, SetStateAction, useEffect, useState } from 'react'

function makeGetKey(key: string) {
  return `${key}/get`
}

function makeHasKey(key: string) {
  return `${key}/has`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function browserHistoryStateHas(state: any, key: string) {
  return state?.[makeHasKey(key)] === true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function browserHistoryStateGet<T>(state: any, key: string): T {
  return state?.[makeGetKey(key)]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function browserHistoryStateGetOrDefault<T>(state: any, key: string, defaultValue: T): T {
  return browserHistoryStateHas(state, key) ? browserHistoryStateGet(state, key) : defaultValue
}

/**
 * Creates some state (useState) and syncs the value-changes with the browser's history stack so that the state-changes are undo/redo-able via the browser's forward/back buttons.
 *
 * It relies on the fact that you can actually push arbitrary (serializable) data onto the browser's history stack without changing the URL.
 * Thus we're able to serialize and store your state as part of the history stack whenever it changes. Then, when the user interacts with the back/forward/refresh buttons, we can get the new value from the history stack and update it to the local React state accordingly.
 *
 * This is especially useful for mobile devices where the "back" button is globally available across all apps, and usually navigating back may mean closing a modal or something.
 *
 * The usage/signature is very similar to `useState`. The main difference is that we require a key in order to identify your state in the browser's history stack.
 *
 * setValue -> pushes a new entry onto the history stack with the new state
 * back button, forward button, refresh page -> sets value based on the history entry
 *
 * @param key - string - uniquely identifies the value
 * @param initialValue - boolean (default: false) - the initial value of the value
 * @param config.replace - boolean (default: false) - the config.replace value pop up the modal history when user close the modal using the close button
 */
export function useBrowserHistoryState<T>(key: string, defaultValue: T, config?: { replace?: boolean }): [T, Dispatch<SetStateAction<T>>] {
  // if we have access to the window/history, check if there's a current value
  // otherwise use the default value as the initial
  const [value, setValue] = useState<T>(() => (typeof window !== 'undefined' ? browserHistoryStateGetOrDefault(window.history.state, key, defaultValue) : defaultValue))

  const shouldReplace = config?.replace === true

  // sync React -> History
  useEffect(() => {
    const valueInHistory = browserHistoryStateGetOrDefault(window.history.state, key, defaultValue)
    if (!value && valueInHistory && shouldReplace) {
      window.history.replaceState({ [makeGetKey(key)]: false, [makeHasKey(key)]: true }, '')
    } else if (JSON.stringify(value) !== JSON.stringify(valueInHistory)) {
      // 1. second param is required but currently-ignored in almost all browsers
      // https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
      //
      // 2. if `value` is undefined, we could have difficulty determining whether the value was not set, or if it was explicitly set to undefined
      // so we keep a second flag "/has" which keeps track of whether or not the value is explicit

      window.history.pushState({ [makeGetKey(key)]: value, [makeHasKey(key)]: true }, '')
      if (shouldReplace) {
        window.history.replaceState({ [makeGetKey(key)]: false, [makeHasKey(key)]: true }, '')
      }
    }
  }, [value, key, defaultValue, shouldReplace])

  // sync History -> React
  useEffect(() => {
    const popStateHandler = (event: PopStateEvent) => {
      const valueInHistory = browserHistoryStateGetOrDefault(event.state, key, defaultValue)

      if (JSON.stringify(valueInHistory) !== JSON.stringify(value)) {
        setValue(valueInHistory)
      }
    }

    window.addEventListener('popstate', popStateHandler)

    return () => window.removeEventListener('popstate', popStateHandler)
  }, [value, key, defaultValue])

  return [value, setValue]
}
