import PropTypes, { InferProps } from 'prop-types'

import { CoreInputProps, CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The visual style of the Range
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'hue', 'saturation', 'contrast', 'custom'] as const),
  /**
   * Should it be unfilled or not? (Note: some skins will always be unfilled.)
   * @default false
   */
  unfilled: PropTypes.bool,
  /**
   * When using a skin with a two-color gradient, the start color of the gradient
   */
  gradientStartColor: PropTypes.string,
  /**
   * When using a skin with a two-color gradient, the end color of the gradient. "saturation" Ranges also need to specify this value
   */
  gradientEndColor: PropTypes.string,
}

type propsType = InferProps<typeof propTypes>

export type RangeProps = CoreProps<CoreInputProps, MinNativeRef, propsType>

export const Range = renderWithRef<MinNativeRef, RangeProps>(
  'Range',
  null,
  ({ children, skin = 'standard', unfilled = false, gradientEndColor, gradientStartColor, ...props }, ref) => {
    const classNames = ['swan-range']
    if (unfilled) classNames.push('swan-range-unfilled')
    if (skin !== 'standard') classNames.push(`swan-range-skin-${skin}`)

    const processedProps = {
      type: 'range',
      ...props,
      style: {
        ...(props.style || {}),
        '--swan-internal-range-gradient-start-color': gradientStartColor,
        '--swan-internal-range-gradient-end-color': gradientEndColor,
      },
    }
    return (
      <RenderComp root="input" classNames={classNames} forwardedRef={ref} props={processedProps}>
        {children}
      </RenderComp>
    )
  },
)
