import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ClearSelectionButtonsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Button
 */
export const ClearSelectionButtons = renderWithRef<MinNativeRef, ClearSelectionButtonsProps>('ClearSelectionButtons', null, ({ children, ...rest }, ref) => {
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={['swan-clear-selection-buttons']} props={rest}>
      {children}
    </RenderComp>
  )
})
