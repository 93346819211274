import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { AspectRatioContainer } from '~/react/components/aspect-ratio/aspect-ratio-container.component'
import { AspectRatioContent } from '~/react/components/aspect-ratio/aspect-ratio-content.component'
import { renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The height / width ratio expressed as the result of dividing the content's height by its width.
   * e.g. 1) If your content is 16px wide and 9px tall, your ratio is 9 / 16 = 0.5625.
   * e.g. 2) If your content is a square, your ratio is 1.
   */
  ratio: PropTypes.number.isRequired,
}

export type AspectRatioProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>
export const AspectRatio = renderWithRef<MinNativeRef, AspectRatioProps>('AspectRatio', propTypes, ({ children, ...props }, ref) => {
  return (
    <AspectRatioContainer {...props} ref={ref}>
      <AspectRatioContent>{children}</AspectRatioContent>
    </AspectRatioContainer>
  )
})
