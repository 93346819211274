import { EventHandler, SyntheticEvent } from 'react'

export function composeEventHandlersUtil<T extends SyntheticEvent<unknown>>(...eventHandlers: Array<EventHandler<T> | null | undefined>): EventHandler<T> {
  return event => {
    eventHandlers.forEach(handler => {
      if (typeof handler === 'function' && !event.isDefaultPrevented()) {
        handler(event)
      }
    })
  }
}
