import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useFloatingLabelLabelProps } from '~/react/contexts/internal/floating-label/'

export type TextInputFloatingLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

/**
 * @subcomponent TextInput
 */
export const TextInputFloatingLabel = renderWithRef<MinNativeRef, TextInputFloatingLabelProps>('TextInputFloatingLabel', null, ({ children, ...props }, ref) => {
  const labelProps = useFloatingLabelLabelProps()

  return (
    <RenderComp root="label" forwardedRef={ref} props={{ ...props, ...labelProps }}>
      {children}
    </RenderComp>
  )
})
