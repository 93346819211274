import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type H1Props = CoreProps<JSX.IntrinsicElements['h1'], MinNativeRef>

export const H1 = renderWithRef<MinNativeRef, H1Props>('H1', null, (props, ref) => {
  const { children } = props
  const classNames = ['swan-heading']

  return (
    <RenderComp root="h1" forwardedRef={ref} classNames={classNames} props={{ fontSize: 'x4large', fontWeight: 'bold', ...props }}>
      {children}
    </RenderComp>
  )
})
