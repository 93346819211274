import PropTypes, { InferProps } from 'prop-types'
import { useEffect } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { useTabsContext } from './tabs.context'

export type TabContentProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

const propTypes = {
  tabId: PropTypes.string.isRequired,
}
const propKeysToRemove = Object.keys(propTypes)

/**
 * @subcomponent Tabs
 */
export const TabContent = renderWithRef<MinNativeRef, TabContentProps>('TabContent', propTypes, (props, ref) => {
  const { children, tabId, id: customId } = props

  const { selectedTabId, labelIds, registerContent, unregisterContent } = useTabsContext()

  const id = useId(customId)

  useEffect(() => {
    registerContent(tabId, id)

    return () => unregisterContent(tabId)
  }, [tabId, id, registerContent, unregisterContent])
  const classNames = new Set<string>([])
  if (tabId === selectedTabId) classNames.add('swan-tab-selected')

  return (
    <RenderComp
      root="div"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      classNames={classNames}
      props={{ ...props, id: id, role: 'tabpanel', 'aria-labelledby': labelIds[tabId] }}
    >
      {children}
    </RenderComp>
  )
})
