import { HTMLAttributes, KeyboardEvent, MutableRefObject, useRef } from 'react'

import { composeEventHandlersUtil, createClickOnEnterHandler, createClickOnSpaceHandler } from '~/react/utilities'

/**
 * Returns props that can be added to a non-button in order to make it act like a button (including accessibility concerns)
 * Also returns a ref that should be added to the non-button element in order to simulate clicks
 *
 * Note that this does not add any style/aesthetics to the element, it only handles accessibility concerns
 *
 * @param onKeyPress - the user-provided onKeyPress (will be augmented in order to make the button more accessible)
 *
 * usage:
 *
 * const [a11yRef, a11yProps] = useNonButtonElementAsButton(onKeyPress);
 *
 * return <span {...allyProps}>Assistive technologies know this is a button now!</span>
 */
export function useNonButtonElementAsButton<T extends HTMLElement>(onKeyPress?: (event: KeyboardEvent<T>) => void): [MutableRefObject<T | null>, HTMLAttributes<T>] {
  const elementRef = useRef<T | null>(null)

  // https://www.w3.org/TR/wai-aria-practices/examples/button/button.html
  // buttons need role="button", tabindex="0", and they can be activated with the space-bar and enter keys
  const a11yProps = {
    role: 'button',
    tabIndex: 0,
    onKeyPress: composeEventHandlersUtil(onKeyPress, createClickOnEnterHandler(elementRef), createClickOnSpaceHandler(elementRef)),
  }

  return [elementRef, a11yProps]
}

/**
 * Returns props that can be added to a non-anchor in order to make it act like an anchor (including accessibility concerns)
 * Also returns a ref that should be added to the non-anchor element in order to simulate clicks
 *
 * Note that this does not add any style/aesthetics to the element, it only handles accessibility concerns
 *
 * @param onKeyPress - the user-provided onKeyPress (will be augmented in order to make the anchor more accessible)
 *
 * usage:
 *
 * const [a11yRef, a11yProps] = useNonAnchorElementAsAnchor(onKeyPress);
 *
 * return <span {...allyProps}>Assistive technologies know this is an anchor now!</span>
 */
export function useNonAnchorElementAsAnchor<T extends HTMLElement>(onKeyPress: ((event: KeyboardEvent<T>) => void) | undefined): [MutableRefObject<T | null>, HTMLAttributes<T>] {
  const elementRef = useRef<T | null>(null)

  // https://www.w3.org/TR/wai-aria-practices/examples/link/link.html
  // links need role="link", tabindex="0", and they can be activated with the enter key
  const a11yProps = {
    role: 'link',
    tabIndex: 0,
    onKeyPress: composeEventHandlersUtil(onKeyPress, createClickOnEnterHandler(elementRef)),
  }

  return [elementRef, a11yProps]
}
