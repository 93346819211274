import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type H6Props = CoreProps<JSX.IntrinsicElements['h6'], MinNativeRef>

export const H6 = renderWithRef<MinNativeRef, H6Props>('H6', null, (props, ref) => {
  const { children } = props
  const classNames = ['swan-heading']

  return (
    <RenderComp root="h6" forwardedRef={ref} classNames={classNames} props={{ fontSize: 'standard', fontWeight: 'bold', ...props }}>
      {children}
    </RenderComp>
  )
})
