import { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useEffect } from 'react'
import warning from 'tiny-warning'

import { DeprecationProps } from './core.types'

export function deprecatedComponent<R, P extends DeprecationProps>({
  root: WrappedComponent,
  displayName,
  description,
}: {
  root: ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<R>>
  displayName: string
  description: string
}): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<R>> {
  const DeprecatedComponent = forwardRef<R, P>((props, forwardedRef) => {
    useEffect(() => {
      warning(props.__dangerouslySuppressWarning, `${displayName} is Deprecated. ${description}`)
    }, [props.__dangerouslySuppressWarning])
    const forwardedProps = props as PropsWithoutRef<P>
    return <WrappedComponent {...forwardedProps} ref={forwardedRef} />
  })
  DeprecatedComponent.displayName = `deprecated(${WrappedComponent.displayName})`
  return DeprecatedComponent
}
