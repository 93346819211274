import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BulletListIconProps = CoreProps<JSX.IntrinsicElements['img'], MinNativeRef>

/**
 * @subcomponent List
 */

export const BulletListIcon = renderWithRef<MinNativeRef, BulletListIconProps>('BulletListIcon', null, (props, ref) => (
  <RenderComp root="img" forwardedRef={ref} classNames={['swan-bullet-list-icon']} props={{ ...props, alt: '', role: 'presentation' }}>
    {props.children}
  </RenderComp>
))
