import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The visual style of the Left Hand Navigation.
   * One of: "standard", "button-menu".
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'button-menu'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type LeftHandNavigationProps = CoreProps<JSX.IntrinsicElements['nav'], MinNativeRef, InferProps<typeof propTypes>>

export const LeftHandNavigation = renderWithRef<MinNativeRef, LeftHandNavigationProps>('LeftHandNavigation', propTypes, (props, ref) => {
  const { children, skin = 'standard' } = props

  const classNames = new Set<string>(['swan-lhn'])
  if (skin && skin !== 'standard') classNames.add(`swan-lhn-skin-${skin}`)

  return (
    <RenderComp root="nav" forwardedRef={ref} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
      {children}
    </RenderComp>
  )
})
