import PropTypes, { InferProps } from 'prop-types'

import { getSwanImageUrl } from '~/core/utilities/manifest-images.utils'

import { MinNativeRef } from '~/react/components/core/core.types'

import { AlertBoxContext } from '~/react/components/alert-box/alert-box.context'
import { deprecatedProp, deprecatedPropValues, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { CoreProps } from '~/react/components'
import { useSwanContext } from '~/react/contexts'
import { useControllableValue } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * The visual style of the AlertBox
   * The 'standard' skin is deprecated; use 'info' instead
   * The 'positive' skin is deprecated; use 'success' instead
   *
   * @default info
   */
  skin: deprecatedPropValues(
    PropTypes.oneOf(['standard', 'info', 'warning', 'error', 'positive', 'success', 'legal-warning'] as const),
    ['standard', 'positive'],
    'Skin `standard` and `positive` are deprecated, instead use `info` and `success` respectively.',
  ),
  /**
   * The width of the AlertBox
   *
   * @deprecated
   * This is deprecated without a replacement.
   *
   * @default standard
   */
  width: deprecatedProp(PropTypes.oneOf(['standard', 'narrow'] as const), 'To maintain consistent user experience `width` is deprecated without a replacement.'),
  /**
   * Whether or not the alert is a toast
   *
   * @default false
   */
  toast: PropTypes.bool,
  /**
   * Whether or not the alert is presently dismissed
   */
  dismissed: PropTypes.bool,
  /**
   * Accessible text for the icon, that should be used for screen readers.
   * Typically it should indicate the type of alert eg. "info", "warning"
   */
  accessibleTextForIcon: PropTypes.string, // TODO: v4 make this props required
  /**
   * Callback fired when the user has requested that the alert be dismissed
   */
  onRequestDismiss: PropTypes.func as PropTypes.Requireable<() => void>,
}

const propKeysToRemove = Object.keys(propTypes)

export type AlertBoxProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const AlertBox = renderWithRef<MinNativeRef, AlertBoxProps>('AlertBox', propTypes, (props, ref) => {
  useComponentStylesLoaded('AlertBox', SWAN_STYLE_KEY_MAP.alertBox)

  const { swanPathType, swanBaseUrl } = useSwanContext()

  const { skin = 'info', width = 'standard', toast = false, children, accessibleTextForIcon, dismissed: controlledDismissed, onRequestDismiss: controlledOnRequestDismiss } = props

  const [dismissed, onRequestDismiss] = useControllableValue({
    value: controlledDismissed,
    onChange: controlledOnRequestDismiss || undefined,
    defaultValue: false,
    defaultOnChange: setValue => {
      setValue(true)
    },
  })

  if (dismissed) {
    return null
  }
  const classNames = new Set(['swan-vanilla-ignore', 'swan-alert-box'])
  if (skin && skin !== 'info' && skin !== 'standard') classNames.add(`swan-alert-box-skin-${skin}`)
  if (width === 'narrow') classNames.add(`swan-alert-box-narrow`)
  if (toast) classNames.add(`swan-alert-box-toast`)

  const warningImgUrl = getSwanImageUrl('legalWarning', swanPathType, swanBaseUrl)

  let iconProps = {}
  if (accessibleTextForIcon) {
    iconProps = {
      role: 'img',
      'aria-label': accessibleTextForIcon,
    }
  }

  return (
    <AlertBoxContext.Provider value={{ onRequestDismiss }}>
      <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ ...props, role: 'alert' }}>
        {skin === 'legal-warning' && warningImgUrl ? (
          <img src={warningImgUrl} alt={accessibleTextForIcon || ''} />
        ) : (
          <span {...iconProps} className={`swan-alert-box-icon swan-alert-box-icon-${skin}`} />
        )}
        {children}
      </RenderComp>
    </AlertBoxContext.Provider>
  )
})
