import { ChangeEvent, createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type ButtonbarSelectedValue = string | null | undefined
export type ButtonbarSelectedValues = string[]

export const ButtonbarContext = createContext<
  | null
  | undefined
  | {
      name: string | undefined | null
      variant: 'single-select' | 'multi-select' | 'toolbar' | null
      selectedValue: ButtonbarSelectedValue
      onSelectedValueChange: (value: NonNullable<ButtonbarSelectedValue>, event: ChangeEvent<HTMLInputElement>) => void
      selectedValues: ButtonbarSelectedValues
      onSelectedValuesChange: (selectedValues: NonNullable<ButtonbarSelectedValues>, event: ChangeEvent<HTMLInputElement>) => void
    }
>(null)

export const useButtonbarContext = () => {
  return useNonNullishContext(ButtonbarContext)
}

export const ButtonbarButtonContext = createContext<
  | {
      inputId?: string | null
    }
  | undefined
  | null
>(undefined)

export const useButtonbarButtonContext = () => {
  return useNonNullishContext(ButtonbarButtonContext)
}
