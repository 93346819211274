import PropTypes, { InferProps } from 'prop-types'
import type { ReactElement, ReactNode } from 'react'
import { Children, cloneElement, isValidElement, useMemo, useRef, useState } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { BreadcrumbList } from './breadcrumb-list.component'

const propTypes = {
  /**
   * Set accessible text for the "more" button
   */
  accessibleTextForMore: PropTypes.string,
}
const propKeysToRemove = Object.keys(propTypes)

export type BreadcrumbsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

const classNames = new Set(['swan-breadcrumbs'])

export const Breadcrumbs = renderWithRef<MinNativeRef, BreadcrumbsProps>('Breadcrumbs', propTypes, (props, ref) => {
  const [isBreadcrumbListExpanded, setIsBreadcrumbListExpanded] = useState<boolean>(false)

  const thisId: string | undefined = useId()

  const secondBreadcrumbRef = useRef()

  // Clone each child so we can assign each breadcrumb a unique id
  const breadcrumbs = useMemo(
    () =>
      Children.map(props.children, (child, index) => {
        if (isValidElement(child)) {
          const id = child.props.id || (thisId ?? 'breadcrumb-') + index
          if (index === 1) {
            return cloneElement(child as ReactElement, { id, ref: secondBreadcrumbRef })
          } else {
            return cloneElement(child as ReactElement, { id })
          }
        }
        return child
      }),
    [props.children, thisId],
  )

  const breadcrumbsList: Array<Exclude<ReactNode, boolean | null | undefined>> = Children.toArray(breadcrumbs)

  const breadcrumbIds: string[] = breadcrumbsList.map((breadcrumb: ReactNode) => (isValidElement(breadcrumb) ? breadcrumb.props.id : null))

  function showMore() {
    setIsBreadcrumbListExpanded(true)

    // for a11y, move focus to the breadcrumb that is now showing where the More button was.
    // wait 500ms for assistive technologies to update their a11y tree
    setTimeout(() => {
      if (secondBreadcrumbRef.current === undefined) return

      const thisBreadcrumb = secondBreadcrumbRef.current as Element

      if (thisBreadcrumb.children && thisBreadcrumb.children.length > 0) {
        const thisLink = thisBreadcrumb.children[0] as HTMLElement
        thisLink.focus()
      }
    }, 500)
  }

  return (
    <RenderComp root="nav" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ ...props }}>
      {props.accessibleTextForMore && breadcrumbIds.length > 4 ? (
        <BreadcrumbList className={isBreadcrumbListExpanded ? '' : 'swan-breadcrumbs-collapsed'}>
          {breadcrumbsList[0]}
          <li className="swan-breadcrumbs-button-more">
            <button aria-label={props.accessibleTextForMore} aria-expanded="false" onClick={showMore} aria-controls={breadcrumbIds.slice(1, -2).join(' ')}>
              ...
            </button>
          </li>
          {breadcrumbsList.slice(1)}
        </BreadcrumbList>
      ) : (
        <BreadcrumbList>{breadcrumbs}</BreadcrumbList>
      )}
    </RenderComp>
  )
})
