import PropTypes, { InferProps } from 'prop-types'
import type { ReactNode } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { VisuallyHidden } from '~/react/components/visually-hidden'

import { composeEventHandlersUtil } from '~/react/utilities'

import { useAlertBoxContext } from './alert-box.context'

const propTypes = {
  /**
   * @deprecated
   * Use `accessibleText` instead
   *
   * A localized label which describes the dismiss button to screen-reader users
   *
   * Typically it is just a string like "Close" or "Dismiss alert" but can accept a ReactNode as well
   */
  visuallyHiddenLabel: deprecatedProp(PropTypes.node, 'Use `accessibleText` instead'),
  /**
   * A localized label which describes the dismiss button to screen-reader users
   *
   * Typically it is just a string like "Close" or "Dismiss alert"
   */
  accessibleText: PropTypes.string,
}
const propKeysToRemove = Object.keys(propTypes)

export type AlertBoxDismissButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * Close button for the AlertBox
 *
 * @subcomponent AlertBox
 */
export const AlertBoxDismissButton = renderWithRef<MinNativeRef, AlertBoxDismissButtonProps>('AlertBoxDismissButton', propTypes, (props, ref) => {
  const classNames = new Set(['swan-alert-box-close-button'])
  const { onRequestDismiss } = useAlertBoxContext()
  const { visuallyHiddenLabel, accessibleText, children, onClick } = props
  const accessibleNode = accessibleText !== null && accessibleText !== undefined ? accessibleText : visuallyHiddenLabel

  return (
    // TODO: v4 replace it with the Tertiary Button
    <RenderComp
      root="button"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      classNames={classNames}
      props={{ ...props, ...{ onClick: composeEventHandlersUtil(onClick, () => typeof onRequestDismiss === 'function' && onRequestDismiss()) } }}
    >
      {accessibleNode !== null && <VisuallyHidden>{accessibleNode as ReactNode}</VisuallyHidden>}
      {children}
    </RenderComp>
  )
})
