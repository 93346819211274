import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ButtonDescriptionProps = CoreProps<JSX.IntrinsicElements['span']>

/**
 * @subcomponent Button
 */
export const ButtonDescription = renderWithRef<MinNativeRef, ButtonDescriptionProps>('ButtonDescription', null, ({ children, ...rest }, ref) => {
  return (
    <RenderComp root="span" forwardedRef={ref} classNames={['swan-button-description']} props={rest}>
      {children}
    </RenderComp>
  )
})
