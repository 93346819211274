import { useEffect, useState } from 'react'

// our generateId implementation is pretty simple: it just increments a number
// this works fine until there are 2+ generateId functions on a single page
// when there are multiple generateId functions on the page (e.g. multiple versions of this lib)
// we end up with duplicate ids
// `signature` solves that issue by creating a pseudo-random identifier for each "instance" of the generateId function
// so even if we have 2+ generateId functions on the page, they won't collide because they each have their own `signature`
// technically, 2 generateId functions could end up with the same signature, but it's really unlikely
// with this implementation; we usually don't see any collisions until about 10k runs
// please do not have 10k instances of this library on one page
let signature = Math.random().toString(36).substring(2, 11)
let id = 0

export function setAutoIdSignature(newSignature: string) {
  signature = newSignature
}

export function resetAutoId(newSignature: string) {
  signature = newSignature
  id = 0
}

// rather than trying to get super random in order to guarantee uniqueness, we just increment the id every time a new uniqueId is requested
// technically this will break if we reach MaxInt components, but that's probably never going to be an issue
// the instanceId is prepended so that this doesn't break when multiple instances of useAutoId are included on the same page

function generateId() {
  id += 1

  return `auto-id-${signature}-${id}`
}

/**
 * Creates a unique identifier for the component
 */
export const useId = (customId?: string | null) => {
  const [autoId, setAutoId] = useState<string | undefined>(customId ?? undefined)
  /**
   * Takes an optional input id of type string and returns an id for your React component. If an id is provided, it will return the same, else it will generate a random id and return that.
   *
   * In order to support SSR, we need the ids that we generate on the server to match the ids the initial client render.
   *
   * Ideally, we'd find some cool way to deterministically generate ids so that we'd always get the same id on the server and on the client, but that's hard.
   *
   * Instead, we'll skirt around the issue by returning undefined for the id on the server and during the initial client render.
   *
   * All ids will be patched-in on the second client render.
   *
   * They should exist in the DOM by the time anyone needs them (e.g. assistive tech or someone clicks on the label for an input)
   *
   * @param {string} customId - optional. If not provided, useEffect will return a random id instead
   */
  useEffect(() => {
    setAutoId(prevId => {
      if (prevId) return prevId
      return generateId()
    })
  }, [])

  return autoId
}
