import { AriaMenuOptions } from '@react-aria/menu'
import { TreeState } from '@react-stately/tree'
import { createContext, RefObject, useMemo } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type SwanMenuListContextValue = {
  menuState: TreeState<object>
  menuProps: AriaMenuOptions<unknown>
  menuRef: RefObject<HTMLUListElement>
}

export function useMenuList(menuProps: Partial<AriaMenuOptions<unknown>>, menuRef: RefObject<HTMLUListElement>, menuState: TreeState<object>) {
  return useMemo<SwanMenuListContextValue>(
    () => ({
      menuState: menuState,
      menuProps: {
        ...menuProps,
      },
      menuRef,
    }),
    [menuState, menuProps, menuRef],
  )
}

export const SwanMenuListContext = createContext<SwanMenuListContextValue | null | undefined>(undefined)

export function useSwanMenuListContext() {
  return useNonNullishContext(SwanMenuListContext)
}
