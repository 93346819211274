import PropTypes, { InferProps } from 'prop-types'
import { useEffect, useRef } from 'react'

import { assignRefs } from '~/core/utilities'

import { CoreInputProps } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * An indeterminate checkbox means that its state is unclear.
   * Typically, this is used when the checkbox has some sub-options under it.
   *
   * @default false
   */
  indeterminate: PropTypes.bool,
  /**
   * If set to true, the checkbox will animate instantly.
   * @default false
   */
  instant: PropTypes.bool,
  /**
   * The visual style of the Checkbox.
   * One of: "standard", "favourite".
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'favorite'] as const),
}
const propKeysToRemove = Object.keys(propTypes)

export type CheckboxProps = CoreProps<CoreInputProps, HTMLInputElement, InferProps<typeof propTypes>>

export const Checkbox = renderWithRef<HTMLInputElement, CheckboxProps>('Checkbox', propTypes, (props, ref) => {
  const { instant = false, skin = 'standard', indeterminate = false } = props

  const classNames = new Set(['swan-checkbox'])
  if (skin == 'favorite') {
    classNames.add('swan-checkbox-skin-favorite')
  }
  if (instant) {
    classNames.add('swan-checkbox-instant')
  }

  const inputRef = useRef<HTMLInputElement>()
  const finalRef = assignRefs(inputRef, ref)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate
    }
  }, [indeterminate])

  return (
    // TODO: v4 discuss below should it be props={{ ...props, type: 'checkbox' }}
    <RenderComp root="input" forwardedRef={finalRef} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ type: 'checkbox', ...props }}>
      {props.children}
    </RenderComp>
  )
})
