import { useMenuTrigger } from '@react-aria/menu'
import { MenuTriggerState, useMenuTriggerState } from '@react-stately/menu'
import { useTreeState } from '@react-stately/tree'
import { ReactNode, useMemo, useRef } from 'react'

import { SwanMenuListContext, useMenuList } from '~/react/components/menu/menu-list.context'
import { SwanPopoverContextProvider, SwanPopoverContextValue, useSwanPopover } from '~/react/components/popover'

import { useCollectionContext } from '~/react/contexts/internal/collection'
import { useModeExtractor } from '~/react/hooks/use-mode-extractor'

import { SwanMenuTriggerContext, SwanMenuTriggerContextValue } from './menu-trigger.context'

export type SwanMenuContextProviderProps = {
  children?: ReactNode
  onAction?: (key: string | number) => void
}

function SwanMenuContextProviderInner(props: SwanMenuContextProviderProps) {
  const { children, onAction } = props

  const { triggerRef, triggerProps, overlayState } = useSwanPopover()

  const { menuTriggerProps, menuProps } = useMenuTrigger(
    {
      ...triggerProps,
      type: 'menu',
    },
    overlayState as MenuTriggerState,
    triggerRef,
  )

  const menuTriggerContextValue = useMemo<SwanMenuTriggerContextValue>(
    () => ({
      triggerProps: menuTriggerProps,
      triggerDisabled: false,
    }),
    [menuTriggerProps],
  )

  const menuRef = useRef(null)
  const { childItems, items, disabledKeys } = useCollectionContext()
  const menuState = useTreeState({ ...menuProps, items, disabledKeys, children: childItems })
  const menuListContextValue = useMenuList({ ...menuProps, onAction }, menuRef, menuState)

  return (
    <SwanMenuTriggerContext.Provider value={menuTriggerContextValue}>
      <SwanMenuListContext.Provider value={menuListContextValue}>{children}</SwanMenuListContext.Provider>
    </SwanMenuTriggerContext.Provider>
  )
}

export function SwanMenuContextProvider(props: SwanMenuContextProviderProps) {
  const menuTriggerState = useMenuTriggerState({})
  const popoverRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLElement>(null)
  const modes = useModeExtractor(triggerRef, menuTriggerState.isOpen)

  const popoverContext = useMemo<SwanPopoverContextValue>(
    () => ({
      popoverRef,
      triggerRef,
      overlayState: menuTriggerState,
      overlayProps: {},
      triggerProps: {},
      modes,
    }),
    [popoverRef, triggerRef, menuTriggerState, modes],
  )

  return (
    <SwanPopoverContextProvider value={popoverContext}>
      <SwanMenuContextProviderInner {...props} />
    </SwanPopoverContextProvider>
  )
}
