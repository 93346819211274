import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'

import { useNonNullishFormFieldContext } from './form.context'

const propTypes = {
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * The skin for the label. Line is intended to be used with the line skin on a form input component
   * @default standard
   */
  skin: deprecatedProp(PropTypes.oneOf(['standard', 'line'] as const), 'With the new visual language, this prop has become redundant.'),
}

const propKeysToRemove = Object.keys(propTypes)

export type FormLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent StandardForm
 */
export const FormLabel = renderWithRef<MinNativeRef, FormLabelProps>('FormLabel', null, ({ children, skin, ...props }, ref) => {
  const { inputId } = useNonNullishFormFieldContext()
  const classNames = ['swan-label']
  if (skin === 'line') classNames.push('swan-label-skin-line')
  return (
    <RenderComp root="label" forwardedRef={ref} props={{ ...props, htmlFor: inputId }} propKeysToRemove={propKeysToRemove} classNames={classNames}>
      {children}
    </RenderComp>
  )
})
