import PropTypes, { InferProps } from 'prop-types'
import { useState } from 'react'
import warning from 'tiny-warning'

import { Nullable } from '~/core/types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { useAccordionContext } from '~/react/components/accordion'
import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useControllableValue } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { CollapsibleContext } from './collapsible.context'

const propTypes = {
  /**
   * Whether or not the collapsible is open, this makes the component controlled
   */
  expanded: PropTypes.bool,
  /**
   * The event fired when the collapsible's expanded state changes
   */
  onRequestExpandedChange: PropTypes.func as PropTypes.Requireable<(expanded: boolean) => void>,
  /**
   * Used to unset the left and right padding of the Collapsible
   * @default false
   */
  fullBleed: PropTypes.bool,
  /**
   * The default expanded state of the Collapsible - used for uncontrolled components
   *
   * @default false
   */
  defaultExpanded: PropTypes.bool,
  /**
   * Used when a collapsible is used inside of an Accordion for tracking the open state of each panel
   */
  collapsibleId: PropTypes.string,
  /**
   * Whether or not the collapsible can be expanded or collapsed
   *
   * @default false
   */
  disabled: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type DefaultNativeProps = JSX.IntrinsicElements['div'] | JSX.IntrinsicElements['li']

export type CollapsibleProps = CoreProps<DefaultNativeProps, MinNativeRef, InferProps<typeof propTypes>>

const useCollapsibleContextProps = (props: CollapsibleProps) => {
  const { expanded: controlledExpanded, onRequestExpandedChange: controlledRequestExpandedChange, defaultExpanded = false, collapsibleId, disabled = false } = props

  const [contentId, setContentId] = useState<string | undefined | null>()
  const accordionContext = useAccordionContext()
  const accordionMode = accordionContext !== undefined

  // when used inside of an Accordion, we need to manage the Collapsible's state at the root (to support `multiple` behavior)
  warning(
    !accordionMode || (controlledExpanded === undefined && controlledRequestExpandedChange === undefined),
    'When a Collapsible is used inside of an Accordion, you must provide the expanded-state to the Accordion component rather than directly to the Collapsible',
  )

  warning(!(accordionMode && collapsibleId === undefined), 'When a Collapsible is used inside of an Accordion, the Collapsible must have a `collapsibleId`')

  const [localExpanded, localOnRequestExpandedChange] = useControllableValue<Nullable<boolean>, [boolean]>({
    value: controlledExpanded,
    onChange: controlledRequestExpandedChange || undefined,
    defaultValue: defaultExpanded,
    defaultOnChange: (setUncontrolledExpanded, expanded) => setUncontrolledExpanded(expanded),
  })

  let expanded = !!localExpanded
  let onRequestExpandedChange = localOnRequestExpandedChange

  if (accordionMode) {
    expanded = !!accordionContext?.expandedCollapsibleMap[collapsibleId!]
    onRequestExpandedChange = isExpanded => {
      accordionContext?.requestExpandedChange(collapsibleId!, isExpanded)
    }
  }

  return {
    expanded,
    onRequestExpandedChange,
    contentId,
    setContentId,
    disabled,
    purpose: accordionContext?.purpose,
  }
}

const displayName = 'Collapsible'
export const Collapsible = renderWithRef<MinNativeRef, CollapsibleProps>(displayName, propTypes, (props, ref) => {
  useComponentStylesLoaded(displayName, SWAN_STYLE_KEY_MAP.accordion)

  const { disabled = false, fullBleed = false } = props

  const classNames = new Set(['swan-vanilla-ignore', 'swan-collapsible'])
  if (disabled) classNames.add('swan-collapsible-disabled')
  if (fullBleed) classNames.add('swan-collapsible-full-bleed')

  const contextProps = useCollapsibleContextProps(props)

  const processedProps = {
    ...props,
    'aria-disabled': !!props.disabled,
    as: props.as || props.component || (contextProps?.purpose === 'list' ? 'li' : 'div'),
  }

  return (
    <CollapsibleContext.Provider value={{ ...contextProps }}>
      <RenderComp root="div" forwardedRef={ref} classNames={classNames} propKeysToRemove={propKeysToRemove} props={processedProps}>
        {props.children}
      </RenderComp>
    </CollapsibleContext.Provider>
  )
})
