import { AriaListBoxOptions } from '@react-aria/listbox'
import { ListState } from '@react-stately/list'
import { createContext, RefObject, useMemo } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type ListboxSelectedValues = 'all' | Set<string | number>

export type SwanListboxSelectionProps = {
  selectionMode?: 'single' | 'multiple'
  selectedKeys?: ListboxSelectedValues
  onSelectionChange?: (key: ListboxSelectedValues) => void
}

export type SwanListboxListContextValue = {
  listState: ListState<object>
  listProps: AriaListBoxOptions<object>
  listboxRef: RefObject<HTMLUListElement>
}

export function useListboxList(listProps: Partial<AriaListBoxOptions<object>>, listboxRef: RefObject<HTMLUListElement>, listboxState: ListState<object>) {
  return useMemo<SwanListboxListContextValue>(
    () => ({
      listState: listboxState,
      listProps: {
        disallowEmptySelection: true,
        shouldFocusOnHover: true,
        shouldSelectOnPressUp: true,
        ...listProps,
      },
      listboxRef,
    }),
    [listboxState, listProps, listboxRef],
  )
}

export const SwanListboxListContext = createContext<SwanListboxListContextValue | null | undefined>(undefined)

export function useSwanListboxListContext() {
  return useNonNullishContext(SwanListboxListContext)
}
