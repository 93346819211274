import PropTypes, { InferProps } from 'prop-types'
import { Children, cloneElement, isValidElement, ReactElement } from 'react'

import { tokensRaw as tokens } from '~/core/tokens/tokens-raw'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { Carousel, CarouselSlide } from '~/react/components/carousel'
import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { ThumbnailsHeroVideoThumbnailSlide } from '~/react/components/thumbnails-hero/thumbnails-hero-video-thumbnail-slide.component'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { ThumbnailsHeroBackgroundContainer } from './thumbnails-hero-background-container.component'
import { ThumbnailsHeroContentContainer } from './thumbnails-hero-content-container.component'
import { ThumbnailsHeroImagesSlidesContainer } from './thumbnails-hero-images-slides-container.component'
import { ThumbnailsHeroThumbnailsSlidesContainer } from './thumbnails-hero-thumbnails-slides-container.component'

const propTypes = {
  /**
   * The index of the slide to select
   *
   * @default 0
   */
  currentSlide: PropTypes.number,
  /**
   * A callback fired when the ThumbnailsHero changes the selected slide.
   * It is passed the index of the currently selected slide and the index of the slide it will change to
   */
  onSlideChange: PropTypes.func as PropTypes.Requireable<(currentIndex: number, targetIndex: number) => void>,
  /**
   * Provide accessible text for slide controllers
   */
  accessibleTextForDots: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
const propKeysToRemove = Object.keys(propTypes)

export type ThumbnailsHeroProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>
export const ThumbnailsHero = renderWithRef<MinNativeRef, ThumbnailsHeroProps>('ThumbnailsHero', propTypes, (props, ref) => {
  useComponentStylesLoaded('ThumbnailsHero', SWAN_STYLE_KEY_MAP.thumbnailsHero)

  const { currentSlide = 0, accessibleTextForDots, onSlideChange, children } = props

  const thumbnailsArray: Array<ReactElement> = []
  const imagesArray: Array<ReactElement> = []
  let backgroundContainer = null
  let contentContainer = null

  Children.forEach(children, child => {
    if (!isValidElement(child)) return
    switch (child.type) {
      case ThumbnailsHeroThumbnailsSlidesContainer:
        thumbnailsArray.push(
          ...Children.map(child.props.children, (grandChild, index) =>
            [CarouselSlide, ThumbnailsHeroVideoThumbnailSlide].includes(grandChild.type)
              ? // eslint-disable-next-line react/no-array-index-key
                cloneElement(grandChild, { key: index, component: grandChild.props.component || 'span' })
              : null,
          ),
        )
        break
      case ThumbnailsHeroImagesSlidesContainer:
        imagesArray.push(
          // eslint-disable-next-line react/no-array-index-key
          ...Children.map(child.props.children, (grandChild, index) => (grandChild.type === CarouselSlide ? cloneElement(grandChild, { key: index }) : null)),
        )
        break
      case ThumbnailsHeroBackgroundContainer:
        backgroundContainer = child
        break
      case ThumbnailsHeroContentContainer:
        contentContainer = child
        break
      default:
      // console.log('Unknown', child)
    }
  })

  const classNames = new Set<string>(['swan-thumbnails-hero'])

  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
      {backgroundContainer}
      <div className="swan-thumbnails-hero-images-carousel-container">
        <Carousel
          slidesToScroll={1}
          slidesToShow={1}
          arrows={false}
          accessibleTextForNext=""
          accessibleTextForPrevious=""
          accessibleTextForDots={accessibleTextForDots}
          customPaging={index => (
            <button aria-label={accessibleTextForDots[index]} key={index}>
              {thumbnailsArray[index]}
            </button>
          )}
          progressIndicator={'dots'}
          progressIndicatorAlignment={'left'}
          currentSlide={currentSlide ?? 0}
          beforeChange={onSlideChange ?? undefined}
          fade
          responsive={[
            {
              breakpoint: parseInt(tokens.SwanBaseBreakpointMdStart, 10),
              settings: {
                fade: false,
              },
            },
          ]}
        >
          {imagesArray}
        </Carousel>
      </div>
      {contentContainer}
    </RenderComp>
  )
})
