import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type TextInputWithButtonBesideProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent TextInput
 */
export const TextInputWithButtonBeside = renderWithRef<MinNativeRef, TextInputWithButtonBesideProps>('TextInputWithButtonBeside', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="div" classNames={['swan-vanilla-ignore', 'swan-input-with-button-beside']} forwardedRef={ref} props={{ role: 'group', ...props }}>
      {children}
    </RenderComp>
  )
})
