import { AriaButtonProps } from '@react-aria/button'
import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type SwanListboxButtonContextValue = {
  triggerProps: AriaButtonProps<'button'>
  triggerDisabled: boolean
}

export const SwanListboxButtonContext = createContext<SwanListboxButtonContextValue | null | undefined>(undefined)

export function useSwanListboxButtonContext() {
  return useNonNullishContext(SwanListboxButtonContext)
}
