import { Dispatch, SetStateAction, useState } from 'react'

type ControllableStateConfiguration<T, OnChangeArgs extends Array<unknown>> = {
  value: T | undefined
  onChange: ((...args: OnChangeArgs) => void) | undefined
  defaultValue: T
  defaultOnChange: (setValue: Dispatch<SetStateAction<T>>, ...args: OnChangeArgs) => void
}

/**
 * When your component needs to be able to operate in controlled _or_ uncontrolled mode, you need to use the controlled value and onChange if they exist, and use some internal state if they don't.
 *
 * This hook handles that for you.
 *
 * Usage:
 *
 * Pass in the user-defined value and onChange (which may be undefined)
 * As well as the initialValue and onChange to use in case there's no user-defined value
 *
 * You'll get back a [value, onChange] that you can use throughout the component
 *
 * @param config.value - the controlled value which might be undefined
 * @param config.onChange - the setter for the controlled value which might be undefined
 * @param config.defaultValue - the initial value if the state is not controlled
 * @param config.defaultOnChange - the setter for the uncontrolled value; it will be passed a setUncontrolledValue fn that can directly manipulate the state
 * @returns [value, onChange]
 */
export function useControllableValue<T, OnChangeArgs extends Array<unknown> = []>(
  configuration: ControllableStateConfiguration<T, OnChangeArgs>,
): [T, (...args: OnChangeArgs) => void] {
  const { value, onChange, defaultValue, defaultOnChange } = configuration

  const isControlled = value !== undefined

  const [uncontrolledValue, setUncontrolledValue] = useState<T>(defaultValue)

  // use the controlled value if we're in "controlled" mode, otherwise use the uncontrolled value
  // if `isControlled` is true, `value` is must be defined
  // C'mon TypeScript, we shouldn't need to assert here...
  const officialValue = isControlled ? (value as T) : uncontrolledValue

  let officialOnChange: (...args: OnChangeArgs) => void
  if (isControlled) {
    // if a controlled onChange isn't provided in controlled-mode, the value is read-only

    // we'll just use a no-op function to represent the read-only nature of the value
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    officialOnChange = onChange !== undefined ? onChange : () => {}
  } else {
    officialOnChange = (...args: OnChangeArgs) => {
      defaultOnChange(setUncontrolledValue, ...args)

      // it's possible that a controlled onChange was provided even though the controlled value was _not_ provided
      // that's fine! We'll update our internal value with the uncontrolled onChange, and then invoke the controlled onChange too (which will not affect our state)
      if (onChange !== undefined) {
        onChange(...args)
      }
    }
  }

  return [officialValue, officialOnChange]
}
