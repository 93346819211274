import PropTypes, { InferProps } from 'prop-types'
import { ComponentType, FC, Fragment, useMemo } from 'react'

import { SWAN_BASE_CDN_URL } from '~/core/constants'
import { MANIFEST_FONTS } from '~/core/manifest/fonts'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types'
import { isNonNullish } from '~/core/utilities/is.utils'
import { getSameKeyValueMapForManifest, manifestResolve } from '~/core/utilities/manifest.utils'

import { swanLoaderConfigProps } from '~/react/contexts/swan-provider/swan-provider.context'

import { useSwanHeadConfig } from './head.hook'

export type SwanFontKey = keyof typeof MANIFEST_FONTS
export const SWAN_FONT_KEY_MAP = getSameKeyValueMapForManifest(MANIFEST_FONTS)

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum SwanFontNameEnum {
  // TODO: deprecate referring to font directly (e.g. Graphik) instead of hierarchy (e.g. Primary)
  // Default Font
  Graphik = 'Graphik',
  GraphikMedium = 'GraphikMedium',
  GraphikRegular = 'GraphikRegular',
  // Special Font
  Tiempos = 'Tiempos',
  TiemposRegular = 'TiemposRegular',

  // Primary
  Primary = 'Graphik',
  PrimaryBold = 'GraphikMedium',
  PrimaryRegular = 'GraphikRegular',
  // Secondary
  Secondary = 'Tiempos',
  SecondaryRegular = 'TiemposRegular',
}

export type SwanFontName = keyof typeof SwanFontNameEnum

/* eslint-enable @typescript-eslint/no-duplicate-enum-values */
const propTypes = {
  fontNames: PropTypes.arrayOf(
    PropTypes.oneOf([
      SwanFontNameEnum.Graphik,
      SwanFontNameEnum.GraphikMedium,
      SwanFontNameEnum.GraphikRegular,
      SwanFontNameEnum.Tiempos,
      SwanFontNameEnum.TiemposRegular,
      SwanFontNameEnum.Primary,
      SwanFontNameEnum.PrimaryBold,
      SwanFontNameEnum.PrimaryRegular,
      SwanFontNameEnum.Secondary,
      SwanFontNameEnum.SecondaryRegular,
    ] as const).isRequired,
  ),
  renderStyleContentAsChildren: PropTypes.bool,
  ...swanLoaderConfigProps,
}
export type SwanFontsProps = InferProps<typeof propTypes> & {
  renderWith?: ComponentType<unknown>
}

export const SwanFonts: FC<SwanFontsProps> = ({ fontNames, swanPathType, swanBaseUrl, swanTenant, swanLocale, renderWith: Comp = Fragment, renderStyleContentAsChildren }) => {
  const { swanBaseUrl: baseUrl, swanPathType: pathType } = useSwanHeadConfig(swanBaseUrl, swanPathType, swanTenant, swanLocale)
  const [fontPreloadUrls, styleSheetContent] = useMemo(() => {
    return getSwanFontRenderConfig(fontNames || undefined, pathType, baseUrl)
  }, [fontNames, pathType, baseUrl])
  return (
    <Comp>
      {fontPreloadUrls.map(url => (
        <link rel="preload" crossOrigin="anonymous" as="font" href={url} key={url} />
      ))}
      {renderStyleContentAsChildren ? (
        <style type="text/css" key="swan-font-config-children">
          {styleSheetContent}
        </style>
      ) : (
        <style type="text/css" key="swan-font-config-inner-html" dangerouslySetInnerHTML={{ __html: styleSheetContent }} />
      )}
    </Comp>
  )
}

export function getSwanFontUrl(key: SwanFontKey, pathType?: SwanPathTypeEnum, swanAssetBaseUrl?: string) {
  return manifestResolve(MANIFEST_FONTS, key, SwanAssetFolders.FONTS, pathType, swanAssetBaseUrl)
}

export function getSwanFontRenderConfig(
  fontNames: SwanFontNameEnum[] = [SwanFontNameEnum.Graphik],
  pathType: SwanPathTypeEnum = SwanPathTypeEnum.hashed,
  swanAssetBaseUrl: string = SWAN_BASE_CDN_URL,
): [string[], string] {
  const preLoadUrls: string[] = fontNames.flatMap(name => getPreLoadUrlsForFontName(name, pathType, swanAssetBaseUrl)).filter(isNonNullish)
  const configString = fontNames.map(name => getSwanFontStyleConfig(name, pathType, swanAssetBaseUrl)).join('\n')
  return [preLoadUrls, configString]
}

function getPreLoadUrlsForFontName(name: SwanFontNameEnum, pathType: SwanPathTypeEnum, swanAssetBaseUrl: string): (string | null)[] {
  switch (name) {
    case SwanFontNameEnum.Graphik:
    case SwanFontNameEnum.Primary:
      return [SwanFontNameEnum.GraphikRegular, SwanFontNameEnum.GraphikMedium].flatMap(n => getPreLoadUrlsForFontName(n, pathType, swanAssetBaseUrl))
    case SwanFontNameEnum.GraphikMedium:
    case SwanFontNameEnum.PrimaryBold:
      return [getSwanFontUrl(SWAN_FONT_KEY_MAP.graphicMedium2, pathType, swanAssetBaseUrl)]
    case SwanFontNameEnum.GraphikRegular:
    case SwanFontNameEnum.PrimaryRegular:
      return [getSwanFontUrl(SWAN_FONT_KEY_MAP.graphicRegular2, pathType, swanAssetBaseUrl)]
    case SwanFontNameEnum.Tiempos:
    case SwanFontNameEnum.Secondary:
      return [SwanFontNameEnum.TiemposRegular].flatMap(n => getPreLoadUrlsForFontName(n, pathType, swanAssetBaseUrl))
    case SwanFontNameEnum.TiemposRegular:
    case SwanFontNameEnum.SecondaryRegular:
      return [getSwanFontUrl(SWAN_FONT_KEY_MAP.tiemposRegular2, pathType, swanAssetBaseUrl)]
    default:
      return [null]
  }
}

function getSwanFontStyleConfig(name: SwanFontNameEnum, pathType: SwanPathTypeEnum, swanAssetBaseUrl: string): string {
  const fontUrlForKey = (key: SwanFontKey) => getSwanFontUrl(key, pathType, swanAssetBaseUrl) || ''
  switch (name) {
    case SwanFontNameEnum.Graphik:
    case SwanFontNameEnum.Primary:
      return [SwanFontNameEnum.GraphikRegular, SwanFontNameEnum.GraphikMedium].map(n => getSwanFontStyleConfig(n, pathType, swanAssetBaseUrl)).join('\n')
    case SwanFontNameEnum.Tiempos:
    case SwanFontNameEnum.Secondary:
      return [SwanFontNameEnum.TiemposRegular].map(n => getSwanFontStyleConfig(n, pathType, swanAssetBaseUrl)).join('\n')
    case SwanFontNameEnum.GraphikMedium:
    case SwanFontNameEnum.PrimaryBold:
      return `
        @font-face {
          font-family: 'Graphik';
          font-stretch: normal;
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${fontUrlForKey(SWAN_FONT_KEY_MAP.graphicMedium2)}') format('woff2'),
            url('${fontUrlForKey(SWAN_FONT_KEY_MAP.graphicMedium)}') format('woff');
        }`
    case SwanFontNameEnum.GraphikRegular:
    case SwanFontNameEnum.PrimaryRegular:
      return `
      @font-face {
        font-family: 'Graphik';
        font-stretch: normal;
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${fontUrlForKey(SWAN_FONT_KEY_MAP.graphicRegular2)}') format('woff2'),
          url('${fontUrlForKey(SWAN_FONT_KEY_MAP.graphicRegular)}') format('woff');
      }`
    case SwanFontNameEnum.TiemposRegular:
    case SwanFontNameEnum.SecondaryRegular:
      return `
      @font-face {
        font-family: 'Tiempos';
        font-stretch: normal;
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${fontUrlForKey(SWAN_FONT_KEY_MAP.tiemposRegular2)}') format('woff2');
      }
      `
    default:
      return ``
  }
}
