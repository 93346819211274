import { PropsWithChildren, PropsWithoutRef, ReactElement, ReactNode } from 'react'

import { IdentityTypeFunction } from '~/core/types'
import { className, isValidStyleProperty, iterToSet, processStyleProps } from '~/core/utilities'

import { PropConfig } from './core.types'

/**
 * @deprecated Will be removed in SWAN 4 without replacement
 */
export function getDerivedProps<P extends object, DP extends object | null | undefined>(derivedProps: PropConfig<P, DP>['derivedProps'], props: PropsWithoutRef<P>): DP {
  if (typeof derivedProps === 'function') {
    return derivedProps(props)
  }
  return derivedProps as DP
}

/**
 * @deprecated Will be removed in SWAN 4 without replacement
 */
export function getProcessedProps<P extends object, DP extends object | null | undefined>(
  processProps: PropConfig<P, DP>['processProps'],
  props: PropsWithoutRef<P>,
  derivedProps: DP,
): PropsWithoutRef<P> {
  if (typeof processProps === 'function') {
    return processProps(props, derivedProps)
  }
  return props
}

/**
 * @deprecated Will be removed in SWAN 4 without replacement
 */
export function getDefaultClassNameIterable<P extends object, DP extends object | null | undefined>(
  classNames: PropConfig<P, DP>['classNames'],
  props: PropsWithoutRef<P>,
  derivedProps: DP,
): Iterable<string> | null | undefined {
  if (!classNames) return null
  if (typeof classNames === 'function') {
    return classNames(props, derivedProps)
  }
  // If the defaultClassname is not a function, it could share the initial classnames with multiple instances
  // Hence, creating a new set.
  // Additionally, inside renderElement, it would be sent to process Styles which would re-use this.
  return new Set(classNames)
}

/**
 * Get only the core props from a given set of props
 */
export function getCoreProps<P extends object>(props: P): Partial<P> {
  const filteredPropsEntries = Object.entries(props).filter(([key]) => isValidStyleProperty(key) || isValidGlobalProps(key))
  return Object.fromEntries(filteredPropsEntries) as Partial<P>
}

// Filter Props
const commonPropKeys = new Set(['as', 'component', 'render', 'swanStyle', 'className', 'children', 'htmlSize', '__dangerouslySuppressWarning'])

export function isValidGlobalProps(key: string): boolean {
  return commonPropKeys.has(key)
}

function shouldFilterPropKeyFactory(filterPropKeys?: Iterable<string> | null) {
  if (!filterPropKeys) return () => false
  const filterPropKeySet = iterToSet(filterPropKeys)
  return function shouldFilterPropKey(key: string): boolean {
    return filterPropKeySet.has(key)
  }
}

export function filterProps<P extends { className?: string }>(props: P, filterPropKeys?: Iterable<string> | null, classNameIterable?: Iterable<string> | null) {
  const shouldFilterPropKey = shouldFilterPropKeyFactory(filterPropKeys)
  const filteredPropsEntries = Object.entries(props).filter(([key]) => !(isValidStyleProperty(key) || isValidGlobalProps(key) || shouldFilterPropKey(key)))
  const classes = className(props.className, classNameIterable ? Array.from(classNameIterable) : null)
  if (classes) filteredPropsEntries.push(['className', classes])
  const htmlSize = (props as unknown as Record<string, string>)['htmlSize']
  if (htmlSize !== undefined) filteredPropsEntries.push(['size', htmlSize])
  return Object.fromEntries(filteredPropsEntries)
}

/**
 * @deprecated Will be removed in SWAN 4 without replacement
 */
export function wrap<T, P extends object, DP extends object | null | undefined>(
  wrapper: (props: PropsWithoutRef<P>, derivedProps: DP, e: T | null) => T | null,
  props: PropsWithoutRef<P>,
  derivedProps: DP,
): IdentityTypeFunction<T | null> {
  return (element: T | null) => {
    return wrapper(props, derivedProps, element)
  }
}

/**
 * @deprecated Will be removed in SWAN 4 without replacement
 */
export function getProps<NP extends object, P extends PropsWithChildren<{ className?: string }>, DP extends object | null | undefined>(
  userProps: PropsWithoutRef<P>,
  propConfig?: PropConfig<P, DP> | null,
  filterPropKeys?: Iterable<string> | null,
): {
  props: NP
  children: ReactNode | null
  processedProps: PropsWithoutRef<P>
  wrapRoot: IdentityTypeFunction<ReactElement | null> | null
} {
  const { derivedProps, processProps, classNames, wrapRoot, wrapChildren } = propConfig || {}
  const derivedExtraProps = getDerivedProps(derivedProps, userProps)
  const processedProps = getProcessedProps(processProps, userProps, derivedExtraProps)
  const defaultClassNameIterable = getDefaultClassNameIterable(classNames, processedProps, derivedExtraProps)
  const classNameIterable = processStyleProps(processedProps, defaultClassNameIterable)
  const filteredProps = filterProps(processedProps, filterPropKeys, classNameIterable)

  return {
    processedProps,
    props: filteredProps as NP,
    wrapRoot: wrapRoot ? wrap(wrapRoot, processedProps, derivedExtraProps) : null,
    children: wrapChildren ? wrapChildren(processedProps, derivedExtraProps, processedProps.children) : processedProps.children,
  }
}
