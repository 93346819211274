import { useMenu } from '@react-aria/menu'
import { ListProps } from '@react-stately/list'
import { useEffect } from 'react'

import { assignRefs } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, renderWithRef } from '~/react/components/core'
import { MenuItemInternal } from '~/react/components/menu/menu-item-internal'
import { MenuSectionInternal } from '~/react/components/menu/menu-section-internal'
import { useSwanPopover } from '~/react/components/popover'

import { useCollectionContext } from '~/react/contexts/internal/collection'

import { useSwanMenuListContext } from './menu-list.context'

type MenuListPropsGeneric<T> = CoreProps<Omit<JSX.IntrinsicElements['ul'], 'children'>, MinNativeRef, Partial<Pick<ListProps<T>, 'children'>>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MenuListProps = MenuListPropsGeneric<any>

/**
 * @subcomponent Menu
 */
export const MenuList = renderWithRef<HTMLUListElement, MenuListProps>('MenuList', null, (props, ref) => {
  const { children, className, 'aria-label': ariaLabel } = props
  const { setChildItems } = useCollectionContext()
  const { overlayState } = useSwanPopover()
  const { menuProps: menuListProps, menuState, menuRef } = useSwanMenuListContext()

  useEffect(() => {
    setChildItems(() => children)
  }, [setChildItems, children])

  const { menuProps } = useMenu(
    {
      ...menuListProps,
      'aria-label': ariaLabel,
    },
    menuState,
    menuRef,
  )

  const combinedRef = assignRefs(ref, menuRef)

  const classes = new Set(['swan-menu-list', 'swan-listbox-list'])
  if (className) classes.add(className)

  return (
    <ul {...props} {...menuProps} ref={combinedRef} className={[...classes].join(' ')}>
      {overlayState.isOpen
        ? [...menuState.collection].map(item => {
            return item.type === 'section' ? (
              <MenuSectionInternal key={item.key} section={item} state={menuState} />
            ) : (
              <MenuItemInternal key={item.key} item={item} state={menuState} />
            )
          })
        : null}
    </ul>
  )
})
