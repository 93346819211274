export const MANIFEST_STYLES = {
  utility: {
    hashed: 'utility.5519e7e8d582ae5d9f02153a8042df88.min.css',
    versioned: 'utility.min.css',
  },
  core: {
    hashed: 'core.7887ff93d7a51f32d1250d84d96898ca.min.css',
    versioned: 'core.min.css',
  },
  zoomControls: {
    hashed: 'zoom-controls.fdebf98ea6c52ec9cafb7da5f47e6184.min.css',
    versioned: 'zoom-controls.min.css',
  },
  visible: {
    hashed: 'visible.388bb400607befa65ff09c5a81d28b7f.min.css',
    versioned: 'visible.min.css',
  },
  toggleSwitch: {
    hashed: 'toggle-switch.df5e38e6f211fc797523abfd0c423fcd.min.css',
    versioned: 'toggle-switch.min.css',
  },
  thumbnailsHero: {
    hashed: 'thumbnails-hero.7b3fa59f58da180add3cd7bf3c5913d8.min.css',
    versioned: 'thumbnails-hero.min.css',
  },
  tabs: {
    hashed: 'tabs.cb647e29cd44210cf1e45590620dbe69.min.css',
    versioned: 'tabs.min.css',
  },
  table: {
    hashed: 'table.4e52d9069a843e8ca01301fc7f341565.min.css',
    versioned: 'table.min.css',
  },
  stepIndicator: {
    hashed: 'step-indicator.7475c81fefa8ce680a43d4fad6c5cb22.min.css',
    versioned: 'step-indicator.min.css',
  },
  statusMessage: {
    hashed: 'status-message.5a5dae6bd07da8296f6aedf3d0beb7aa.min.css',
    versioned: 'status-message.min.css',
  },
  standardTile: {
    hashed: 'standard-tile.ba88f90b2f9704ba4f2a7b5dd8220600.min.css',
    versioned: 'standard-tile.min.css',
  },
  spinner: {
    hashed: 'spinner.e28c0ae32bd5b461b339d7683cf90c86.min.css',
    versioned: 'spinner.min.css',
  },
  selectionSet: {
    hashed: 'selection-set.6853399cdcc202ff9da13cde1ef332e3.min.css',
    versioned: 'selection-set.min.css',
  },
  ratingsStars: {
    hashed: 'ratings-stars.e164eed6ff785abfb972c16df7ae04b5.min.css',
    versioned: 'ratings-stars.min.css',
  },
  progressiveImage: {
    hashed: 'progressive-image.f0a54c9123842f31dd3ce49ef0961118.min.css',
    versioned: 'progressive-image.min.css',
  },
  progressBar: {
    hashed: 'progress-bar.62a0f2a9125f6611361a9becb64edc21.min.css',
    versioned: 'progress-bar.min.css',
  },
  pricing: {
    hashed: 'pricing.d41d8cd98f00b204e9800998ecf8427e.min.css',
    versioned: 'pricing.min.css',
  },
  priceBar: {
    hashed: 'price-bar.ca7aa0964bdcf773d96fd086bf55361e.min.css',
    versioned: 'price-bar.min.css',
  },
  popover: {
    hashed: 'popover.2e09326fd9e6321e992a85736a5363b6.min.css',
    versioned: 'popover.min.css',
  },
  pagination: {
    hashed: 'pagination.2c03191f280c81657b0f76510407d9c8.min.css',
    versioned: 'pagination.min.css',
  },
  modalDialog: {
    hashed: 'modal-dialog.df892413d24d60f8ceb8749ce8c4b7d5.min.css',
    versioned: 'modal-dialog.min.css',
  },
  listbox: {
    hashed: 'listbox.c0f0ff8e1d61cc33dbba6715188e4e9c.min.css',
    versioned: 'listbox.min.css',
  },
  icon: {
    hashed: 'icon.d41d8cd98f00b204e9800998ecf8427e.min.css',
    versioned: 'icon.min.css',
  },
  hidden: {
    hashed: 'hidden.90549d732670d4ba02fac0dc001a0bb8.min.css',
    versioned: 'hidden.min.css',
  },
  grid: {
    hashed: 'grid.393ab9dc32ba1b6be15bf4d30fc3c6d6.min.css',
    versioned: 'grid.min.css',
  },
  fileTile: {
    hashed: 'file-tile.86a9557f279489bd4e626b9b1347daba.min.css',
    versioned: 'file-tile.min.css',
  },
  fileDropzone: {
    hashed: 'file-dropzone.8791a0f195bddf0b5fde79482b9d6426.min.css',
    versioned: 'file-dropzone.min.css',
  },
  fieldSet: {
    hashed: 'field-set.d41d8cd98f00b204e9800998ecf8427e.min.css',
    versioned: 'field-set.min.css',
  },
  emptyState: {
    hashed: 'empty-state.46e5c6ac5dc9486f3ced02f03841f060.min.css',
    versioned: 'empty-state.min.css',
  },
  carousel: {
    hashed: 'carousel.4c340bd4dba72abd9173c0dd4a9a672f.min.css',
    versioned: 'carousel.min.css',
  },
  button: {
    hashed: 'button.d41d8cd98f00b204e9800998ecf8427e.min.css',
    versioned: 'button.min.css',
  },
  baseTile: {
    hashed: 'base-tile.92a93fe92c003ef1fc6f1079551d5ec9.min.css',
    versioned: 'base-tile.min.css',
  },
  banner: {
    hashed: 'banner.8051ec07101c1af3fe1d6a1bc90601d6.min.css',
    versioned: 'banner.min.css',
  },
  avatar: {
    hashed: 'avatar.d67d114201fbc23f9ebe19c0ae0dd88d.min.css',
    versioned: 'avatar.min.css',
  },
  anchorBar: {
    hashed: 'anchor-bar.68e28cda916f286efca946638c71daef.min.css',
    versioned: 'anchor-bar.min.css',
  },
  alertBox: {
    hashed: 'alert-box.569877fe89ec1331a6c4d5f30ae3cbaf.min.css',
    versioned: 'alert-box.min.css',
  },
  accordion: {
    hashed: 'accordion.936bb94e824844038b179c3b7dd9f11e.min.css',
    versioned: 'accordion.min.css',
  },
} as const;
