import PropTypes, { InferProps } from 'prop-types'
import { ChangeEvent, useEffect } from 'react'

import { CoreInputProps, MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { useTabsContext } from './tabs.context'

const propTypes = {
  /**
   * The id of the matching tab
   */
  tabId: PropTypes.string.isRequired,
}
const propKeysToRemove = ['tabId']

export type TabHeaderInputProps = CoreProps<CoreInputProps, MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Tabs
 */
export const TabHeaderInput = renderWithRef<MinNativeRef, TabHeaderInputProps>('TabHeaderInput', propTypes, (props, ref) => {
  const { tabsName, selectedTabId, contentIds, registerHeader, unregisterHeader, onRequestTabChange } = useTabsContext()
  const { children, id: customId, tabId } = props
  const id = useId(customId)

  useEffect(() => {
    registerHeader(tabId, id)

    return () => unregisterHeader(tabId)
  }, [tabId, id, registerHeader, unregisterHeader])

  return (
    <RenderComp
      root="input"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      props={{
        ...props,
        id: id,
        type: 'radio',
        role: 'tab',
        name: tabsName,
        ['aria-controls']: contentIds[tabId],
        checked: tabId === selectedTabId,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.checked) {
            onRequestTabChange(tabId, event)
          }
        },
      }}
    >
      {children}
    </RenderComp>
  )
})
