import { ListProps } from '@react-stately/list'
import { Node } from '@react-types/shared'
import { createContext, Dispatch, SetStateAction, useEffect } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type CollectionChildren<T> = ListProps<T>['children']

export type CollectionContextValue<T> = {
  items?: Iterable<T>
  childItems?: CollectionChildren<Node<T>>
  setChildItems: Dispatch<SetStateAction<CollectionChildren<Node<T>>>>
  disabledKeys?: Set<string | number> | undefined
  setDisabledKeys: Dispatch<SetStateAction<Set<string | number> | undefined>>
}

/**
 * This context/provider is to lift the react-aria <Item> state up the react tree for combobox and listbox components
 *
 * For internal use only.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SwanCollectionContext = createContext<CollectionContextValue<any> | null | undefined>(undefined)

export function useCollectionContext() {
  return useNonNullishContext(SwanCollectionContext)
}

/**
 * Updates the item's disabled state in the collection context
 */
export function useTrackDisabledState(key: string | number, disabled: boolean | undefined | null) {
  const { setDisabledKeys } = useCollectionContext()

  useEffect(() => {
    if (disabled != null) {
      setDisabledKeys(s => {
        const newState = new Set(s)
        if (disabled) {
          newState.add(key)
        } else {
          newState.delete(key)
        }
        return newState
      })
    }
  }, [setDisabledKeys, key, disabled])
}
