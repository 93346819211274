import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Whether to hide the first banner on screens smaller than Medium
   */
  hideFirstBannerBelowMedium: PropTypes.bool,
  /**
   * Whether to hide the second banner on screens smaller than Medium
   */
  hideSecondBannerBelowMedium: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type DoubleBannerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Banner
 */
export const DoubleBanner = renderWithRef<MinNativeRef, DoubleBannerProps>('DoubleBanner', propTypes, (props, ref) => {
  const { children, hideFirstBannerBelowMedium, hideSecondBannerBelowMedium } = props

  const classNameSet = new Set<string>(['swan-double-banner'])
  if (hideFirstBannerBelowMedium) classNameSet.add('swan-double-banner-hide-first-banner-below-medium')
  if (hideSecondBannerBelowMedium) classNameSet.add('swan-double-banner-hide-second-banner-below-medium')

  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNameSet} props={props}>
      {children}
    </RenderComp>
  )
})
