/**
 * Do not edit directly
 * Generated on Tue, 18 Mar 2025 15:44:34 GMT
 */

export const tokens = {
  "SwanBaseBorderRadius0": "var(--swan-base-border-radius-0)",
  "SwanBaseBorderRadius100": "var(--swan-base-border-radius-100)",
  "SwanBaseBorderRadius200": "var(--swan-base-border-radius-200)",
  "SwanBaseBorderRadius300": "var(--swan-base-border-radius-300)",
  "SwanBaseBorderRadius400": "var(--swan-base-border-radius-400)",
  "SwanBaseBorderRadiusMax": "var(--swan-base-border-radius-max)",
  "SwanBaseBorderWidth0": "var(--swan-base-border-width-0)",
  "SwanBaseBorderWidth100": "var(--swan-base-border-width-100)",
  "SwanBaseBorderWidth200": "var(--swan-base-border-width-200)",
  "SwanBaseBorderWidth300": "var(--swan-base-border-width-300)",
  "SwanBaseBreakpointXsStart": "var(--swan-base-breakpoint-xs-start)",
  "SwanBaseBreakpointXsEnd": "var(--swan-base-breakpoint-xs-end)",
  "SwanBaseBreakpointSmStart": "var(--swan-base-breakpoint-sm-start)",
  "SwanBaseBreakpointSmEnd": "var(--swan-base-breakpoint-sm-end)",
  "SwanBaseBreakpointMdStart": "var(--swan-base-breakpoint-md-start)",
  "SwanBaseBreakpointMdEnd": "var(--swan-base-breakpoint-md-end)",
  "SwanBaseBreakpointLgStart": "var(--swan-base-breakpoint-lg-start)",
  "SwanBaseBreakpointLgEnd": "var(--swan-base-breakpoint-lg-end)",
  "SwanBaseBreakpointXlStart": "var(--swan-base-breakpoint-xl-start)",
  "SwanBaseColorTransparent": "var(--swan-base-color-transparent)",
  "SwanBaseColorBlack": "var(--swan-base-color-black)",
  "SwanBaseColorBlackOpacity90": "var(--swan-base-color-black-opacity-90)",
  "SwanBaseColorBlackOpacity80": "var(--swan-base-color-black-opacity-80)",
  "SwanBaseColorBlackOpacity70": "var(--swan-base-color-black-opacity-70)",
  "SwanBaseColorBlackOpacity60": "var(--swan-base-color-black-opacity-60)",
  "SwanBaseColorBlackOpacity50": "var(--swan-base-color-black-opacity-50)",
  "SwanBaseColorBlackOpacity40": "var(--swan-base-color-black-opacity-40)",
  "SwanBaseColorBlackOpacity30": "var(--swan-base-color-black-opacity-30)",
  "SwanBaseColorBlackOpacity20": "var(--swan-base-color-black-opacity-20)",
  "SwanBaseColorBlackOpacity10": "var(--swan-base-color-black-opacity-10)",
  "SwanBaseColorGrey100": "var(--swan-base-color-grey-100)",
  "SwanBaseColorGrey200": "var(--swan-base-color-grey-200)",
  "SwanBaseColorGrey300": "var(--swan-base-color-grey-300)",
  "SwanBaseColorGrey400": "var(--swan-base-color-grey-400)",
  "SwanBaseColorGrey500": "var(--swan-base-color-grey-500)",
  "SwanBaseColorGrey600": "var(--swan-base-color-grey-600)",
  "SwanBaseColorGrey700": "var(--swan-base-color-grey-700)",
  "SwanBaseColorGrey800": "var(--swan-base-color-grey-800)",
  "SwanBaseColorGrey900": "var(--swan-base-color-grey-900)",
  "SwanBaseColorGrey950": "var(--swan-base-color-grey-950)",
  "SwanBaseColorWhite": "var(--swan-base-color-white)",
  "SwanBaseColorWhiteOpacity90": "var(--swan-base-color-white-opacity-90)",
  "SwanBaseColorWhiteOpacity80": "var(--swan-base-color-white-opacity-80)",
  "SwanBaseColorWhiteOpacity70": "var(--swan-base-color-white-opacity-70)",
  "SwanBaseColorWhiteOpacity60": "var(--swan-base-color-white-opacity-60)",
  "SwanBaseColorWhiteOpacity50": "var(--swan-base-color-white-opacity-50)",
  "SwanBaseColorWhiteOpacity40": "var(--swan-base-color-white-opacity-40)",
  "SwanBaseColorWhiteOpacity30": "var(--swan-base-color-white-opacity-30)",
  "SwanBaseColorWhiteOpacity20": "var(--swan-base-color-white-opacity-20)",
  "SwanBaseColorWhiteOpacity10": "var(--swan-base-color-white-opacity-10)",
  "SwanBaseColorRed100": "var(--swan-base-color-red-100)",
  "SwanBaseColorRed200": "var(--swan-base-color-red-200)",
  "SwanBaseColorRed300": "var(--swan-base-color-red-300)",
  "SwanBaseColorRed400": "var(--swan-base-color-red-400)",
  "SwanBaseColorRed500": "var(--swan-base-color-red-500)",
  "SwanBaseColorRed600": "var(--swan-base-color-red-600)",
  "SwanBaseColorRed700": "var(--swan-base-color-red-700)",
  "SwanBaseColorRed800": "var(--swan-base-color-red-800)",
  "SwanBaseColorRed900": "var(--swan-base-color-red-900)",
  "SwanBaseColorYellow100": "var(--swan-base-color-yellow-100)",
  "SwanBaseColorYellow200": "var(--swan-base-color-yellow-200)",
  "SwanBaseColorYellow300": "var(--swan-base-color-yellow-300)",
  "SwanBaseColorYellow400": "var(--swan-base-color-yellow-400)",
  "SwanBaseColorYellow500": "var(--swan-base-color-yellow-500)",
  "SwanBaseColorYellow600": "var(--swan-base-color-yellow-600)",
  "SwanBaseColorYellow700": "var(--swan-base-color-yellow-700)",
  "SwanBaseColorYellow800": "var(--swan-base-color-yellow-800)",
  "SwanBaseColorYellow900": "var(--swan-base-color-yellow-900)",
  "SwanBaseColorGreen100": "var(--swan-base-color-green-100)",
  "SwanBaseColorGreen200": "var(--swan-base-color-green-200)",
  "SwanBaseColorGreen300": "var(--swan-base-color-green-300)",
  "SwanBaseColorGreen400": "var(--swan-base-color-green-400)",
  "SwanBaseColorGreen500": "var(--swan-base-color-green-500)",
  "SwanBaseColorGreen600": "var(--swan-base-color-green-600)",
  "SwanBaseColorGreen700": "var(--swan-base-color-green-700)",
  "SwanBaseColorGreen800": "var(--swan-base-color-green-800)",
  "SwanBaseColorGreen900": "var(--swan-base-color-green-900)",
  "SwanBaseColorBlue100": "var(--swan-base-color-blue-100)",
  "SwanBaseColorBlue200": "var(--swan-base-color-blue-200)",
  "SwanBaseColorBlue300": "var(--swan-base-color-blue-300)",
  "SwanBaseColorBlue400": "var(--swan-base-color-blue-400)",
  "SwanBaseColorBlue500": "var(--swan-base-color-blue-500)",
  "SwanBaseColorBlue600": "var(--swan-base-color-blue-600)",
  "SwanBaseColorBlue700": "var(--swan-base-color-blue-700)",
  "SwanBaseColorBlue800": "var(--swan-base-color-blue-800)",
  "SwanBaseColorBlue900": "var(--swan-base-color-blue-900)",
  "SwanBaseColorNavy100": "var(--swan-base-color-navy-100)",
  "SwanBaseColorNavy200": "var(--swan-base-color-navy-200)",
  "SwanBaseColorNavy300": "var(--swan-base-color-navy-300)",
  "SwanBaseColorNavy400": "var(--swan-base-color-navy-400)",
  "SwanBaseColorNavy500": "var(--swan-base-color-navy-500)",
  "SwanBaseColorNavy600": "var(--swan-base-color-navy-600)",
  "SwanBaseColorNavy700": "var(--swan-base-color-navy-700)",
  "SwanBaseColorNavy800": "var(--swan-base-color-navy-800)",
  "SwanBaseColorNavy900": "var(--swan-base-color-navy-900)",
  "SwanBaseFontFamilyPrimary": "var(--swan-base-font-family-primary)",
  "SwanBaseFontFamilySecondary": "var(--swan-base-font-family-secondary)",
  "SwanBaseFontSize100": "var(--swan-base-font-size-100)",
  "SwanBaseFontSize200": "var(--swan-base-font-size-200)",
  "SwanBaseFontSize300": "var(--swan-base-font-size-300)",
  "SwanBaseFontSize400": "var(--swan-base-font-size-400)",
  "SwanBaseFontSize500": "var(--swan-base-font-size-500)",
  "SwanBaseFontSize600": "var(--swan-base-font-size-600)",
  "SwanBaseFontSize700": "var(--swan-base-font-size-700)",
  "SwanBaseFontSize800": "var(--swan-base-font-size-800)",
  "SwanBaseFontSize900": "var(--swan-base-font-size-900)",
  "SwanBaseFontSize050": "var(--swan-base-font-size-050)",
  "SwanBaseFontSize075": "var(--swan-base-font-size-075)",
  "SwanBaseFontWeightNormal": "var(--swan-base-font-weight-normal)",
  "SwanBaseFontWeightBold": "var(--swan-base-font-weight-bold)",
  "SwanBaseFontLetterSpacing100": "var(--swan-base-font-letter-spacing-100)",
  "SwanBaseFontLetterSpacing200": "var(--swan-base-font-letter-spacing-200)",
  "SwanBaseFontLetterSpacing300": "var(--swan-base-font-letter-spacing-300)",
  "SwanBaseFontLetterSpacing025": "var(--swan-base-font-letter-spacing-025)",
  "SwanBaseFontLetterSpacing050": "var(--swan-base-font-letter-spacing-050)",
  "SwanBaseFontLetterSpacing075": "var(--swan-base-font-letter-spacing-075)",
  "SwanBaseFontLineHeight100": "var(--swan-base-font-line-height-100)",
  "SwanBaseFontLineHeight200": "var(--swan-base-font-line-height-200)",
  "SwanBaseFontLineHeight300": "var(--swan-base-font-line-height-300)",
  "SwanBaseFontLineHeight400": "var(--swan-base-font-line-height-400)",
  "SwanBaseFontLineHeight500": "var(--swan-base-font-line-height-500)",
  "SwanBaseFontLineHeight075": "var(--swan-base-font-line-height-075)",
  "SwanBaseSpace0": "var(--swan-base-space-0)",
  "SwanBaseSpace100": "var(--swan-base-space-100)",
  "SwanBaseSpace150": "var(--swan-base-space-150)",
  "SwanBaseSpace200": "var(--swan-base-space-200)",
  "SwanBaseSpace300": "var(--swan-base-space-300)",
  "SwanBaseSpace400": "var(--swan-base-space-400)",
  "SwanBaseSpace550": "var(--swan-base-space-550)",
  "SwanBaseSpace700": "var(--swan-base-space-700)",
  "SwanBaseSpace900": "var(--swan-base-space-900)",
  "SwanBaseSpace1200": "var(--swan-base-space-1200)",
  "SwanBaseSpace1600": "var(--swan-base-space-1600)",
  "SwanBaseSpace025": "var(--swan-base-space-025)",
  "SwanBaseSpace050": "var(--swan-base-space-050)",
  "SwanBaseSpace075": "var(--swan-base-space-075)",
  "SwanSemBorderRadiusNone": "var(--swan-sem-border-radius-none)",
  "SwanSemBorderRadiusSubtle": "var(--swan-sem-border-radius-subtle)",
  "SwanSemBorderRadiusStandard": "var(--swan-sem-border-radius-standard)",
  "SwanSemBorderRadiusStrong": "var(--swan-sem-border-radius-strong)",
  "SwanSemBorderRadiusRounded": "var(--swan-sem-border-radius-rounded)",
  "SwanSemBorderRadiusFocus": "var(--swan-sem-border-radius-focus)",
  "SwanSemBorderRadiusAction": "var(--swan-sem-border-radius-action)",
  "SwanSemBorderRadiusInput": "var(--swan-sem-border-radius-input)",
  "SwanSemBorderRadiusContainer": "var(--swan-sem-border-radius-container)",
  "SwanSemBorderWidthNone": "var(--swan-sem-border-width-none)",
  "SwanSemBorderWidthStandard": "var(--swan-sem-border-width-standard)",
  "SwanSemBorderWidthStrong": "var(--swan-sem-border-width-strong)",
  "SwanSemBorderWidthStronger": "var(--swan-sem-border-width-stronger)",
  "SwanSemBorderWidthInput": "var(--swan-sem-border-width-input)",
  "SwanSemBorderWidthAction": "var(--swan-sem-border-width-action)",
  "SwanSemBorderWidthPartition": "var(--swan-sem-border-width-partition)",
  "SwanSemBorderWidthFloating": "var(--swan-sem-border-width-floating)",
  "SwanSemBorderWidthSelected": "var(--swan-sem-border-width-selected)",
  "SwanSemColorBgNone": "var(--swan-sem-color-bg-none)",
  "SwanSemColorBgNoneHover": "var(--swan-sem-color-bg-none-hover)",
  "SwanSemColorBgNoneActive": "var(--swan-sem-color-bg-none-active)",
  "SwanSemColorBgStandard": "var(--swan-sem-color-bg-standard)",
  "SwanSemColorBgHover": "var(--swan-sem-color-bg-hover)",
  "SwanSemColorBgActive": "var(--swan-sem-color-bg-active)",
  "SwanSemColorBgStrong": "var(--swan-sem-color-bg-strong)",
  "SwanSemColorBgStrongHover": "var(--swan-sem-color-bg-strong-hover)",
  "SwanSemColorBgStrongActive": "var(--swan-sem-color-bg-strong-active)",
  "SwanSemColorBgDisabled": "var(--swan-sem-color-bg-disabled)",
  "SwanSemColorBgSelected": "var(--swan-sem-color-bg-selected)",
  "SwanSemColorBgSelectedHover": "var(--swan-sem-color-bg-selected-hover)",
  "SwanSemColorBgSelectedActive": "var(--swan-sem-color-bg-selected-active)",
  "SwanSemColorBgSelectedAccent": "var(--swan-sem-color-bg-selected-accent)",
  "SwanSemColorBgSelectedAccentHover": "var(--swan-sem-color-bg-selected-accent-hover)",
  "SwanSemColorBgSelectedAccentActive": "var(--swan-sem-color-bg-selected-accent-active)",
  "SwanSemColorBgAccent": "var(--swan-sem-color-bg-accent)",
  "SwanSemColorBgAccentStrongPaired": "var(--swan-sem-color-bg-accent-strong-paired)",
  "SwanSemColorBgAccentStrongHoverPaired": "var(--swan-sem-color-bg-accent-strong-hover-paired)",
  "SwanSemColorBgAccentStrongActivePaired": "var(--swan-sem-color-bg-accent-strong-active-paired)",
  "SwanSemColorBgError": "var(--swan-sem-color-bg-error)",
  "SwanSemColorBgErrorStrongPaired": "var(--swan-sem-color-bg-error-strong-paired)",
  "SwanSemColorBgWarning": "var(--swan-sem-color-bg-warning)",
  "SwanSemColorBgWarningStrongPaired": "var(--swan-sem-color-bg-warning-strong-paired)",
  "SwanSemColorBgSuccess": "var(--swan-sem-color-bg-success)",
  "SwanSemColorBgSuccessStrongPaired": "var(--swan-sem-color-bg-success-strong-paired)",
  "SwanSemColorBgPromo": "var(--swan-sem-color-bg-promo)",
  "SwanSemColorBgPromoStrongPaired": "var(--swan-sem-color-bg-promo-strong-paired)",
  "SwanSemColorBgHelp": "var(--swan-sem-color-bg-help)",
  "SwanSemColorBgHelpStrongPaired": "var(--swan-sem-color-bg-help-strong-paired)",
  "SwanSemColorBgInfo": "var(--swan-sem-color-bg-info)",
  "SwanSemColorBgInfoStrongPaired": "var(--swan-sem-color-bg-info-strong-paired)",
  "SwanSemColorBgAction": "var(--swan-sem-color-bg-action)",
  "SwanSemColorBgActionHover": "var(--swan-sem-color-bg-action-hover)",
  "SwanSemColorBgActionActive": "var(--swan-sem-color-bg-action-active)",
  "SwanSemColorBgControl": "var(--swan-sem-color-bg-control)",
  "SwanSemColorBgControlHover": "var(--swan-sem-color-bg-control-hover)",
  "SwanSemColorBgControlActive": "var(--swan-sem-color-bg-control-active)",
  "SwanSemColorBgInput": "var(--swan-sem-color-bg-input)",
  "SwanSemColorBgContainer": "var(--swan-sem-color-bg-container)",
  "SwanSemColorBgPage": "var(--swan-sem-color-bg-page)",
  "SwanSemColorBorderNone": "var(--swan-sem-color-border-none)",
  "SwanSemColorBorderNoneHover": "var(--swan-sem-color-border-none-hover)",
  "SwanSemColorBorderNoneActive": "var(--swan-sem-color-border-none-active)",
  "SwanSemColorBorderStandard": "var(--swan-sem-color-border-standard)",
  "SwanSemColorBorderHover": "var(--swan-sem-color-border-hover)",
  "SwanSemColorBorderActive": "var(--swan-sem-color-border-active)",
  "SwanSemColorBorderStrong": "var(--swan-sem-color-border-strong)",
  "SwanSemColorBorderStrongHover": "var(--swan-sem-color-border-strong-hover)",
  "SwanSemColorBorderStrongActive": "var(--swan-sem-color-border-strong-active)",
  "SwanSemColorBorderDisabled": "var(--swan-sem-color-border-disabled)",
  "SwanSemColorBorderSelected": "var(--swan-sem-color-border-selected)",
  "SwanSemColorBorderSelectedHover": "var(--swan-sem-color-border-selected-hover)",
  "SwanSemColorBorderSelectedActive": "var(--swan-sem-color-border-selected-active)",
  "SwanSemColorBorderSelectedAccent": "var(--swan-sem-color-border-selected-accent)",
  "SwanSemColorBorderSelectedAccentHover": "var(--swan-sem-color-border-selected-accent-hover)",
  "SwanSemColorBorderSelectedAccentActive": "var(--swan-sem-color-border-selected-accent-active)",
  "SwanSemColorBorderAccent": "var(--swan-sem-color-border-accent)",
  "SwanSemColorBorderAccentStrongPaired": "var(--swan-sem-color-border-accent-strong-paired)",
  "SwanSemColorBorderAccentStrongHoverPaired": "var(--swan-sem-color-border-accent-strong-hover-paired)",
  "SwanSemColorBorderAccentStrongActivePaired": "var(--swan-sem-color-border-accent-strong-active-paired)",
  "SwanSemColorBorderError": "var(--swan-sem-color-border-error)",
  "SwanSemColorBorderErrorStrongPaired": "var(--swan-sem-color-border-error-strong-paired)",
  "SwanSemColorBorderWarning": "var(--swan-sem-color-border-warning)",
  "SwanSemColorBorderWarningStrongPaired": "var(--swan-sem-color-border-warning-strong-paired)",
  "SwanSemColorBorderSuccess": "var(--swan-sem-color-border-success)",
  "SwanSemColorBorderSuccessStrongPaired": "var(--swan-sem-color-border-success-strong-paired)",
  "SwanSemColorBorderPromo": "var(--swan-sem-color-border-promo)",
  "SwanSemColorBorderPromoStrongPaired": "var(--swan-sem-color-border-promo-strong-paired)",
  "SwanSemColorBorderHelp": "var(--swan-sem-color-border-help)",
  "SwanSemColorBorderHelpStrongPaired": "var(--swan-sem-color-border-help-strong-paired)",
  "SwanSemColorBorderInfo": "var(--swan-sem-color-border-info)",
  "SwanSemColorBorderInfoStrongPaired": "var(--swan-sem-color-border-info-strong-paired)",
  "SwanSemColorBorderAction": "var(--swan-sem-color-border-action)",
  "SwanSemColorBorderActionHover": "var(--swan-sem-color-border-action-hover)",
  "SwanSemColorBorderActionActive": "var(--swan-sem-color-border-action-active)",
  "SwanSemColorBorderControl": "var(--swan-sem-color-border-control)",
  "SwanSemColorBorderControlHover": "var(--swan-sem-color-border-control-hover)",
  "SwanSemColorBorderControlActive": "var(--swan-sem-color-border-control-active)",
  "SwanSemColorBorderInput": "var(--swan-sem-color-border-input)",
  "SwanSemColorBorderInputHover": "var(--swan-sem-color-border-input-hover)",
  "SwanSemColorBorderInputActive": "var(--swan-sem-color-border-input-active)",
  "SwanSemColorBorderInputError": "var(--swan-sem-color-border-input-error)",
  "SwanSemColorBorderContainer": "var(--swan-sem-color-border-container)",
  "SwanSemColorBorderPartition": "var(--swan-sem-color-border-partition)",
  "SwanSemColorBrandVistaBlue": "var(--swan-sem-color-brand-vista-blue)",
  "SwanSemColorBrandMidnight": "var(--swan-sem-color-brand-midnight)",
  "SwanSemColorBrandWarmWhite": "var(--swan-sem-color-brand-warm-white)",
  "SwanSemColorIconStandard": "var(--swan-sem-color-icon-standard)",
  "SwanSemColorIconDisabled": "var(--swan-sem-color-icon-disabled)",
  "SwanSemColorIconAccent": "var(--swan-sem-color-icon-accent)",
  "SwanSemColorIconAccentStrongPaired": "var(--swan-sem-color-icon-accent-strong-paired)",
  "SwanSemColorIconError": "var(--swan-sem-color-icon-error)",
  "SwanSemColorIconErrorStrongPaired": "var(--swan-sem-color-icon-error-strong-paired)",
  "SwanSemColorIconWarning": "var(--swan-sem-color-icon-warning)",
  "SwanSemColorIconWarningStrongPaired": "var(--swan-sem-color-icon-warning-strong-paired)",
  "SwanSemColorIconSuccess": "var(--swan-sem-color-icon-success)",
  "SwanSemColorIconSuccessStrongPaired": "var(--swan-sem-color-icon-success-strong-paired)",
  "SwanSemColorIconPromo": "var(--swan-sem-color-icon-promo)",
  "SwanSemColorIconPromoStrongPaired": "var(--swan-sem-color-icon-promo-strong-paired)",
  "SwanSemColorIconHelp": "var(--swan-sem-color-icon-help)",
  "SwanSemColorIconHelpStrongPaired": "var(--swan-sem-color-icon-help-strong-paired)",
  "SwanSemColorIconInfo": "var(--swan-sem-color-icon-info)",
  "SwanSemColorIconInfoStrongPaired": "var(--swan-sem-color-icon-info-strong-paired)",
  "SwanSemColorIconSubtle": "var(--swan-sem-color-icon-subtle)",
  "SwanSemColorTextStandard": "var(--swan-sem-color-text-standard)",
  "SwanSemColorTextSubtle": "var(--swan-sem-color-text-subtle)",
  "SwanSemColorTextInput": "var(--swan-sem-color-text-input)",
  "SwanSemColorTextPlaceholder": "var(--swan-sem-color-text-placeholder)",
  "SwanSemColorTextError": "var(--swan-sem-color-text-error)",
  "SwanSemColorTextErrorAlt": "var(--swan-sem-color-text-error-alt)",
  "SwanSemColorTextErrorStrongPaired": "var(--swan-sem-color-text-error-strong-paired)",
  "SwanSemColorTextWarning": "var(--swan-sem-color-text-warning)",
  "SwanSemColorTextWarningAlt": "var(--swan-sem-color-text-warning-alt)",
  "SwanSemColorTextWarningStrongPaired": "var(--swan-sem-color-text-warning-strong-paired)",
  "SwanSemColorTextDisabled": "var(--swan-sem-color-text-disabled)",
  "SwanSemColorTextPromo": "var(--swan-sem-color-text-promo)",
  "SwanSemColorTextPromoAlt": "var(--swan-sem-color-text-promo-alt)",
  "SwanSemColorTextPromoStrongPaired": "var(--swan-sem-color-text-promo-strong-paired)",
  "SwanSemColorTextHover": "var(--swan-sem-color-text-hover)",
  "SwanSemColorTextActive": "var(--swan-sem-color-text-active)",
  "SwanSemColorTextAccent": "var(--swan-sem-color-text-accent)",
  "SwanSemColorTextAccentAlt": "var(--swan-sem-color-text-accent-alt)",
  "SwanSemColorTextAccentStrongPaired": "var(--swan-sem-color-text-accent-strong-paired)",
  "SwanSemColorTextHelp": "var(--swan-sem-color-text-help)",
  "SwanSemColorTextHelpAlt": "var(--swan-sem-color-text-help-alt)",
  "SwanSemColorTextHelpStrongPaired": "var(--swan-sem-color-text-help-strong-paired)",
  "SwanSemColorTextSuccess": "var(--swan-sem-color-text-success)",
  "SwanSemColorTextSuccessAlt": "var(--swan-sem-color-text-success-alt)",
  "SwanSemColorTextSuccessStrongPaired": "var(--swan-sem-color-text-success-strong-paired)",
  "SwanSemColorTextInfo": "var(--swan-sem-color-text-info)",
  "SwanSemColorTextInfoAlt": "var(--swan-sem-color-text-info-alt)",
  "SwanSemColorTextInfoStrongPaired": "var(--swan-sem-color-text-info-strong-paired)",
  "SwanSemColorTextLink": "var(--swan-sem-color-text-link)",
  "SwanSemColorTextLinkHover": "var(--swan-sem-color-text-link-hover)",
  "SwanSemColorTextLinkActive": "var(--swan-sem-color-text-link-active)",
  "SwanSemElevationNone": "var(--swan-sem-elevation-none)",
  "SwanSemElevationSubtle": "var(--swan-sem-elevation-subtle)",
  "SwanSemElevationStandard": "var(--swan-sem-elevation-standard)",
  "SwanSemElevationStrong": "var(--swan-sem-elevation-strong)",
  "SwanSemElevationFloating": "var(--swan-sem-elevation-floating)",
  "SwanSemElevationOverlay": "var(--swan-sem-elevation-overlay)",
  "SwanSemElevationScrollHorizontal": "var(--swan-sem-elevation-scroll-horizontal)",
  "SwanSemFocusColorInner": "var(--swan-sem-focus-color-inner)",
  "SwanSemFocusColorOuter": "var(--swan-sem-focus-color-outer)",
  "SwanSemFocusStandard": "var(--swan-sem-focus-standard)",
  "SwanSemFocusInset": "var(--swan-sem-focus-inset)",
  "SwanSemFocusTight": "var(--swan-sem-focus-tight)",
  "SwanSemFontSizeX4large": "var(--swan-sem-font-size-x4large)",
  "SwanSemFontSizeX3large": "var(--swan-sem-font-size-x3large)",
  "SwanSemFontSizeX2large": "var(--swan-sem-font-size-x2large)",
  "SwanSemFontSizeXlarge": "var(--swan-sem-font-size-xlarge)",
  "SwanSemFontSizeLarge": "var(--swan-sem-font-size-large)",
  "SwanSemFontSizeStandard": "var(--swan-sem-font-size-standard)",
  "SwanSemFontSizeSmall": "var(--swan-sem-font-size-small)",
  "SwanSemFontSizeXsmall": "var(--swan-sem-font-size-xsmall)",
  "SwanSemMqEqXl": "'only screen and (min-width: 1920px)'",
  "SwanSemMqEqLg": "'only screen and (min-width: 1440px) and (max-width: 1919px)'",
  "SwanSemMqEqMd": "'only screen and (min-width: 1024px) and (max-width: 1439px)'",
  "SwanSemMqEqSm": "'only screen and (min-width: 768px) and (max-width: 1023px)'",
  "SwanSemMqEqXs": "'only screen and (max-width: 767px)'",
  "SwanSemMqGtXl": "'only screen and (min-width: 1920px)'",
  "SwanSemMqGtLg": "'only screen and (min-width: 1920px)'",
  "SwanSemMqGtMd": "'only screen and (min-width: 1440px)'",
  "SwanSemMqGtSm": "'only screen and (min-width: 1024px)'",
  "SwanSemMqGtXs": "'only screen and (min-width: 768px)'",
  "SwanSemMqGteXl": "'only screen and (min-width: 1920px)'",
  "SwanSemMqGteLg": "'only screen and (min-width: 1440px)'",
  "SwanSemMqGteMd": "'only screen and (min-width: 1024px)'",
  "SwanSemMqGteSm": "'only screen and (min-width: 768px)'",
  "SwanSemMqGteXs": "'only screen and (min-width: 0px)'",
  "SwanSemMqLtXl": "'only screen and (max-width: 1919px)'",
  "SwanSemMqLtLg": "'only screen and (max-width: 1439px)'",
  "SwanSemMqLtMd": "'only screen and (max-width: 1023px)'",
  "SwanSemMqLtSm": "'only screen and (max-width: 767px)'",
  "SwanSemMqLteLg": "'only screen and (max-width: 1919px)'",
  "SwanSemMqLteMd": "'only screen and (max-width: 1439px)'",
  "SwanSemMqLteSm": "'only screen and (max-width: 1023px)'",
  "SwanSemMqLteXs": "'only screen and (max-width: 767px)'",
  "SwanSemSizeIconStandard": "var(--swan-sem-size-icon-standard)",
  "SwanSemSizeIconDynamic": "var(--swan-sem-size-icon-dynamic)",
  "SwanSemSizeIconStrokeWidth": "var(--swan-sem-size-icon-stroke-width)",
  "SwanSemSizeIconSmall": "var(--swan-sem-size-icon-small)",
  "SwanSemSizeIllustrationStandard": "var(--swan-sem-size-illustration-standard)",
  "SwanSemSizeIllustrationLarge": "var(--swan-sem-size-illustration-large)",
  "SwanSemSizeActionAndInputInnerHeight": "var(--swan-sem-size-action-and-input-inner-height)",
  "SwanSemSizeActionAndInputInnerMinWidth": "var(--swan-sem-size-action-and-input-inner-min-width)",
  "SwanSemSizeClickTargetMinHeight": "var(--swan-sem-size-click-target-min-height)",
  "SwanSemSizeClickTargetMinWidth": "var(--swan-sem-size-click-target-min-width)",
  "SwanSemSpace1": "var(--swan-sem-space-1)",
  "SwanSemSpace2": "var(--swan-sem-space-2)",
  "SwanSemSpace3": "var(--swan-sem-space-3)",
  "SwanSemSpace4": "var(--swan-sem-space-4)",
  "SwanSemSpace5": "var(--swan-sem-space-5)",
  "SwanSemSpace6": "var(--swan-sem-space-6)",
  "SwanSemSpace7": "var(--swan-sem-space-7)",
  "SwanSemSpace8": "var(--swan-sem-space-8)",
  "SwanSemSpace9": "var(--swan-sem-space-9)",
  "SwanSemSpace10": "var(--swan-sem-space-10)",
  "SwanSemSpace11": "var(--swan-sem-space-11)",
  "SwanSemSpace12": "var(--swan-sem-space-12)",
  "SwanSemSpaceNone": "var(--swan-sem-space-none)",
  "SwanSemSpaceGutterStandard": "var(--swan-sem-space-gutter-standard)",
  "SwanSemSpaceGutterTight": "var(--swan-sem-space-gutter-tight)",
  "SwanSemSpaceInputPaddingHorizontal": "var(--swan-sem-space-input-padding-horizontal)",
  "SwanSemSpaceInputPaddingVertical": "var(--swan-sem-space-input-padding-vertical)",
  "SwanSemSpaceBetweenSections": "var(--swan-sem-space-between-sections)",
  "SwanSemSpaceBetweenSubsections": "var(--swan-sem-space-between-subsections)",
  "SwanSemSpaceBetweenActions": "var(--swan-sem-space-between-actions)",
  "SwanSemSpaceBetweenIconAndText": "var(--swan-sem-space-between-icon-and-text)",
  "SwanSemSpaceToActions": "var(--swan-sem-space-to-actions)",
  "SwanSemZIndexRaised": "var(--swan-sem-z-index-raised)",
  "SwanSemZIndexLinkCovering": "var(--swan-sem-z-index-link-covering)",
  "SwanSemZIndexOverlay": "var(--swan-sem-z-index-overlay)",
  "SwanSemZIndexFloating": "var(--swan-sem-z-index-floating)",
  "SwanSemZIndexOverTheTop": "var(--swan-sem-z-index-over-the-top)",
  "SwanInternalColorBgWarmWhite": "var(--swan-internal-color-bg-warm-white)",
  "SwanInternalColorBgMidnight": "var(--swan-internal-color-bg-midnight)",
}
