import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

type EmptyStateTypes = {
  /**
   * Use `standard` size for most empty state content. Use `large` size for full-page empty states
   *
   * @default standard
   */
  size?: 'standard' | 'large'
}

export type EmptyStateProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, EmptyStateTypes>

export const EmptyState = renderWithRef<MinNativeRef, EmptyStateProps>('EmptyState', null, (props, ref) => {
  useComponentStylesLoaded('EmptyState', SWAN_STYLE_KEY_MAP.emptyState)

  const { children, size = 'standard', ...rest } = props

  const classNames = new Set(['swan-empty-state', `swan-empty-state-${size}`])

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={rest}>
      {children}
    </RenderComp>
  )
})
