import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type DropdownOptionProps = CoreProps<JSX.IntrinsicElements['option'], MinNativeRef>

/**
 * @subcomponent Dropdown
 */

export const DropdownOption = renderWithRef<MinNativeRef, DropdownOptionProps>('DropdownOption', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="option" forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
