import { Dispatch, MutableRefObject, SetStateAction, useMemo, useState } from 'react'

import { ROOT_CLASSNAMES } from '~/core/constants'

import { useIsomorphicLayoutEffect } from '~/react/hooks/use-isomorphic-layout-effect'

function hasStandardModeEnabled(currentElement: HTMLElement | null | undefined) {
  if (currentElement?.className.includes(ROOT_CLASSNAMES.standardMode)) {
    return true
  }
  return false
}

/**
 * checks if a element is wrapped inside the element with the standard mode or not
 * refElement - element
 * ancestorElement - element with dark/compact mode
 */
export function hasNestedStandardMode(element: Element | null | undefined, ancestorElement: Element | null | undefined) {
  let currentElement = element?.parentElement

  while (currentElement && currentElement !== ancestorElement) {
    if (hasStandardModeEnabled(currentElement)) {
      return true
    }
    currentElement = currentElement.parentElement
  }
  return false
}

function setMode(elementRef: MutableRefObject<HTMLElement | null | undefined>, callback: Dispatch<SetStateAction<boolean>>, mode: keyof typeof ROOT_CLASSNAMES) {
  if (hasStandardModeEnabled(elementRef.current)) {
    callback(false)
  } else {
    const closestCompactModeElem = elementRef.current?.closest(`.${ROOT_CLASSNAMES[mode]}`)

    if (closestCompactModeElem) {
      const hasNestedStandardModeElem = hasNestedStandardMode(elementRef.current, closestCompactModeElem)
      callback(!hasNestedStandardModeElem)
    } else {
      // in case mode is switched from compact to standard at a later time
      callback(false)
    }
  }
}

/**
 * The hook accepts a ref to an element and optional param to force a re-evaluation of mode object.
 * returns a object containing two boolean properties
 * - `hasDarkMode`: whether the element is currently in dark mode.
 * - `hasCompactMode`: whether the element is currently in compact mode.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModeExtractor(elementRef: MutableRefObject<HTMLElement | null | undefined>, retrigger?: any) {
  const [hasDarkMode, setHasDarkMode] = useState(false)
  const [hasCompactMode, setHasCompactMode] = useState(false)

  useIsomorphicLayoutEffect(() => {
    setMode(elementRef, setHasDarkMode, 'darkMode')
    setMode(elementRef, setHasCompactMode, 'compactMode')
  }, [elementRef, retrigger])

  const modes = useMemo(
    () => ({
      hasDarkMode,
      hasCompactMode,
    }),
    [hasDarkMode, hasCompactMode],
  )

  return modes
}
