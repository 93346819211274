import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type TableBodyProps = CoreProps<JSX.IntrinsicElements['tbody'], MinNativeRef>

/**
 * @subcomponent Table
 */
export const TableBody = renderWithRef<MinNativeRef, TableBodyProps>('TableBody', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="tbody" classNames={['swan-tbody']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
