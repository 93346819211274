import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ClearAllButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef>

/**
 * @subcomponent Button
 */
export const ClearAllButton = renderWithRef<MinNativeRef, ClearAllButtonProps>('ClearAllButton', null, ({ children, ...rest }, ref) => {
  return (
    <RenderComp root="button" forwardedRef={ref} classNames={['swan-link', 'swan-clear-all-button']} props={rest}>
      {children}
    </RenderComp>
  )
})
