import { useEffect, useState } from 'react'

import { iterToSet } from '~/core/utilities'
import { setToString } from '~/core/utilities/set.utils'

function getBrowserClasses(possibleNames: Set<string> = new Set()) {
  const { userAgent } = navigator
  if (userAgent.indexOf('Safari/') !== -1 && userAgent.indexOf('Chrome/') === -1 && userAgent.indexOf('Chromium/') === -1) {
    possibleNames.add('swan-safari')
  }
  if (userAgent.indexOf('Firefox/') !== -1 && userAgent.indexOf('Seamonkey/') === -1) {
    possibleNames.add('swan-firefox')
  }
  if (userAgent.indexOf('Edg/') !== -1) {
    // Chromium-based Edge
    possibleNames.add('swan-edge') // this gets put on ANY version of Edge
  }
  return possibleNames
}

/**
 * This hook appends and returns CSS classNames to the <html> tag respective to the browser being used to make SWAN components fully compatible with that browser. It accepts two parameters:
 *
 * @param {boolean} autoSyncWithHead
 * @param {string} initialClassNames - optional classname iterable to be merged
 */
export function useBrowserClasses(autoSyncWithHead?: boolean, initialClassNames?: Iterable<string> | null | undefined) {
  const [browserClassName, setBrowserClassName] = useState<string>(() => setToString(iterToSet(initialClassNames)))
  useEffect(() => {
    setBrowserClassName(setToString(getBrowserClasses(iterToSet(initialClassNames))))
  }, [initialClassNames])
  useEffect(() => {
    if (autoSyncWithHead && browserClassName) {
      try {
        const head = document.querySelector('head')
        head?.classList.add(browserClassName)
        return () => head?.classList.remove(browserClassName)
      } catch {
        console.info('Could not sync browser class name.')
      }
    }
  }, [browserClassName, autoSyncWithHead])
  return browserClassName
}
