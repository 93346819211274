import { useState } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { PriceBarQuantityContext } from './price-bar.context'

export type PriceBarQuantityProps = CoreProps<JSX.IntrinsicElements['button']>

/**
 * @subcomponent PriceBar
 */
export const PriceBarQuantity = renderWithRef<MinNativeRef, PriceBarQuantityProps>('PriceBarQuantity', null, (props, ref) => {
  const classNames = new Set(['swan-button'])
  const [buttonId, setButtonId] = useState<string | null>() || null
  const [labelId, setLabelId] = useState<string | null>() || null

  return (
    <PriceBarQuantityContext.Provider value={{ buttonId, setButtonId, labelId, setLabelId }}>
      <RenderComp root="button" forwardedRef={ref} classNames={classNames} props={props}>
        {props.children}
      </RenderComp>
    </PriceBarQuantityContext.Provider>
  )
})
