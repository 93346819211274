import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type SquareImageContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

export const SquareImageContainer = renderWithRef<MinNativeRef, SquareImageContainerProps>('SquareImageContainer', null, (props, ref) => {
  const classNames = new Set(['swan-square-image-container'])

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props}>
      <div className="swan-square-image-container-inner">{props.children}</div>
    </RenderComp>
  )
})
