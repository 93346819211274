import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type StandardTileSeparatelyClickableContentsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardTile
 */

export const StandardTileSeparatelyClickableContents = renderWithRef<MinNativeRef, StandardTileSeparatelyClickableContentsProps>(
  'StandardTileSeparatelyClickableContents',
  null,
  (props, ref) => (
    <RenderComp root="div" forwardedRef={ref} classNames={['swan-standard-tile-separately-clickable-contents']} props={props}>
      {props.children}
    </RenderComp>
  ),
)
