import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ComboboxPopoverTitleProps = CoreProps<JSX.IntrinsicElements['h4']>

export const ComboboxPopoverTitle = renderWithRef<MinNativeRef, ComboboxPopoverTitleProps>('ComboboxPopoverTitle', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="h4" classNames={['swan-combobox-popover-title']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
