import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { Box } from '~/react/components/box'
import { RenderComp, renderWithRef } from '~/react/components/core'
import { FlexBox } from '~/react/components/flex-box'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { Link } from '~/react/components/link'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * The number of stars to be filled and the number shown, can have one decimal place
   * @default 0
   */
  ratingValue: PropTypes.number.isRequired,
  /**
   * Hides the numeric rating
   * @default false
   */
  hideRating: PropTypes.bool,
  /**
   * The amount of reviews
   */
  reviews: PropTypes.number,
  /**
   * The link to the reviews
   */
  href: PropTypes.string,
  /**
   * The visual style of the Rating Stars
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'monochromatic'] as const),
}

export type RatingsStarsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const RatingsStars = renderWithRef<MinNativeRef, RatingsStarsProps>(
  'RatingsStars',
  null,
  ({ skin = 'standard', ratingValue = 0, hideRating = false, reviews, href, 'aria-label': ariaLabel, ...props }, ref) => {
    useComponentStylesLoaded('RatingsStars', SWAN_STYLE_KEY_MAP.ratingsStars)

    const [ratingInteger, ratingFractional] = ratingValue
      .toFixed(1)
      .split('.')
      .map(u => Number(u)) // Normalizing to one decimal place

    const classNames = ['swan-ratings']
    if (skin !== 'standard') classNames.push(`swan-ratings-skin-${skin}`)

    return (
      <RenderComp root="div" props={props} forwardedRef={ref} classNames={classNames}>
        <FlexBox className="swan-ratings-stars-container" flexWrap="wrap" alignItems="center" gap={2}>
          <Box className="swan-ratings-stars" role="img" aria-label={ariaLabel}>
            {Array.from({ length: 5 }).map((_, idx) => {
              const ratingClasses = ['swan-ratings-star']
              if (idx + 1 <= ratingInteger) ratingClasses.push('swan-ratings-star-filled')
              if ((ratingFractional && idx + 1) === ratingInteger + 1) ratingClasses.push(`swan-ratings-star-filled-p${ratingFractional}`)
              // eslint-disable-next-line react/no-array-index-key
              return <span key={`star_${idx}`} className={ratingClasses.join(' ')} />
            })}
          </Box>
          {!hideRating && <span className="swan-ratings-stars-rating">{ratingValue}</span>}
          {reviews !== undefined && reviews !== null && <span className="swan-ratings-stars-reviews">({href ? <Link to={href}>{reviews}</Link> : reviews})</span>}
        </FlexBox>
      </RenderComp>
    )
  },
)
