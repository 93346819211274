import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

import { useSwanContext } from '~/react/contexts/swan-provider/swan-provider.context'

const propTypes = {
  /**
   * The visual style of the Link.
   * One of: "standard", "cta", "cta-left", "unstyled".
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'cta', 'cta-left', 'unstyled'] as const),
  /**
   * Set the width of the Link.
   * One of: "standard", "full-width".
   * @default standard
   */
  width: PropTypes.oneOf(['standard', 'full-width'] as const),
  /**
   * Whether the Link's clickable target should cover the entirety of it's nearest ancestor element.
   *
   * @default false
   */
  covering: PropTypes.bool,
  /**
   * Whether this is a "link with icon".
   *
   * @default false
   */
  withIcon: PropTypes.bool,
  /**
   * @deprecated
   * `withIconSeparated` behaves the same as `withIcon`, use `withIcon` instead.
   *
   * @description
   * Whether this "link with icon" has the icon and text visually separated.
   *
   * @default false
   */
  withIconSeparated: deprecatedProp(PropTypes.bool, '`withIconSeparated` behaves the same as `withIcon`, use `withIcon` instead.'),
  /**
   * Sets the destination URL in case you are setting globalLinkComponent(SwanProvider) to 3rd party library's Link (Gatsby, Next.js, React router, etc.).
   */
  to: PropTypes.string,
}

const filteredKeysSet = new Set(Object.keys(propTypes))
filteredKeysSet.delete('to') // 'to' prop needs to be passed through to any globalLinkComponent components
const propKeysToRemove = Array.from(filteredKeysSet)

export type LinkProps = CoreProps<JSX.IntrinsicElements['a'], MinNativeRef, InferProps<typeof propTypes>>

export const Link = renderWithRef<MinNativeRef, LinkProps>('Link', propTypes, (props, ref) => {
  const { children, skin = 'standard', width = 'standard', withIcon = false, withIconSeparated = false, covering = false } = props

  const { globalLinkComponent } = useSwanContext()

  let processedProps = { ...props }

  if (globalLinkComponent) {
    processedProps = {
      ...processedProps,
      as: props.as || props.component || globalLinkComponent,
    }
  }
  if (!props.href && !props.as && !props.component && !props.render) {
    processedProps = {
      href: props.to || undefined,
      ...processedProps,
    }
  }

  const classNames = new Set<string>(['swan-link'])
  if (skin !== 'standard') classNames.add(`swan-link-skin-${skin}`)
  if (width === 'full-width') classNames.add(`swan-link-full-width`)
  if (withIcon || withIconSeparated) classNames.add('swan-link-with-icon')
  if (withIconSeparated) classNames.add('swan-link-with-icon-separated')
  if (covering) classNames.add('swan-link-covering')

  return (
    <RenderComp root="a" forwardedRef={ref} classNames={classNames} props={processedProps} propKeysToRemove={propKeysToRemove}>
      {skin === 'cta-left' && <Icon iconType="arrowLeft" />}
      {children}
      {skin === 'cta' && <Icon iconType="arrowRight" />}
    </RenderComp>
  )
})
