import { ComponentRef, ElementType, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { CoreCreateComponentArg, MinNativeProps, MinNativeRef } from './core.types'
import { getProps } from './core.utils'
import { renderElem } from './render-element.util'
import { renderWithRef } from './render-with-ref'

/**
 * @deprecated Use `renderWithRef` instead. Will be removed in SWAN 4
 */
export function createComponent<
  NP extends object = MinNativeProps,
  R extends ComponentRef<ElementType<NP>> = MinNativeRef<NP>,
  P extends NP = NP,
  DP extends object | null | undefined = undefined,
>({
  propTypes,
  displayName,
  root,
  propConfig,
  requiredStyleKeys = [],
  propKeysToRemove = propTypes ? Object.keys(propTypes) : null,
}: CoreCreateComponentArg<R, NP, P, DP>): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<R>> {
  return renderWithRef<R, P>(displayName, propTypes, (userProps, ref) => {
    useComponentStylesLoaded(displayName, requiredStyleKeys)
    const { props, wrapRoot, children, processedProps } = getProps<NP, P, DP>(userProps, propConfig, propKeysToRemove)

    const rootElement = renderElem<R, NP>({
      root,
      ref,
      renderProps: processedProps,
      props,
      children,
    })

    return wrapRoot ? wrapRoot(rootElement) : rootElement
  })
}
