import { useEffect } from 'react'

import { isNotTestOrProduction, styleKeyVarMap } from '~/core/utilities'

import { SwanStyleSheetKey } from '~/react/components'

// keep track of checked component styles
const styleCheckDone = new Set()

export function useComponentStylesLoaded(componentName: string, requiredStyleKeys: SwanStyleSheetKey | SwanStyleSheetKey[] | null) {
  useEffect(() => {
    const styleKeys = requiredStyleKeys === null ? [] : Array.isArray(requiredStyleKeys) ? requiredStyleKeys : [requiredStyleKeys]

    if (componentName && styleKeys.length > 0 && !styleCheckDone.has(componentName) && isNotTestOrProduction()) {
      // Mark the component as checked
      styleCheckDone.add(componentName)

      setTimeout(() => {
        // Check for the CSS variable value
        const missingKey: string[] = []
        styleKeys.forEach((styleKey: SwanStyleSheetKey) => {
          const cssVarName = styleKeyVarMap[styleKey]
          if (cssVarName.length > 0) {
            const compStyles = getComputedStyle(document.documentElement).getPropertyValue(cssVarName)
            if (!compStyles) {
              missingKey.push(styleKey)
            }
          }
        })

        if (missingKey.length) {
          console.warn(`${componentName} is missing the required style key(s): ${missingKey.join(', ')}`)
        }
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
