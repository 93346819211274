import { MANIFEST_STYLES } from '~/core/manifest'

// typescript will throw an error if a new style key is added and it's not configured here
// we could probably be smarter about this by converting the style key name to kebab case
export const styleKeyVarMap: Record<keyof typeof MANIFEST_STYLES, string> = {
  core: '',
  utility: '',
  accordion: '--swan-accordion',
  alertBox: '--swan-alert-box',
  anchorBar: '--swan-anchor-bar',
  avatar: '--swan-avatar',
  banner: '--swan-banner',
  baseTile: '--swan-base-tile',
  button: '--swan-button' /** @deprecated. button is no longer needed, as the styling is already present in the core stylesheet, so just remove this in v4 */,
  carousel: '--swan-carousel',
  emptyState: '--swan-empty-state',
  fieldSet: '--swan-fieldset' /** @deprecated. fieldSet is no longer needed, as the styling is already present in the core stylesheet, so just remove this in v4 */,
  fileTile: '--swan-file-tile',
  fileDropzone: '--swan-file-dropzone',
  grid: '--swan-grid',
  hidden: '--swan-hidden',
  icon: '--swan-icon' /** @deprecated. icon is no longer needed, as the styling is already present in the core stylesheet, so just remove this in v4 */,
  listbox: '--swan-listbox',
  modalDialog: '--swan-modal-dialog',
  pagination: '--swan-pagination',
  popover: '--swan-popover',
  priceBar: '--swan-price-bar',
  pricing: '--swan-pricing' /** @deprecated. pricing is no longer needed, as the styling is already present in the core stylesheet, so just remove this in v4 */,
  progressBar: '--swan-progress-bar',
  progressiveImage: '',
  ratingsStars: '--swan-ratings-stars',
  selectionSet: '--swan-selection-set',
  spinner: '--swan-spinner',
  standardTile: '--swan-standard-tile',
  statusMessage: '--swan-status-message',
  stepIndicator: '--swan-step-indicator',
  table: '--swan-table',
  tabs: '--swan-tabs',
  thumbnailsHero: '--swan-thumbnails-hero',
  toggleSwitch: '--swan-toggle-switch',
  visible: '--swan-visible',
  zoomControls: '--swan-zoom-controls',
  /* PLOP_STYLE_KEY_IN_STYLE_KEY_UTILS */
  // Please don't remove the above Plop token
}
