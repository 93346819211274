import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { PopoverTrigger } from './popover-trigger.component'

export type PopoverLauncherProps = CoreProps<JSX.IntrinsicElements['span'], HTMLSpanElement>

/**
 * PopoverLauncher wraps PopoverTrigger and adds the trigger to the first element. It matches legacy styling by wrapping trigger in a span with a class
 *
 * Only the first child element will be treated as the trigger element
 *
 * The trigger element MUST be a focusable element for A11Y - no plain text or icons
 *
 * @subcomponent Popover
 */
export const PopoverLauncher = renderWithRef<HTMLSpanElement, PopoverLauncherProps>('PopoverLauncher', null, (props, ref) => {
  const { children } = props
  const classNames = ['swan-popover-launcher']
  return (
    <RenderComp root="span" forwardedRef={ref} classNames={classNames} props={props}>
      <PopoverTrigger>{children}</PopoverTrigger>
    </RenderComp>
  )
})
