import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type PriceBarBannerSegmentImageProps = CoreProps<JSX.IntrinsicElements['img'], MinNativeRef>

/**
 * @subcomponent PriceBar
 */
export const PriceBarBannerSegmentImage = renderWithRef<MinNativeRef, PriceBarBannerSegmentImageProps>('PriceBarBannerSegmentImage', null, (props, ref) => {
  const classNames = new Set(['swan-price-bar-banner-icon'])

  return <RenderComp root="img" forwardedRef={ref} classNames={classNames} props={props}></RenderComp>
})
