import { InferProps } from 'prop-types'
import { ComponentProps } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { BoundedContent } from '~/react/components/bounded-content'
import { renderWithRef } from '~/react/components/core'

import { BannerWithLeftHandNavigationBelowBannerContents } from './banner-with-left-hand-navigation-below-banner-contents.component'

const propTypes = {}

type NativeProps = ComponentProps<typeof BoundedContent>
export type BannerWithLeftHandNavigationBelowBannerProps = CoreProps<NativeProps, MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Banner
 */
export const BannerWithLeftHandNavigationBelowBanner = renderWithRef<MinNativeRef, BannerWithLeftHandNavigationBelowBannerProps>(
  'BannerWithLeftHandNavigationBelowBanner',
  propTypes,
  (props, ref) => {
    return (
      <BoundedContent ref={ref} {...props}>
        <BannerWithLeftHandNavigationBelowBannerContents>{props.children}</BannerWithLeftHandNavigationBelowBannerContents>
      </BoundedContent>
    )
  },
)
