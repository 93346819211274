import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { Icon, IconProps } from '~/react/components/icon'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

type StatusMessageCustomProps = {
  /**
   * The visual style of the StatusMessage
   *
   * @default info
   */
  skin?: 'info' | 'success' | 'warning' | 'error' | 'help'
  /**
   * The size of the StatusMessage
   *
   * @default standard
   */
  size?: 'standard' | 'small'
  /**
   * Accessible text for the icon, that should be used for screen readers.
   * Typically it should indicate the type of alert eg. "info", "warning"
   */
  accessibleTextForIcon?: string // TODO: v4 make this props required
}

export type StatusMessageProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, StatusMessageCustomProps>

const iconMap: Record<NonNullable<StatusMessageProps['skin']>, IconProps['iconType']> = { info: 'info', success: 'success', warning: 'warning', error: 'error', help: 'helpQuery' }

export const StatusMessage = renderWithRef<MinNativeRef, StatusMessageProps>('StatusMessage', null, (props, ref) => {
  useComponentStylesLoaded('StatusMessage', SWAN_STYLE_KEY_MAP.statusMessage)

  const { children, skin = 'info', size = 'standard', accessibleTextForIcon, ...rest } = props

  const classNames = new Set(['swan-status-message', `swan-status-message-skin-${skin}`, `swan-status-message-size-${size}`])

  return (
    <RenderComp classNames={classNames} root="div" forwardedRef={ref} props={rest}>
      <Icon skin={skin} iconType={iconMap[skin]} size={size} alt={accessibleTextForIcon || ''} />
      {children}
    </RenderComp>
  )
})
