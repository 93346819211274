import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const OneThroughTwelve = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const
const ZeroThroughTwelve = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const
const propTypes = {
  /**
   * The number of columns that this Column should occupy
   */
  span: PropTypes.oneOf(OneThroughTwelve).isRequired,
  /**
   * The number of columns that this Column should occupy on extra-small screens
   */
  spanXs: PropTypes.oneOf(OneThroughTwelve),
  /**
   * The number of columns that this Column should occupy on small screens
   */
  spanSm: PropTypes.oneOf(OneThroughTwelve),
  /**
   * The number of columns that this Column should occupy on medium screens
   */
  spanMd: PropTypes.oneOf(OneThroughTwelve),
  /**
   * The number of columns that this Column should occupy on large screens
   */
  spanLg: PropTypes.oneOf(OneThroughTwelve),
  /**
   * The number of columns that this Column should occupy on X-large screens
   */
  spanXl: PropTypes.oneOf(OneThroughTwelve),
  /**
   * The default number of columns that this column should be shifted to the right
   */
  offset: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * The number of columns that this column should be shifted to the right on small screens
   */
  offsetSm: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * The number of columns that this column should be shifted to the right on medium screens
   */
  offsetMd: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * The number of columns that this column should be shifted to the right on large screens
   */
  offsetLg: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * The number of columns that this column should be shifted to the right on X-large screens
   */
  offsetXl: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order by pushing it to the right by a specified number of columns.
   */
  push: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on small screens by pushing it to the right by a specified number of columns.
   */
  pushSm: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on medium screens by pushing it to the right by a specified number of columns.
   */
  pushMd: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on large screens by pushing it to the right by a specified number of columns.
   */
  pushLg: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on X-large screens by pushing it to the right by a specified number of columns.
   */
  pushXl: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order by pulling it to the left by a specified number of columns.
   */
  pull: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on small screens by pulling it to the left by a specified number of columns.
   */
  pullSm: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on medium screens by pulling it to the left by a specified number of columns.
   */
  pullMd: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on large screens by pulling it to the left by a specified number of columns.
   */
  pullLg: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * Change the column order on X-large screens by pulling it to the left by a specified number of columns.
   */
  pullXl: PropTypes.oneOf(ZeroThroughTwelve),
  /**
   * In a lineWrap GridContainer, decide whether to arrange the Column's children as a single column, evenly spaced between the top and bottom.
   * @default false
   */
  flexColumn: PropTypes.bool,
  /**
   * Whether or not the Column's content should be vertically centered within the Row
   * @default false
   */
  verticallyCenterContent: PropTypes.bool,
  /**
   *  Whether or not the Column's content should be sticky
   * @default false
   */
  sticky: PropTypes.bool,
  /**
   * If a Column is normally sticky, which screen sizes the Column should NOT be sticky on
   * Available options: "none", "xs"
   * @default none
   */
  unsticky: PropTypes.oneOf(['none', 'xs'] as const),
  /**
   * Whether the Column is a Floating Container
   * @default false
   */
  floatingContainer: PropTypes.bool,
  /**
   * Alignment for floating Container
   * @default left
   */
  floatingContainerAlignment: PropTypes.oneOf(['left', 'right'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type ColumnProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent GridContainer
 */
export const Column = renderWithRef<MinNativeRef, ColumnProps>('Column', null, ({ children, ...props }, ref) => {
  const {
    span,
    spanXs,
    spanSm,
    spanMd,
    spanLg,
    spanXl,
    offset,
    offsetSm,
    offsetMd,
    offsetLg,
    offsetXl,
    push,
    pushSm,
    pushMd,
    pushLg,
    pushXl,
    pull,
    pullSm,
    pullMd,
    pullLg,
    pullXl,
    flexColumn = false,
    verticallyCenterContent = false,
    sticky = false,
    unsticky = 'none',
    floatingContainer = false,
    floatingContainerAlignment = 'left',
  } = props
  const classNames = [`swan-col-${span}`]
  if (spanXs !== undefined) classNames.push(`swan-col-xs-${spanXs}`)
  if (spanSm !== undefined) classNames.push(`swan-col-sm-${spanSm}`)
  if (spanMd !== undefined) classNames.push(`swan-col-md-${spanMd}`)
  if (spanLg !== undefined) classNames.push(`swan-col-lg-${spanLg}`)
  if (spanXl !== undefined) classNames.push(`swan-col-xl-${spanXl}`)

  if (offset !== undefined) classNames.push(`swan-col-offset-${offset}`)
  if (offsetSm !== undefined) classNames.push(`swan-col-sm-offset-${offsetSm}`)
  if (offsetMd !== undefined) classNames.push(`swan-col-md-offset-${offsetMd}`)
  if (offsetLg !== undefined) classNames.push(`swan-col-lg-offset-${offsetLg}`)
  if (offsetXl !== undefined) classNames.push(`swan-col-xl-offset-${offsetXl}`)

  if (pull !== undefined) classNames.push(`swan-col-pull-${pull}`)
  if (pullSm !== undefined) classNames.push(`swan-col-sm-pull-${pullSm}`)
  if (pullMd !== undefined) classNames.push(`swan-col-md-pull-${pullMd}`)
  if (pullLg !== undefined) classNames.push(`swan-col-lg-pull-${pullLg}`)
  if (pullXl !== undefined) classNames.push(`swan-col-xl-pull-${pullXl}`)

  if (push !== undefined) classNames.push(`swan-col-push-${push}`)
  if (pushSm !== undefined) classNames.push(`swan-col-sm-push-${pushSm}`)
  if (pushMd !== undefined) classNames.push(`swan-col-md-push-${pushMd}`)
  if (pushLg !== undefined) classNames.push(`swan-col-lg-push-${pushLg}`)
  if (pushXl !== undefined) classNames.push(`swan-col-xl-push-${pushXl}`)

  if (flexColumn) classNames.push('swan-col-flex-column')
  if (verticallyCenterContent) classNames.push('swan-col-vertically-center')
  if (sticky) classNames.push('swan-col-sticky')
  if (unsticky === 'xs') classNames.push('swan-col-unsticky-xs')
  if (floatingContainer) classNames.push('swan-floating-container')
  if (floatingContainerAlignment === 'right') classNames.push('swan-floating-container-right')

  return (
    <RenderComp root="div" forwardedRef={ref} props={props} propKeysToRemove={propKeysToRemove} classNames={classNames}>
      {children}
    </RenderComp>
  )
})
