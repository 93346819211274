import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { useProgressBarContext } from '~/react/components/progress-bar/progress-bar.context'

export type ProgressOutputProps = CoreProps<JSX.IntrinsicElements['output'], MinNativeRef>

/**
 * @subcomponent ProgressBar
 */
export const ProgressOutput = renderWithRef<MinNativeRef, ProgressOutputProps>('ProgressOutput', null, ({ children, ...props }, ref) => {
  const ctx = useProgressBarContext()
  return (
    <RenderComp root="output" classNames={['swan-progress-label']} forwardedRef={ref} props={{ ...props, htmlFor: props.htmlFor ?? ctx?.id }}>
      {children}
    </RenderComp>
  )
})
