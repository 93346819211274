import { createContext, Dispatch, SetStateAction, useContext, useEffect } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type FormFieldContextValue = {
  inputId: string | undefined
  setInputId: Dispatch<SetStateAction<string | undefined>>

  errorIds: Set<string>
  setErrorIds: Dispatch<SetStateAction<Set<string>>>

  helperIds: Set<string>
  setHelperIds: Dispatch<SetStateAction<Set<string>>>
}

export const FormFieldContext = createContext<FormFieldContextValue | undefined | null>(null)
FormFieldContext.displayName = 'FormFieldContext'

export const useFormFieldContext = () =>
  // might return null
  useContext(FormFieldContext)

export const useNonNullishFormFieldContext = () => useNonNullishContext(FormFieldContext)

export function useFormFieldProps(id?: string) {
  const formFieldContext = useFormFieldContext()

  useEffect(() => {
    if (formFieldContext) {
      const { setInputId } = formFieldContext
      setInputId(id)

      return () => setInputId(undefined)
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }, [formFieldContext, id])

  // if we're not inside of a FormInput, we've got nothing to say
  if (formFieldContext === undefined || formFieldContext === null) {
    return {
      id,
    }
  }

  const { errorIds, helperIds } = formFieldContext
  return {
    id,
    'aria-invalid': errorIds.size > 0,
    // TODO: having multiple ids for aria-errormessage doesn't appear to be valid and axe will complain
    'aria-errormessage': errorIds.size > 0 ? [...errorIds].join(' ') : undefined,
    'aria-describedby': errorIds.size + helperIds.size > 0 ? [...helperIds, ...errorIds].join(' ') : undefined,
  }
}
