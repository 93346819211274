import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BannerPriceProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent Banner
 */
export const BannerPrice = renderWithRef<MinNativeRef, BannerPriceProps>('BannerPrice', {}, (props, ref) => {
  const classNames = ['swan-banner-price']
  const { children } = props
  return (
    <RenderComp root="p" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
