import { useEffect } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks/use-id'

import { useModalDialogContext } from './modal-dialog.context'

export type ModalDialogTitleProps = CoreProps<JSX.IntrinsicElements['h2'], MinNativeRef>

/**
 * @subcomponent Dialog
 */
export const ModalDialogTitle = renderWithRef<MinNativeRef, ModalDialogTitleProps>('ModalDialogTitle', null, ({ children, id: customId, ...props }, ref) => {
  const id = useId(customId)
  const { setTitleId } = useModalDialogContext()
  useEffect(() => {
    setTitleId(id)
    // when the component unmounts, tell the parent that it is no longer labeled
    return () => setTitleId(undefined)
  }, [id, setTitleId])
  return (
    <RenderComp root="h2" forwardedRef={ref} props={{ ...props, id }} classNames={['swan-modal-dialog-title']}>
      {children}
    </RenderComp>
  )
})
