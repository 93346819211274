import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {}

const propKeysToRemove = Object.keys(propTypes)

export type MarkupProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

export const Markup = renderWithRef<MinNativeRef, MarkupProps>('Markup', propTypes, (props, ref) => {
  const { children } = props

  const classNames = new Set<string>(['swan-markup'])
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
      {children}
    </RenderComp>
  )
})

export const UncustomizableMarkup = Markup
