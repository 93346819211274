import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Visual style of StepIndicatorStepValue
   * Available options: "standard", "error"
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'error'] as const),
}

export type StepIndicatorStepValueProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent StepIndicator
 */
export const StepIndicatorStepValue = renderWithRef<MinNativeRef, StepIndicatorStepValueProps>('StepIndicatorStepValue', null, ({ children, skin = 'standard', ...props }, ref) => {
  const classNames = new Set<string>(['swan-step-value'])
  if (skin !== 'standard') classNames.add(`swan-step-value-skin-${skin}`)

  return (
    <RenderComp root="span" classNames={classNames} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
