import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type SiteMainProps = CoreProps<JSX.IntrinsicElements['main'], MinNativeRef>

export const SiteMain = renderWithRef<MinNativeRef, SiteMainProps>('SiteMain', null, ({ children, ...props }, ref) => (
  <RenderComp root="main" classNames={['swan-site-main']} forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
