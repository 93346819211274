import { createContext } from 'react'

import { StyleBackgroundColor } from '~/core/types'
import { Responsive } from '~/core/types/utility.types'

import { useNonNullishContext } from '~/react/hooks'
import { TransitionStatus } from '~/react/hooks/use-transition'

import { ModalDialogBodyWidths, ModalDialogVariant } from './modal-dialog.types'

export type ModalDialogContextType = {
  variant: ModalDialogVariant | null
  titleId: string | undefined | null
  setTitleId: (id: string | undefined | null) => void
  takeOver: boolean
  isModal: boolean
  bodyWidth: (typeof ModalDialogBodyWidths)[number] | undefined
  footerPinned: boolean | undefined
  setFooterPinned: (pinned: boolean | undefined) => void
  transitionState: TransitionStatus
  handleModalCloseButton: () => void
  bgc: Responsive<StyleBackgroundColor> | undefined
  backgroundColor: Responsive<StyleBackgroundColor> | undefined
}

export const ModalDialogContext = createContext<ModalDialogContextType | null | undefined>(null)
ModalDialogContext.displayName = 'ModalDialogContext'

export function useModalDialogContext() {
  return useNonNullishContext(ModalDialogContext)
}
