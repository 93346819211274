import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * which skin to use. Standard uses the vista blue and Alert uses the red alert color
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'alert'] as const),
}

export type CountProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

export const Count = renderWithRef<MinNativeRef, CountProps>('Count', null, ({ children, skin = 'standard', ...props }, ref) => {
  const classNameSet = new Set<string>(['swan-count'])

  if (skin != 'standard') classNameSet.add(`swan-count-skin-${skin}`)

  return (
    <RenderComp root="span" classNames={classNameSet} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
