import { CoreInputProps, CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type RadioButtonProps = CoreProps<CoreInputProps, MinNativeRef>

export const RadioButton = renderWithRef<MinNativeRef, RadioButtonProps>('RadioButton', null, ({ children, ...props }, ref) => (
  <RenderComp root="input" classNames={['swan-radio']} forwardedRef={ref} props={{ ...props, type: 'radio' }}>
    {children}
  </RenderComp>
))
