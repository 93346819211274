import { useState } from 'react'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'
import { RenderComp } from '~/react/components/core/render-comp.component'
import { renderWithRef } from '~/react/components/core/render-with-ref'

import { FormFieldContext } from './form.context'

export type FormFieldProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardForm
 */
export const FormField = renderWithRef<MinNativeRef, FormFieldProps>('FormField', null, ({ children, ...props }, ref) => {
  const [inputId, setInputId] = useState<string | undefined>(undefined)
  const [helperIds, setHelperIds] = useState<Set<string>>(new Set())
  const [errorIds, setErrorIds] = useState<Set<string>>(new Set())

  return (
    <FormFieldContext.Provider value={{ inputId, setInputId, helperIds, setHelperIds, errorIds, setErrorIds }}>
      <RenderComp root="div" forwardedRef={ref} props={props}>
        {children}
      </RenderComp>
    </FormFieldContext.Provider>
  )
})
