import PropTypes, { InferProps } from 'prop-types'

import { classNames } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useStepIndicatorContext } from './step-indicator-context'

const propTypes = {
  /**
   * Number of the step
   * @default 1
   */
  stepNumber: PropTypes.number.isRequired,
}
const propKeysToRemove = Object.keys(propTypes)

export type StepIndicatorStepProps = CoreProps<JSX.IntrinsicElements['li'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent StepIndicator
 */
export const StepIndicatorStep = renderWithRef<MinNativeRef, StepIndicatorStepProps>('StepIndicatorStep', propTypes, (props, ref) => {
  const { currentStepNumber } = useStepIndicatorContext()
  const { stepNumber = 1, children } = props
  const className = classNames(new Set(['swan-step']), {
    'swan-step-past': stepNumber < currentStepNumber,
    'swan-step-previous': stepNumber === currentStepNumber - 1,
    'swan-step-current': stepNumber === currentStepNumber,
    'swan-step-next': stepNumber === currentStepNumber + 1,
    'swan-step-future': stepNumber > currentStepNumber,
  })

  return (
    <RenderComp
      root="li"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      classNames={className}
      props={{
        ...props,
        ...(stepNumber === currentStepNumber ? { 'aria-current': 'step' } : {}),
      }}
    >
      {children}
    </RenderComp>
  )
})
