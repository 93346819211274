import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

type NativeProps = JSX.IntrinsicElements[keyof JSX.IntrinsicElements]

export type BoxProps = CoreProps<NativeProps>

export const Box = renderWithRef<MinNativeRef, BoxProps>('Box', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
