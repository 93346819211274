import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type TableCaptionProps = CoreProps<JSX.IntrinsicElements['caption'], MinNativeRef>

/**
 * @subcomponent Table
 */
export const TableCaption = renderWithRef<MinNativeRef, TableCaptionProps>('TableBody', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="caption" classNames={['swan-table-caption']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
