import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type FormLabelOptionalProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent StandardForm
 */
export const FormLabelOptional = renderWithRef<MinNativeRef, FormLabelOptionalProps>('FormLabelOptional', null, ({ children, ...props }, ref) => (
  <RenderComp root="span" forwardedRef={ref} props={props} classNames={['swan-label-optional']}>
    {children}
  </RenderComp>
))
