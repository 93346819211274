import { InferProps } from 'prop-types'
import type { ComponentRef } from 'react'

import { processStyleProps } from '~/core/utilities'

import { CoreProps, filterProps, renderWithRef } from '~/react/components/core'

import { ComboboxInput, ComboboxInputProps } from './combobox-input.component'
import { ComboboxList } from './combobox-list.component'
import { ComboboxPopover, ComboboxPopoverProps } from './combobox-popover.component'
import { Combobox, ComboboxProps } from './combobox.component'

const propTypes = {}

type NativeProps = ComboboxProps & ComboboxInputProps & ComboboxPopoverProps
type NativeRef = ComponentRef<typeof ComboboxInput>

export type BasicComboboxProps = CoreProps<NativeProps, NativeRef, InferProps<typeof propTypes>>

/**
 * Wrapper over Combobox for basic usecases
 *
 * @subcomponent Combobox
 */
export const BasicCombobox = renderWithRef<NativeRef, BasicComboboxProps>('BasicCombobox', undefined, (props, ref) => {
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    onSelectionChange,
    disableAutoFilter,
    selectedKey,
    inModal,
    children,
    className,
    allowsCustomValue,
    items,
    menuTrigger = 'input',
  } = props
  const processedClassName = Array.from(processStyleProps(props, className ? [className] : undefined)).join(' ')
  const comboboxInputProps = filterProps(props, [
    'allowsCustomValue',
    'onSelectionChange',
    'disableAutoFilter',
    'selectedKey',
    'aria-label',
    'aria-labelledby',
    'inModal',
    'items',
    'menuTrigger',
  ])
  return (
    <Combobox
      items={items}
      onSelectionChange={onSelectionChange}
      disableAutoFilter={disableAutoFilter}
      selectedKey={selectedKey}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={processedClassName}
      allowsCustomValue={allowsCustomValue}
      menuTrigger={menuTrigger}
    >
      <ComboboxInput ref={ref} {...comboboxInputProps} />
      <ComboboxPopover inModal={inModal}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <ComboboxList>{children as unknown as any}</ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
})
