import PropTypes, { InferProps } from 'prop-types'
import { ChangeEvent } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { filterProps, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useControllableValue, useId } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'
import { conditionalPropType, nullishPropType } from '~/react/prop-types'

import { SelectedValue, SelectedValues, SelectionSetContext } from './selection-set.context'

const isMultiSelect = (props: { variant?: string | null }) => props.variant === 'multi-select'

const propTypes = {
  /**
   * The visual style of the SelectionSet
   * Available options: "standard", "buttons", "simple-column", "tiles-horizontal", "tiles-vertical", "tiles-mini"
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'buttons', 'simple-column', 'tiles-horizontal', 'tiles-vertical', 'tiles-mini'] as const),

  /**
   * The name of the SelectionSet. Used to group options together
   */
  name: PropTypes.string,
  /**
   * The variant of SelectionSet
   * `single-select` where only one option can be selected.
   * `multi-select` where more than one option can be selected at once.
   * @default single-select
   */
  variant: PropTypes.oneOf(['single-select', 'multi-select'] as const),
  /**
   * The image width
   * Available options: "fixed", "proportional"
   *
   * @default fixed
   */
  imageWidth: PropTypes.oneOf(['fixed', 'proportional'] as const),
  /**
   * How images should be aligned vertically
   * Available options: "cover", "top", "center"
   *
   * @default cover
   */
  imageVerticalAlignment: PropTypes.oneOf(['cover', 'top', 'center'] as const),
  /**
   * Padding for tile contents
   * Available options: "standard", "tight"
   *
   * @default standard
   */
  tileContentsPadding: PropTypes.oneOf(['standard', 'tight'] as const),
  /**
   * Padding for tile image
   * Available options: "standard", "wide"
   *
   * @default standard
   */
  tileImagePadding: PropTypes.oneOf(['standard', 'wide'] as const),

  // For Single select
  /**
   * Selected value for single select variant
   */
  selectedValue: conditionalPropType(isMultiSelect, nullishPropType, PropTypes.string),
  /**
   * Default selected value for single select variant
   */
  defaultSelectedValue: conditionalPropType(isMultiSelect, nullishPropType, PropTypes.string),
  /**
   * Callback function for handling single select variant value selection events
   */
  onSelectedValueChange: conditionalPropType(
    isMultiSelect,
    nullishPropType,
    PropTypes.func as PropTypes.Requireable<(selectedValue: SelectedValue, event: ChangeEvent<HTMLInputElement>) => void>,
  ),

  // For multi select
  /**
   * Selected values for multi select variant
   */
  selectedValues: conditionalPropType(isMultiSelect, PropTypes.objectOf(PropTypes.bool.isRequired), nullishPropType),
  /**
   * Default selected value for multi select variant
   */
  defaultSelectedValues: conditionalPropType(isMultiSelect, PropTypes.objectOf(PropTypes.bool.isRequired), nullishPropType),
  /**
   * Callback function for handling multi select variant value selection events
   */
  onSelectedValuesChange: conditionalPropType(
    isMultiSelect,
    PropTypes.func as PropTypes.Requireable<(newSelectedValues: SelectedValues, event: ChangeEvent<HTMLInputElement>) => void>,
    nullishPropType,
  ),
}

type SelectionSetPropTypes = InferProps<typeof propTypes>
type SelectionSetSingleSelectProps = Pick<
  SelectionSetPropTypes,
  'skin' | 'name' | 'imageWidth' | 'imageVerticalAlignment' | 'tileContentsPadding' | 'tileImagePadding' | 'selectedValue' | 'defaultSelectedValue' | 'onSelectedValueChange'
> & {
  /**
   * `single-select` where only one option can be selected.
   * @default single-select
   */
  variant?: 'single-select' | null
  selectedValues?: never
  defaultSelectedValues?: never
  onSelectedValuesChange?: never
}

type SelectionSetMultiSelectProps = Pick<
  SelectionSetPropTypes,
  'skin' | 'name' | 'imageWidth' | 'imageVerticalAlignment' | 'tileContentsPadding' | 'tileImagePadding' | 'selectedValues' | 'defaultSelectedValues' | 'onSelectedValuesChange'
> & {
  /**
   * `multi-select` where more than one option can be selected at once.
   */
  variant: 'multi-select'
  selectedValue?: never
  defaultSelectedValue?: never
  onSelectedValueChange?: never
}

const propKeysToRemove = Object.keys(propTypes)

export type SelectionSetProps = CoreProps<JSX.IntrinsicElements['div'], HTMLDivElement, SelectionSetSingleSelectProps | SelectionSetMultiSelectProps>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectionSet = renderWithRef<MinNativeRef, SelectionSetProps>('SelectionSet', propTypes as any, (props, ref) => {
  useComponentStylesLoaded('SelectionSet', SWAN_STYLE_KEY_MAP.selectionSet)
  const {
    children,
    variant = 'single-select',
    skin = 'standard',
    imageWidth = 'fixed',
    imageVerticalAlignment = 'cover',
    tileContentsPadding = 'standard',
    tileImagePadding = 'standard',

    selectedValue: controlledSelectedValue,
    defaultSelectedValue = null,
    onSelectedValueChange: controlledOnSelectedValueChange,

    selectedValues: controlledSelectedValues,
    defaultSelectedValues = {},
    onSelectedValuesChange: controlledOnSelectedValuesChange,

    name,
  } = props
  const classNames = new Set<string>(['swan-selection-set'])
  if (skin !== 'standard') classNames.add(`swan-selection-set-skin-${skin}`)
  if (skin === 'simple-column') classNames.add('swan-selection-set-show-inputs')
  if (imageWidth === 'proportional') classNames.add('swan-selection-set-image-width-proportional')
  if (imageVerticalAlignment === 'center') classNames.add('swan-selection-set-image-vertical-alignment-center')
  if (tileContentsPadding === 'tight') classNames.add('swan-selection-set-tile-contents-padding-tight')
  if (tileImagePadding === 'wide') classNames.add('swan-selection-set-tile-image-padding-wide')

  const inputName = useId(name)

  // state for single-select

  const [selectedValue, onSelectedValueChange] = useControllableValue<SelectedValue, [NonNullable<SelectedValue>, ChangeEvent<HTMLInputElement>]>({
    value: controlledSelectedValue,
    onChange: controlledOnSelectedValueChange || undefined,
    defaultValue: defaultSelectedValue,
    defaultOnChange: (setValue, ...onChangeArgs) => {
      const [newValue] = onChangeArgs
      setValue(newValue)
    },
  })

  // state for multi-select
  const [selectedValues, onSelectedValuesChange] = useControllableValue<SelectedValues, [SelectedValues, ChangeEvent<HTMLInputElement>]>({
    value: controlledSelectedValues ?? undefined,
    onChange: controlledOnSelectedValuesChange || undefined,
    defaultValue: defaultSelectedValues || {},
    defaultOnChange: (setValue, ...onChangeArgs) => {
      const [newSelectedValues] = onChangeArgs

      setValue(newSelectedValues)
    },
  })

  const overriddenProps = {
    ...filterProps(props, null, null),
    role: variant === 'single-select' ? 'radiogroup' : 'group',
  }

  return (
    <SelectionSetContext.Provider
      value={{
        name: inputName,
        variant,
        skin,
        imageWidth,
        imageVerticalAlignment,
        tileContentsPadding,
        tileImagePadding,
        selectedValue,
        onSelectedValueChange,
        selectedValues,
        onSelectedValuesChange,
      }}
    >
      <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ ...props, ...overriddenProps }}>
        {children}
      </RenderComp>
    </SelectionSetContext.Provider>
  )
})
