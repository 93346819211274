import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Should it be active or not
   *
   * @default false
   */
  active: PropTypes.bool,
  /**
   * Should it be disabled or not
   *
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * Provide the text that should be used for screen readers.
   */
  accessibleText: PropTypes.string,
}

export type PaginationStepProps = CoreProps<JSX.IntrinsicElements['a'], HTMLAnchorElement, InferProps<typeof propTypes>>

/**
 * @subcomponent Pagination
 */
export const PaginationStep = renderWithRef<MinNativeRef, PaginationStepProps>(
  'PaginationStep',
  null,
  ({ children, active = false, disabled = false, accessibleText, as, component, ...props }, ref) => {
    const renderAsSpan = disabled && as !== 'button' && component !== 'button'

    const processedProps = {
      'aria-label': accessibleText ?? undefined,
      ...props,
      disabled: (as === 'button' || component === 'button') && disabled,
      as: (renderAsSpan && 'span') || as || component || 'a',
      'aria-current': !!active,
    }

    const classNames = new Set<string>(['swan-pagination-step'])
    if (disabled) classNames.add('swan-pagination-step-disabled')
    if (active) classNames.add('swan-pagination-step-active')

    return (
      <li>
        <RenderComp root="a" classNames={classNames} forwardedRef={ref} props={processedProps}>
          {children}
        </RenderComp>
      </li>
    )
  },
)
