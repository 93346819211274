import { MANIFEST_ILLUSTRATIONS } from '~/core/manifest/illustrations'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types'

import { getSameKeyValueMapForManifest, manifestResolve } from './manifest.utils'

export type SwanIllustrationKey = keyof typeof MANIFEST_ILLUSTRATIONS
export type IllustrationManifestType = Record<SwanIllustrationKey, Record<SwanPathTypeEnum, string>>

export const SWAN_ILLUSTRATION_KEY_MAP = getSameKeyValueMapForManifest(MANIFEST_ILLUSTRATIONS)

export function getSwanIllustrationUrl(key: SwanIllustrationKey, pathType?: SwanPathTypeEnum, swanAssetBaseUrl?: string) {
  return manifestResolve(MANIFEST_ILLUSTRATIONS, key, SwanAssetFolders.ILLUSTRATIONS, pathType, swanAssetBaseUrl)
}
