import { useEffect } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { useCollapsibleContext } from './collapsible.context'

export type CollapsibleContentProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Collapsible
 */
export const CollapsibleContent = renderWithRef<MinNativeRef, CollapsibleContentProps>('CollapsibleContent', null, (props, ref) => {
  const { id: customId } = props
  const id = useId(customId)
  const { setContentId, expanded } = useCollapsibleContext()
  useEffect(() => {
    setContentId(id)
    // tell the parent that the id no longer exists when this comp unmounts
    return () => setContentId(undefined)
  }, [id, setContentId])

  const classNames = new Set(['swan-collapsible-content'])
  if (expanded) classNames.add('swan-collapsible-content-open')

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={{ ...props, id }}>
      {props.children}
    </RenderComp>
  )
})
