import { useMemo } from 'react'

import { SWAN_BASE_CDN_URL } from '~/core/constants'
import { SwanPathTypeEnum } from '~/core/types'
import { getSwanBaseUrl } from '~/core/utilities'

import { SwanContextValue, useSwanContext } from '~/react/contexts/swan-provider/swan-provider.context'

export function useSwanHeadConfig(baseUrl?: string | null, pathType?: SwanPathTypeEnum | null, tenant?: string | null, locale?: string | null) {
  const { swanBaseUrl, swanPathType, swanTenant, swanLocale } = useSwanContext()

  return useMemo((): Pick<SwanContextValue, 'swanPathType' | 'swanBaseUrl'> => {
    const finalComputedUrl = swanBaseUrl === SWAN_BASE_CDN_URL ? getSwanBaseUrl(tenant || swanTenant, locale || swanLocale) : swanBaseUrl
    return {
      swanPathType: pathType || swanPathType,
      swanBaseUrl: baseUrl || finalComputedUrl,
    }
  }, [swanPathType, swanBaseUrl, swanTenant, swanLocale, baseUrl, pathType, tenant, locale])
}
