import PropTypes, { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { Button } from '~/react/components/button'
import { renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

const propTypes = {
  /**
   * The localized accessible text used for screen readers
   */
  accessibleText: PropTypes.string.isRequired,
}

export type FileTileDeleteButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent FileTile
 */
export const FileTileDeleteButton = renderWithRef<MinNativeRef, FileTileDeleteButtonProps>('FileTileDeleteButton', propTypes, (props, ref) => {
  const { accessibleText, ...rest } = props

  return (
    <Button className={'swan-file-tile-delete-button'} buttonShape="round" aria-label={accessibleText} ref={ref} {...rest}>
      <Icon iconType="delete" />
    </Button>
  )
})
