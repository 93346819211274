import { useEffect } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { usePriceBarQuantityContext } from './price-bar.context'

export type PriceBarQuantityAmountProps = CoreProps<JSX.IntrinsicElements['span']>

/**
 * @subcomponent PriceBar
 */
export const PriceBarQuantityAmount = renderWithRef<MinNativeRef, PriceBarQuantityAmountProps>('PriceBarQuantityAmount', null, (props, ref) => {
  const classNames = new Set(['swan-price-bar-button-quantity-amount'])
  const { id: customId, children } = props
  const { buttonId, setButtonId, labelId } = usePriceBarQuantityContext()

  const id = useId(customId)

  useEffect(() => {
    setButtonId(id)

    return () => setButtonId(undefined)
  }, [id, setButtonId])

  return (
    <RenderComp root="span" forwardedRef={ref} classNames={classNames} props={{ ...props, id: buttonId, 'aria-labelledby': labelId }}>
      {children}
    </RenderComp>
  )
})
