import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ThumbnailsHeroThumbnailsSlidesContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent ThumbnailsHero
 */
export const ThumbnailsHeroThumbnailsSlidesContainer = renderWithRef<MinNativeRef, ThumbnailsHeroThumbnailsSlidesContainerProps>(
  'ThumbnailsHeroThumbnailsSlidesContainer',
  null,
  ({ children, ...props }, ref) => {
    return (
      <RenderComp root="div" forwardedRef={ref} classNames={['swan-thumbnails-hero-thumbnails-slides']} props={props}>
        {children}
      </RenderComp>
    )
  },
)
