import { SWAN_VERSION } from '~/core/constants'

export const SWAN_VERSION_META_NAME = 'swanVersion'

export function createSwanVersionMetaTag() {
  if (typeof window !== 'undefined' && !document.querySelector('meta[name="swanVersion"]')) {
    const metaTag = document.createElement('meta')
    metaTag.name = SWAN_VERSION_META_NAME
    metaTag.content = SWAN_VERSION
    document.head.appendChild(metaTag)
  }
}

export function createSwanGlobalObject(swanBaseUrl?: string) {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as unknown as any).__swan = {
      version: SWAN_VERSION,
      swanBaseUrl: swanBaseUrl,
    }
  }
}
