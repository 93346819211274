import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useButtonbarButtonContext } from './buttonbar.context'

export type ButtonbarButtonLabelProps = CoreProps<JSX.IntrinsicElements['label'], HTMLLabelElement>

/**
 * @subcomponent Buttonbar
 */
export const ButtonbarButtonLabel = renderWithRef<MinNativeRef, ButtonbarButtonLabelProps>('ButtonbarButtonLabel', null, (props, ref) => {
  const buttonbarButtonContext = useButtonbarButtonContext()

  return (
    <RenderComp root="label" forwardedRef={ref} props={{ ...props, htmlFor: buttonbarButtonContext?.inputId }}>
      {props.children}
    </RenderComp>
  )
})
