import { PropsWithChildren } from 'react'

import { processStyleProps } from '~/core/utilities'

import { CoreRenderComp } from './core.types'
import { filterProps } from './core.utils'
import { renderElem } from './render-element.util'

export function RenderComp<R, NP extends object, P extends object>({
  root,
  props,
  propKeysToRemove,
  forwardedRef,
  classNames,
  children,
}: PropsWithChildren<CoreRenderComp<R, NP, P>>) {
  const classNameIter = processStyleProps(props, classNames)
  const forwardedProps = filterProps(props, propKeysToRemove, classNameIter) as NP
  return renderElem<R, NP>({
    root,
    ref: forwardedRef,
    renderProps: props,
    props: forwardedProps,
    children,
  })
}
