import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type ListPriceProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent Pricing
 */

export const ListPrice = renderWithRef<MinNativeRef, ListPriceProps>('ListPrice', null, (props, ref) => {
  const { children } = props
  const classNames = new Set(['swan-list-price'])

  return (
    <RenderComp root="span" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
