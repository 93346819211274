import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type PriceBarInfoDetailsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent PriceBar
 */
export const PriceBarInfoDetails = renderWithRef<MinNativeRef, PriceBarInfoDetailsProps>('PriceBarInfoDetails', null, (props, ref) => {
  const { children } = props
  const classNames = new Set(['swan-price-bar-main-info-details'])

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
