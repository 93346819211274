import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { renderWithRef } from '~/react/components/core'

import { ResponsiveImageContainer } from './responsive-image-container'
import { ResponsiveImage } from './responsive-image.component'

const propTypes = {
  /**
   * The height / width ratio expressed as the result of dividing the content's height by its width
   * Examples: 16:9 => 9 / 16 => 0.5625, 1:1 => 1 / 1 => 1.0
   */
  aspectRatio: PropTypes.number.isRequired,
}

export type BasicResponsiveImageProps = CoreProps<JSX.IntrinsicElements['img'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent ResponsiveImage
 */
export const BasicResponsiveImage = renderWithRef<MinNativeRef, BasicResponsiveImageProps>('BasicResponsiveImage', propTypes, ({ aspectRatio, ...props }, ref) => {
  return (
    <ResponsiveImageContainer aspectRatio={aspectRatio}>
      <ResponsiveImage {...props} ref={ref} />
    </ResponsiveImageContainer>
  )
})
