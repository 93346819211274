import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Decide whether or not to allow sticky Column.
   * @default false
   */
  sticky: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type RowProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent GridContainer
 */
export const Row = renderWithRef<MinNativeRef, RowProps>('Row', null, ({ children, sticky = false, ...props }, ref) => {
  const classNames = ['swan-row']
  if (sticky) classNames.push('swan-row-sticky')
  return (
    <RenderComp root="div" forwardedRef={ref} props={props} propKeysToRemove={propKeysToRemove} classNames={classNames}>
      {children}
    </RenderComp>
  )
})
