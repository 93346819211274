import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ListboxOptionTextContainerProps = CoreProps<JSX.IntrinsicElements['div']>

/**
 * @subcomponent Listbox
 */

export const ListboxOptionTextContainer = renderWithRef<MinNativeRef, ListboxOptionTextContainerProps>('ListboxOptionTextContainer', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="div" classNames={['swan-listbox-option-text-container']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
