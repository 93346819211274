import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BreadcrumbListProps = CoreProps<JSX.IntrinsicElements['ul'], MinNativeRef>

/**
 * @subcomponent Breadcrumbs
 */
export const BreadcrumbList = renderWithRef<MinNativeRef, BreadcrumbListProps>('BreadcrumbList', null, ({ children, ...props }, ref) => (
  <RenderComp root="ul" forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
