import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type SectionProps = CoreProps<JSX.IntrinsicElements['section'], MinNativeRef>

export const Section = renderWithRef<MinNativeRef, SectionProps>('Section', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="section" forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
