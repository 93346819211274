import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type PromoCodeProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

export const PromoCode = renderWithRef<MinNativeRef, PromoCodeProps>('PromoCode', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" classNames={['swan-promo-code']} forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
