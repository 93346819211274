import { renderWithRef } from '~/react/components/core/render-with-ref'

import { Dialog, DialogProps, dialogPropTypes } from './dialog.component'

export type ModalDialogProps = DialogProps

/**
 * @subcomponent Dialog
 */
export const ModalDialog = renderWithRef<HTMLDialogElement, ModalDialogProps>('ModalDialog', dialogPropTypes, ({ children, ...props }, ref) => (
  <Dialog ref={ref} {...props} isModal={true}>
    {children}
  </Dialog>
))
