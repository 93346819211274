import { ReactNode } from 'react'

import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { SwanMenuContextProvider } from '~/react/components/menu/menu.context'

import { CollectionContextProvider } from '~/react/contexts/internal/collection'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

export type MenuProps = {
  /**
   * Callback function that gets triggered when the value changes (for backward compatibility).
   */
  onChange?: (value: string | number) => void
}

export function Menu(props: MenuProps & { children?: ReactNode }) {
  const { children, onChange } = props

  useComponentStylesLoaded('Menu', [SWAN_STYLE_KEY_MAP.popover, SWAN_STYLE_KEY_MAP.listbox])

  return (
    <CollectionContextProvider>
      <SwanMenuContextProvider onAction={onChange}>{children}</SwanMenuContextProvider>
    </CollectionContextProvider>
  )
}
