import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export const StepIndicatorContext = createContext<
  | {
      currentStepNumber: number
    }
  | undefined
  | null
>(undefined)
StepIndicatorContext.displayName = 'StepIndicatorContext'

export const useStepIndicatorContext = () => useNonNullishContext(StepIndicatorContext)
