import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useTableHeadContext } from './table.context'

export type TableCellProps = CoreProps<JSX.IntrinsicElements['td'], MinNativeRef>

/**
 * @subcomponent Table
 */
export const TableCell = renderWithRef<MinNativeRef, TableCellProps>('TableCell', null, ({ children, ...props }, ref) => {
  const isHead = useTableHeadContext()
  const tableCellProps = isHead ? { ...props, as: props.as || props.component || 'th' } : props
  const tableCellClassName = isHead ? 'swan-th' : 'swan-td'

  return (
    <RenderComp root="td" classNames={[tableCellClassName]} forwardedRef={ref} props={tableCellProps}>
      {children}
    </RenderComp>
  )
})
