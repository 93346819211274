import { InferProps } from 'prop-types'
import { useRef } from 'react'

import { assignRefs } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { useSwanPopover } from '~/react/components/popover'
import { PopoverContentInternal, PopoverContentInternalProps } from '~/react/components/popover/popover-content-internal.component'

const propTypes = {}

const propKeysToRemove = Object.keys(propTypes)

export type MenuPopoverProps = CoreProps<
  PopoverContentInternalProps,
  HTMLDivElement,
  InferProps<typeof propTypes> & {
    // Redeclaring the props so as to capture proper default value
    /**
     * The placement of the popover. eg: "bottom start", "top end", "top", "bottom"
     * @default bottom start
     */
    placement?: PopoverContentInternalProps['placement']
    /**
     * Specifies whether to hide the arrow of the popover.
     * @default true
     */
    hideArrow?: PopoverContentInternalProps['hideArrow']
  },
  PopoverContentInternalProps
>

/**
 * @subcomponent Menu
 */
export const MenuPopover = renderWithRef<MinNativeRef, MenuPopoverProps>('MenuPopover', {}, (props, ref) => {
  const { children, className, placement = 'bottom start', hideArrow = true } = props
  const { overlayState } = useSwanPopover()
  const popoverRef = useRef<HTMLDivElement>(null)
  const combinedRef = assignRefs(ref, popoverRef)

  const classes = new Set(['swan-menu-popover', 'swan-listbox-popover'])
  if (className) classes.add(className)

  return overlayState.isOpen ? (
    <RenderComp root={PopoverContentInternal} forwardedRef={combinedRef} propKeysToRemove={propKeysToRemove} classNames={classes} props={{ placement, hideArrow, ...props }}>
      {children}
    </RenderComp>
  ) : (
    /* the list needs to be rendered in the dom for the items to be registered with the parent menu */
    <div aria-hidden="true" className="swan-display-none">
      {children}
    </div>
  )
})
