import PropTypes, { InferProps } from 'prop-types'
import { MouseEventHandler, useCallback, useRef } from 'react'

import { assignRefs } from '~/core/utilities'

import type { CoreInputProps, CoreProps } from '~/react/components/core/core.types'
import { getCoreProps } from '~/react/components/core/core.utils'

import { Button } from '~/react/components/button'
import { RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'
import { TextInput } from '~/react/components/text-input'

export const searchInputPropTypes = {
  /**
   * The visual variant.
   * Note that error is implied if the SearchInput is used inside of a FormInput that also contains a FormError.
   * Available options: 'standard', 'error'
   *
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'error'] as const),

  /**
   * The click handler for the clear button
   */
  onClear: PropTypes.func,

  /**
   * The click handler for the search button
   */
  onSearchSubmit: PropTypes.func,

  /**
   * The aria-label for the clear button, required
   */
  accessibleTextForClearButton: PropTypes.string.isRequired,

  /**
   * The aria-label for the search button, required
   */
  accessibleTextForSearchButton: PropTypes.string.isRequired,
}

const propKeysToRemove = Object.keys(searchInputPropTypes)

// The types are incorrectly generated when using prop-types with more than one required type,
// the override below fixes this issue by directly using typescript to define those required types
// See https://vistaprint.atlassian.net/browse/DSYS-3009 for bug ticket
type CustomProps = Omit<InferProps<typeof searchInputPropTypes>, 'accessibleTextForClearButton' | 'accessibleTextForSearchButton'> & {
  accessibleTextForClearButton: string
  accessibleTextForSearchButton: string
}
export type SearchInputProps = CoreProps<CoreInputProps, HTMLInputElement, CustomProps>

export const SearchInput = renderWithRef<HTMLInputElement, SearchInputProps>('SearchInput', searchInputPropTypes, ({ skin = 'standard', ...props }, ref) => {
  const innerRef = useRef<HTMLInputElement>()
  const combinedRef = assignRefs(ref, innerRef)

  const { disabled, onClear, onSearchSubmit, accessibleTextForClearButton, accessibleTextForSearchButton } = props
  const containerProps = getCoreProps(props)
  const containerPropKeys = Object.keys(containerProps)

  // TODO (browser): we need to set the disabled attribute on the container for browser's that do not support :has
  containerProps.disabled = disabled

  const onClearClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      if (innerRef.current) {
        innerRef.current.value = ''
        // Focus the input after clearing it since the clear button will now be hidden
        innerRef.current.focus()
      }
      if (onClear) {
        onClear(e)
      }
    },
    [onClear],
  )

  const classNames = new Set(['swan-search-input'])
  if (skin === 'error') classNames.add('swan-search-input-skin-error')

  return (
    <RenderComp root="div" classNames={classNames} props={containerProps}>
      <RenderComp forwardedRef={combinedRef} root={TextInput} props={{ type: 'search', ...props }} propKeysToRemove={[...containerPropKeys, ...propKeysToRemove]} />
      <Button className="swan-search-input-clear" skin="tertiary" buttonShape="round" onClick={onClearClick} aria-label={accessibleTextForClearButton} disabled={disabled}>
        <Icon iconType="clear" />
      </Button>
      <span className="swan-search-divider" />
      <Button
        type="submit"
        skin="tertiary"
        buttonShape="round"
        className="swan-search-input-submit"
        onClick={onSearchSubmit ?? undefined}
        aria-label={accessibleTextForSearchButton}
        disabled={disabled}
      >
        <Icon iconType="search" />
      </Button>
    </RenderComp>
  )
})
