export const MANIFEST_ICONS = {
  zoomOut: {
    hashed: 'zoom_out.772f954aa8914b78937e25fe5c2cdfd8.svg',
    versioned: 'zoom_out.svg',
  },
  zoomIn: {
    hashed: 'zoom_in.44cbb4a3b06e4169f1386e72da3911ac.svg',
    versioned: 'zoom_in.svg',
  },
  workWithAPro: {
    hashed: 'work_with_a_pro.54f00512b71a6a5080a43705e091f527.svg',
    versioned: 'work_with_a_pro.svg',
  },
  wayfinding: {
    hashed: 'wayfinding.ce93b403912bfb4730823374bd542d5d.svg',
    versioned: 'wayfinding.svg',
  },
  warning: {
    hashed: 'warning.59db9627694bfe6101a22e466beda2ec.svg',
    versioned: 'warning.svg',
  },
  view: {
    hashed: 'view.cde626e3b4cc870cb4eb31e03135b6a4.svg',
    versioned: 'view.svg',
  },
  videoChat: {
    hashed: 'video_chat.20db5d5043da6b92953bcd33e02b8286.svg',
    versioned: 'video_chat.svg',
  },
  vertical: {
    hashed: 'vertical.b2513c98c5c5a483182454922f648d24.svg',
    versioned: 'vertical.svg',
  },
  uploadYourArtwork: {
    hashed: 'upload_your_artwork.f828fbaa5cab5380b5b8cb16ff60c6f2.svg',
    versioned: 'upload_your_artwork.svg',
    deprecated: { version: 'v3.3', message: 'Use `upload` instead.' },
  },
  upload: {
    hashed: 'upload.16020141fa68d29928c4178fb86f8461.svg',
    versioned: 'upload.svg',
  },
  unlock: {
    hashed: 'unlock.9c607a81c446a9ab7f929a81e24c7823.svg',
    versioned: 'unlock.svg',
  },
  undo: {
    hashed: 'undo.b8357da76287a3a049acec979833211b.svg',
    versioned: 'undo.svg',
  },
  twoSide: {
    hashed: 'two_side.943e5e5fb33feb2e153eed9329b8532f.svg',
    versioned: 'two_side.svg',
  },
  toggleOff: {
    hashed: 'toggle_off.fb1740b0b9ebc115410008bd018c43ee.svg',
    versioned: 'toggle_off.svg',
    deprecated: { version: 'v3.3', message: 'Use `cross` instead.' },
  },
  text: {
    hashed: 'text.130b2316a85bd49520ae2220e99f6e6b.svg',
    versioned: 'text.svg',
  },
  templates: {
    hashed: 'templates.ae9361c412e0c034b57a131b12255f18.svg',
    versioned: 'templates.svg',
  },
  tables: {
    hashed: 'tables.c8fb909e00a7afd80fcb82568d49b07b.svg',
    versioned: 'tables.svg',
  },
  success: {
    hashed: 'success.4fdf44249cfeb9e69cddd08e9d82880a.svg',
    versioned: 'success.svg',
  },
  straight: {
    hashed: 'straight.9ac96cd9dcb289d0ef037696a472bb1c.svg',
    versioned: 'straight.svg',
  },
  starFilled: {
    hashed: 'star_filled.dfbd6306d615c1f4dd047cfec17af079.svg',
    versioned: 'star_filled.svg',
    deprecated: { version: 'v3.3', message: 'Use `star` instead.' },
  },
  starEmpty: {
    hashed: 'star_empty.ed760cd6952477b481de5e4a2532a485.svg',
    versioned: 'star_empty.svg',
    deprecated: { version: 'v3.3', message: 'Use `star` instead.' },
  },
  star: {
    hashed: 'star.ed760cd6952477b481de5e4a2532a485.svg',
    versioned: 'star.svg',
  },
  standard: {
    hashed: 'standard.df3d1b7a4ad0d3e6bbe03a77abe7508c.svg',
    versioned: 'standard.svg',
  },
  square: {
    hashed: 'square.ea8ef17812e6139b4f7c9190e2df27a5.svg',
    versioned: 'square.svg',
  },
  sparkle: {
    hashed: 'sparkle.f96d8f23d25c94848faad3002d02b45e.svg',
    versioned: 'sparkle.svg',
  },
  slightCurveUp: {
    hashed: 'slight_curve_up.2433ebf5e29258caae71b145058e0b1a.svg',
    versioned: 'slight_curve_up.svg',
  },
  slightCurveDown: {
    hashed: 'slight_curve_down.b06d47e3a0f449493351278c39401701.svg',
    versioned: 'slight_curve_down.svg',
  },
  shuffle: {
    hashed: 'shuffle.83ade9f438e4e88cb4e9a3263d8ed22d.svg',
    versioned: 'shuffle.svg',
  },
  shoppingBag_0: {
    hashed: 'shopping_bag_0.f7b2f931ecb99b3104d83a4d51238ec1.svg',
    versioned: 'shopping_bag_0.svg',
  },
  share: {
    hashed: 'share.6c8e1f9f19a7384242ceb4f5b739c468.svg',
    versioned: 'share.svg',
  },
  shapes: {
    hashed: 'shapes.f714c63cf96bc6a4c0e2414e9407a7ca.svg',
    versioned: 'shapes.svg',
  },
  settings: {
    hashed: 'settings.fb3ec5cd331d1bc51a38e69112c5b0bd.svg',
    versioned: 'settings.svg',
  },
  sendToBack: {
    hashed: 'send_to_back.d830f35a61f7fd753a6df96421452ecd.svg',
    versioned: 'send_to_back.svg',
  },
  secure: {
    hashed: 'secure.f1f07e94179562e17eae796bd9406a6e.svg',
    versioned: 'secure.svg',
  },
  search: {
    hashed: 'search.0ed88af2d9772071041f88a0eb491cc0.svg',
    versioned: 'search.svg',
  },
  searchLarge: {
    hashed: 'search-large.0ed88af2d9772071041f88a0eb491cc0.svg',
    versioned: 'search-large.svg',
    deprecated: { version: 'v3.3', message: 'Use `search` instead.' },
  },
  saveAsCopy: {
    hashed: 'save_as_copy.1081e62f8a41e5ec4e58aeef55bcced5.svg',
    versioned: 'save_as_copy.svg',
  },
  save: {
    hashed: 'save.714e2e1b4374a6b48c6daaa47769bba5.svg',
    versioned: 'save.svg',
  },
  satisfaction: {
    hashed: 'satisfaction.735a8072023e16d5e732462e061dbc52.svg',
    versioned: 'satisfaction.svg',
    deprecated: {
      version: 'v3.3',
      message: 'Use `guaranteedSatisfaction` instead.',
    },
  },
  roundedCorners: {
    hashed: 'rounded_corners.8cb7744027ae299d92ed7b29b451d0da.svg',
    versioned: 'rounded_corners.svg',
  },
  rotate: {
    hashed: 'rotate.dc9ba37f20867298483890bea40ec063.svg',
    versioned: 'rotate.svg',
  },
  resize: {
    hashed: 'resize.d3c7903f2db5c5d38f4191a6bb2c74f8.svg',
    versioned: 'resize.svg',
  },
  reset: {
    hashed: 'reset.6c7c15742e9d9096a69e8fc600b9e55e.svg',
    versioned: 'reset.svg',
  },
  replace: {
    hashed: 'replace.b67c76857a4f76638e66593ca63d4d22.svg',
    versioned: 'replace.svg',
  },
  reorder: {
    hashed: 'reorder.382d8844f1df9cb7e77efaa85cc119dc.svg',
    versioned: 'reorder.svg',
  },
  redo: {
    hashed: 'redo.8740f23081f3076ecdfe80e69a211983.svg',
    versioned: 'redo.svg',
  },
  qrCode: {
    hashed: 'qr_code.c5ca5d4c6c814c1321a10f8b8bd1d6a5.svg',
    versioned: 'qr_code.svg',
  },
  promo: {
    hashed: 'promo.f603ea598ef2ce6e58d71588aa3e4532.svg',
    versioned: 'promo.svg',
  },
  printedProducts: {
    hashed: 'printed_products.6897631f46fb4c16cc61f9003779a606.svg',
    versioned: 'printed_products.svg',
  },
  priceTag: {
    hashed: 'price_tag.2865552069c2d205f116b4f7a5f76eba.svg',
    versioned: 'price_tag.svg',
  },
  preview: {
    hashed: 'preview.e720508c5a83409357ad48d82fbcee6a.svg',
    versioned: 'preview.svg',
  },
  polish: {
    hashed: 'polish.f96d8f23d25c94848faad3002d02b45e.svg',
    versioned: 'polish.svg',
    deprecated: { version: 'v3.3', message: 'Use `sparkle` instead.' },
  },
  plusRounded: {
    hashed: 'plus_rounded.724bc0953885f55e578529370a4963db.svg',
    versioned: 'plus_rounded.svg',
    deprecated: { version: 'v3.3', message: 'Use `add` instead.' },
  },
  plus: {
    hashed: 'plus.dca40afd7a81c95c718176a04a4fba83.svg',
    versioned: 'plus.svg',
  },
  playRounded: {
    hashed: 'play_rounded.4e89da2fd7bf849d7da87afc18ca7acf.svg',
    versioned: 'play_rounded.svg',
    deprecated: { version: 'v3.3', message: 'Use `play` instead.' },
  },
  play: {
    hashed: 'play.b5e69b7530b3ce98603b57255f01d66b.svg',
    versioned: 'play.svg',
  },
  phoneCall: {
    hashed: 'phone_call.ccb32b1b8a67ae171ab7979615d1c9ec.svg',
    versioned: 'phone_call.svg',
    deprecated: { version: 'v3.3', message: 'Use `phone` instead.' },
  },
  phone: {
    hashed: 'phone.e2d8fff5c99f40dc6096bc97d3c2e04b.svg',
    versioned: 'phone.svg',
  },
  personal: {
    hashed: 'personal.75101f0d874807395bf60ef32853e412.svg',
    versioned: 'personal.svg',
  },
  pause: {
    hashed: 'pause.3d061da5be91b9aeb6c9ecd75b3ed0e0.svg',
    versioned: 'pause.svg',
  },
  passwordShow: {
    hashed: 'password_show.43a1049a1dbdf167774db390fa3aad4e.svg',
    versioned: 'password_show.svg',
  },
  passwordHide: {
    hashed: 'password_hide.ccdb78b5e46b477c11cb8c1b90d7ba4f.svg',
    versioned: 'password_hide.svg',
  },
  orders: {
    hashed: 'orders.c4a3fe86c741794e74e677cd931c2b5b.svg',
    versioned: 'orders.svg',
  },
  orderSearch: {
    hashed: 'order_search.89e10056a3909f0afbb6c86a537087f0.svg',
    versioned: 'order_search.svg',
  },
  orderHistory: {
    hashed: 'order_history.6f71e935ecaacf125909505fb73170bc.svg',
    versioned: 'order_history.svg',
  },
  numericList: {
    hashed: 'numeric_list.5852a43c04a98b7bd9099c15cb187488.svg',
    versioned: 'numeric_list.svg',
  },
  myProjects_1: {
    hashed: 'my_projects_1.1f85d56667aa2820f6f351ab52be2c5e.svg',
    versioned: 'my_projects_1.svg',
    deprecated: { version: 'v3.3', message: 'Use `myProjects` instead.' },
  },
  myProjects: {
    hashed: 'my_projects.1f85d56667aa2820f6f351ab52be2c5e.svg',
    versioned: 'my_projects.svg',
  },
  myDigitalMarketing: {
    hashed: 'my_digital_marketing.b55e9ba01aed17c656d4d64f98e9c88c.svg',
    versioned: 'my_digital_marketing.svg',
  },
  moreHorizontal: {
    hashed: 'more_horizontal.3e2b5c399d3a86d8bd44fbf64417b754.svg',
    versioned: 'more_horizontal.svg',
  },
  minus: {
    hashed: 'minus.94a34251d9afd3b3e1100bea6828924c.svg',
    versioned: 'minus.svg',
  },
  menu: {
    hashed: 'menu.4a6ffcab2bb3f40ccaf420db3ec79273.svg',
    versioned: 'menu.svg',
  },
  mail: {
    hashed: 'mail.bf52df695c673a49a874d2c0c6a8c2e1.svg',
    versioned: 'mail.svg',
  },
  magic: {
    hashed: 'magic.153e580abc3ed0a5387cd5881000653a.svg',
    versioned: 'magic.svg',
  },
  logomaker: {
    hashed: 'logomaker.e94e4e9da98348e967dd160b4cb869ef.svg',
    versioned: 'logomaker.svg',
  },
  logo: {
    hashed: 'logo.d2627cf638817398d9c7a220b578d516.svg',
    versioned: 'logo.svg',
  },
  login: {
    hashed: 'login.7e7f4793540a01cdedae0eb039b23b73.svg',
    versioned: 'login.svg',
    deprecated: { version: 'v3.3', message: 'Use `arrowRight` instead.' },
  },
  lock: {
    hashed: 'lock.f1f07e94179562e17eae796bd9406a6e.svg',
    versioned: 'lock.svg',
  },
  liveChat: {
    hashed: 'live_chat.0c91b22f94451864870cbcfb132e04d6.svg',
    versioned: 'live_chat.svg',
  },
  list: {
    hashed: 'list.b965426971847782647117548f241db9.svg',
    versioned: 'list.svg',
  },
  like: {
    hashed: 'like.8d63c68e1fba0fe459673f8a3f875764.svg',
    versioned: 'like.svg',
  },
  language: {
    hashed: 'language.b05553f915fca4ab1dc14452d2318f8a.svg',
    versioned: 'language.svg',
  },
  invoice: {
    hashed: 'invoice.22647769391c29bb68837bfeef5e1e75.svg',
    versioned: 'invoice.svg',
  },
  info: {
    hashed: 'info.b64b18161f0d5f737b78d50f684fb742.svg',
    versioned: 'info.svg',
  },
  indeterminate: {
    hashed: 'indeterminate.94a34251d9afd3b3e1100bea6828924c.svg',
    versioned: 'indeterminate.svg',
  },
  image: {
    hashed: 'image.9450c651fb1053109493a08d371842b0.svg',
    versioned: 'image.svg',
  },
  home: {
    hashed: 'home.4c010b1f5f75365413a6a22317903861.svg',
    versioned: 'home.svg',
  },
  helpQuery: {
    hashed: 'help_query.ce0b6b79f3dfb2f803a739e0d11cd9fe.svg',
    versioned: 'help_query.svg',
  },
  help: {
    hashed: 'help.fdc652f2b42f86fcbb5ca03ad665ed60.svg',
    versioned: 'help.svg',
    deprecated: { version: 'v3.3', message: 'Use `phone` instead.' },
  },
  heartFilled: {
    hashed: 'heart_filled.0c3d3ca0c00759dbff686cf73bac3d6a.svg',
    versioned: 'heart_filled.svg',
    deprecated: { version: 'v3.3', message: 'Use `heart` instead.' },
  },
  heartEmpty: {
    hashed: 'heart_empty.e97b9f1a4398b515768606e0c1d640cb.svg',
    versioned: 'heart_empty.svg',
    deprecated: { version: 'v3.3', message: 'Use `heart` instead.' },
  },
  heart: {
    hashed: 'heart.e97b9f1a4398b515768606e0c1d640cb.svg',
    versioned: 'heart.svg',
  },
  guaranteedSatisfaction: {
    hashed: 'guaranteed_satisfaction.f5e5ab2db7017b26049c0bc0715f5247.svg',
    versioned: 'guaranteed_satisfaction.svg',
  },
  fullDesign: {
    hashed: 'full_design.c51e9e3edd8b007e6d0576bfb997ebb5.svg',
    versioned: 'full_design.svg',
  },
  fullCurveUp: {
    hashed: 'full_curve_up.239d57f4e6280516d451cf0f894c72d0.svg',
    versioned: 'full_curve_up.svg',
  },
  fullCurveDown: {
    hashed: 'full_curve_down.0973e086a6cca6beb49e4b92c2e0691e.svg',
    versioned: 'full_curve_down.svg',
  },
  focus: {
    hashed: 'focus.aff942f61f10a5cc3a3d5572d892269a.svg',
    versioned: 'focus.svg',
  },
  filter: {
    hashed: 'filter.d2f0379c89b073e7856b4ba7c62b941c.svg',
    versioned: 'filter.svg',
  },
  fill: {
    hashed: 'fill.3f41b6ee7e30f264418c1393864d32ea.svg',
    versioned: 'fill.svg',
  },
  fileSvg: {
    hashed: 'file_svg.16f8058d7c7ed0e42331d2db625877be.svg',
    versioned: 'file_svg.svg',
  },
  filePsd: {
    hashed: 'file_psd.9e757548e32ce5b4d14e01f6c5cfc832.svg',
    versioned: 'file_psd.svg',
  },
  filePng: {
    hashed: 'file_png.8e53e2cf6ab9eb70a6afad94940f407a.svg',
    versioned: 'file_png.svg',
  },
  filePdf: {
    hashed: 'file_pdf.03a08a4f0cee7c1117281fa2422d3541.svg',
    versioned: 'file_pdf.svg',
  },
  fileJpg: {
    hashed: 'file_jpg.c0a31acdbb2c85542e4d0248c414964e.svg',
    versioned: 'file_jpg.svg',
  },
  fileId: {
    hashed: 'file_id.1f572927720563aa883e730914afc172.svg',
    versioned: 'file_id.svg',
  },
  fileGeneric: {
    hashed: 'file_generic.29b7e1fb6fe439dec9be97c8e86b0a64.svg',
    versioned: 'file_generic.svg',
  },
  fileEps: {
    hashed: 'file_eps.c98342689dcf7c3bebc56bed97a7d798.svg',
    versioned: 'file_eps.svg',
  },
  fileAi: {
    hashed: 'file_ai.6b2ee3dde56aa4862a94ff331e474c7f.svg',
    versioned: 'file_ai.svg',
  },
  expand: {
    hashed: 'expand.01c08de3397a6ff0fc4e5887f42f1bb5.svg',
    versioned: 'expand.svg',
  },
  error: {
    hashed: 'error.a65ada8992e9179959b2a8ff63f54ae5.svg',
    versioned: 'error.svg',
  },
  emoji: {
    hashed: 'emoji.b84309c3e4afbe99caef3e7954ceb2f5.svg',
    versioned: 'emoji.svg',
  },
  email: {
    hashed: 'email.bf52df695c673a49a874d2c0c6a8c2e1.svg',
    versioned: 'email.svg',
    deprecated: { version: 'v3.6', message: 'Use `mail` instead.' },
  },
  edit: {
    hashed: 'edit.f25f12dc296c96d5a4dd9b4c3e733c20.svg',
    versioned: 'edit.svg',
  },
  download: {
    hashed: 'download.02a5cd26b21906d60814a54b58479a3c.svg',
    versioned: 'download.svg',
  },
  dislike: {
    hashed: 'dislike.b5bb5476f31ddd168546b223d3251ea5.svg',
    versioned: 'dislike.svg',
  },
  dictation: {
    hashed: 'dictation.74a482262c0cc5adb842d160cc8d9be5.svg',
    versioned: 'dictation.svg',
  },
  designerMarketplace: {
    hashed: 'designer_marketplace.b84309c3e4afbe99caef3e7954ceb2f5.svg',
    versioned: 'designer_marketplace.svg',
  },
  designEducation: {
    hashed: 'design_education.d32adce91dd28cb8d7f95710ba6c9211.svg',
    versioned: 'design_education.svg',
  },
  deliveryFast: {
    hashed: 'delivery_fast.9995b46411cb13cd531483be2d0cd047.svg',
    versioned: 'delivery_fast.svg',
  },
  delivery: {
    hashed: 'delivery.3541aae92f3c5cf675b3fc0f9eed1f6d.svg',
    versioned: 'delivery.svg',
  },
  deleteStudio: {
    hashed: 'delete_studio.c5e398c71cc1b21c4c5b47f23b9722bc.svg',
    versioned: 'delete_studio.svg',
    deprecated: { version: 'v3.3', message: 'Use `delete` instead.' },
  },
  delete: {
    hashed: 'delete.c5e398c71cc1b21c4c5b47f23b9722bc.svg',
    versioned: 'delete.svg',
  },
  cross: {
    hashed: 'cross.fb1740b0b9ebc115410008bd018c43ee.svg',
    versioned: 'cross.svg',
  },
  creditCard: {
    hashed: 'credit_card.84db1f95c7099dc26f38218eeb6e399d.svg',
    versioned: 'credit_card.svg',
  },
  consumer: {
    hashed: 'consumer.403146c560eae655e220bf46614b9684.svg',
    versioned: 'consumer.svg',
  },
  collapse: {
    hashed: 'collapse.9931b86eee1a48cc87a6dea0cb0ee88e.svg',
    versioned: 'collapse.svg',
  },
  closeSmall: {
    hashed: 'close_small.fb1740b0b9ebc115410008bd018c43ee.svg',
    versioned: 'close_small.svg',
    deprecated: { version: 'v3.3', message: 'Use `close` instead.' },
  },
  closeLarge: {
    hashed: 'close_large.fb1740b0b9ebc115410008bd018c43ee.svg',
    versioned: 'close_large.svg',
    deprecated: { version: 'v3.3', message: 'Use `close` instead.' },
  },
  close: {
    hashed: 'close.fb1740b0b9ebc115410008bd018c43ee.svg',
    versioned: 'close.svg',
  },
  clock: {
    hashed: 'clock.6f71e935ecaacf125909505fb73170bc.svg',
    versioned: 'clock.svg',
    deprecated: { version: 'v3.3', message: 'Use `orderHistory` instead.' },
  },
  clear: {
    hashed: 'clear.f1beeafd15a60ed278c031d3856d2f21.svg',
    versioned: 'clear.svg',
  },
  chevronRight: {
    hashed: 'chevron_right.03024c5bbf0f27c7e12edb1bc4160f84.svg',
    versioned: 'chevron_right.svg',
  },
  chevronLeft: {
    hashed: 'chevron_left.90be720a8f305e3b49f47f416f251c3d.svg',
    versioned: 'chevron_left.svg',
  },
  checkRoundedEdgesMini: {
    hashed: 'check_rounded_edges_mini.f40333dc3e38ee20800b3d79994563c7.svg',
    versioned: 'check_rounded_edges_mini.svg',
    deprecated: { version: 'v3.3', message: 'Use `check` instead.' },
  },
  checkRoundedEdges: {
    hashed: 'check_rounded_edges.f40333dc3e38ee20800b3d79994563c7.svg',
    versioned: 'check_rounded_edges.svg',
    deprecated: { version: 'v3.3', message: 'Use `check` instead.' },
  },
  check: {
    hashed: 'check.f40333dc3e38ee20800b3d79994563c7.svg',
    versioned: 'check.svg',
  },
  chatReview: {
    hashed: 'chat_review.b96a6859c3a9cb59b5e7956bf45de490.svg',
    versioned: 'chat_review.svg',
    deprecated: { version: 'v3.3', message: 'Use `liveChat` instead.' },
  },
  caretUp: {
    hashed: 'caret_up.d99f975b21275ab43bea547989410e0e.svg',
    versioned: 'caret_up.svg',
  },
  caretRight: {
    hashed: 'caret_right.03024c5bbf0f27c7e12edb1bc4160f84.svg',
    versioned: 'caret_right.svg',
    deprecated: { version: 'v3.3', message: 'Use `chevronRight` instead.' },
  },
  caretDown: {
    hashed: 'caret_down.53c278384addd2ae1c7c7d02d77d0cd3.svg',
    versioned: 'caret_down.svg',
  },
  cancel: {
    hashed: 'cancel.58ce91bac998ea1ce1ce306b653deea6.svg',
    versioned: 'cancel.svg',
  },
  calendar: {
    hashed: 'calendar.bf08dd009a96c4db872ba2cb2549fddb.svg',
    versioned: 'calendar.svg',
  },
  business: {
    hashed: 'business.f2b4146dbea2f8969846665e5ae47766.svg',
    versioned: 'business.svg',
  },
  browseDesigns: {
    hashed: 'browse_designs.b4620fac13a93ee4c9d6e8129513aa06.svg',
    versioned: 'browse_designs.svg',
  },
  bringToFront: {
    hashed: 'bring_to_front.ed06f373ee7988d4060cd2c7ccaf326e.svg',
    versioned: 'bring_to_front.svg',
  },
  attachment: {
    hashed: 'attachment.66bb02d13be13ec4f8431b4f03f280e1.svg',
    versioned: 'attachment.svg',
  },
  arrowRight: {
    hashed: 'arrow_right.7e7f4793540a01cdedae0eb039b23b73.svg',
    versioned: 'arrow_right.svg',
  },
  arrowLeft: {
    hashed: 'arrow_left.4e6fd6c30f4c04743c1188029c049a77.svg',
    versioned: 'arrow_left.svg',
  },
  arrowUp: {
    hashed: 'arrow-up.a4bd00282b448cc5a182766b4e257578.svg',
    versioned: 'arrow-up.svg',
  },
  arrowDown: {
    hashed: 'arrow-down.c412460f044ef5ed990e8a6d221bc39d.svg',
    versioned: 'arrow-down.svg',
  },
  alignRight: {
    hashed: 'align_right.93c2928fa80a89d984b92bebaf35b5b6.svg',
    versioned: 'align_right.svg',
  },
  alignLeft: {
    hashed: 'align_left.11f078727683d0eaa0b44de547fd5cac.svg',
    versioned: 'align_left.svg',
  },
  alignCenter: {
    hashed: 'align_center.e526003496a42e0508da937bd71344f3.svg',
    versioned: 'align_center.svg',
  },
  aiSupport: {
    hashed: 'ai_support.85622cb66f01154cd6859333d6e166ea.svg',
    versioned: 'ai_support.svg',
  },
  ai: {
    hashed: 'ai.153e580abc3ed0a5387cd5881000653a.svg',
    versioned: 'ai.svg',
  },
  add: {
    hashed: 'add.724bc0953885f55e578529370a4963db.svg',
    versioned: 'add.svg',
  },
  account: {
    hashed: 'account.7e51e8b40a59ee440b6e1db5012789aa.svg',
    versioned: 'account.svg',
  },
} as const;
