import PropTypes, { InferProps } from 'prop-types'
import { ChangeEvent } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, filterProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useControllableValue, useId } from '~/react/hooks'
import { conditionalPropType, nullishPropType } from '~/react/prop-types'

import { ButtonbarContext, ButtonbarSelectedValue, ButtonbarSelectedValues } from './buttonbar.context'

const isMultiSelect = (props: { variant?: string | null }) => props.variant === 'multi-select'

const propTypes = {
  /**
   * The width of the buttonbar
   * @default standard
   */
  width: PropTypes.oneOf(['standard', 'full-width'] as const),

  /**
   * Name for the input element(ButtonbarButton)
   */
  name: PropTypes.string,

  /**
   * `single-select` means using radio buttons, which are mutually exclusive
   * `multi-select` means using checkbox options, of which many can be selected at once
   * `toolbar` means using regular buttons
   *
   * @default single-select
   */

  variant: PropTypes.oneOf(['single-select', 'multi-select', 'toolbar'] as const),

  // For single select
  /**
   * 'value' of currently selected button.
   *
   * Used by `single-select` variant.
   */
  selectedValue: conditionalPropType(isMultiSelect, nullishPropType, PropTypes.string),
  /**
   * By default selected button.
   *
   * Used by `single-select` variant.
   */
  defaultSelectedValue: conditionalPropType(isMultiSelect, nullishPropType, PropTypes.string),
  /**
   * Callback fired when selected option is changed, `event` is actual `<input>` event in case you need it.
   *
   * Used by `single-select` variant.
   */
  onSelectedValueChange: conditionalPropType(
    isMultiSelect,
    nullishPropType,
    PropTypes.func as PropTypes.Requireable<(value: ButtonbarSelectedValue, event: ChangeEvent<HTMLInputElement>) => void>,
  ),

  // For multi select
  /**
   * List of 'values' of currently selected buttons.
   *
   * Used by `multi-select` variant.
   */
  selectedValues: conditionalPropType(isMultiSelect, PropTypes.arrayOf(PropTypes.string.isRequired), nullishPropType),
  /**
   * By default selected buttons.
   *
   * Used by `multi-select` variant.
   */
  defaultSelectedValues: conditionalPropType(isMultiSelect, PropTypes.arrayOf(PropTypes.string.isRequired), nullishPropType),
  /**
   * Callback fired when one of the selections' value changes.
   *
   * It is passed the updated list of `selectedValues` and the actual `<input>` event in case you need it.
   *
   * Used by `multi-select` variant.
   */
  onSelectedValuesChange: conditionalPropType(
    isMultiSelect,
    PropTypes.func as PropTypes.Requireable<(selectedValues: ButtonbarSelectedValues, event: ChangeEvent<HTMLInputElement>) => void>,
    nullishPropType,
  ),
}

type ButtonBarPropTypes = InferProps<typeof propTypes>

type ButtonBarToolbarProps = Pick<ButtonBarPropTypes, 'width' | 'name' | 'selectedValues' | 'defaultSelectedValues' | 'onSelectedValuesChange'> & {
  /**
   * `toolbar` means using regular buttons
   */
  variant: 'toolbar'
  selectedValue?: never
  defaultSelectedValue?: never
  onSelectedValueChange?: never
}

type ButtonBarMultiSelectProps = Pick<ButtonBarPropTypes, 'width' | 'name' | 'selectedValues' | 'defaultSelectedValues' | 'onSelectedValuesChange'> & {
  /**
   * `multi-select` means using checkbox options
   */
  variant: 'multi-select'
  selectedValue?: never
  defaultSelectedValue?: never
  onSelectedValueChange?: never
}

type ButtonBarSingleSelectProps = Pick<ButtonBarPropTypes, 'width' | 'name' | 'selectedValue' | 'defaultSelectedValue' | 'onSelectedValueChange'> & {
  /**
   * `single-select` means using radio buttons
   */
  variant?: 'single-select' | null
  selectedValues?: never
  defaultSelectedValues?: never
  onSelectedValuesChange?: never
}

const propKeysToRemove = Object.keys(propTypes)

export type ButtonbarProps = CoreProps<JSX.IntrinsicElements['div'], HTMLDivElement, ButtonBarSingleSelectProps | ButtonBarMultiSelectProps | ButtonBarToolbarProps>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Buttonbar = renderWithRef<MinNativeRef, ButtonbarProps>('Buttonbar', propTypes as any, (props, ref) => {
  const {
    children,
    variant = 'single-select',
    width = 'standard',

    selectedValue: controlledSelectedValue,
    defaultSelectedValue = null,
    onSelectedValueChange: controlledOnSelectedValueChange,

    selectedValues: controlledSelectedValues,
    defaultSelectedValues = [],
    onSelectedValuesChange: controlledOnSelectedValuesChange,

    'aria-label': ariaLabel,
    'aria-controls': ariaControls,

    name: customName,
  } = props
  const classNames = new Set<string>(['swan-buttonbar'])
  if (width !== 'standard') classNames.add(`swan-buttonbar-${width}`)

  const name = useId(customName)

  // state for single-select

  const [selectedValue, onSelectedValueChange] = useControllableValue<ButtonbarSelectedValue, [ButtonbarSelectedValue, ChangeEvent<HTMLInputElement>]>({
    value: controlledSelectedValue ?? undefined,
    onChange: controlledOnSelectedValueChange ?? undefined,
    defaultValue: defaultSelectedValue,
    defaultOnChange: (setValue, ...onChangeArgs) => {
      const [newValue] = onChangeArgs
      setValue(newValue)
    },
  })

  // state for multi-select

  const [selectedValues, onSelectedValuesChange] = useControllableValue<ButtonbarSelectedValues, [ButtonbarSelectedValues, ChangeEvent<HTMLInputElement>]>({
    value: controlledSelectedValues ?? undefined,
    onChange: controlledOnSelectedValuesChange ?? undefined,
    defaultValue: defaultSelectedValues || [],
    defaultOnChange: (setValue, ...onChangeArgs) => {
      const [newSelectedValues] = onChangeArgs

      setValue(newSelectedValues)
    },
  })

  let roleValue = 'toolbar'
  switch (variant) {
    case 'single-select':
      roleValue = 'radiogroup'
      break
    case 'multi-select':
      roleValue = 'group'
      break
    default:
      break
  }

  const overriddenProps = {
    ...filterProps(props, null, null),
    role: roleValue,
    'aria-label': ariaLabel,
    'aria-controls': ariaControls,
  }

  return (
    <ButtonbarContext.Provider
      value={{
        name,
        variant,
        selectedValue,
        onSelectedValueChange,
        selectedValues,
        onSelectedValuesChange,
      }}
    >
      <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ ...props, ...overriddenProps }}>
        {children}
      </RenderComp>
    </ButtonbarContext.Provider>
  )
})
