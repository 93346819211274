import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useSwanPopover } from './popover.context'

export type PopoverCloserProps = CoreProps<JSX.IntrinsicElements['div'], HTMLDivElement>

/**
 * @subcomponent Popover
 */
export const PopoverCloser = renderWithRef<HTMLDivElement, PopoverCloserProps>('PopoverCloser', null, (props, ref) => {
  const { overlayState } = useSwanPopover()

  const classNames = ['swan-popover-close']
  const overriddenProps = {
    onClick: () => {
      overlayState.close()
    },
  }

  return (
    <RenderComp root="div" classNames={classNames} forwardedRef={ref} props={{ ...props, ...overriddenProps }}>
      {props.children}
    </RenderComp>
  )
})
