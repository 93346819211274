import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { StepIndicatorContext } from './step-indicator-context'

const propTypes = {
  /**
   * The number of the currently active step
   * @default 1
   */
  currentStepNumber: PropTypes.number.isRequired,
  /**
   * Specifies if all steps should be shown, or only the current step
   *
   * @default false
   */
  showAllSteps: PropTypes.bool,
}
const propKeysToRemove = Object.keys(propTypes)

export type StepIndicatorProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent StepIndicator
 */
export const StepIndicator = renderWithRef<MinNativeRef, StepIndicatorProps>('StepIndicator', propTypes, (props, ref) => {
  useComponentStylesLoaded('StepIndicator', SWAN_STYLE_KEY_MAP.stepIndicator)
  const { currentStepNumber = 1, showAllSteps = false } = props

  const classNames = new Set(['swan-step-indicator'])
  if (showAllSteps) classNames.add('swan-step-indicator-show-all-steps')

  return (
    <StepIndicatorContext.Provider value={{ currentStepNumber }}>
      <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
        {props.children}
      </RenderComp>
    </StepIndicatorContext.Provider>
  )
})
