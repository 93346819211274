import { useMenuSection } from '@react-aria/menu'
import { useSeparator } from '@react-aria/separator'
import { TreeState } from '@react-stately/tree'
import type { Node } from '@react-types/shared'
import { ReactNode } from 'react'

import { MenuItemInternal } from './menu-item-internal'

export type MenuSectionInternalProps = {
  section: {
    key: string | number
    'aria-label'?: string
    rendered: ReactNode
    childNodes: Iterable<Node<object>>
  }
  state: TreeState<object>
}

export function MenuSectionInternal({ section, state }: MenuSectionInternalProps) {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  const { separatorProps } = useSeparator({
    elementType: 'li',
  })

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && <li className="swan-menu-section-separator swan-listbox-section-separator" {...separatorProps} />}
      <li {...itemProps}>
        {section.rendered && (
          <span {...headingProps} className="swan-listbox-section-title swan-menu-section-title">
            {section.rendered}
          </span>
        )}
        <ul {...groupProps} className="swan-listbox-section swan-menu-section">
          {[...section.childNodes].map(node => (
            <MenuItemInternal key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  )
}
