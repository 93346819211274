import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Whether the List Item is selected.
   *
   * @default false
   */
  selected: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type ListItemProps = CoreProps<JSX.IntrinsicElements['li'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent List
 */

export const ListItem = renderWithRef<MinNativeRef, ListItemProps>('ListItem', propTypes, (props, ref) => {
  const { children, selected = false } = props

  const classNames = new Set<string>()
  if (selected) classNames.add('swan-list-item-selected')

  return (
    <RenderComp root="li" forwardedRef={ref} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
      {children}
    </RenderComp>
  )
})
