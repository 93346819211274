import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type EmptyStateActionsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent EmptyState
 */

export const EmptyStateActions = renderWithRef<MinNativeRef, EmptyStateActionsProps>('EmptyStateActions', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-empty-state-actions']} props={props}>
    {props.children}
  </RenderComp>
))
