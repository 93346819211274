import { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

/**
 * @subcomponent Breadcrumb
 */

const propTypes = {}
const propKeysToRemove = Object.keys(propTypes)

export type BreadcrumbProps = CoreProps<JSX.IntrinsicElements['li'], MinNativeRef, InferProps<typeof propTypes>>

export const Breadcrumb = renderWithRef<MinNativeRef, BreadcrumbProps>('Breadcrumb', propTypes, (props, ref) => {
  const { id, children } = props

  const classNames = new Set([])

  return (
    <RenderComp root="li" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ ...props, id, key: id }}>
      {children}
    </RenderComp>
  )
})
