import { useEffect, useState } from 'react'

import { tokensRaw } from '~/core/tokens/tokens-raw'

export const isSafariVersion18 = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  }
  const { userAgent } = navigator
  const { vendor } = navigator

  // Check if it's Safari by Apple
  const isSafari = vendor && vendor.includes('Apple') && userAgent.includes('Safari') && !userAgent.includes('Chrome')

  if (!isSafari) {
    return false // Not Safari
  }

  const { matches: isXs } = window.matchMedia(`(max-width: ${tokensRaw.SwanBaseBreakpointXsEnd})`)

  // Extract Safari version
  const match = userAgent.match(/Version\/(\d+(\.\d+)?)/)

  if (match && match[1]) {
    const version = parseFloat(match[1])

    // Check if version is on 18, but before 18.2 as that fixes the bug
    // Also check if the user is on iPhone to stop this executing on Macs, etc.
    return version >= 18 && version < 18.2 && userAgent.includes('iPhone') && isXs
  }

  return false // Could not determine version
}

/**
 * Checks to see if the user is on a version of Safari which has seemingly incorrect viewport calculations,
 * i.e. iOS Safari >= 18, < 18.2
 */
export const useIsSafariVersion18 = (): boolean => {
  const [isSafari18, setIsSafari18] = useState(false)

  useEffect(() => {
    setIsSafari18(isSafariVersion18())
  }, [])

  return isSafari18
}
