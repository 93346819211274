import { useEffect } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { usePriceBarQuantityContext } from './price-bar.context'

export type PriceBarQuantityLabelProps = CoreProps<JSX.IntrinsicElements['label']>

/**
 * @subcomponent PriceBar
 */
export const PriceBarQuantityLabel = renderWithRef<MinNativeRef, PriceBarQuantityLabelProps>('PriceBarQuantityLabel', null, (props, ref) => {
  const classNames = new Set(['swan-price-bar-button-label'])
  const { id: customId, children } = props
  const { labelId, setLabelId, buttonId } = usePriceBarQuantityContext()

  const id = useId(customId)

  useEffect(() => {
    setLabelId(id)

    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <RenderComp root="label" forwardedRef={ref} classNames={classNames} props={{ ...props, id: labelId, htmlFor: buttonId }}>
      {children}
    </RenderComp>
  )
})
