import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type StandardTileDescriptionProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardTile
 */

export const StandardTileDescription = renderWithRef<MinNativeRef, StandardTileDescriptionProps>('StandardTileDescription', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-standard-tile-description']} props={props}>
    {props.children}
  </RenderComp>
))
