import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { ProgressBarContextProvider } from '~/react/components/progress-bar/progress-bar.context'

export type ProgressBarContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent ProgressBar
 */
export const ProgressBarContainer = renderWithRef<MinNativeRef, ProgressBarContainerProps>('ProgressBarContainer', undefined, (props, ref) => (
  <ProgressBarContextProvider>
    <RenderComp root="div" forwardedRef={ref} classNames={['swan-progress-bar-container']} props={props}>
      {props.children}
    </RenderComp>
  </ProgressBarContextProvider>
))
