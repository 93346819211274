import { MutableRefObject, useCallback, useEffect, useState } from 'react'

export const useDragAndDrop = (fileDropzoneRef: MutableRefObject<HTMLDivElement | null>) => {
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [isMultiDropError, setIsMultiDropError] = useState<boolean>(false)

  const handleDragOver = useCallback(
    (event: DragEvent) => {
      event.preventDefault()
      const input = fileDropzoneRef.current?.querySelector<HTMLInputElement>('.swan-file-input')
      if (!input) return null

      if (input.disabled === false) {
        setIsDragging(true)
        if (!input.multiple && event.dataTransfer && event.dataTransfer.items.length > 1) {
          setIsMultiDropError(true)
        }
      }
    },
    [fileDropzoneRef],
  )

  const handleDragEnter = useCallback((event: DragEvent) => {
    event.preventDefault()
  }, [])

  const handleDragLeave = useCallback(
    (event: DragEvent) => {
      event.preventDefault()
      const input = fileDropzoneRef.current?.querySelector<HTMLInputElement>('.swan-file-input')
      if (!input) return null

      if (input.disabled === false) {
        setIsDragging(false)
        setIsMultiDropError(false)
      }
    },
    [fileDropzoneRef],
  )

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault()

      const input = fileDropzoneRef.current?.querySelector<HTMLInputElement>('.swan-file-input')
      if (!input) return null

      if (input.disabled === false) {
        setIsDragging(false)
        setIsMultiDropError(false)

        const eventFiles = event.dataTransfer?.files

        // If there are no files, exit early
        if (!eventFiles || eventFiles.length <= 0) return

        if (!input.multiple) {
          // If single file upload only return the first file
          const firstFile = eventFiles.item(0)
          if (!firstFile) return

          const list = new DataTransfer()
          list.items.add(firstFile)
          input.files = list.files
        } else {
          input.files = eventFiles
        }

        // Manually trigger the onChange function by dispatching a change event
        input.dispatchEvent(
          new Event('change', {
            bubbles: true,
          }),
        )
      }
    },
    [fileDropzoneRef],
  )

  useEffect(() => {
    // Get the input element using the provided selector
    const fileDropzoneElement = fileDropzoneRef.current

    if (fileDropzoneElement) {
      fileDropzoneElement.addEventListener('dragover', handleDragOver)
      fileDropzoneElement.addEventListener('dragenter', handleDragEnter)
      fileDropzoneElement.addEventListener('dragleave', handleDragLeave)
      fileDropzoneElement.addEventListener('drop', handleDrop)

      // Clean up the listeners when the component unmounts
      return () => {
        fileDropzoneElement.removeEventListener('dragover', handleDragOver)
        fileDropzoneElement.removeEventListener('dragenter', handleDragEnter)
        fileDropzoneElement.removeEventListener('dragleave', handleDragLeave)
        fileDropzoneElement.removeEventListener('drop', handleDrop)
      }
    }
  }, [fileDropzoneRef, handleDragEnter, handleDragLeave, handleDragOver, handleDrop])

  return { isDragging, isMultiDropError }
}
