import PropTypes, { InferProps } from 'prop-types'
import { ReactNode } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'
import { VisuallyHidden } from '~/react/components/visually-hidden'

const propTypes = {
  /**
   * @deprecated
   * Use `accessibleText` instead
   *
   * A localized label which describes the button to screen-reader users
   *
   * Typically it is just a string like "Zoom in"
   */
  visuallyHiddenLabel: deprecatedProp(PropTypes.node, 'Use `accessibleText` instead'),
  /**
   * A localized label which describes the button to screen-reader users
   *
   * Typically it is just a string like "Zoom in"
   */
  accessibleText: PropTypes.string,
}

export type ZoomControlsInProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent ZoomControls
 */
export const ZoomControlsIn = renderWithRef<MinNativeRef, ZoomControlsInProps>('ZoomControlsIn', propTypes, (props, ref) => {
  const { visuallyHiddenLabel, accessibleText, children, ...rest } = props
  return (
    <RenderComp root="button" forwardedRef={ref} classNames={['swan-zoom-controls-in']} props={rest}>
      <VisuallyHidden>{accessibleText ?? (visuallyHiddenLabel as ReactNode)}</VisuallyHidden>
      <Icon iconType="zoomIn" />
      {children}
    </RenderComp>
  )
})
