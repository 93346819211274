import { ChangeEvent, createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type TabsContextType = {
  tabsName?: string
  selectedTabId: string | null
  onRequestTabChange: (tabId: string | null, event: ChangeEvent<HTMLInputElement>) => void
  headerIds: { [tabId: string]: string | undefined | null }
  registerHeader: (tabId: string, id: string | undefined | null) => void
  unregisterHeader: (tabId: string) => void
  labelIds: { [tabId: string]: string | undefined | null }
  registerLabel: (tabId: string, id: string | undefined | null) => void
  unregisterLabel: (tabId: string) => void
  contentIds: { [tabId: string]: string | undefined | null }
  registerContent: (tabId: string, id: string | undefined | null) => void
  unregisterContent: (tabId: string) => void
}
export const TabsContext = createContext<TabsContextType | undefined | null>(null)
TabsContext.displayName = 'TabsContext'

export function useTabsContext() {
  return useNonNullishContext(TabsContext)
}
