import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedPropValues, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * A localized string which will be used for shown beside the spinner as well as for accessibility to read aloud by screen-readers
   */
  accessibleText: PropTypes.string.isRequired,
  /**
   * The visual style of the Loader
   * @default standard
   *
   * Size "mini" and "tiny" are deprecated. Use "standard" instead
   */
  size: deprecatedPropValues(PropTypes.oneOf(['super', 'standard', 'mini', 'tiny'] as const), ['mini', 'tiny'], 'Use standard size instead'),
  /**
   * The alignment of text beside the spinner
   * @default vertical
   */
  layout: PropTypes.oneOf(['vertical', 'horizontal'] as const),
  /**
   * Hide or show the accessibleText
   * @default false
   */
  showText: PropTypes.bool,
  /**
   * Using this will create a box behind the spinner
   * @default false
   */
  overlay: PropTypes.bool,
}
const propKeysToRemove = Object.keys(propTypes)

export type SpinnerProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

export const Spinner = renderWithRef<MinNativeRef, SpinnerProps>('Spinner', propTypes, (props, ref) => {
  useComponentStylesLoaded('Spinner', SWAN_STYLE_KEY_MAP.spinner)
  const { size = 'standard', accessibleText, layout = 'vertical', showText = false, overlay = false } = props

  const classNames = new Set<string>([])

  classNames.add('swan-spinner')
  if (size !== 'standard') classNames.add(`swan-spinner-${size}`)
  if (layout === 'horizontal') classNames.add(`swan-spinner-horizontal`)
  if (showText) classNames.add(`swan-spinner-show-text`)
  if (overlay) classNames.add(`swan-spinner-overlay`)

  return (
    <RenderComp root="span" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={{ ...props }}>
      <span className="swan-spinner-animation">
        {/*  TODO: v4 remove this below span `.swan-spinner-animation-inner` as it is no longer needed */}
        <span className="swan-spinner-animation-inner"></span>
      </span>
      <span className="swan-spinner-text">{accessibleText}</span>
    </RenderComp>
  )
})
