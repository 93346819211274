import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

export type ThumbnailsHeroContentProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>
const ThumbnailsHeroContentInternal = renderWithRef<MinNativeRef, ThumbnailsHeroContentProps>('ThumbnailsHeroContent', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={['swan-thumbnails-hero-content']} props={props}>
      {children}
    </RenderComp>
  )
})

/**
 * @deprecated
 * Remove any content and use only an image
 *
 * @subcomponent ThumbnailsHero
 */
export const ThumbnailsHeroContent = deprecatedComponent({
  description: 'Remove any content and use only an image',
  displayName: 'ThumbnailsHeroContent',
  root: ThumbnailsHeroContentInternal,
})
