import { ROOT_CLASSNAMES } from '~/core/constants'

import { setToString } from './set.utils'
import { iterToSet } from './transform.utils'

export type useRootClassNamesArg = {
  standardMode?: boolean
  darkMode?: boolean
  compactMode?: boolean
}

export function getRootClassNames({ standardMode = false, darkMode = false, compactMode = false }: useRootClassNamesArg = {}, defaultClassNames: Iterable<string> = []) {
  const classNameSet = iterToSet(defaultClassNames)
  classNameSet.add(ROOT_CLASSNAMES.base)
  if (standardMode) {
    classNameSet.add(ROOT_CLASSNAMES.standardMode)
  }
  if (darkMode) {
    classNameSet.add(ROOT_CLASSNAMES.darkMode)
  }
  if (compactMode) {
    classNameSet.add(ROOT_CLASSNAMES.compactMode)
  }
  return setToString(classNameSet)
}
