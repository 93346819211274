import { MANIFEST_ICONS } from '~/core/manifest/icons'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types'

import { getSameKeyValueMapForManifest, manifestResolve } from './manifest.utils'

export type SwanIconKey = keyof typeof MANIFEST_ICONS
export type IconManifestType = Record<SwanIconKey, Record<SwanPathTypeEnum, string> & { deprecated?: { version: string | null; message: string } }>

export const SWAN_ICON_KEY_MAP = getSameKeyValueMapForManifest(MANIFEST_ICONS)
export const SWAN_DEPRECATED_ICONS: Array<Partial<SwanIconKey>> = (Object.keys(MANIFEST_ICONS) as Array<SwanIconKey>).filter(
  (key: SwanIconKey) => (MANIFEST_ICONS as IconManifestType)[key].deprecated !== undefined,
)

export function getSwanIconUrl(key: SwanIconKey, pathType?: SwanPathTypeEnum, swanAssetBaseUrl?: string) {
  return manifestResolve(MANIFEST_ICONS, key, SwanAssetFolders.ICONS, pathType, swanAssetBaseUrl)
}
