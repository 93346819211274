import PropTypes, { InferProps } from 'prop-types'
import { MouseEvent } from 'react'

import { CoreProps, deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useControllableValue } from '~/react/hooks'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'
import { composeEventHandlersUtil } from '~/react/utilities'

export const ToggleSwitchSize = {
  Standard: 'standard',
  Super: 'super',
  Mini: 'mini',
} as const

const propTypes = {
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * Size (height) of the toggle.
   * Available options: 'standard', 'super', 'mini'
   * @default standard
   */
  size: deprecatedProp(
    PropTypes.oneOf([ToggleSwitchSize.Standard, ToggleSwitchSize.Super, ToggleSwitchSize.Mini] as const),
    'Sizing is now handled automatically by standardMode/compactMode',
  ),
  /**
   * Whether the on/off text is visually hidden
   *
   * @default false
   */
  hideText: PropTypes.bool,
  /**
   * Whether or not the switch is "activated" aka "on"
   */
  activated: PropTypes.bool,
  /**
   * Callback fired when the ToggleSwitch wants to change its activated state
   */
  onRequestActivatedChange: PropTypes.func as PropTypes.Requireable<(activated: boolean, event: MouseEvent<HTMLButtonElement>) => void>,
  /**
   * The initial activated state.
   * Ignored if `activated` is provided
   *
   * @default false
   */
  defaultActivated: PropTypes.bool,
}

export type ToggleSwitchProps = CoreProps<JSX.IntrinsicElements['button'], HTMLButtonElement, InferProps<typeof propTypes>>

export const ToggleSwitch = renderWithRef<HTMLButtonElement, ToggleSwitchProps>('ToggleSwitch', propTypes, (props, ref) => {
  useComponentStylesLoaded('ToggleSwitch', SWAN_STYLE_KEY_MAP.toggleSwitch)

  const {
    hideText = false,
    size = ToggleSwitchSize.Standard,
    defaultActivated = false,
    activated: controlledActivated,
    onRequestActivatedChange: controlledOnRequestActivatedChange,
    children,
    ...rest
  } = props

  const [activated, onRequestActivatedChange] = useControllableValue<boolean | null, [boolean, MouseEvent<HTMLButtonElement>]>({
    value: controlledActivated,
    onChange: controlledOnRequestActivatedChange || undefined,
    defaultValue: defaultActivated,
    defaultOnChange: (setActivated, ...onChangeArgs) => {
      const [val] = onChangeArgs
      setActivated(val)
    },
  })

  const classNames = new Set<string>(['swan-vanilla-ignore', 'swan-toggle-switch'])
  if (size === ToggleSwitchSize.Mini) classNames.add(`swan-toggle-switch-${size}`)
  if (size === ToggleSwitchSize.Super) classNames.add(`swan-toggle-switch-${size}`)
  if (hideText) classNames.add('swan-toggle-switch-hide-text')

  return (
    <RenderComp
      root="button"
      forwardedRef={ref}
      classNames={classNames}
      props={{
        ...rest,
        role: 'switch',
        'aria-checked': !!activated,
        onClick: composeEventHandlersUtil(props.onClick, event => {
          onRequestActivatedChange(!activated, event)
        }),
      }}
    >
      {children}
    </RenderComp>
  )
})
