import PropTypes, { InferProps } from 'prop-types'

import { CoreInputProps, MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { useFormFieldProps } from '~/react/components/form'

import { useFloatingLabelTextInputElement } from '~/react/contexts/internal/floating-label'
import { useId } from '~/react/hooks'

const propTypes = {
  /**
   * The visual variant.
   * Note that error is implied if the TextInput is used inside of a FormInput that also contains a FormError.
   * Available options: 'standard', 'error'
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'error'] as const),
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * The size variation
   * Available options: 'standard', 'mini',
   *
   * @default standard
   */
  size: deprecatedProp(PropTypes.oneOf(['standard', 'mini'] as const), 'Sizing is now handled automatically by standardMode/compactMode'),
}

type propsType = InferProps<typeof propTypes>

export type TextInputProps = CoreProps<CoreInputProps, MinNativeRef, propsType>

export const TextInput = renderWithRef<MinNativeRef, TextInputProps>('TextInput', null, (props, ref) => {
  const id = useId(props.id)
  const floatingProps = useFloatingLabelTextInputElement({ ...props, id })
  const formProps = useFormFieldProps(floatingProps.id)

  // Don't change the order of the key values in below
  const processedProps = {
    ...floatingProps,
    ...formProps,
  } as TextInputProps

  const { children, size = 'standard', skin = 'standard' } = props
  const { 'aria-invalid': ariaInvalid } = formProps
  const classNames = new Set(['swan-input'])
  if (skin === 'error' || ariaInvalid) classNames.add('swan-input-skin-error')
  if (size !== 'standard') classNames.add(`swan-input-${size}`)

  return (
    <RenderComp root="input" classNames={classNames} forwardedRef={ref} props={processedProps}>
      {children}
    </RenderComp>
  )
})
