import { InferProps } from 'prop-types'
import { ComponentType, Fragment, ReactNode } from 'react'

import { SWAN_PUBLIC_ASSET_FOLDER, SWAN_VERSION } from '~/core/constants'
import { SwanAssetFolders } from '~/core/types/enums'
import { getSwanAssetFolderName, SWAN_VERSION_META_NAME } from '~/core/utilities'

import { swanLoaderConfigProps } from '~/react/contexts'

import { useSwanHeadConfig } from './head.hook'

const propTypes = {
  ...swanLoaderConfigProps,
}
export type SwanPageMetaProps = InferProps<typeof propTypes> & {
  renderWith?: ComponentType<{ children: ReactNode }>
}

/**
 * @subcomponent SwanHead
 */
export const SwanPageMeta = ({ swanBaseUrl, swanPathType, swanTenant, swanLocale, renderWith: Comp = Fragment }: SwanPageMetaProps) => {
  const { swanBaseUrl: baseUrl } = useSwanHeadConfig(swanBaseUrl, swanPathType, swanTenant, swanLocale)
  const favFolder = `${baseUrl}${SWAN_PUBLIC_ASSET_FOLDER}/${getSwanAssetFolderName(SwanAssetFolders.FAVICONS)}`
  return (
    <Comp>
      <meta key="charset" charSet="UTF-8" />
      <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta key="telephone format detection" name="format-detection" content="telephone=no" />
      <meta key={SWAN_VERSION_META_NAME} name={SWAN_VERSION_META_NAME} content={SWAN_VERSION} />
      <link key="favIcon" rel="shortcut icon" href={`${favFolder}/favicon.ico`} />
      <link key="favIcon152" rel="apple-touch-icon" sizes="152x152" href={`${favFolder}/apple-icon-152x152.png`} />
      <link key="favIcon120" rel="apple-touch-icon" sizes="120x120" href={`${favFolder}/apple-icon-120x120.png`} />
      <link key="favIcon76" rel="apple-touch-icon" sizes="76x76" href={`${favFolder}/apple-icon-76x76.png`} />
      <link key="favIcon60" rel="apple-touch-icon" sizes="60x60" href={`${favFolder}/apple-icon-60x60.png`} />
    </Comp>
  )
}
