import PropTypes, { InferProps } from 'prop-types'
import { ReactElement, useEffect, useState } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { ErrorBoundary } from '~/react/components/error-boundary'

import { ProgressiveImageBase } from './progressive-image-base.component'

export type ProgressiveImageData = {
  /**
   * A full-resolution image.
   * When using `srcSet` this is also a fallback for older browsers.
   */
  src: string

  /**
   * Set of images we will allow the browser to choose between, and what size each image is.
   * Each set of image information is separated from the previous one by a comma.
   */
  srcSet?: string

  /**
   * Set of media conditions (e.g. screen widths) and indicates what image size would be best to choose, when certain media conditions are true.
   */
  sizes?: string
}

const propTypes = {
  /**
   * Callback function to handle error events.
   */
  onError: PropTypes.func as PropTypes.Requireable<(evt: Event) => void>,
  /**
   * Specifies when to trigger the image loading. Can be one of 'immediate', 'inView', or a number (delay in milliseconds).
   * Available options: immediate, inView, number
   * @default inView
   */
  trigger: PropTypes.oneOfType([PropTypes.oneOf(['immediate', 'inView'] as const), PropTypes.number]),
  children: PropTypes.func as PropTypes.Validator<NonNullable<(imageData: ProgressiveImageData, isLoading: boolean) => ReactElement>>,
  placeholder: PropTypes.string,
}

const propKeysToRemove = ['trigger', 'intersectionOptions']

type ExtraProps = Pick<JSX.IntrinsicElements['img'], 'className' | 'src' | 'srcSet' | 'sizes'> & {
  /**
   * Options for configuring the intersection observer behavior.
   *
   * @default { threshold: 0 }
   */
  intersectionOptions?: IntersectionOptions
} & InferProps<typeof propTypes>

export type ProgressiveImageProps = CoreProps<Omit<JSX.IntrinsicElements['div'], 'className' | 'children' | 'onError'>, MinNativeRef, ExtraProps> & {
  children: (imageData: ProgressiveImageData, isLoading: boolean) => ReactElement
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProgressiveImage = renderWithRef<MinNativeRef, ProgressiveImageProps>('ProgressiveImage', propTypes, (props, _ref) => {
  const { children, onError, placeholder = '', src: inputSrc = '', srcSet, sizes, trigger = 'inView', intersectionOptions = { threshold: 0 } } = props

  const [imageData, setImageData] = useState<ProgressiveImageData & { delay: number }>({
    delay: 0,
    src: placeholder ?? '',
    sizes: undefined,
    srcSet: undefined,
  })

  const { ref, inView } = useInView({ ...intersectionOptions, triggerOnce: true })

  useEffect(() => {
    if (trigger === 'immediate' || (trigger === 'inView' && inView)) {
      setImageData({ src: inputSrc, srcSet, sizes, delay: 0 })
    } else if (typeof trigger === 'number') {
      setImageData({ src: inputSrc, srcSet, sizes, delay: trigger })
    }
  }, [trigger, inputSrc, srcSet, sizes, inView])

  const classNames = new Set(['swan-progressive-image-wrapper'])

  return (
    <ErrorBoundary fallback={children({ src: inputSrc }, false)}>
      <RenderComp root="div" propKeysToRemove={propKeysToRemove} forwardedRef={ref} props={props} classNames={classNames}>
        <ProgressiveImageBase
          src={imageData.src}
          placeholder={placeholder ?? ''}
          srcSetData={{ srcSet: imageData.srcSet || '', sizes: imageData.sizes || '' }}
          delay={imageData.delay}
          onError={evt => {
            if (onError) onError(evt)
          }}
        >
          {(
            src: string,
            loading: boolean,
            srcSetData?: {
              srcSet: string
              sizes: string
            },
          ) =>
            children(
              {
                src,
                srcSet: srcSetData?.srcSet,
                sizes: srcSetData?.sizes,
              },
              // if `immediate` => return always `loading` value
              // if `inView` and not on viewport => return `true`, no matter what `loading` says (!inView will return true and "true || anything" is true)
              // if `inView` and on viewport => return `loading` value
              // if `delay` => return `loading`
              (trigger !== 'immediate' && trigger === 'inView' && !inView) || loading,
            )
          }
        </ProgressiveImageBase>
      </RenderComp>
    </ErrorBoundary>
  )
})
