import { tokensRaw as tokens } from '~/core/tokens/tokens-raw'

import { createResponsiveSystem } from './react-responsive-system'

const maxWidths = {
  xs: parseInt(tokens.SwanBaseBreakpointXsEnd, 10),
  sm: parseInt(tokens.SwanBaseBreakpointSmEnd, 10),
  md: parseInt(tokens.SwanBaseBreakpointMdEnd, 10),
  lg: parseInt(tokens.SwanBaseBreakpointLgEnd, 10),
  xl: Infinity,
}

export const { ScreenClassProvider, ScreenClassContext, useScreenClass, useIsScreenClassInitialized, responsive, useResponsiveProps } = createResponsiveSystem({
  breakpoints: maxWidths,
  defaultScreenClass: 'xs',
  cascadeMode: 'mobile-first',
})
