import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

// TODO: v4 make it required to provide one or the other (either aria-label or aria-labelledby)
type TabsHeadersTypes = {
  /**
   * Provides an accessible label for the tab list.
   */
  'aria-label'?: JSX.IntrinsicElements['div']['aria-label']

  /**
   * References of the element that labels the tab list.
   */
  'aria-labelledby'?: JSX.IntrinsicElements['div']['aria-labelledby']
}

export type TabsHeadersProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, TabsHeadersTypes>

/**
 * @subcomponent Tabs
 */
export const TabsHeaders = renderWithRef<MinNativeRef, TabsHeadersProps>('TabsHeader', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="div" classNames={['swan-tabs-headers']} forwardedRef={ref} props={{ ...props, role: 'tablist' }}>
      {children}
    </RenderComp>
  )
})
