import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type ShortTaxMessageProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent ShortTaxMessage
 */

export const ShortTaxMessage = renderWithRef<MinNativeRef, ShortTaxMessageProps>('ShortTaxMessage', null, (props, ref) => {
  const { children } = props
  const classNames = new Set(['swan-short-tax-message'])

  return (
    <RenderComp root="span" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
