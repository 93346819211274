import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type PipeSeparatorProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

export const PipeSeparator = renderWithRef<MinNativeRef, PipeSeparatorProps>('PipeSeparator', null, ({ children, ...props }, ref) => (
  <RenderComp root="span" classNames={['swan-pipe-separator']} forwardedRef={ref} props={{ ...props, role: 'presentation' }}>
    {children}
  </RenderComp>
))
