import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type TabsContentsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Tabs
 */

export const TabsContents = renderWithRef<MinNativeRef, TabsContentsProps>('TabsContents', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="div" classNames={['swan-tabs-contents']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
