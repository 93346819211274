import PropTypes, { InferProps } from 'prop-types'
import { ComponentType, FC, Fragment, useMemo } from 'react'

import { MANIFEST_STYLES } from '~/core/manifest'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types'
import { isNonNullish } from '~/core/utilities/is.utils'
import { getSameKeyValueMapForManifest, manifestResolve } from '~/core/utilities/manifest.utils'

import { swanLoaderConfigProps } from '~/react/contexts'

import { useSwanHeadConfig } from './head.hook'

export type SwanStyleSheetKey = keyof typeof MANIFEST_STYLES
export const SWAN_STYLE_KEY_MAP = getSameKeyValueMapForManifest(MANIFEST_STYLES)
const manifestStylesKeys = [
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.utility,
  ...(Object.keys(MANIFEST_STYLES) as SwanStyleSheetKey[]).filter(u => u !== SWAN_STYLE_KEY_MAP.core && u !== SWAN_STYLE_KEY_MAP.utility),
]

export const SWAN_STYLE_KEY_LIST = manifestStylesKeys

export function getSwanStylesheetUrl(key: SwanStyleSheetKey, pathType?: SwanPathTypeEnum, base?: string) {
  return manifestResolve(MANIFEST_STYLES, key, SwanAssetFolders.STYLES, pathType, base)
}

const propTypes = {
  styleSheetKeys: PropTypes.arrayOf(PropTypes.oneOf(SWAN_STYLE_KEY_LIST).isRequired),
  ...swanLoaderConfigProps,
}
export type SwanStylesheetsProps = InferProps<typeof propTypes> & {
  renderWith?: ComponentType<unknown>
}

/**
 * @subcomponent SwanHead
 */
export const SwanStylesheets: FC<SwanStylesheetsProps> = ({
  styleSheetKeys = SWAN_STYLE_KEY_LIST,
  swanPathType,
  swanBaseUrl,
  swanTenant,
  swanLocale,
  renderWith: Comp = Fragment,
}) => {
  const { swanBaseUrl: baseUrl, swanPathType: pathType } = useSwanHeadConfig(swanBaseUrl, swanPathType, swanTenant, swanLocale)
  const urls = useMemo(() => {
    const deDuplicatedStyleKeys = new Set(styleSheetKeys)
    deDuplicatedStyleKeys.delete(SWAN_STYLE_KEY_MAP.core)
    deDuplicatedStyleKeys.delete(SWAN_STYLE_KEY_MAP.utility)
    const styleKeysList = Array.from(deDuplicatedStyleKeys)
    styleKeysList.sort()
    styleKeysList.unshift(SWAN_STYLE_KEY_MAP.core, SWAN_STYLE_KEY_MAP.utility)
    const urls = styleKeysList.map(key => getSwanStylesheetUrl(key, pathType, baseUrl))
    const filteredUrls = urls.filter(isNonNullish)
    return filteredUrls
  }, [styleSheetKeys, pathType, baseUrl])

  return (
    <Comp>
      {urls.map(url => (
        <link rel="stylesheet" href={url} key={url} />
      ))}
    </Comp>
  )
}
SwanStylesheets.propTypes = propTypes
