import { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {}

const propKeysToRemove = Object.keys(propTypes)

export type AnchorBarListItemDescriptionProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * Description/sub-title field for a list item in the AnchorBar
 *
 * @subcomponent AnchorBar
 */
export const AnchorBarListItemDescription = renderWithRef<MinNativeRef, AnchorBarListItemDescriptionProps>('AnchorBarListItem', propTypes, (props, ref) => {
  const classNames = new Set(['swan-anchor-bar-list-item-description'])
  const { children } = props
  return (
    <RenderComp root="span" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
