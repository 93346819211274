import { ComponentType } from 'react'

import { SwanContextValue } from '~/react/contexts'

import { SwanFontNameEnum, SwanFonts } from './fonts.component'
import { SwanPageMeta } from './page-meta.component'
import { SwanStyleSheetKey, SwanStylesheets } from './stylesheets.component'

type SwanHeadProps = Partial<Pick<SwanContextValue, 'swanBaseUrl' | 'swanPathType' | 'swanTenant' | 'swanLocale'>> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderWith?: ComponentType<any>
  fontNames?: SwanFontNameEnum[]
  styleSheetKeys?: SwanStyleSheetKey[]
  renderStyleContentAsChildren?: boolean
}

export const SwanHead = ({ fontNames, styleSheetKeys, renderStyleContentAsChildren, ...otherProps }: SwanHeadProps) => {
  return (
    <>
      <SwanPageMeta {...otherProps} />
      <SwanFonts fontNames={fontNames} renderStyleContentAsChildren={renderStyleContentAsChildren} {...otherProps} />
      <SwanStylesheets styleSheetKeys={styleSheetKeys} {...otherProps} />
    </>
  )
}
