import { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

const propTypes = {}

export type FileTileThumbnailProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent FileTile
 */
export const FileTileThumbnail = renderWithRef<MinNativeRef, FileTileThumbnailProps>('FileTileThumbnail', propTypes, (props, ref) => {
  const { children, ...rest } = props

  const classNames = new Set(['swan-file-tile-thumbnail'])

  return (
    <RenderComp classNames={classNames} root="div" forwardedRef={ref} props={{ ...rest }}>
      {children ? <div className="swan-file-tile-thumbnail-image-container">{children}</div> : null}
      <Icon className="swan-file-tile-icon" iconType="fileGeneric" />
      <Icon className="swan-file-tile-icon-error" iconType="error" skin="error" />
    </RenderComp>
  )
})
