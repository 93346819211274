import PropTypes, { InferProps } from 'prop-types'
import type { ComponentProps, ReactNode } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { CollapsibleContent } from './collapsible-content.component'
import { CollapsibleSummaryButton } from './collapsible-summary-button.component'
import { CollapsibleSummary } from './collapsible-summary.component'
import { Collapsible } from './collapsible.component'

type DefaultNativeProps = ComponentProps<typeof Collapsible>

const propTypes = {
  /**
   * Used to render the contents of the generated CollapsibleSummaryButton
   */
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

const propKeysToRemove = Object.keys(propTypes)

export type BasicCollapsibleProps = CoreProps<DefaultNativeProps, MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Collapsible
 */
export const BasicCollapsible = renderWithRef<MinNativeRef, BasicCollapsibleProps>('BasicCollapsible', propTypes, ({ children, ...rest }, ref) => {
  return (
    <RenderComp root={Collapsible} forwardedRef={ref} propKeysToRemove={propKeysToRemove} props={rest}>
      <CollapsibleSummary>
        <CollapsibleSummaryButton
          render={
            typeof rest.heading !== 'string'
              ? ({ children, ...propsInRender }) => (
                  <div role="button" tabIndex={0} {...(propsInRender as unknown as object)}>
                    {children}
                  </div>
                )
              : undefined
          }
        >
          {rest.heading as ReactNode}
        </CollapsibleSummaryButton>
      </CollapsibleSummary>
      <CollapsibleContent>{children}</CollapsibleContent>
    </RenderComp>
  )
})
