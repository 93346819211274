import PropTypes from 'prop-types'
import { PropsWithoutRef, WeakValidationMap } from 'react'

import { FluidImage, FluidImageProps } from '~/react/components/fluid-image'
import { ProgressiveImage, ProgressiveImageProps } from '~/react/components/progressive-image'
import { BasicResponsiveImage, BasicResponsiveImageProps } from '~/react/components/responsive-image'

const propTypes = {
  /**
   * Type of image to use.
   * One of: "responsive", "progressive", "fluid".
   *
   * @default fluid
   */
  variant: PropTypes.oneOf(['responsive', 'progressive', 'fluid'] as const),
}

type ImgFluidProps = { variant?: 'fluid' } & PropsWithoutRef<FluidImageProps>
type ImgResponsiveProps = { variant: 'responsive' } & PropsWithoutRef<BasicResponsiveImageProps>
type ImgProgressiveProps = { variant: 'progressive' } & PropsWithoutRef<ProgressiveImageProps>

export type ImgProps = ImgFluidProps | ImgResponsiveProps | ImgProgressiveProps

export const Img = (props: ImgProps) => {
  if (props.variant === 'responsive') {
    return <BasicResponsiveImage {...{ ...props, variant: undefined }} />
  }
  if (props.variant === 'progressive') {
    return <ProgressiveImage {...{ ...props, variant: undefined }} />
  }
  return <FluidImage {...{ ...props, variant: undefined }} />
}

Img.displayName = 'Img'
Img.propTypes = propTypes as WeakValidationMap<ImgProps>
