import { Node } from '@react-types/shared'
import { ReactNode, useMemo, useState } from 'react'

import { CollectionChildren, CollectionContextValue, SwanCollectionContext } from './collection.context'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SwanCollectionContextProps<T = any> = {
  children: ReactNode
  items?: Iterable<T>
  disabledKeys?: Set<string | number> | undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CollectionContextProvider<T = any>(props: SwanCollectionContextProps<T>) {
  const { children, items } = props

  const [disabledKeys, setDisabledKeys] = useState(props.disabledKeys)
  const [prevDisabledKeys, setPrevDisabledKeys] = useState(props.disabledKeys)
  if (disabledKeys !== prevDisabledKeys) {
    setDisabledKeys(disabledKeys)
    setPrevDisabledKeys(disabledKeys)
  }

  const [childItems, setChildItems] = useState<CollectionChildren<Node<T>> | undefined>()

  const contextValue = useMemo<CollectionContextValue<T>>(
    () => ({
      items,
      childItems,
      setChildItems,
      disabledKeys,
      setDisabledKeys,
    }),
    [items, childItems, setChildItems, disabledKeys, setDisabledKeys],
  )

  return <SwanCollectionContext.Provider value={contextValue}>{children}</SwanCollectionContext.Provider>
}
