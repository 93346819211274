import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The visual style of the Ordered List.
   * One of: "standard", "checkmark", "shaded", "minimal".
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'checkmark', 'shaded', 'minimal'] as const),
  /**
   * The starting number of this Ordered List.
   */
  start: PropTypes.number,
}

const propKeysToRemove = Object.keys(propTypes)

export type OrderedListProps = CoreProps<JSX.IntrinsicElements['ol'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent List
 */

export const OrderedList = renderWithRef<MinNativeRef, OrderedListProps>('OrderedList', propTypes, (props, ref) => {
  const { children, skin = 'standard', start } = props

  const classNames = new Set<string>(['swan-list'])
  if (skin != 'standard') classNames.add(`swan-list-skin-${skin}`)

  return (
    <RenderComp
      root="ol"
      forwardedRef={ref}
      classNames={classNames}
      props={{
        ...props,
        style: {
          ...(props.style || {}),
          '--swan-internal-ordered-list-counter': start,
        },
      }}
      propKeysToRemove={propKeysToRemove}
    >
      {children}
    </RenderComp>
  )
})
