import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, deprecatedPropValues, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The type of callout
   * @deprecated The `variant` prop is no longer supported. Use `emphasis=low` for "overlay"; omit or use `variant=standard` for "inverse".
   * @default standard
   */
  variant: deprecatedProp(PropTypes.oneOf(['standard', 'overlay', 'inverse'] as const), 'Use `emphasis=low` for overlay; omit or use `variant=standard` for inverse'),

  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * The size variation
   * @default standard
   */
  size: deprecatedProp(PropTypes.oneOf(['standard', 'mini'] as const), 'Sizing is now handled automatically by standardMode/compactMode'),
  /**
   * The visual style of the Callout
   * The 'foil', 'new' and 'announcement' skins are deprecated; use 'accent' instead
   * The 'discount' skin is deprecated; use 'promo' instead
   * The 'standard' skin is deprecated; use 'info' instead
   * @default info
   */
  skin: deprecatedPropValues(
    PropTypes.oneOf(['standard', 'announcement', 'accent', 'discount', 'holiday', 'error', 'info', 'new', 'foil', 'warning', 'success', 'help', 'promo'] as const),
    ['standard', 'foil', 'new', 'announcement', 'discount'],
    'Omit or use the `accent` skin (replaces `foil`, `new`, and `announcement`), `promo` (replaces `discount`) skin, `info` (replaces `standard`) instead',
  ),

  /**
   * The level of emphasis of the callout
   *
   * @default standard
   */
  emphasis: PropTypes.oneOf(['standard', 'low'] as const),

  /**
   * The border of the Callout
   * @deprecated The 'border' prop is no longer being supported.
   * @default standard
   */
  border: deprecatedProp(
    PropTypes.oneOf(['standard', 'none'] as const),
    'To maintain a high-quality user experience, we are no longer supporting this feature, and are not providing a direct replacement.',
  ),
}

export type CalloutProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

export const Callout = renderWithRef<MinNativeRef, CalloutProps>('Callout', propTypes, (props, ref) => {
  const { skin = 'info', border = 'standard', variant = 'standard', emphasis = 'standard', size = 'standard', children, ...rest } = props

  const classNames = new Set<string>(['swan-callout'])
  if (skin !== 'standard' && skin !== 'info') classNames.add(`swan-callout-skin-${skin}`)
  if (border === 'none') classNames.add('swan-callout-borderless')
  if (variant === 'overlay') classNames.add('swan-callout-overlay')
  if (variant === 'inverse') classNames.add('swan-callout-inverse')
  if (emphasis === 'low') classNames.add('swan-callout-emphasis-low')
  if (size === 'mini') classNames.add('swan-callout-mini')

  return (
    <RenderComp root="span" forwardedRef={ref} classNames={classNames} props={rest}>
      {children}
    </RenderComp>
  )
})
