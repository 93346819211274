import { InferProps } from 'prop-types'

import { className as classesMerger } from '~/core/utilities'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { renderWithRef } from '~/react/components/core'
import { ListboxPopover, ListboxPopoverProps } from '~/react/components/listbox'

const propTypes = {}

export type ComboboxPopoverProps = CoreProps<ListboxPopoverProps, MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Combobox
 */
export const ComboboxPopover = renderWithRef<MinNativeRef, ComboboxPopoverProps>('ComboboxPopover', propTypes, (props, ref) => {
  const { children, placement = 'bottom start', className } = props

  const classes = classesMerger(className, 'swan-combobox-popover')

  return (
    <ListboxPopover className={classes} {...props} isNonModal placement={placement} ref={ref}>
      {children}
    </ListboxPopover>
  )
})
