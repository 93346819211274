import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ThumbnailsHeroImagesSlidesContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent ThumbnailsHero
 */
export const ThumbnailsHeroImagesSlidesContainer = renderWithRef<MinNativeRef, ThumbnailsHeroImagesSlidesContainerProps>(
  'ThumbnailsHeroImagesSlidesContainer',
  null,
  ({ children, ...props }, ref) => {
    return (
      <RenderComp root="div" forwardedRef={ref} classNames={['swan-thumbnails-hero-images-slides']} props={props}>
        {children}
      </RenderComp>
    )
  },
)
