import PropTypes, { InferProps } from 'prop-types'
import { ComponentProps } from 'react'

import { CoreInputProps, CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'
import { composeEventHandlersUtil } from '~/react/utilities'

import { SelectionSetInputContext, useSelectionSetContext } from './selection-set.context'

const propTypes = {
  value: PropTypes.string.isRequired,
}

export type SelectionSetInputProps = CoreProps<CoreInputProps, MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent SelectionSet
 */
export const SelectionSetInput = renderWithRef<MinNativeRef, SelectionSetInputProps>('SelectionSetInput', propTypes, (props, ref) => {
  const { value, children, id: customId, onChange: customOnChange } = props
  const selectionSetContext = useSelectionSetContext()
  const id = useId(customId)
  const isMultiSelect = selectionSetContext.variant === 'multi-select'
  const classNames = ['swan-selection-set-input', isMultiSelect ? 'swan-checkbox' : 'swan-radio']

  /* eslint-enable @typescript-eslint/no-non-null-assertion */
  const checked = isMultiSelect ? selectionSetContext.selectedValues[value] : selectionSetContext.selectedValue === value

  const onChange: ComponentProps<'input'>['onChange'] = isMultiSelect
    ? event => {
        selectionSetContext.onSelectedValuesChange({ ...selectionSetContext.selectedValues, [value]: event.target.checked }, event)
      }
    : event => {
        if (event.target.checked) {
          /* eslint-enable @typescript-eslint/no-non-null-assertion */
          selectionSetContext.onSelectedValueChange(value, event)
        }
      }

  const forwardedProps = {
    type: isMultiSelect ? 'checkbox' : 'radio',
    ...props,
    id,
    name: selectionSetContext.name,
    value,
    checked,
    onChange: composeEventHandlersUtil(customOnChange, onChange),
  }
  return (
    <>
      <RenderComp root="input" forwardedRef={ref} classNames={classNames} props={forwardedProps} />

      <SelectionSetInputContext.Provider value={{ selectionSetInputId: id }}>{children}</SelectionSetInputContext.Provider>
    </>
  )
})
