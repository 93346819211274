import PropTypes, { InferProps } from 'prop-types'
import { CSSProperties } from 'react'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useIsSafariVersion18 } from '~/react/hooks/use-is-safari-version-18'
import { TransitionStatus } from '~/react/hooks/use-transition'

import { useInternalDialogConfigContext } from './dialog.component'
import { useModalDialogContext } from './modal-dialog.context'

const propTypes = {
  /**
   * removes the padding around the dialog's content
   *
   * @default false
   */
  fullBleed: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type ModalDialogContentProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Dialog
 */
export const ModalDialogContent = renderWithRef<MinNativeRef, ModalDialogContentProps>('ModalDialogContent', propTypes, (props, ref) => {
  const { bgc, backgroundColor, variant, takeOver, footerPinned, bodyWidth, transitionState, isModal } = useModalDialogContext()
  const { portalRef } = useInternalDialogConfigContext()

  const { fullBleed = false, children } = props

  const wrapperClassName = new Set<string>(['swan-modal-dialog'])

  if (!isModal) wrapperClassName.add('swan-modeless-dialog')
  if (fullBleed) wrapperClassName.add('swan-modal-dialog-skin-full-bleed')
  if (TransitionStatus.ENTERED === transitionState) wrapperClassName.add('swan-modal-dialog-visible')
  if (variant && ['panel-right', 'panel-left', 'panel-bottom', 'panel-top', 'menu'].includes(variant)) wrapperClassName.add('swan-modal-dialog-panel')
  if (variant && ['panel-left', 'menu'].includes(variant)) wrapperClassName.add('swan-modal-dialog-panel-left')
  if (variant && ['panel-right', 'panel-left', 'panel-bottom', 'panel-top', 'menu'].includes(variant) && bodyWidth === 'capped')
    wrapperClassName.add('swan-modal-dialog-panel-capped')
  if (bodyWidth === 'grow') wrapperClassName.add('swan-modal-dialog-grow')
  if (variant === 'panel-bottom') wrapperClassName.add('swan-modal-dialog-panel-bottom')
  if (variant === 'panel-top') wrapperClassName.add('swan-modal-dialog-panel-top')
  if (variant === 'menu') wrapperClassName.add('swan-modal-dialog-panel-menu')
  if (takeOver) wrapperClassName.add('swan-modal-dialog-takeover')
  if (footerPinned) wrapperClassName.add('swan-modal-dialog-panel-pinned')

  const isSafari18 = useIsSafariVersion18()
  const style: CSSProperties = { ...props.style }
  if (isSafari18) {
    style.minHeight = '-webkit-fill-available'
    style.maxHeight = '-webkit-fill-available'
  }

  return (
    <>
      <RenderComp root="div" forwardedRef={ref} classNames={wrapperClassName} props={{ bgc, backgroundColor, ...props, style }} propKeysToRemove={propKeysToRemove}>
        {children}
      </RenderComp>
      <div ref={portalRef} />
    </>
  )
})
