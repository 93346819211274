import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type DividerProps = CoreProps<JSX.IntrinsicElements['hr'], MinNativeRef>

export const Divider = renderWithRef<MinNativeRef, DividerProps>('Divider', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="hr" classNames={['swan-divider']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
