import PropTypes, { InferProps } from 'prop-types'

import { className } from '~/core/utilities'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { VisuallyHidden } from '~/react/components/visually-hidden'

import { ColorSwatchGradientTypes, ColorSwatchLabelPositions, useColorSwatchesContext } from './color-swatches.context'

const propTypes = {
  /**
   * The main color of the swatch. Accepts a css color value
   * @default transparent
   */
  primaryColor: PropTypes.string,
  /**
   * The optional secondary color of the swatch. Accepts a css color value
   */
  secondaryColor: PropTypes.string,
  /**
   * URL for a background image for the swatch.
   */
  backgroundImage: PropTypes.string,
  /**
   * Whether or not the underlying input for the swatch should be enabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * A text representation of the color value. Used in the hover tooltip and as a hidden label for accessibility
   */
  accessibleText: PropTypes.string,
  /**
   * Where to position the label tooltip relative to the swatch. Overrides the position setting of the ColorSwatches component.
   */
  labelPosition: PropTypes.oneOf(ColorSwatchLabelPositions),
  /**
   * Configures a gradient between the primary and secondary colors. By default shows the two colors stacked but separate
   */
  gradient: PropTypes.oneOf(ColorSwatchGradientTypes),
}

export type ColorSwatchProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

function getBackgroundUrl(backgroundImage?: string | null) {
  // The spec requires urls be wrapped in a quotation mark `url("")` (necessary for data urls), but we haven't always done that
  // for backwards-compatibility, we only add it if the consumer hasn't done so already
  const includedQuote = !backgroundImage?.startsWith('"') ? '"' : ''
  return backgroundImage ? `url(${includedQuote}${backgroundImage}${includedQuote})` : undefined
}

/**
 * @subcomponent ColorSwatches
 */
export const ColorSwatch = renderWithRef<MinNativeRef, ColorSwatchProps>('ColorSwatch', null, (props, ref) => {
  const {
    children,
    primaryColor = 'transparent',
    secondaryColor,
    backgroundImage,
    accessibleText,
    labelPosition,
    disabled = false,
    gradient,
    title,
    style = {},
    ...restProps
  } = props
  const ctx = useColorSwatchesContext()

  const processedLabelPosition = labelPosition ?? ctx?.labelPosition

  const processedProps = {
    ...restProps,
    disabled,
    'aria-disabled': Boolean(disabled),
    role: 'presentation',
    style: {
      ...style,
      backgroundColor: primaryColor ?? undefined,
      color: secondaryColor ?? undefined,
      backgroundImage: getBackgroundUrl(backgroundImage),
    },
  }

  const classNames = ['swan-color-swatch']
  if (disabled) classNames.push(`swan-color-swatch-disabled`)
  if (gradient) classNames.push(`swan-color-swatch-${gradient}-gradient`)

  const labelClasses = className([
    'swan-color-swatch-accessible-label',
    processedLabelPosition && processedLabelPosition !== 'top' ? `swan-color-swatch-accessible-label-${processedLabelPosition}` : undefined,
  ])

  return (
    <span className="swan-color-swatch-wrapper">
      <RenderComp root="div" classNames={classNames} forwardedRef={ref} props={processedProps}>
        {children}
      </RenderComp>
      <VisuallyHidden className={labelClasses}>{accessibleText ?? title}</VisuallyHidden>
    </span>
  )
})
