import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type StandardTileNameProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardTile
 */
export const StandardTileName = renderWithRef<MinNativeRef, StandardTileNameProps>('StandardTileName', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-standard-tile-name']} props={props}>
    {props.children}
  </RenderComp>
))
