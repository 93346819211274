import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useSelectionSetInputContext } from './selection-set.context'

export type SelectionSetLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

/**
 * @subcomponent SelectionSet
 */
export const SelectionSetLabel = renderWithRef<MinNativeRef, SelectionSetLabelProps>('SelectionSet', {}, (props, ref) => {
  const selectionSetInputContext = useSelectionSetInputContext()
  const classNames = ['swan-selection-set-label']
  return (
    <RenderComp root="label" forwardedRef={ref} classNames={classNames} props={{ ...props, htmlFor: selectionSetInputContext?.selectionSetInputId }}>
      {props.children}
    </RenderComp>
  )
})
