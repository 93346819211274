import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ResponsiveImageProps = CoreProps<JSX.IntrinsicElements['img'], MinNativeRef>

export const ResponsiveImage = renderWithRef<MinNativeRef, ResponsiveImageProps>('ResponsiveImage', null, ({ children, ...props }, ref) => (
  <RenderComp root="img" classNames={['swan-responsive-image']} forwardedRef={ref} props={props}>
    {children}
  </RenderComp>
))
