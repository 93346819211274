import { ChangeEvent, createContext, useContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type SelectionSetSkin = 'standard' | 'buttons' | 'simple-column' | 'tiles-horizontal' | 'tiles-vertical' | 'tiles-mini' | null

export type SelectedValue = string | null

export type SelectedValues = { [value: string]: boolean }

export const SelectionSetContext = createContext<
  | null
  | undefined
  | {
      name: string | undefined | null
      skin: SelectionSetSkin
      variant: 'single-select' | 'multi-select' | null
      imageWidth: 'fixed' | 'proportional' | null
      imageVerticalAlignment: 'cover' | 'top' | 'center' | null
      tileContentsPadding: 'standard' | 'tight' | null
      tileImagePadding: 'standard' | 'wide' | null
      selectedValue: SelectedValue
      onSelectedValueChange: (value: NonNullable<SelectedValue>, event: ChangeEvent<HTMLInputElement>) => void
      selectedValues: SelectedValues
      onSelectedValuesChange: (selectedValues: SelectedValues, event: ChangeEvent<HTMLInputElement>) => void
    }
>(null)
SelectionSetContext.displayName = 'SelectionSetContext'

export const SelectionSetInputContext = createContext<
  | undefined
  | {
      selectionSetInputId?: string | null
    }
>(undefined)

export function useSelectionSetContext() {
  return useNonNullishContext(SelectionSetContext)
}

export function useSelectionSetInputContext() {
  return useContext(SelectionSetInputContext)
}
