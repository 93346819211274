import PropTypes, { InferProps } from 'prop-types'
import { useContext } from 'react'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { Card } from '~/react/components/card'
import { renderWithRef } from '~/react/components/core'
import { FormError, FormFieldContext } from '~/react/components/form'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { Progress } from '~/react/components/progress-bar'
import { Spinner } from '~/react/components/spinner'
import { Typography } from '~/react/components/typography'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * The name of the file
   */
  fileName: PropTypes.string.isRequired,
  /**
   * The localized accessible text for the Progress component
   */
  accessibleTextForProgress: PropTypes.string.isRequired,
  /**
   * The localized accessible text for the Spinner component
   */
  accessibleTextForSpinner: PropTypes.string.isRequired,
  /**
   * The description of the file, could be file size or dimensions e.g. "3.56 MB" or "600x400"
   */
  fileDescription: PropTypes.string,
  /**
   * The visual layout of the FileTile
   *
   * @default thumb
   */
  layout: PropTypes.oneOf(['thumb', 'list'] as const),
  /**
   * The visual style of the FileTile
   *
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'queued', 'loading', 'error'] as const),
  /**
   * The percentage of progress of the file upload
   */
  progressValue: PropTypes.number,
  /**
   * Error message on file upload failure
   */
  errorMessage: PropTypes.string,
}

export type FileTileProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const FileTile = renderWithRef<MinNativeRef, FileTileProps>('FileTile', propTypes, (props, ref) => {
  useComponentStylesLoaded('FileTile', SWAN_STYLE_KEY_MAP.fileTile)

  const {
    children,
    fileName,
    fileDescription,
    progressValue,
    accessibleTextForProgress,
    accessibleTextForSpinner,
    skin = 'standard',
    layout = 'thumb',
    errorMessage,
    ...rest
  } = props

  // If errorMessage is provided, we should always use the error skin
  const hasError = skin === 'error' || errorMessage
  const classNames = new Set(['swan-file-tile', `swan-file-tile-skin-${hasError ? 'error' : skin}`, `swan-file-tile-layout-${layout}`])

  // Check if the form context exists if using skin=error to ensure the component is wrapped in a FormField
  const formContext = useContext(FormFieldContext)
  if (hasError && !formContext) throw Error('FileTile must be wrapped in FormField with a FileInput when using skin="error"')

  return (
    <Card className={Array.from(classNames).join(' ')} ref={ref} {...rest} bordered>
      <Spinner className="swan-file-tile-spinner" accessibleText={accessibleTextForSpinner} />
      {children}
      <div className="swan-file-tile-details">
        <Typography className="swan-file-tile-name" fontSkin="title-item">
          {fileName}
        </Typography>
        <Typography className="swan-file-tile-description" fontSkin="body-small" textColor="subtle">
          {hasError ? <FormError as="div">{errorMessage}</FormError> : skin === 'queued' ? accessibleTextForSpinner : fileDescription}
        </Typography>
        <div className="swan-file-tile-progress-container">
          <Progress value={progressValue ?? 0} aria-label={accessibleTextForProgress} />
        </div>
      </div>
    </Card>
  )
})
