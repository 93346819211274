import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

export type SkipLinkProps = CoreProps<JSX.IntrinsicElements['a'], MinNativeRef>

export const SkipLink = renderWithRef<MinNativeRef, SkipLinkProps>('SkipLink', null, ({ children, ...props }, ref) => (
  <RenderComp root="a" classNames={['swan-skip-link']} forwardedRef={ref} props={{ href: '#maincontentstart', ...props }}>
    {children}
  </RenderComp>
))
