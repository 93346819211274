import { Context, useContext } from 'react'

/**
 * This hook can be used to ensure your context is always defined. It takes a context parameter of type `Context | undefined | null` and returns a `Context` type output.
 *
 * If the value passed is undefined or null, it will throw an error.
 *
 * @param {Context | undefined | null} context
 */
export function useNonNullishContext<T extends object>(context: Context<T | null | undefined>): T {
  const value = useContext(context)
  if (value === null || value === undefined) {
    throw new Error(`Context:${context.displayName} is null Or undefined`)
  }
  return value
}
