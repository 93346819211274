import { SWAN_BASE_CDN_URL } from '~/core/constants'

// if any new locale is added here, also add it to the react-aria plugin in rollup.config.ts
const baseUrlForVistaprint = {
  'da-DK': 'www.vistaprint.dk',
  'de-AT': 'www.vistaprint.at',
  'de-CH': 'www.vistaprint.ch',
  'de-DE': 'www.vistaprint.de',
  'en-AU': 'www.vistaprint.com.au',
  'en-CA': 'www.vistaprint.ca',
  'en-GB': 'www.vistaprint.co.uk',
  'en-IE': 'www.vistaprint.ie',
  'en-IN': 'www.vistaprint.in',
  'en-NZ': 'www.vistaprint.co.nz',
  'en-SG': 'www.vistaprint.sg',
  'en-US': 'www.vistaprint.com',
  'es-ES': 'www.vistaprint.es',
  'es-US': 'www.vistaprint.com',
  'fi-FI': 'www.vistaprint.fi',
  'fr-BE': 'www.vistaprint.be',
  'fr-CA': 'www.vistaprint.ca',
  'fr-CH': 'www.vistaprint.ch',
  'fr-FR': 'www.vistaprint.fr',
  'it-CH': 'www.vistaprint.ch',
  'it-IT': 'www.vistaprint.it',
  'ja-JP': 'www.vistaprint.jp',
  'nb-NO': 'www.vistaprint.no',
  'nl-BE': 'www.vistaprint.be',
  'nl-NL': 'www.vistaprint.nl',
  'pt-PT': 'www.vistaprint.pt',
  'sv-SE': 'www.vistaprint.se',
}

const swanBaseUrlForTenantAndLocales: Record<string, Record<string, string>> = {
  vistaprint: baseUrlForVistaprint,
}

export function getSwanBaseUrl(tenant?: string | null, locale?: string | null): string {
  if (!tenant || !locale) return SWAN_BASE_CDN_URL
  // TODO: Add Warning if either is passed or invalid value is passed
  const tenantConfig = swanBaseUrlForTenantAndLocales[tenant.toLowerCase()]
  const [lang, culture] = locale.split('-')
  const formattedLocale = `${lang.toLowerCase()}-${culture.toUpperCase()}`
  if (tenantConfig?.[formattedLocale]) return `https://${tenantConfig[formattedLocale]}/swan/`
  return SWAN_BASE_CDN_URL
}

getSwanBaseUrl.swanBaseUrlForTenantAndLocales = swanBaseUrlForTenantAndLocales
