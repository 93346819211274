import { SWAN_BASE_CDN_URL, SWAN_HASHED_FOLDER, SWAN_PUBLIC_ASSET_FOLDER, SWAN_VERSIONED_FOLDER } from '~/core/constants'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types/enums'
import { SameKeyValueMap } from '~/core/types/utility.types'

import { isTestEnv } from './env.utils'

export function getSwanAssetFolderName(folder: SwanAssetFolders): string {
  switch (folder) {
    case SwanAssetFolders.FAVICONS:
      return 'favicons'
    case SwanAssetFolders.POLYFILLS:
      return 'polyfills'
    case SwanAssetFolders.FONTS:
      return 'fonts'
    case SwanAssetFolders.ICONS:
      return 'icons'
    case SwanAssetFolders.ILLUSTRATIONS:
      return 'illustrations'
    case SwanAssetFolders.IMAGES:
      return 'images'
    case SwanAssetFolders.JS:
      return 'js'
    case SwanAssetFolders.STYLES:
      return 'styles'
  }
}

function getVersionPrefix(pathType: SwanPathTypeEnum | null = SwanPathTypeEnum.hashed) {
  if (isTestEnv()) {
    return 'pkg-folder-test'
  }
  if (pathType === SwanPathTypeEnum.versioned) return SWAN_VERSIONED_FOLDER
  return SWAN_HASHED_FOLDER
}

export function manifestResolve<Key extends string>(
  manifest: Record<Key, Record<SwanPathTypeEnum, string>>,
  key: Key,
  folder: SwanAssetFolders,
  pathType: SwanPathTypeEnum = SwanPathTypeEnum.hashed,
  swanAssetBaseUrl: string = SWAN_BASE_CDN_URL,
): string | null {
  const manifestConfig = manifest[key]
  if (!manifestConfig) {
    console.error(`Warning: Invalid style key \`${key}\`: Please remove any deprecated, unsupported, or misspelled style keys from your SwanHead component.`)
    return null
  }
  const fileName = manifestConfig[pathType] || manifestConfig.hashed || manifestConfig.versioned
  if (fileName) {
    const versionPrefix = getVersionPrefix(pathType)
    const folderName = getSwanAssetFolderName(folder)
    return `${swanAssetBaseUrl || ''}${versionPrefix}/${folderName}/${fileName}`
  }
  return null
}

export function getSameKeyValueMapForManifest<T extends object>(manifest: T): SameKeyValueMap<T> {
  return Object.fromEntries(Object.keys(manifest).map(k => [k, k])) as SameKeyValueMap<T>
}

export function polyfillResolve(fileName: string, swanAssetBaseUrl: string = SWAN_BASE_CDN_URL): string {
  const folderName = getSwanAssetFolderName(SwanAssetFolders.POLYFILLS)
  return `${swanAssetBaseUrl}${SWAN_PUBLIC_ASSET_FOLDER}/${folderName}/${fileName}`
}
