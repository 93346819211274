import PropTypes, { InferProps } from 'prop-types'
import warning from 'tiny-warning'

import { StyleFontSize, StyleFontWeight } from '~/core/types'

import { CoreProps, DeprecationProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Whether the component should not wrap it's content
   *
   * @default false
   */
  noWrap: PropTypes.bool,
  /**
   * Whether the content should truncate
   *
   * @default false
   */
  truncate: PropTypes.bool,
}
type TypographyPropTypes = InferProps<typeof propTypes> & DeprecationProps

const propKeysToRemove: (keyof TypographyPropTypes)[] = ['noWrap', 'truncate']

const headingToSizeMap: Record<string, StyleFontSize> = {
  h1: 'x4large',
  h2: 'x3large',
  h3: 'x2large',
  h4: 'large',
  h5: 'standard',
  h6: 'standard',
}
const headingSizeToWeightMap: Record<string, StyleFontWeight> = {
  h1: 'bold',
  h2: 'bold',
  h3: 'bold',
  h4: 'bold',
  h5: 'bold',
  h6: 'bold',
}

function get<K extends string | number | symbol, V>(obj: Record<K, V>, key?: K | null): V | undefined {
  if (key === null || key === undefined || !obj) return undefined
  if (key in obj) {
    return obj[key]
  }
  return undefined
}

export type TypographyProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, TypographyPropTypes>

export const Typography = renderWithRef<MinNativeRef, TypographyProps>('Typography', propTypes, (props, ref) => {
  const { children, noWrap = false, truncate = false, as, component } = props

  const classNames = new Set<string>()

  const tag = (as || component) as unknown as string
  if (typeof tag === 'string' && tag in headingSizeToWeightMap) classNames.add('swan-heading')
  if (noWrap) classNames.add(`swan-text-no-wrap`)
  if (truncate) classNames.add(`swan-text-truncate`)

  if (typeof tag === 'string' && tag in headingSizeToWeightMap) {
    warning(props.__dangerouslySuppressWarning, `Typography rendered as ${tag}: Consider using SWAN's heading component <${tag.toUpperCase()}>`)
  }

  const processedProps: TypographyProps = {
    fontSize: props.fontSize || get(headingToSizeMap, tag) || undefined,
    fontWeight: props.fontWeight || get(headingSizeToWeightMap, tag),
    ...props,
  }

  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={processedProps}>
      {children}
    </RenderComp>
  )
})
