import { createContext, ReactNode, useContext, useMemo } from 'react'

type InternalComponentConfigProviderProps<T> = {
  config: T
  children: ReactNode
}

type InternalComponentConfigType<T extends Record<string, unknown>> = T

export const getComponentConfigProvider = <T extends Record<string, unknown>>(componentName: string) => {
  const InternalComponentConfig = createContext<InternalComponentConfigType<Record<string, unknown>>>({})
  InternalComponentConfig.displayName = `${componentName}InternalComponentConfig`

  function useInterComponentConfigCtx(): InternalComponentConfigType<T> {
    return useContext(InternalComponentConfig) as InternalComponentConfigType<T>
  }

  const InternalComponentConfigProvider = <T extends Record<string, unknown>>({ config, children }: InternalComponentConfigProviderProps<T>) => {
    const parentConfig = useInterComponentConfigCtx()
    const value = useMemo(
      () => ({
        ...parentConfig,
        ...config,
      }),
      [config, parentConfig],
    )

    return <InternalComponentConfig.Provider value={value}>{children}</InternalComponentConfig.Provider>
  }
  InternalComponentConfigProvider.displayName = 'InternalComponentConfigProvider'

  return [InternalComponentConfigProvider, useInterComponentConfigCtx, InternalComponentConfig] as const
}
