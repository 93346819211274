import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type SkeletonTextHeadingProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent SkeletonText
 */
export const SkeletonTextHeading = renderWithRef<MinNativeRef, SkeletonTextHeadingProps>('SkeletonTextHeading', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="div" classNames={['swan-skeleton-text-heading', 'swan-loading-shimmer']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
