import { MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { BannerImageProps, bannerImagePropTypes } from './banner-image.component'

export type BannerSecondaryImageProps = BannerImageProps

/**
 * @subcomponent Banner
 */
export const BannerSecondaryImage = renderWithRef<MinNativeRef, BannerImageProps>('BannerSecondaryImage', bannerImagePropTypes, (props, ref) => {
  const classNames = new Set(['swan-banner-image', 'swan-banner-secondary-image'])
  if (props.imageFocalPoint) classNames.add(`swan-banner-image-focal-point-${props.imageFocalPoint.replace(/ +/g, '-')}`)
  const { children } = props
  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={Object.keys(bannerImagePropTypes)} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
