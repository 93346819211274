import { MANIFEST_IMAGES } from '~/core/manifest/images'
import { SwanAssetFolders, SwanPathTypeEnum } from '~/core/types'

import { getSameKeyValueMapForManifest, manifestResolve } from './manifest.utils'

export type SwanImageKey = keyof typeof MANIFEST_IMAGES
export const SWAN_IMAGE_KEY_MAP = getSameKeyValueMapForManifest(MANIFEST_IMAGES)

export function getSwanImageUrl(key: SwanImageKey, pathType?: SwanPathTypeEnum, swanAssetBaseUrl?: string) {
  return manifestResolve(MANIFEST_IMAGES, key, SwanAssetFolders.IMAGES, pathType, swanAssetBaseUrl)
}
