import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The visual style of the list.
   * One of: "standard", "checkmark", "minimal".
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'checkmark', 'minimal'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type ListProps = CoreProps<JSX.IntrinsicElements['ul'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent List
 */

export const List = renderWithRef<MinNativeRef, ListProps>('List', propTypes, (props, ref) => {
  const { children, skin = 'standard', role } = props

  const classNames = new Set<string>(['swan-list'])
  if (skin != 'standard') classNames.add(`swan-list-skin-${skin}`)

  return (
    <RenderComp
      root="ul"
      forwardedRef={ref}
      classNames={classNames}
      props={{
        ...props,
        // Safari + Voiceover has a "feature" where it doesn't read a list with list-style: none as a list
        // it's fixed in CSS, but also add the role="list" to be extra clear
        // https://gerardkcohen.me/writing/2017/voiceover-list-style-type.html
        role: skin === 'minimal' ? (role ?? 'list') : role,
      }}
      propKeysToRemove={propKeysToRemove}
    >
      {children}
    </RenderComp>
  )
})
