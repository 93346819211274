import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type CollapsibleSummaryProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Collapsible
 */
export const CollapsibleSummary = renderWithRef<MinNativeRef, CollapsibleSummaryProps>('CollapsibleSummary', null, (props, ref) => {
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={['swan-collapsible-summary']} props={props}>
      {props.children}
    </RenderComp>
  )
})
