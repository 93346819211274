import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { FloatingLabelContextProvider } from '~/react/contexts/internal/floating-label'

export type TextInputWithFloatingLabelProps = CoreProps<JSX.IntrinsicElements['div']>

/**
 * @subcomponent TextInput
 */
export const TextInputWithFloatingLabel = renderWithRef<MinNativeRef, TextInputWithFloatingLabelProps>('TextInputWithFloatingLabel', null, (props, ref) => {
  const { children } = props
  const classes = ['swan-vanilla-ignore', 'swan-input-with-floating-label']
  return (
    <FloatingLabelContextProvider>
      <RenderComp root="div" forwardedRef={ref} props={props} classNames={classes}>
        {children}
      </RenderComp>
    </FloatingLabelContextProvider>
  )
})
