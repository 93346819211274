import { useEffect, useState } from 'react'

export enum TransitionStatus {
  ENTERED = 'entered',
  ENTERING = 'entering',
  EXITED = 'exited',
  EXITING = 'exiting',
}

/**
 * Takes a boolean input and based on the change in the value, it returns various statuses(TransitionStatus).
 *
 * Useful when we want to know the various stages of a state change to apply either apply a CSS animation or some other task.
 *
 * @param {boolean} transitionIsOpen
 * @param {number} duration
 */
export function useTransition(transitionIsOpen: boolean, duration = 200) {
  const [transitionState, setTransitionState] = useState<TransitionStatus>(transitionIsOpen ? TransitionStatus.ENTERED : TransitionStatus.EXITED)
  useEffect(() => {
    setTransitionState(currentState => {
      if (transitionIsOpen) {
        return currentState !== TransitionStatus.ENTERED ? TransitionStatus.ENTERING : TransitionStatus.ENTERED
      }
      return currentState !== TransitionStatus.EXITED ? TransitionStatus.EXITING : TransitionStatus.EXITED
    })
    const timer = setTimeout(() => {
      setTransitionState(currentState => {
        switch (currentState) {
          case TransitionStatus.ENTERING:
            return TransitionStatus.ENTERED
          case TransitionStatus.EXITING:
            return TransitionStatus.EXITED
          default:
            return currentState
        }
      })
    }, duration)
    return () => clearTimeout(timer)
  }, [duration, transitionIsOpen])
  return transitionState
}
