import { ListboxOption, ListboxOptionProps } from '~/react/components/listbox'

export type MenuItemProps = ListboxOptionProps

/**
 * @subcomponent Menu
 */
export const MenuItem = ListboxOption

MenuItem.displayName = 'MenuItem'
