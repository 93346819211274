import { useButton } from '@react-aria/button'
import { Children, cloneElement, isValidElement, ReactNode } from 'react'

import { assignRefs } from '~/core/utilities'

import { useSwanPopover } from './popover.context'

/**
 * @subcomponent Popover
 */
export function PopoverTrigger({ children }: { children?: ReactNode }) {
  const { triggerRef, triggerProps = {} } = useSwanPopover()
  const { buttonProps } = useButton(triggerProps, triggerRef)

  const props = {
    ref: triggerRef,
    ...buttonProps,
    type: undefined,
  }

  // Axe tests will yell if aria-expanded has a value of "false"
  if (!buttonProps['aria-expanded']) {
    delete props['aria-expanded']
  }

  return (
    <>
      {Children.map(children, (child, index) => {
        if (index === 0 && isValidElement(child) && !child.props.disabled) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const combinedRefs = assignRefs(triggerRef, (child as unknown as any).ref)
          return cloneElement(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            child as unknown as any,
            {
              ...props,
              disabled: child.props.disabled !== 'undefined' ? child.props.disabled : (props as typeof buttonProps & { disabled?: boolean }).disabled,
              ref: combinedRefs,
            },
          )
        }
        return child
      })}
    </>
  )
}
