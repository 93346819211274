import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * Decide whether or not to include dividers between each Column.
   * @default false
   */
  dividers: PropTypes.bool,
  /**
   * Specify the gutter width
   * Available options: 'standard', 'tight'
   * @default standard
   */
  gutter: PropTypes.oneOf(['standard', 'tight'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type GridContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const GridContainer = renderWithRef<MinNativeRef, GridContainerProps>('GridContainer', null, ({ children, dividers = false, gutter = 'standard', ...props }, ref) => {
  useComponentStylesLoaded('GridContainer', SWAN_STYLE_KEY_MAP.grid)
  const classNames = ['swan-grid-container']
  if (dividers) classNames.push('swan-grid-dividers')
  if (gutter === 'tight') classNames.push('swan-grid-container-tight')
  return (
    <RenderComp root="div" forwardedRef={ref} props={props} propKeysToRemove={propKeysToRemove} classNames={classNames}>
      {children}
    </RenderComp>
  )
})
