import { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { Li } from '~/react/components/list'

const propTypes = {}

const propKeysToRemove = Object.keys(propTypes)

export type AnchorBarListItemProps = CoreProps<JSX.IntrinsicElements['li'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * Navigation item for the AnchorBar
 *
 * @subcomponent AnchorBar
 */
export const AnchorBarListItem = renderWithRef<MinNativeRef, AnchorBarListItemProps>('AnchorBarListItem', propTypes, (props, ref) => {
  const classNames = new Set(['swan-anchor-bar-list-item'])
  const { children } = props
  return (
    <RenderComp root={Li} forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
