import { Responsive } from '~/core/types/utility.types'

// Intentionally not exported publicly
export const SpacePropType = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'] as const
export type StyleSpace = (typeof SpacePropType)[number]
// Intentionally not exported publicly
export const SpaceDensityPropType = ['between-sections', 'between-subsections', 'between-actions', 'to-actions', 'between-icon-and-text'] as const
type StyleSpaceDensitySectionLevel = 'between-sections' | 'between-subsections'
type StyleSpacingBetweenIconAndText = 'between-icon-and-text'
type StyleSpaceDensity = (typeof SpaceDensityPropType)[number]

export type StyleSpaceWithAuto = (typeof SpacePropType)[number] | 'auto'
export type StyleSpaceWithDensity = StyleSpaceWithAuto | StyleSpaceDensity
type StyleSpaceWithDensityExcludingIconText = Exclude<StyleSpaceWithDensity, StyleSpacingBetweenIconAndText>

export type StyleBackgroundColor =
  | 'standard'
  | 'info'
  | 'strong'
  | 'accent'
  | 'success'
  | 'warning'
  | 'error'
  | 'help'
  | 'promo'
  | 'warm-white'
  | 'midnight'
  | 'holiday'
  | 'holiday-beige-1'
  | 'holiday-green-1'
  | 'holiday-green-2'
  | 'holiday-red-1'
  | 'holiday-red-2'
  | 'black'
  | 'transparent'

export type StyleTextAlign = 'standard' | 'left' | 'center' | 'right'
export type StyleFontWeight = 'bold' | 'normal'

export type StyleFontSize = 'x4large' | 'x3large' | 'x2large' | 'xlarge' | 'large' | 'standard' | 'small' | 'xsmall'

export type StyleFontFamily = 'primary' | 'secondary'

export type StyleTextColor = 'accent' | 'error' | 'help' | 'holiday' | 'promo' | 'standard' | 'info' | 'subtle' | 'success' | 'warning'

export type StyleFontSkin =
  | 'title-display'
  | 'title-headline'
  | 'title-section'
  | 'title-subsection'
  | 'title-item'
  | 'editorial-headline'
  | 'editorial-content'
  | 'body-standard'
  | 'body-standard-bold'
  | 'body-small'
  | 'body-small-bold'
  | 'footnote'
  | 'footnote-bold'

export type StyleDisplay =
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'table'
  | 'inline-table'
  | 'table-caption'
  | 'table-cell'
  | 'table-column'
  | 'table-column-group'
  | 'table-footer-group'
  | 'table-header-group'
  | 'table-row-group'
  | 'table-row'
  | 'flow-root'
  | 'grid'
  | 'inline-grid'
  | 'contents'
  | 'list-item'
  | 'none'

export type StyleOverflow = 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto' | 'inherit' | 'initial' | 'revert' | 'unset'

export type StyleBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type SwanStyleProps = {
  // Spacing
  margin?: Responsive<StyleSpaceWithAuto>
  m?: Responsive<StyleSpaceWithAuto>
  marginTop?: Responsive<StyleSpaceWithDensityExcludingIconText>
  mt?: Responsive<StyleSpaceWithDensityExcludingIconText>
  marginRight?: Responsive<StyleSpaceWithAuto | Exclude<StyleSpaceDensity, StyleSpaceDensitySectionLevel>>
  mr?: Responsive<StyleSpaceWithAuto | Exclude<StyleSpaceDensity, StyleSpaceDensitySectionLevel>>
  marginBottom?: Responsive<StyleSpaceWithDensityExcludingIconText>
  mb?: Responsive<StyleSpaceWithDensityExcludingIconText>
  marginLeft?: Responsive<StyleSpaceWithAuto | Exclude<StyleSpaceDensity, StyleSpaceDensitySectionLevel>>
  ml?: Responsive<StyleSpaceWithAuto | Exclude<StyleSpaceDensity, StyleSpaceDensitySectionLevel>>
  marginX?: Responsive<StyleSpaceWithAuto | Exclude<StyleSpaceDensity, StyleSpaceDensitySectionLevel | StyleSpacingBetweenIconAndText>>
  mx?: Responsive<StyleSpaceWithAuto | Exclude<StyleSpaceDensity, StyleSpaceDensitySectionLevel | StyleSpacingBetweenIconAndText>>
  marginY?: Responsive<StyleSpaceWithDensityExcludingIconText>
  my?: Responsive<StyleSpaceWithDensityExcludingIconText>
  padding?: Responsive<StyleSpace>
  p?: Responsive<StyleSpace>
  paddingTop?: Responsive<StyleSpace>
  pt?: Responsive<StyleSpace>
  paddingRight?: Responsive<StyleSpace>
  pr?: Responsive<StyleSpace>
  paddingBottom?: Responsive<StyleSpace>
  pb?: Responsive<StyleSpace>
  paddingLeft?: Responsive<StyleSpace>
  pl?: Responsive<StyleSpace>
  paddingX?: Responsive<StyleSpace>
  px?: Responsive<StyleSpace>
  paddingY?: Responsive<StyleSpace>
  py?: Responsive<StyleSpace>
  // Background
  backgroundColor?: Responsive<StyleBackgroundColor>
  bgc?: Responsive<StyleBackgroundColor>

  // Typography
  textAlign?: Responsive<StyleTextAlign>
  textAllCaps?: Responsive<boolean>
  textColor?: Responsive<StyleTextColor>
  fontWeight?: Responsive<StyleFontWeight>
  fontSize?: StyleFontSize
  fontFamily?: StyleFontFamily
  fontSkin?: StyleFontSkin
  // Misc
  display?: Responsive<StyleDisplay>
  // Modes
  standardMode?: boolean
  darkMode?: boolean
  compactMode?: boolean
  overflow?: Responsive<StyleOverflow>
  visuallyHidden?: Responsive<boolean>
  loadingShimmer?: Responsive<boolean>
}

export type SwanCoreStyleProps = {
  swanStyle?: SwanStyleProps & Partial<Record<StyleBreakpoints, SwanStyleProps>>
  className?: string
} & SwanStyleProps

export type StylePropsKeys = keyof SwanStyleProps
export type StylePropsValues = SwanStyleProps[StylePropsKeys]
