import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedComponent, RenderComp, renderWithRef } from '~/react/components/core'

export type ThumbnailsHeroContentContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>
const ThumbnailsHeroContentContainerInternal = renderWithRef<MinNativeRef, ThumbnailsHeroContentContainerProps>(
  'ThumbnailsHeroContentContainer',
  null,
  ({ children, ...props }, ref) => {
    return (
      <RenderComp root="div" forwardedRef={ref} classNames={['swan-thumbnails-hero-content-container']} props={props}>
        {children}
      </RenderComp>
    )
  },
)

/**
 * @deprecated
 * Remove any content and use only an image
 *
 * @subcomponent ThumbnailsHero
 */
export const ThumbnailsHeroContentContainer = deprecatedComponent({
  description: 'Remove any content and use only an image',
  displayName: 'ThumbnailsHeroContentContainer',
  root: ThumbnailsHeroContentContainerInternal,
})
