import { AriaButtonProps } from '@react-aria/button'
import { AriaPopoverProps, OverlayTriggerAria } from '@react-aria/overlays'
import { OverlayTriggerState } from '@react-stately/overlays'
import { createContext, ReactNode, RefObject, useContext } from 'react'

export type PopoverPlacement = AriaPopoverProps['placement']

export type Modes = {
  hasDarkMode: boolean
  hasCompactMode: boolean
}

export type SwanPopoverContextValue = {
  overlayState: OverlayTriggerState
  popoverRef: RefObject<HTMLDivElement>
  triggerRef: RefObject<HTMLElement>
  overlayProps?: OverlayTriggerAria['overlayProps']
  triggerProps?: AriaButtonProps<'button'>
  fullWidth?: boolean | null
  // this is added for backwards compatibility with the legacy Popover API, which sets placement on the top level component
  // recommended usage is to place it on the PopoverContent component
  popoverPlacement?: PopoverPlacement
  modes: Modes
}

export type SwanPopoverContextProps = {
  children: ReactNode
  value: SwanPopoverContextValue
}

export const SwanPopoverContext = createContext<SwanPopoverContextValue>({} as SwanPopoverContextValue)

export const SwanPopoverContextProvider = (props: SwanPopoverContextProps) => {
  const { children, value } = props

  return <SwanPopoverContext.Provider value={value}>{children}</SwanPopoverContext.Provider>
}

export const useSwanPopover = () => useContext(SwanPopoverContext)
