import PropTypes, { InferProps } from 'prop-types'

import { className } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { AspectRatio } from '~/react/components/aspect-ratio'
import { CoreProps, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The height / width ratio expressed as the result of dividing the content's height by its width
   * Examples: 16:9 => 9 / 16 => 0.5625, 1:1 => 1 / 1 => 1.0
   */
  aspectRatio: PropTypes.number.isRequired,
}

export type ResponsiveImageContainerProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent ResponsiveImage
 */
export const ResponsiveImageContainer = renderWithRef<MinNativeRef, ResponsiveImageContainerProps>(
  'ResponsiveImageContainer',
  propTypes,
  ({ aspectRatio, className: propClassName, ...props }, ref) => {
    return (
      <AspectRatio {...props} className={className(propClassName, 'swan-responsive-image-wrapper')} ratio={aspectRatio} ref={ref}>
        {props.children}
      </AspectRatio>
    )
  },
)
