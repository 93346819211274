import { OneOrMany } from '~/core/types/utility.types'

import { setAddMany, setToString } from './set.utils'

export type ClassNameArg = OneOrMany<null | undefined | string | false | Record<string, boolean | null | undefined>>

/**
 * Conditionally joins CSS class names together.
 */
export function classNames(outputClassNames: Set<string>, ...args: ClassNameArg[]): Set<string> {
  args.forEach(arg => {
    if (arg && typeof arg === 'object') {
      if (Array.isArray(arg)) {
        setAddMany(outputClassNames, classNames(outputClassNames, ...arg))
      } else {
        Object.keys(arg).forEach(key => {
          if (arg[key]) {
            outputClassNames.add(key)
          }
        })
      }
    } else if (arg) {
      outputClassNames.add(arg)
    }
  })
  return outputClassNames
}

export function className(...args: ClassNameArg[]): string {
  const outputClassNames = new Set<string>()
  return setToString(classNames(outputClassNames, ...args), ' ')
}
