import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Width of the SkeletonTextLine
   * Available options: "standard", "narrow"
   * @default standard
   */
  width: PropTypes.oneOf(['standard', 'narrow'] as const),
}

export type SkeletonTextLineProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent SkeletonText
 */
export const SkeletonTextLine = renderWithRef<MinNativeRef, SkeletonTextLineProps>('SkeletonTextLine', null, ({ children, width = 'standard', ...props }, ref) => {
  const classNames = new Set(['swan-skeleton-text-line swan-loading-shimmer'])
  if (width === 'narrow') classNames.add(`swan-skeleton-text-line-narrow`)

  return (
    <RenderComp root="div" classNames={classNames} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
