import { AriaButtonProps } from '@react-aria/button'
import { useComboBox } from '@react-aria/combobox'
import { useFilter } from '@react-aria/i18n'
import { ComboBoxStateOptions, useComboBoxState } from '@react-stately/combobox'
import { createContext, DOMAttributes, InputHTMLAttributes, Key, ReactNode, RefObject, useCallback, useMemo, useRef } from 'react'

import { SwanListboxListContext, useListboxList } from '~/react/components/listbox/listbox-list.context'
import { SwanPopoverContextProvider, SwanPopoverContextValue } from '~/react/components/popover'

import { useCollectionContext } from '~/react/contexts/internal/collection'
import { useNonNullishContext } from '~/react/hooks'
import { useModeExtractor } from '~/react/hooks/use-mode-extractor'

export type SwanComboboxContextValue = {
  buttonProps: AriaButtonProps<'button'>
  inputProps: InputHTMLAttributes<HTMLInputElement>
  labelProps: DOMAttributes<HTMLSpanElement>
  inputRef: RefObject<HTMLInputElement>
  triggerRef: RefObject<HTMLElement> // optional, overrides the popover's trigger element
  buttonRef: RefObject<HTMLButtonElement>
  setInputValue: (value: string) => void
  menuTrigger: 'input' | 'focus'
}

export const SwanComboboxContext = createContext<SwanComboboxContextValue | null | undefined>(undefined)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SwanComboboxContextProviderProps<T = any> = {
  children: ReactNode
  /**
   * Optional collection of dynamic options. Use with a render function as a child of ListboxList
   */
  items?: Iterable<T>
  /**
   * Callback trigger on selection change
   */
  onSelectionChange?: (key: string | number) => void
  /**
   * Specify the selected key
   */
  selectedKey?: string | number
  /**
   * Specify the array of keys that should be disabled
   */
  disabledKeys?: Set<string | number>
  /**
   * Specify the input value
   */
  inputValue?: ComboBoxStateOptions<T>['inputValue']
  /**
   * Default input value
   */
  defaultInputValue?: ComboBoxStateOptions<T>['defaultInputValue']
  /**
   * Callback function that triggers on input change
   */
  onInputChange?: ComboBoxStateOptions<object>['onInputChange']
  /**
   * Dictates the trigger for the opening behaviour of the popover
   */
  menuTrigger?: SwanComboboxContextValue['menuTrigger']

  /**
   * Label for accessibility
   */
  'aria-label'?: string
  /**
   * Label for accessibility
   */
  'aria-labelledby'?: string

  /**
   * Whether the auto filtering should be enable or disabled
   *
   * @default false
   */
  disableAutoFilter?: boolean
  /**
   * Allows a custom input value that doesn't match an option in the list
   * This also prevents the input text from clearing when the input loses focus
   */
  allowsCustomValue?: ComboBoxStateOptions<object>['allowsCustomValue']
  /**
   * Allows the popover to open even there are no items present.
   * Useful when fetching data asynchronously or to display a loading or empty state
   *
   * @default false
   */
  allowEmptyCollection?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SwanComboboxContextProvider(props: SwanComboboxContextProviderProps<any>) {
  const {
    children,
    onSelectionChange,
    selectedKey,
    disableAutoFilter = false,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    inputValue,
    defaultInputValue,
    onInputChange,
    menuTrigger = 'input',
    allowsCustomValue,
    allowEmptyCollection = false,
  } = props

  const { childItems, items, disabledKeys } = useCollectionContext()

  // react-aria introduced a typescript change in 3.9.1, so wrap our existing callback to match the new call signature
  const onChange = useCallback(
    (key: Key | null) => {
      const val = typeof key === 'number' ? key : (key?.toString() ?? '')
      onSelectionChange?.(val ?? '')
    },
    [onSelectionChange],
  )

  // Setup filter function and state.
  const { contains } = useFilter({ sensitivity: 'base' })
  const filterFunc = disableAutoFilter ? undefined : contains
  const state = useComboBoxState({
    children: childItems,
    items,
    defaultFilter: filterFunc,
    onSelectionChange: onChange,
    selectedKey,
    inputValue,
    defaultInputValue,
    onInputChange,
    allowsCustomValue,
    allowsEmptyCollection: allowEmptyCollection,
    menuTrigger,
  })

  // Setup refs and get props for child elements.
  const buttonRef = useRef(null)
  const inputRef = useRef(null)
  const triggerRef = useRef(null)
  const listBoxRef = useRef(null)
  const popoverRef = useRef(null)

  const { buttonProps, inputProps, listBoxProps, labelProps } = useComboBox(
    {
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      menuTrigger,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
      disabledKeys,
    },
    state,
  )

  const modes = useModeExtractor(inputRef, state.isOpen)

  const popoverContextValue = useMemo<SwanPopoverContextValue>(
    () => ({
      popoverRef,
      triggerRef: triggerRef?.current ? triggerRef : inputRef, // for the search input, the wrapper container (not the input) should be the trigger element to position correctly
      overlayState: state,
      fullWidth: true, // the popover will match the input's width
      modes,
    }),
    [inputRef, popoverRef, triggerRef, state, modes],
  )

  const contextValue = useMemo<SwanComboboxContextValue>(
    () => ({
      buttonProps,
      inputProps,
      labelProps,
      buttonRef,
      inputRef,
      triggerRef,
      setInputValue: state.setInputValue,
      menuTrigger,
    }),
    [buttonProps, inputProps, labelProps, state, menuTrigger],
  )

  const listContext = useListboxList(listBoxProps, listBoxRef, state)

  return (
    <SwanPopoverContextProvider value={popoverContextValue}>
      <SwanComboboxContext.Provider value={contextValue}>
        <SwanListboxListContext.Provider value={listContext}>{children}</SwanListboxListContext.Provider>
      </SwanComboboxContext.Provider>
    </SwanPopoverContextProvider>
  )
}

export function useSwanComboboxContext() {
  return useNonNullishContext(SwanComboboxContext)
}
