import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BannerWithLeftHandNavigationBelowBannerContentsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Banner
 */
export const BannerWithLeftHandNavigationBelowBannerContents = renderWithRef<MinNativeRef, BannerWithLeftHandNavigationBelowBannerContentsProps>(
  'BannerWithLeftHandNavigationBelowBannerContents',
  {},
  (props, ref) => {
    const classNames = ['swan-banner-with-left-hand-navigation-below-banner']
    const { children } = props
    return (
      <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props}>
        {children}
      </RenderComp>
    )
  },
)
