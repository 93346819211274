import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * Whether or not to hide the child content on xs screens
   * @default false
   */
  xs: PropTypes.bool,
  /**
   * Whether or not to hide the child content on sm screens
   * @default false
   */
  sm: PropTypes.bool,
  /**
   * Whether or not to hide the child content on md screens
   * @default false
   */
  md: PropTypes.bool,
  /**
   * Whether or not to hide the child content on lg screens
   * @default false
   */
  lg: PropTypes.bool,
  /**
   * Whether or not to hide the child content on xl screens
   * @default false
   */
  xl: PropTypes.bool,
}

const propKeysToRemove = Object.keys(propTypes)

export type HiddenProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const Hidden = renderWithRef<MinNativeRef, HiddenProps>('Hidden', propTypes, (props, ref) => {
  useComponentStylesLoaded('Hidden', SWAN_STYLE_KEY_MAP.hidden)

  const { children, xs = false, sm = false, md = false, lg = false, xl = false } = props

  const classNames = new Set<string>()
  if (xs) classNames.add('swan-hidden-xs')
  if (sm) classNames.add('swan-hidden-sm')
  if (md) classNames.add('swan-hidden-md')
  if (lg) classNames.add('swan-hidden-lg')
  if (xl) classNames.add('swan-hidden-xl')
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
      {children}
    </RenderComp>
  )
})
