import { InferProps } from 'prop-types'
import { MouseEvent, useEffect } from 'react'

import { assignRefs } from '~/core/utilities'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { useSwanPopover } from '~/react/components/popover'
import { SearchInput, searchInputPropTypes } from '~/react/components/search-input/search-input.component'

import { ComboboxInput, ComboboxInputProps } from './combobox-input.component'
import { useSwanComboboxContext } from './combobox.context'

export type ComboboxSearchInputProps = ComboboxInputProps & InferProps<typeof searchInputPropTypes>

/**
 * @subcomponent Combobox
 */
export const ComboboxSearchInput = renderWithRef<HTMLInputElement, ComboboxSearchInputProps>('ComboboxSearchInput', searchInputPropTypes, (props, ref) => {
  const { inputRef, triggerRef, setInputValue } = useSwanComboboxContext()
  const { overlayState } = useSwanPopover()

  const combinedRef = assignRefs(ref, inputRef)

  const getSearchInputProps = (p: JSX.IntrinsicElements['input']) => {
    return {
      ...p,
      onClear: (e: MouseEvent<HTMLButtonElement>) => {
        props.onClear?.(e)
        setInputValue('')
      },
    }
  }

  /**
   * Workaround for issue where react-aria is stealing the enter keypress event and stopping the form from submitting when allowsCustomValue is set
   * https://github.com/adobe/react-spectrum/issues/6228
   */
  useEffect(() => {
    const inputEl = inputRef.current

    const cb = (e: KeyboardEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- react-aria types are not correct
      const focusedKey = (overlayState as any).selectionManager.focusedKey

      // if the enter key is pressed, and no listbox key is focused, submit the form by clicking the submit button in the search input
      if (e.key === 'Enter' && !focusedKey) {
        const button = triggerRef.current?.querySelector('button[type="submit"]') as HTMLButtonElement | null
        button?.click()
      }
    }

    inputEl?.addEventListener('keydown', cb)
    return () => inputEl?.removeEventListener('keydown', cb)
  }, [inputRef, triggerRef, overlayState])

  return (
    <RenderComp root="div" forwardedRef={triggerRef} classNames={['swan-combobox-search-input-container']} props={{}}>
      <ComboboxInput {...props} ref={combinedRef} render={(p, r) => <RenderComp root={SearchInput} forwardedRef={r} props={getSearchInputProps(p)} />} />
    </RenderComp>
  )
})
