import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type MainContentStartProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

export const MainContentStart = renderWithRef<MinNativeRef, MainContentStartProps>('MainContentStart', null, ({ children, ...props }, ref) => (
  <RenderComp root="span" forwardedRef={ref} props={{ id: 'maincontentstart', ...props }}>
    {children}
  </RenderComp>
))
