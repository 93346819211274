import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, deprecatedPropValues, RenderComp, renderWithRef } from '~/react/components/core'
import { useFormFieldProps } from '~/react/components/form'

import { useFloatingLabelFormElement } from '~/react/contexts/internal/floating-label'
import { useId } from '~/react/hooks'

const propTypes = {
  /**
   * The error skin will show a red border around the element.
   * skin 'line' is deprecated. Use 'standard' instead
   * @default standard
   */
  skin: deprecatedPropValues(PropTypes.oneOf(['standard', 'error', 'line'] as const), ['line'], 'Skin `line` is deprecated, use `standard` instead'),
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * Mini will make the dropdown smaller
   * @default standard
   */
  size: deprecatedProp(PropTypes.oneOf(['standard', 'mini'] as const), 'Sizing is now handled automatically by standardMode/compactMode'),
  /**
   * whether or not the component should horizontally to fill its container's availableWidth
   *
   * @default false
   */
  fullWidth: PropTypes.bool,
}

export type DropdownProps = CoreProps<
  Omit<JSX.IntrinsicElements['select'], 'size'>,
  MinNativeRef,
  InferProps<typeof propTypes> & {
    /**
     * Specifies the number of visible options in a drop-down list.
     */
    htmlSize?: JSX.IntrinsicElements['select']['size']
  }
>

export const Dropdown = renderWithRef<MinNativeRef, DropdownProps>('Dropdown', null, ({ children, skin = 'standard', size = 'standard', fullWidth = false, ...props }, ref) => {
  const id = useId(props.id)
  const floatingProps = useFloatingLabelFormElement({ ...props, id })
  const formProps = useFormFieldProps(floatingProps.id)

  const classNameSet = new Set<string>(['swan-dropdown'])

  if (size === 'mini') classNameSet.add(`swan-dropdown-mini`)
  if (skin === 'error' || formProps['aria-invalid']) classNameSet.add(`swan-dropdown-skin-error`)
  if (skin === 'line') classNameSet.add(`swan-dropdown-skin-line`)
  if (fullWidth) classNameSet.add(`swan-dropdown-full-width`)

  return (
    <RenderComp root="select" classNames={classNameSet} forwardedRef={ref} props={{ ...floatingProps, ...formProps }}>
      {children}
    </RenderComp>
  )
})
