import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { composeEventHandlersUtil } from '~/react/utilities'

import { useCollapsibleContext } from './collapsible.context'

export type CollapsibleSummaryButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef>

/**
 * @subcomponent Collapsible
 */
export const CollapsibleSummaryButton = renderWithRef<MinNativeRef, CollapsibleSummaryButtonProps>('CollapsibleSummaryButton', null, (props, ref) => {
  const { expanded, onRequestExpandedChange, contentId, disabled } = useCollapsibleContext()

  const newProps = {
    ...props,
    'aria-expanded': expanded,
    'aria-controls': contentId ?? undefined,
  }
  if (!disabled) {
    newProps.onClick = composeEventHandlersUtil(props.onClick, () => onRequestExpandedChange(!expanded))
  }

  return (
    <RenderComp root="button" forwardedRef={ref} classNames={['swan-collapsible-summary-button']} props={newProps}>
      {props.children}
    </RenderComp>
  )
})
