import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BannerSecondaryImageContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Banner
 */
export const BannerSecondaryImageContainer = renderWithRef<MinNativeRef, BannerSecondaryImageContainerProps>('BannerSecondaryImageContainer', {}, (props, ref) => {
  const classNames = ['swan-banner-image-container', 'swan-banner-secondary-image-container']
  const { children } = props
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
