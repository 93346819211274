import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type LeftHandNavigationHeaderProps = CoreProps<JSX.IntrinsicElements['h2'], MinNativeRef>

/**
 * @subcomponent LeftHandNavigation
 */
export const LeftHandNavigationHeader = renderWithRef<MinNativeRef, LeftHandNavigationHeaderProps>('LeftHandNavigationHeader', null, (props, ref) => (
  <RenderComp root="h2" forwardedRef={ref} classNames={['swan-lhn-header']} props={props}>
    {props.children}
  </RenderComp>
))
