import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type SelectionSetTileDescriptionProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent SelectionSet
 */
export const SelectionSetTileDescription = renderWithRef<MinNativeRef, SelectionSetTileDescriptionProps>('SelectionSetTileDescription', null, (props, ref) => (
  <RenderComp root="p" forwardedRef={ref} classNames={['swan-selection-set-tile-description']} props={props}>
    {props.children}
  </RenderComp>
))
