import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type FormInputGroupProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardForm
 */
export const FormInputGroup = renderWithRef<MinNativeRef, FormInputGroupProps>('FormInputGroup', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" forwardedRef={ref} props={props} classNames={['swan-input-group']}>
    {children}
  </RenderComp>
))
