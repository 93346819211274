import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type H5Props = CoreProps<JSX.IntrinsicElements['h5'], MinNativeRef>

export const H5 = renderWithRef<MinNativeRef, H5Props>('H5', null, (props, ref) => {
  const { children } = props
  const classNames = ['swan-heading']

  return (
    <RenderComp root="h5" forwardedRef={ref} classNames={classNames} props={{ fontSize: 'standard', fontWeight: 'bold', ...props }}>
      {children}
    </RenderComp>
  )
})
