import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BannerSubtitleProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent Banner
 */
export const BannerSubtitle = renderWithRef<MinNativeRef, BannerSubtitleProps>('BannerSubtitle', {}, (props, ref) => {
  const classNames = ['swan-banner-subtitle']
  const { children } = props
  return (
    <RenderComp root="p" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
