export enum SwanPathTypeEnum {
  hashed = 'hashed',
  versioned = 'versioned',
}

export enum SwanAssetFolders {
  FONTS = 'FONTS',
  ICONS = 'ICONS',
  ILLUSTRATIONS = 'ILLUSTRATIONS',
  IMAGES = 'IMAGES',
  JS = 'JS',
  STYLES = 'STYLES',
  FAVICONS = 'FAVICONS',
  POLYFILLS = 'POLYFILLS',
}
