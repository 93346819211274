import { Item } from '@react-stately/collections'
import { ItemProps } from '@react-types/shared'
import { ComponentPropsWithoutRef } from 'react'

import { ListboxOptionCustomProps } from './listbox-option-internal.component'

// textValue is explicitly defined in ListboxOptionCustomProps for documentation purposes, but the type is the same as Item
export type ListboxOptionProps = Omit<ComponentPropsWithoutRef<typeof Item>, 'textValue'> & Partial<Omit<ListboxOptionCustomProps, 'children'>>

/**
 * @subcomponent Listbox
 */
export const ListboxOption = (props: ListboxOptionProps) => {
  // this renders nothing, it does not get called, do not try and put anything in here
  return <Item {...(props as ItemProps<unknown>)} />
}

// the Item component has some functions on it for the react-aria collection behavior.
// This will copy the props over
Object.assign(ListboxOption, Item)

ListboxOption.displayName = 'ListboxOption'
