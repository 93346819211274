import PropTypes, { InferProps } from 'prop-types'

import { renderWithRef } from '~/react/components/core/render-with-ref'

import { ListboxButton } from './listbox-button.component'
import { ListboxList } from './listbox-list.component'
import { ListboxPopover } from './listbox-popover.component'
import { Listbox, ListboxProps } from './listbox.component'

const propTypes = {
  /**
   * Set to "true" if the Basic Listbox is rendering inside a Modal.
   * @default false
   */
  inModal: PropTypes.bool,
}

export type BasicListboxProps = ListboxProps & InferProps<typeof propTypes>

/**
 * @subcomponent Listbox
 */
export const BasicListbox = renderWithRef<HTMLDivElement, BasicListboxProps>('BasicListbox', propTypes, (props, ref) => {
  const { children, inModal = false, darkMode, bgc, backgroundColor, 'aria-label': ariaLabel, ...otherProps } = props
  const bgColor = bgc || backgroundColor
  return (
    <Listbox darkMode={darkMode} bgc={bgColor} {...otherProps} ref={ref}>
      <ListboxButton aria-label={ariaLabel} />
      <ListboxPopover darkMode={darkMode} bgc={bgColor} inModal={inModal}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <ListboxList>{children as unknown as any}</ListboxList>
      </ListboxPopover>
    </Listbox>
  )
})
