import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The height / width ratio expressed as the result of dividing the content's height by its width.
   * e.g. 1) If your content is 16px wide and 9px tall, your ratio is 9 / 16 = 0.5625.
   * e.g. 2) If your content is a square, your ratio is 1.
   */
  ratio: PropTypes.number.isRequired,
}

export type AspectRatioContainerProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent AspectRatio
 */
export const AspectRatioContainer = renderWithRef<MinNativeRef, AspectRatioContainerProps>('AspectRatioContainer', propTypes, ({ children, ratio, style, ...rest }, ref) => {
  return (
    <RenderComp
      root="span"
      forwardedRef={ref}
      classNames={['swan-aspect-ratio-container']}
      props={{
        ...rest,
        style: {
          ...style,
          paddingBottom: ratio ? `${ratio * 100}%` : undefined,
        },
      }}
    >
      {children}
    </RenderComp>
  )
})
