import { styleKeyVarMap } from '~/core/utilities/style-keys.utils'

/**
 * Halts execution until SWAN styles have finished loading.
 *
 * Useful for ensuring any SWAN components have their required styles before executing a test that relies on that UI.
 *
 * @param timeout - how long to wait before rejecting the promise
 */
export async function waitForStyles(timeout = 5000): Promise<void> {
  const startTime = Date.now()

  return new Promise<void>((resolve, reject) => {
    const checkStyles = () => {
      const style = getComputedStyle(document.documentElement)
      const stylesLoaded = Object.values(styleKeyVarMap).every(key => {
        // Some keys don't have a corresponding CSS variables
        if (!key) return true
        return style.getPropertyValue(key) === '1'
      })

      if (stylesLoaded) {
        resolve()
      } else if (Date.now() - startTime >= timeout) {
        const loadedStyles = Object.entries(styleKeyVarMap).reduce((acc, [key, val]) => {
          // Some keys don't have a corresponding CSS variables
          if (!val) {
            return {
              ...acc,
              [key]: true,
            }
          }
          return {
            ...acc,
            [key]: style.getPropertyValue(key) === '1',
          }
        }, {})

        // eslint-disable-next-line no-console
        console.log('Loaded styles:', JSON.stringify(loadedStyles, null, 2))

        reject(new Error('Timeout exceeded while waiting for styles to load'))
      } else {
        setTimeout(checkStyles, 100) // Poll every 100ms
      }
    }

    checkStyles()
  })
}
