import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type CollapsibleSummaryTextClosedProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent Collapsible
 */
export const CollapsibleSummaryTextClosed = renderWithRef<MinNativeRef, CollapsibleSummaryTextClosedProps>('CollapsibleSummaryTextClosed', null, (props, ref) => {
  return (
    <RenderComp root="span" forwardedRef={ref} classNames={['swan-collapsible-summary-text-closed']} props={props}>
      {props.children}
    </RenderComp>
  )
})
