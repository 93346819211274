import { RefObject, useEffect, useState } from 'react'

import { tokensRaw } from '~/core/index'
import { prefersReducedMotion } from '~/core/utilities/'

const getSectionElements = (elementIds: string[]) => {
  const sections = elementIds.map(id => document.getElementById(id)).filter(el => !!el) as HTMLElement[]

  // sort by vertical position to ensure the sections are in order
  return sections.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top)
}

/**
 * If the target element is an anchor element and has an href that can be found on the page,
 *  stop the navigation from happening and scroll to the element instead
 */
const getClickHandler = (header: HTMLElement) => (e: MouseEvent) => {
  const anchor = (e.target as HTMLElement).closest('a')

  if (!anchor) return

  const anchorTarget = anchor.getAttribute('href') ?? ''
  const targetElement = document.querySelector(anchorTarget)
  const targetId = targetElement?.getAttribute('id')

  if (targetId) {
    e.preventDefault()

    const target = document.getElementById(targetId) as HTMLElement
    const { top } = target.getBoundingClientRect()
    const { height } = header.getBoundingClientRect()

    // margin so the element isn't at the very top of the viewport
    const scrollMargin = parseInt(tokensRaw.SwanBaseSpace200) || 0

    window.scrollTo({
      top: window.scrollY + top - height - scrollMargin,
      // typescript is yelling, but instant is a valid value
      behavior: (prefersReducedMotion() ? 'instant' : 'smooth') as ScrollBehavior,
    })

    if (!target.getAttribute('tabindex')) {
      target.setAttribute('tabindex', '-1')
    }

    // Safari 14 does not support preventScroll, so the page will scroll instantly
    target.focus({ preventScroll: true })
  }
}

/**
 * Creates an intersection observer to determine the current section
 *
 * https://www.smashingmagazine.com/2021/07/dynamic-header-intersection-observer/
 */
function getScrollObserver(setActiveAnchor: (id: string) => void, header: HTMLElement, sections: HTMLElement[]) {
  if (!('IntersectionObserver' in window)) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }

  let prevYPosition = 0
  let direction = 'up'

  const options = {
    rootMargin: `${-2 * header.getBoundingClientRect().height}px 0px`,
    threshold: 0,
  }

  const getTargetSection = (entry: IntersectionObserverEntry) => {
    const index = sections.findIndex(section => section == entry.target)

    if (index >= sections.length - 1) {
      return entry.target
    } else {
      return sections[index + 1]
    }
  }

  const shouldUpdate = (entry: IntersectionObserverEntry) => {
    if ((direction === 'down' && !entry.isIntersecting) || (direction === 'up' && entry.isIntersecting)) {
      return true
    }

    return false
  }

  const onIntersect: IntersectionObserverCallback = entries => {
    entries.forEach(entry => {
      direction = window.scrollY > prevYPosition ? 'down' : 'up'
      prevYPosition = window.scrollY

      const target = direction === 'down' ? getTargetSection(entry) : entry.target
      const id = target.getAttribute('id') ?? ''

      if (shouldUpdate(entry)) {
        // on mobile the anchor may not be in view
        // this is breaking the vertical scroll behavior so disable for now
        // header.querySelector(`a[href="#${id}"]`)?.scrollIntoView({ block: 'nearest', inline: 'center' })
        setActiveAnchor(id)
      }
    })
  }

  const observer = new IntersectionObserver(onIntersect, options)

  sections.forEach(section => {
    observer.observe(section)
  })

  return () => observer.disconnect()
}

/**
 * Determines if the bar is sticky to the top of the page
 *
 * When the bar is pinned, it has top: -1, and thus is 1px off the screen.
 * The IntersectionObserver fires when it's no longer completely visible in the viewport (aka sticky, -1px top)
 */
export function useAnchorBarIsPinned(ref: RefObject<HTMLElement>, sticky: boolean) {
  const [isPinned, setIsPinned] = useState(false)

  useEffect(() => {
    if (!sticky || !('IntersectionObserver' in window)) return

    const observer = new IntersectionObserver(
      e => {
        e.forEach(entry => setIsPinned(entry.intersectionRatio < 1))
      },
      { threshold: 1 },
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [ref, sticky])

  return [isPinned]
}

type NavScrollOptions = {
  /**
   * Callback for the scroll logic to call when a new section scrolls into view
   */
  setActiveAnchor: (anchor: string) => void
  /**
   * Whether or not the AnchorBar has sticky behavior
   * If false, it won't create track content section's scroll
   */
  sticky?: boolean

  /**
   * Whether or not the anchor bar is currently pinned to the top of the page
   */
  pinned: boolean
}

function useScrollOnClick(ref: RefObject<HTMLElement>) {
  useEffect(() => {
    const current = ref.current
    if (!current) return

    const handler = getClickHandler(current)
    current?.addEventListener('click', handler)
    return () => current?.removeEventListener('click', handler)
  }, [ref])
}

/**
 * creates an intersection observer to track the currently visible section and fire the activeAnchorChanged event
 */
function useDestinationScrollObserver(ref: RefObject<HTMLElement>, options: NavScrollOptions) {
  const { sticky, setActiveAnchor } = options

  useEffect(() => {
    const current = ref.current
    if (!current || !sticky) return

    const headerLinks = [...current.querySelectorAll('.swan-anchor-bar-list-item a[href]')]
    const sections = getSectionElements(headerLinks.map(el => (el.getAttribute('href') ?? '').replace('#', '')))

    const disconnect = getScrollObserver(setActiveAnchor, current, sections)

    return () => {
      disconnect()
    }
    // intentionally removing setActiveAnchor because it can cause the observer to be recreated/fire too frequently if it's not a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sticky, ref])
}

/**
 * Creates an intersection observer to determine if the first or last items are outside of the visible bounding area
 *   and applies a gradient overlay to denote there are more items available ons croll
 */
function useHorizontalItemVisibilityObserver(ref: RefObject<HTMLElement>, options: NavScrollOptions) {
  const { pinned } = options

  useEffect(() => {
    const current = ref.current
    const listElement = ref.current?.querySelector('.swan-anchor-bar-list')
    if (!current || !listElement) return

    const headerLinks = [...current.querySelectorAll('.swan-anchor-bar-list-item')]
    const firstListItem = headerLinks[0]
    const lastListItem = headerLinks[headerLinks.length - 1]

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          const className = entry.target === firstListItem ? 'swan-anchor-bar-scroll-left' : 'swan-anchor-bar-scroll-right'

          if (entry.intersectionRatio < 1) {
            if (!current.classList.contains(className)) {
              current.classList.add(className)
            }
          } else {
            current.classList.remove(className)
          }
        }),
      { threshold: 1, root: listElement },
    )

    observer.observe(firstListItem)
    observer.observe(lastListItem)

    return () => observer.disconnect()
  }, [ref, pinned]) // the observer needs to rerun when the component becomes sticky to reapply the scroll classes
}

export function useAnchorBarScroll(ref: RefObject<HTMLElement>, options: NavScrollOptions) {
  useScrollOnClick(ref)

  useDestinationScrollObserver(ref, options)

  useHorizontalItemVisibilityObserver(ref, options)
}
