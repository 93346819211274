import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { useFormFieldProps } from '~/react/components/form'

import { useId } from '~/react/hooks'

const propTypes = {
  /**
   * The visual variant.
   * Note that error is implied if the TextInput is used inside of a FormInput that also contains a FormError.
   * Available options: 'standard', 'error'
   *
   * @default standard
   */
  skin: PropTypes.oneOf(['standard', 'error'] as const),
  /**
   * Which dimensions of the TextArea should be resizable.
   * Available options: 'vertical', 'both'
   *
   * @default both
   */
  resize: PropTypes.oneOf(['both', 'vertical'] as const),
  /**
   * Whether or not the TextArea should expand to fill the full width of its container
   *
   * @default false
   */
  fullWidth: PropTypes.bool,
}

export type TextAreaProps = CoreProps<JSX.IntrinsicElements['textarea'], MinNativeRef, InferProps<typeof propTypes>>

export const TextArea = renderWithRef<MinNativeRef, TextAreaProps>('TextArea', null, (props, ref) => {
  const formProps = useFormFieldProps(useId(props.id))
  const processedProps = {
    ...props,
    ...formProps,
  }

  const classNames = new Set<string>(['swan-textarea'])
  const { children, skin = 'standard', resize = 'both', 'aria-invalid': ariaInvalid, fullWidth = false, ...rest } = processedProps

  if (skin === 'error' || ariaInvalid) classNames.add(`swan-textarea-skin-error`)
  if (fullWidth) classNames.add(`swan-textarea-full-width`)
  if (resize === 'vertical') classNames.add(`swan-textarea-resize-vertical`)

  return (
    <RenderComp root="textarea" classNames={classNames} forwardedRef={ref} props={rest}>
      {children}
    </RenderComp>
  )
})
