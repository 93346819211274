import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ToggleSwitchOffLabelProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent ToggleSwitch
 */
export const ToggleSwitchOffLabel = renderWithRef<MinNativeRef, ToggleSwitchOffLabelProps>('ToggleSwitchOffLabel', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="span" forwardedRef={ref} classNames={['swan-toggle-switch-text-off']} props={props}>
      {children}
    </RenderComp>
  )
})
