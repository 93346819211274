import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export const bannerImagePropTypes = {
  /**
   * Sets the focal point if the image gets clipped. (X-axis first, then Y-axis)
   */
  imageFocalPoint: PropTypes.oneOf([
    'left top',
    'left center',
    'left bottom',
    'center top',
    'center center',
    'center bottom',
    'right top',
    'right center',
    'right bottom',
  ] as const),
}
const keysToRemove = Object.keys(bannerImagePropTypes)

export type BannerImageProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof bannerImagePropTypes>>

/**
 * @subcomponent Banner
 */
export const BannerImage = renderWithRef<MinNativeRef, BannerImageProps>('BannerImage', bannerImagePropTypes, (props, ref) => {
  const classNames = new Set(['swan-banner-image'])
  if (props.imageFocalPoint) classNames.add(`swan-banner-image-focal-point-${props.imageFocalPoint.replace(/ +/g, '-')}`)
  const { children } = props
  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={keysToRemove} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
