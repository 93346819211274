import PropTypes, { InferProps } from 'prop-types'
import { ReactNode } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { Button } from '~/react/components/button'
import { deprecatedProp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'
import { VisuallyHidden } from '~/react/components/visually-hidden'

import { className } from '~/core'
import { composeEventHandlersUtil } from '~/react/utilities/compose-event-handlers.util'

import { useModalDialogContext } from './modal-dialog.context'

const propTypes = {
  /**
   * @deprecated
   * Use `accessibleText` instead
   *
   * Specify the node or element that should be used for screen readers.
   */
  visuallyHiddenLabel: deprecatedProp(PropTypes.node, 'Use `accessibleText` instead'),
  /**
   * A localized label which describes the button to screen-reader users e.g. "Close"
   */
  accessibleText: PropTypes.string,
}

export type ModalDialogCloseButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Dialog
 */
export const ModalDialogCloseButton = renderWithRef<MinNativeRef, ModalDialogCloseButtonProps>('ModalDialogCloseButton', propTypes, (props, ref) => {
  const { handleModalCloseButton } = useModalDialogContext()
  // discarding children
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { visuallyHiddenLabel, accessibleText, onClick, className: appliedClasses, children: _children, ...restProps } = props
  const accessibleNode = accessibleText ?? visuallyHiddenLabel
  const classNames = className('swan-modal-dialog-close-button', appliedClasses)

  return (
    <Button skin="tertiary" buttonShape="round" className={classNames} ref={ref} {...restProps} onClick={composeEventHandlersUtil(onClick, handleModalCloseButton)}>
      {accessibleNode !== null ? <VisuallyHidden>{accessibleNode as ReactNode}</VisuallyHidden> : null}
      <Icon iconType="close" size="standard" />
    </Button>
  )
})
