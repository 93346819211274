import { usePreventScroll } from '@react-aria/overlays'
import PropTypes, { InferProps, Validator } from 'prop-types'
import { HTMLAttributes, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

import { assignRefs, isFunction, processStyleProps } from '~/core/utilities'

import { CoreProps } from '~/react/components/core/core.types'

import { filterProps, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { getComponentConfigProvider } from '~/react/contexts/internal/component-config'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'
import { useTransition } from '~/react/hooks/use-transition'

import { ModalDialogContext } from './modal-dialog.context'
import { ModalDialogBodyWidths, ModalDialogVariantEnum } from './modal-dialog.types'

export const dialogPropTypes = {
  /**
   * Specify Modal type
   * @default standard
   */
  variant: PropTypes.oneOf([
    ModalDialogVariantEnum.Menu,
    ModalDialogVariantEnum.Standard,
    ModalDialogVariantEnum.PanelRight,
    ModalDialogVariantEnum.PanelTop,
    ModalDialogVariantEnum.PanelLeft,
    ModalDialogVariantEnum.PanelBottom,
  ] as const),
  /**
   * Callback function which will be invoked when the modal wants to be dismissed.
   */
  onRequestDismiss: PropTypes.func.isRequired as Validator<() => void>,
  /**
   * Decide whether to fill the full screen.
   *
   * @default false
   */
  takeOver: PropTypes.bool,
  /**
   * Width of the dialog body. Some dialog variants allow the body width to be limited (capped)
   *
   * @default standard
   */
  bodyWidth: PropTypes.oneOf(ModalDialogBodyWidths),
  /**
   * @internal
   * @ignore
   * This is not a part of the public API yet
   * Decide whether dialog is modal or modeless
   * @default false
   */
  isModal: PropTypes.bool,
  /**
   * Show/hide dialog
   *
   * @default false
   */
  isOpen: PropTypes.bool,

  /**
   * only renders the contents of the dialog if the modal is open
   * this is different than native dialog behavior, where the content is rendered but hidden
   * @default false
   */
  onlyRenderWhenOpen: PropTypes.bool,
}

export type DialogConfigType = {
  inModal: boolean
  portal: boolean
  portalRef: MutableRefObject<HTMLDivElement | null>
  dialogRef: MutableRefObject<HTMLDialogElement | null>
  onlyRenderWhenOpen: boolean
}

const [DialogConfigProvider, useInternalDialogConfigContext, DialogConfigContext] = getComponentConfigProvider<DialogConfigType>('Dialog')

export type DialogPropsType = InferProps<typeof dialogPropTypes>

const propKeysToRemove = Object.keys(dialogPropTypes)

type NativeProps = HTMLAttributes<HTMLDialogElement>

export type DialogProps = CoreProps<NativeProps, HTMLDialogElement, DialogPropsType, NativeProps>

export const Dialog = renderWithRef<HTMLDialogElement, DialogProps>('Dialog', dialogPropTypes, (props, ref) => {
  useComponentStylesLoaded('Dialog', SWAN_STYLE_KEY_MAP.modalDialog)

  const { bgc, backgroundColor, ...otherProps } = props
  const { variant = 'standard', onRequestDismiss, takeOver = false, bodyWidth = 'standard', isOpen = false, children, isModal = false, onlyRenderWhenOpen = false } = otherProps

  const [showDialog, setShowDialog] = useState<boolean>(!!isOpen)
  const [titleId, setTitleId] = useState<string | undefined | null>(undefined)
  const [footerPinned, setFooterPinned] = useState<boolean | undefined>(undefined)
  const [canTriggerDialog, setTriggerDialog] = useState<boolean>(typeof window !== 'undefined' && typeof window.HTMLDialogElement !== 'undefined') // wait to open dialog while registering the polyfill
  const transitionState = useTransition(!!isOpen)

  // prevent the page scroll
  usePreventScroll({ isDisabled: !showDialog })

  const dialogRef = useRef<HTMLDialogElement | null>(null)
  const portalRef = useRef<HTMLDivElement | null>(null)

  const combinedRef = assignRefs(ref, dialogRef)

  const closeModalDialog = useCallback(() => {
    // trigger the dialog close ONLY when the dialog is opened
    if (dialogRef.current?.open) {
      dialogRef.current?.close()
    }
  }, [])

  // trigger when modal get closed
  const handleOnDialogClose = useCallback(
    (event: Event) => {
      setShowDialog(false)
      if (isFunction(onRequestDismiss) && event.target === dialogRef.current) {
        onRequestDismiss()
      }
    },
    [onRequestDismiss],
  )

  useEffect(() => {
    if (!canTriggerDialog || !dialogRef.current) {
      return
    }
    if (isOpen) {
      setShowDialog(true)
      if (isModal) {
        dialogRef.current.showModal()
      } else {
        dialogRef.current.show()
      }
    } else {
      closeModalDialog()
    }
  }, [isOpen, isModal, canTriggerDialog, closeModalDialog])

  useEffect(() => {
    const dialogEle = dialogRef.current
    let intervalId: NodeJS.Timeout | undefined = undefined

    if (dialogEle && !window.HTMLDialogElement) {
      intervalId = setInterval(() => {
        const dialogPolyfill = window.dialogPolyfill
        if (dialogPolyfill) {
          dialogPolyfill.registerDialog(dialogEle)
          setTriggerDialog(true)
          clearInterval(intervalId)
        }
      }, 200)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])

  useEffect(() => {
    const dialogEle = dialogRef.current
    let isDragging = false

    const handleMouseDown = () => {
      isDragging = false
    }

    const handleMouseMove = () => {
      isDragging = true
    }

    const handleClick = (event: MouseEvent) => {
      // close the dialog when the user clicks outside the dialog body but not dragging
      if (isModal && event.target === dialogRef.current && !isDragging) {
        closeModalDialog()
      }
    }

    if (dialogEle) {
      dialogEle.addEventListener('mousedown', handleMouseDown)
      dialogEle.addEventListener('mousemove', handleMouseMove)
      dialogEle.addEventListener('click', handleClick)
      dialogEle.addEventListener('close', handleOnDialogClose)

      return () => {
        dialogEle.removeEventListener('mousedown', handleMouseDown)
        dialogEle.removeEventListener('mousemove', handleMouseMove)
        dialogEle.removeEventListener('click', handleClick)
        dialogEle.removeEventListener('close', handleOnDialogClose)
      }
    }
  }, [closeModalDialog, handleOnDialogClose, isModal])

  const classNames = new Set<string>(['swan-dialog', 'swan-vanilla-ignore'])
  if (!isModal) classNames.add('swan-dialog-modeless')

  const classNameIter = processStyleProps(otherProps)

  // filter out any props on the Dialog that we actually want to apply to the ModalDialogContent instead
  const dialogProps = filterProps(props, ['backgroundColor', 'bgc'], classNameIter)

  // a11y support for browsers that don't natively recognize the <dialog> tag
  dialogProps['role'] = 'dialog'
  dialogProps['aria-modal'] = isModal ? 'true' : 'false'

  // aria-labelledby set to title id when not supplied directly
  if (!props['aria-label'] && !props['aria-labelledby'] && titleId) {
    dialogProps['aria-labelledby'] = titleId
  }

  return (
    <ModalDialogContext.Provider
      value={{
        variant,
        transitionState,
        handleModalCloseButton: closeModalDialog,
        titleId,
        setTitleId,
        bgc,
        backgroundColor,
        takeOver: takeOver ?? false,
        isModal: isModal ?? false,
        bodyWidth: bodyWidth || 'standard',
        footerPinned,
        setFooterPinned,
      }}
    >
      <DialogConfigProvider config={{ inModal: true, portal: false, portalRef, dialogRef }}>
        <RenderComp root="dialog" forwardedRef={combinedRef} classNames={classNames} props={dialogProps} propKeysToRemove={propKeysToRemove}>
          {!onlyRenderWhenOpen || isOpen ? children : null}
        </RenderComp>
      </DialogConfigProvider>
    </ModalDialogContext.Provider>
  )
})

export { DialogConfigContext, useInternalDialogConfigContext }
