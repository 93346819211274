import { useButton } from '@react-aria/button'
import { FocusEvent } from 'react'

import { assignRefs, className } from '~/core/utilities'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { TextInput, TextInputProps } from '~/react/components/text-input'

import { Button, Icon } from '~/react'
import { useFloatingLabelTextInputElement } from '~/react/contexts/internal/floating-label'

import { useSwanComboboxContext } from './combobox.context'

const propTypes = {}

export type ComboboxInputProps = Omit<TextInputProps, 'value' | 'defaultValue' | 'onChange'>

/**
 * @subcomponent Combobox
 */
export const ComboboxInput = renderWithRef<HTMLInputElement, ComboboxInputProps>('ComboboxInput', propTypes, (props, ref) => {
  const { buttonRef, buttonProps: contextButtonProps, inputRef, inputProps, menuTrigger } = useSwanComboboxContext()
  const { buttonProps } = useButton(
    {
      ...contextButtonProps,
      isDisabled: props.disabled,
    },
    buttonRef,
  )

  const combinedRef = assignRefs(ref, inputRef)

  const compProps = {
    ...inputProps,
    ...props,
    // to prevent the overwriting of the react-aria onBlur method, which triggers the closing of the ComboboxPopover
    onBlur: (e: FocusEvent<HTMLInputElement, Element>) => {
      props.onBlur?.(e)
      inputProps.onBlur?.(e)
    },
    className: className(props.className, 'swan-input swan-combobox'),
  }

  const filteredProps = useFloatingLabelTextInputElement(compProps) // id will never be undefined since the react-aria code always sets it

  // TODO: Until we have internationalization, we want to remove this hard-coded aria-label from the button
  // We also add aria-hidden as it's now an unlabelled button which we don't want in the DOM tree. Note that this button is already
  // removed from the tab order by react-aria.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { 'aria-label': _ariaLabel, ...filteredButtonProps } = buttonProps
  filteredButtonProps['aria-hidden'] = true

  return (
    <>
      <RenderComp forwardedRef={combinedRef} root={TextInput} props={{ ...filteredProps, type: 'text' }} />
      {menuTrigger === 'focus' && (
        <Button ref={buttonRef} {...filteredButtonProps} className="swan-combobox-button" skin="tertiary">
          {buttonProps['aria-expanded'] ? <Icon iconType="caretUp" /> : <Icon iconType="caretDown" />}
        </Button>
      )}
    </>
  )
})
