import type { MutableRefObject, ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import SlickCarousel, { Settings as SlickCarouselProps } from 'react-slick'

type CarouselProps = {
  beforeChange?: SlickCarouselProps['beforeChange']
  children?: ReactNode
  skin?: 'promo-bar' | string | null
}

/**
 * Sets dark mode if the slide has dark mode on the first child
 */
function setDarkModeFromSlide(slide: Element | undefined, setDarkMode: (darkMode: boolean) => void) {
  const childEl = slide?.querySelector('.swan-carousel-slide')?.firstElementChild
  setDarkMode(childEl?.classList?.contains('swan-dark-mode') ?? false)
}

/**
 * When the promobar variant is used with a slide with darkMode, the buttons need to switch to white
 * This hook is responsible for adding the swan-dark-mode css class to the entire carousel when the current slide is inverse
 *
 * TODO (browser): this could be solved with the :has css selector
 *
 * min version Safari 15.4, FF (no support yet)
 *
 * ```
 * .swan-carousel-skin-promo-bar:has(.slick-current .swan-carousel-slide > .swan-dark-mode) {
 *   .slick-arrow {
 *     --swan-icon-filter-standard: var(--swan-icon-filter-white);
 *   }
 * }
 * ```
 */
export function usePromoBarDarkMode(carouselRef: MutableRefObject<SlickCarousel | undefined>, props: CarouselProps) {
  const [forceDarkMode, setForceDarkMode] = useState(false)
  const { beforeChange: propBeforeChange, children, skin } = props

  const beforeChange = useCallback(
    (current: number, next: number) => {
      if (propBeforeChange) {
        propBeforeChange(current, next)
      }

      if (skin !== 'promo-bar') return

      // if the next slide has dark mode set on it,
      const slideElements = carouselRef.current?.innerSlider?.list?.querySelectorAll('.slick-slide')
      if (slideElements) {
        setDarkModeFromSlide(slideElements[next], setForceDarkMode)
      }
    },
    [carouselRef, setForceDarkMode, skin, propBeforeChange],
  )

  // we also need to run the check when the children change
  useEffect(() => {
    if (skin !== 'promo-bar') return

    const currentSlide = carouselRef.current?.innerSlider?.list?.querySelector('.slick-current')

    if (currentSlide) {
      setDarkModeFromSlide(currentSlide, setForceDarkMode)
    }
  }, [carouselRef, children, skin])

  return {
    forceDarkMode,
    beforeChange,
  }
}
