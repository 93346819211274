import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ColorSwatchesMoreProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

/**
 * @subcomponent ColorSwatches
 */
export const ColorSwatchesMore = renderWithRef<MinNativeRef, ColorSwatchesMoreProps>('ColorSwatchesMore', null, ({ children, ...props }, ref) => {
  return (
    <RenderComp root="span" classNames={['swan-color-swatches-more']} forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
