import { Overlay, OverlayProps, usePopover } from '@react-aria/overlays'
import { ReactNode } from 'react'

import { ROOT_CLASSNAMES } from '~/core/constants'
import { tokensRaw } from '~/core/tokens/tokens-raw'
import { assignRefs } from '~/core/utilities'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { useInternalDialogConfigContext } from '~/react/components/modal-dialog'

import { PopoverPlacement, useSwanPopover } from './popover.context'

type Props = {
  /**
   * Specifies where the popover should be placed in relation to the trigger element
   *
   * @default top
   */
  placement?: PopoverPlacement

  /**
   * The content to be displayed within the popover.
   */
  children?: ReactNode

  /**
   * The offset distance from the trigger element.
   */
  offset?: number

  /**
   * Specifies whether the popover is used in a non-modal context.
   *
   * @default false
   */
  isNonModal?: boolean

  /**
   * Whether to hide the popover arrow
   *
   * @default false
   */
  hideArrow?: boolean

  /**
   * Specifies whether the popover should take up the full available width.
   */
  fullBleed?: boolean

  /**
   * The label text for accessibility.
   */
  labelText?: string

  /**
   * The container for the portal where the popover is rendered.
   */
  portalContainer?: OverlayProps['portalContainer']
}
const propKeysToRemove = ['placement', 'offset', 'inModal', 'isNonModal', 'hideArrow', 'fullBleed', 'portalContainer', 'labelText']
export type PopoverContentInternalProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, Props>

// should match the variable in the CSS
const DISTANCE_FROM_TRIGGER_PX = parseInt(tokensRaw.SwanSemSpace2) || 4

/**
 * Not a public component, has base functionality to be used by listbox, combobox, popover and menu
 *
 * @subcomponent Popover
 */
export const PopoverContentInternal = renderWithRef<MinNativeRef, PopoverContentInternalProps>('PopoverContent', {}, (props, ref) => {
  const {
    children,
    offset = DISTANCE_FROM_TRIGGER_PX,
    isNonModal = false,
    portalContainer: portalContainerProp,
    hideArrow = false,
    fullBleed,
    className,
    placement = 'top',
  } = props
  const { popoverRef, triggerRef, overlayProps, overlayState, modes } = useSwanPopover()

  const { inModal, portalRef, dialogRef } = useInternalDialogConfigContext()

  const portalContainer = portalContainerProp ?? (inModal && portalRef.current ? portalRef.current : undefined)

  const {
    popoverProps,
    underlayProps,
    placement: calculatedPlacement,
    arrowProps,
  } = usePopover(
    {
      popoverRef,
      triggerRef,
      placement,
      offset,
      isNonModal,
      // the popover will be absolutely positioned relative to the dialog element if it's in a modal
      boundaryElement: (inModal && dialogRef.current) || undefined,
    },
    overlayState,
  )

  const combinedRef = assignRefs(ref, popoverRef)

  const classes = new Set([ROOT_CLASSNAMES.base, 'swan-popover']) // the .swan class is needed for typography styling
  if (fullBleed) classes.add('swan-popover-skin-full-bleed')
  if (modes.hasDarkMode) classes.add(ROOT_CLASSNAMES.darkMode)
  if (modes.hasCompactMode) classes.add(ROOT_CLASSNAMES.compactMode)
  if (className) classes.add(className)

  return (
    <Overlay {...overlayProps} portalContainer={portalContainer}>
      {!isNonModal && <div {...underlayProps} className="swan-popover-underlay" />}
      <RenderComp
        root="div"
        forwardedRef={combinedRef}
        props={{ ...props, ...popoverProps, 'aria-label': props.labelText, 'data-placement': !hideArrow ? calculatedPlacement : undefined }}
        propKeysToRemove={propKeysToRemove}
        classNames={classes}
      >
        {hideArrow ? null : (
          <svg {...arrowProps} className="swan-popover-arrow" viewBox="0 0 24 24">
            <path d="M0 0 L12 12 L24 0" />
          </svg>
        )}
        {children}
      </RenderComp>
    </Overlay>
  )
})
