import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BannerTitleProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

/**
 * @subcomponent Banner
 */
export const BannerTitle = renderWithRef<MinNativeRef, BannerTitleProps>('BannerTitle', {}, (props, ref) => {
  const classNames = ['swan-banner-title']
  const { children } = props
  return (
    <RenderComp root="p" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
