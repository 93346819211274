import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type FieldSetProps = CoreProps<JSX.IntrinsicElements['fieldset'], MinNativeRef>

export const FieldSet = renderWithRef<MinNativeRef, FieldSetProps>('FieldSet', null, ({ children, ...props }, ref) => (
  <RenderComp root="fieldset" forwardedRef={ref} props={props} classNames={['swan-fieldset']}>
    {children}
  </RenderComp>
))
