import PropTypes, { InferProps } from 'prop-types'
import { useCallback, useEffect } from 'react'

import { processStyleProps } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, filterProps, renderWithRef } from '~/react/components/core'

import { PopoverContentInternal, PopoverContentInternalProps } from './popover-content-internal.component'
import { PopoverDialog } from './popover-dialog.component'
import { PopoverPlacement, useSwanPopover } from './popover.context'

const propTypes = {
  /**
   * Should it be wrapped inside th the dialog popover or not?
   *
   * @default false
   */
  dialog: PropTypes.bool,
  /**
   * removes the padding around the Popover's content
   * @default undefined
   */
  fullBleed: PropTypes.bool,
}

export type PopoverContentProps = CoreProps<
  Omit<PopoverContentInternalProps, 'Placement'>,
  MinNativeRef,
  InferProps<typeof propTypes> & {
    /**
     * The placement of the popover. eg: "bottom start", "top end", "top", "bottom"
     * @default top
     */
    placement?: PopoverPlacement
  }
>

/**
 * @subcomponent Popover
 */
export const PopoverContent = renderWithRef<MinNativeRef, PopoverContentProps>('PopoverContent', {}, (props, ref) => {
  const { children, dialog = false, fullBleed, hideArrow, placement } = props
  const { overlayState, popoverPlacement } = useSwanPopover()

  const filteredProps = filterProps(props, Object.keys(propTypes), processStyleProps(props))

  const popoverProps = {
    ...filteredProps,
    hideArrow,
    placement: placement ?? popoverPlacement ?? 'top',
    fullBleed: fullBleed ?? undefined,
    role: !dialog ? 'tooltip' : undefined,
    children: dialog ? <PopoverDialog>{children}</PopoverDialog> : children,
  }

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        overlayState.close()
      }
    },
    [overlayState],
  )

  useEffect(() => {
    // only add the event listener if this is not a dialog popover - the PopoverDialog handles esc functionality already
    if (dialog) {
      return
    }

    if (overlayState.isOpen) {
      document.addEventListener('keydown', onKeyDown as EventListener)
    } else {
      document.removeEventListener('keydown', onKeyDown as EventListener)
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown as EventListener)
    }
  }, [dialog, overlayState.isOpen, onKeyDown])

  // eslint-disable-next-line react/jsx-no-undef
  return overlayState.isOpen ? <PopoverContentInternal {...popoverProps} ref={ref} /> : null
})
