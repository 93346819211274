import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

type TPriceBarQuantityContext =
  | undefined
  | null
  | {
      buttonId?: string | null
      setButtonId: (value?: string | null) => void
      labelId?: string | null
      setLabelId: (value?: string | null) => void
    }

export const PriceBarQuantityContext = createContext<TPriceBarQuantityContext>(undefined)
PriceBarQuantityContext.displayName = 'PriceBarQuantityContext'

export const usePriceBarQuantityContext = () => useNonNullishContext(PriceBarQuantityContext)
