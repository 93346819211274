import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useFloatingLabelLabelProps } from '~/react/contexts/internal/floating-label/'

export type ListboxFloatingLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

/**
 * @subcomponent Listbox
 */
export const ListboxFloatingLabel = renderWithRef<MinNativeRef, ListboxFloatingLabelProps>('ListboxFloatingLabel', null, ({ children, ...props }, ref) => {
  const labelProps = useFloatingLabelLabelProps()

  return (
    <RenderComp root="label" classNames={['swan-listbox-floating-label']} forwardedRef={ref} props={{ ...props, ...labelProps }}>
      {children}
    </RenderComp>
  )
})
