import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

/**
 * @deprecated No replacement for legacy code. It was in use for PaginationInput which was removed in SWAN 3.0
 */
export const PaginationContext = createContext<{ labelId: string | undefined | null; setLabelId: (labelId: string | undefined | null) => void } | null | undefined>(null)

/**
 * @deprecated No replacement for legacy code. It was in use for PaginationInput which was removed in SWAN 3.0
 */
export function usePaginationContext() {
  return useNonNullishContext(PaginationContext)
}
