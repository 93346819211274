import PropTypes, { InferProps } from 'prop-types'

import { tokens } from '~/core/index'
import { SpaceDensityPropType, SpacePropType, StyleSpace, StyleSpaceWithDensity } from '~/core/types/swan-style.types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * The CSS align-items property sets the align-self value on all direct children as a group. In Flexbox, it controls the alignment of items on the Cross Axis. In Grid Layout, it controls the alignment of items on the Block Axis within their grid area.
   * One of: "stretch", "center", "start", "end", "flex-start", "flex-end", "baseline", "inherit", "initial", "revert", "unset".
   */
  alignItems: PropTypes.oneOf(['stretch', 'center', 'start', 'end', 'flex-start', 'flex-end', 'baseline', 'inherit', 'initial', 'revert', 'unset'] as const),
  /**
   * The flex-wrap CSS property sets whether flex items are forced onto one line or can wrap onto multiple lines. If wrapping is allowed, it sets the direction that lines are stacked.
   * One of: "nowrap", "wrap", "wrap-reverse", "inherit", "initial", "revert", "unset".
   */
  flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse', 'inherit', 'initial', 'revert', 'unset'] as const),
  /**
   * The flex-direction CSS property sets how flex items are placed in the flex container defining the main axis and the direction (normal or reversed).
   * One of: "row", "row-reverse", "column", "column-reverse", "inherit", "initial", "revert", "unset".
   */
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse', 'inherit', 'initial', 'revert', 'unset'] as const),
  /**
   * The CSS justify-content property defines how the browser distributes space between and around content items along the main-axis of a flex container, and the inline axis of a grid container.
   * One of: "stretch", "center", "start", "end", "flex-start", "flex-end", "left", "right", "normal", "space-between", "space-around", "space-evenly", "inherit", "initial", "revert", "unset".
   */
  justifyContent: PropTypes.oneOf([
    'stretch',
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'left',
    'right',
    'normal',
    'space-between',
    'space-around',
    'space-evenly',
    'inherit',
    'initial',
    'revert',
    'unset',
  ] as const),
  /**
   * The gap property places a uniform gap (gutter) between the flex container's children.
   */
  gap: PropTypes.oneOf([...SpacePropType, ...SpaceDensityPropType] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type FlexBoxProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

const spaceMap = SpacePropType.reduce(
  (acc, space) => {
    const spaceToken = `SwanSemSpace${space}` as const
    if (spaceToken in tokens && spaceToken !== 'SwanSemSpace0') {
      return {
        ...acc,
        [space]: tokens[spaceToken],
      }
    }

    return acc
  },
  {
    0: tokens.SwanSemSpaceNone,
  } as Record<Exclude<StyleSpace, string>, string>,
)

// If this is needed elsewhere, we can look to move outside of FlexBox at a later date
const gapMap: Record<Exclude<Extract<StyleSpaceWithDensity, string>, 'auto'>, string> = {
  ...spaceMap,
  'between-sections': tokens.SwanSemSpaceBetweenSections,
  'between-subsections': tokens.SwanSemSpaceBetweenSubsections,
  'between-actions': tokens.SwanSemSpaceBetweenActions,
  'to-actions': tokens.SwanSemSpaceToActions,
  'between-icon-and-text': tokens.SwanSemSpaceBetweenIconAndText,
}

export const FlexBox = renderWithRef<MinNativeRef, FlexBoxProps>(
  'FlexBox',
  null,
  ({ children, style = {}, alignItems, flexWrap, flexDirection, justifyContent, gap, ...props }, ref) => {
    const processedStyles = {
      ...style,
      alignItems: alignItems !== null ? alignItems : undefined,
      flexWrap: flexWrap !== null ? flexWrap : undefined,
      flexDirection: flexDirection !== null ? flexDirection : undefined,
      justifyContent: justifyContent !== null ? justifyContent : undefined,
      gap: gap != null ? gapMap[gap] : undefined, // use != instead of !== to handle `undefined`
    }
    return (
      <RenderComp root="div" forwardedRef={ref} props={{ ...props, style: processedStyles }} propKeysToRemove={propKeysToRemove} classNames={['swan-display-flex']}>
        {children}
      </RenderComp>
    )
  },
)
