import { AriaButtonProps } from '@react-aria/button'
import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type SwanMenuTriggerContextValue = {
  triggerProps: AriaButtonProps<'button'>
  triggerDisabled: boolean
}

export const SwanMenuTriggerContext = createContext<SwanMenuTriggerContextValue | null | undefined>(undefined)

export function useSwanMenuTriggerContext() {
  return useNonNullishContext(SwanMenuTriggerContext)
}
