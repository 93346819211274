import { InferProps } from 'prop-types'
import { HTMLAttributes } from 'react'

import { processStyleProps } from '~/core/utilities'

import { CoreProps, renderWithRef } from '~/react/components/core'
import { FlexBox } from '~/react/components/flex-box'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { CollectionContextProvider } from '~/react/contexts/internal/collection'
import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { SwanComboboxContextProvider, SwanComboboxContextProviderProps } from './combobox.context'

export const comboboxPropTypes = {}

type NativeProps = HTMLAttributes<HTMLDivElement>

export type ComboboxProps = CoreProps<NativeProps, HTMLDivElement, InferProps<typeof comboboxPropTypes> & SwanComboboxContextProviderProps, NativeProps>

export const Combobox = renderWithRef<HTMLDivElement, ComboboxProps>('Combobox', comboboxPropTypes, (props, ref) => {
  useComponentStylesLoaded('Combobox', [SWAN_STYLE_KEY_MAP.listbox, SWAN_STYLE_KEY_MAP.popover])

  const { children, className, items, menuTrigger } = props

  const classes = new Set<string>(['swan-combobox-container'])
  if (className) classes.add(className)
  if (menuTrigger === 'focus') classes.add('swan-combobox-container-with-focus-trigger')

  return (
    <FlexBox className={Array.from(processStyleProps(props, classes)).join(' ')} ref={ref}>
      <CollectionContextProvider items={items}>
        <SwanComboboxContextProvider {...props}>{children}</SwanComboboxContextProvider>
      </CollectionContextProvider>
    </FlexBox>
  )
})
