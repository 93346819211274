import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useNonNullishFloatingLabelContext } from '~/react/contexts/internal/floating-label'

export type FloatingLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef>

/**
 * @subcomponent InputWithFloatingLabel
 */
export const FloatingLabel = renderWithRef<MinNativeRef, FloatingLabelProps>('FloatingLabel', null, ({ children, ...props }, ref) => {
  const { inputId } = useNonNullishFloatingLabelContext()

  return (
    <RenderComp root="label" forwardedRef={ref} props={{ ...props, htmlFor: inputId }} classNames={['swan-label']}>
      {children}
    </RenderComp>
  )
})
