import { useButton } from '@react-aria/button'
import { Children, cloneElement, isValidElement, ReactNode } from 'react'

import { assignRefs } from '~/core/utilities'

import { useSwanMenuTriggerContext } from '~/react/components/menu/menu-trigger.context'
import { useSwanPopover } from '~/react/components/popover'

/**
 * @subcomponent Menu
 */
export function MenuTrigger({ children }: { children?: ReactNode }) {
  const { triggerRef } = useSwanPopover()
  const { triggerProps } = useSwanMenuTriggerContext()

  const { buttonProps } = useButton(triggerProps, triggerRef)

  const newProps = {
    ...buttonProps,
    ref: triggerRef,
    type: undefined,
    className: 'swan-menu-trigger', // Used for SWAN overlay
  }

  return (
    <>
      {Children.map(children, (child, index) => {
        if (index === 0 && isValidElement(child) && !child.props.disabled) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const combinedRefs = assignRefs(triggerRef, (child as unknown as any).ref)
          return cloneElement(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            child as unknown as any,
            {
              ...newProps,
              disabled: child.props.disabled !== 'undefined' ? child.props.disabled : (newProps as typeof buttonProps & { disabled?: boolean }).disabled,
              ref: combinedRefs,
            },
          )
        }
        return child
      })}
    </>
  )
}
