import { LegacyRef, MutableRefObject, RefCallback } from 'react'

export function assignRef<T>(ref: MutableRefObject<T> | LegacyRef<T> | null | undefined, value: T) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    ;(ref as MutableRefObject<T>).current = value
  }
}

/**
 *
 * @param refs
 *
 * Example
 * (props, ref) => {
 *   const tempRef = useRef()
 *
 *   onX = () => {
 *     tempRef.current.some_work()
 *   }
 *
 *   return <div ref={assignRefs(ref, tempRef)} />
 *
 *   return renderElem({
 *      baseElement: 'div'
 *      ref: assignRefs(ref, tempRef)
 *      ...other_stuff
 *   })
 * }
 */
export function assignRefs<T>(...refs: Array<MutableRefObject<T> | LegacyRef<T> | null | undefined>): RefCallback<T> {
  return (value: T) => {
    refs.forEach(ref => assignRef(ref, value))
  }
}
