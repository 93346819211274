import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { TableHeadContext } from './table.context'

export type TableHeadProps = CoreProps<JSX.IntrinsicElements['thead'], MinNativeRef>

/**
 * @subcomponent Table
 */
export const TableHead = renderWithRef<MinNativeRef, TableHeadProps>('TableHead', null, ({ children, ...props }, ref) => {
  return (
    <TableHeadContext.Provider value>
      <RenderComp root="thead" classNames={['swan-thead']} forwardedRef={ref} props={props}>
        {children}
      </RenderComp>
    </TableHeadContext.Provider>
  )
})
