import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

export type ZoomControlsProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

export const ZoomControls = renderWithRef<MinNativeRef, ZoomControlsProps>('ZoomControls', null, ({ children, ...props }, ref) => {
  useComponentStylesLoaded('ZoomControls', SWAN_STYLE_KEY_MAP.zoomControls)
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={['swan-zoom-controls']} props={props}>
      {children}
    </RenderComp>
  )
})
