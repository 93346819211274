import PropTypes, { InferProps } from 'prop-types'
import { useEffect } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

import { useId } from '~/react/hooks'

import { useTabsContext } from './tabs.context'

export type TabHeaderLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef, InferProps<typeof propTypes>>

const propTypes = {
  /**
   * The id of the matching tab
   */
  tabId: PropTypes.string.isRequired,
}

const propKeysToRemove = Object.keys(propTypes)

/**
 * @subcomponent Tabs
 */
export const TabHeaderLabel = renderWithRef<MinNativeRef, TabHeaderLabelProps>('TabHeaderLabel', propTypes, (props, ref) => {
  const { headerIds, registerLabel, unregisterLabel } = useTabsContext()

  const { children, id: customId, tabId } = props
  const id = useId(customId)

  useEffect(() => {
    registerLabel(tabId, id)

    return () => unregisterLabel(tabId)
  }, [tabId, id, registerLabel, unregisterLabel])

  return (
    <RenderComp
      root="label"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      props={{
        ...props,
        id: id,
        htmlFor: headerIds[tabId],
        // role: 'tab',
      }}
    >
      {children}
    </RenderComp>
  )
})
