import { InferProps } from 'prop-types'
import { ElementType, ReactNode, useEffect, useMemo } from 'react'

import { Nullable, SwanPathTypeEnum } from '~/core/types'
import { createSwanGlobalObject, createSwanVersionMetaTag, getSwanBaseUrl, loadSwanPolyfill } from '~/core/utilities'

import { SwanContext, SwanContextValue, swanLoaderConfigProps } from '~/react/contexts/swan-provider/swan-provider.context'

createSwanVersionMetaTag()

const propTypes = {
  ...swanLoaderConfigProps,
  pathType: swanLoaderConfigProps.swanPathType,
}

export type SwanProviderProps = Omit<InferProps<typeof propTypes>, 'pathType'> & {
  globalLinkComponent?: ElementType
  pathType?: Nullable<SwanPathTypeEnum>
  children?: ReactNode
}

const DEFAULT_SWAN_PATH_TYPE_ENUM: SwanPathTypeEnum = SwanPathTypeEnum.hashed

export const SwanProvider = ({ swanBaseUrl, pathType, swanPathType, swanTenant, swanLocale, globalLinkComponent, children }: SwanProviderProps) => {
  const baseUrl = swanBaseUrl || getSwanBaseUrl(swanTenant, swanLocale)
  const path = swanPathType || pathType || DEFAULT_SWAN_PATH_TYPE_ENUM

  const value = useMemo((): SwanContextValue => {
    return {
      globalLinkComponent,
      swanBaseUrl: baseUrl,
      swanPathType: path,
      swanTenant,
      swanLocale,
    }
  }, [globalLinkComponent, baseUrl, path, swanTenant, swanLocale])

  useEffect(() => {
    createSwanGlobalObject(value.swanBaseUrl)
  }, [value.swanBaseUrl])

  useEffect(() => {
    // add polyfill url in the head
    loadSwanPolyfill(baseUrl)
  }, [baseUrl])

  return <SwanContext.Provider value={value}>{children}</SwanContext.Provider>
}

SwanProvider.displayName = 'SwanProvider'

SwanProvider.propTypes = propTypes
