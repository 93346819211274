import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BulletListContentProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent List
 */

export const BulletListContent = renderWithRef<MinNativeRef, BulletListContentProps>('BulletListContent', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-bullet-list-contents']} props={props}>
    {props.children}
  </RenderComp>
))
