import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BannerImageContainerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Banner
 */
export const BannerImageContainer = renderWithRef<MinNativeRef, BannerImageContainerProps>('BannerImageContainer', {}, (props, ref) => {
  const classNames = ['swan-banner-image-container']
  const { children } = props
  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
