import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { Button } from '~/react/components/button'
import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

const propTypes = {
  /**
   * Provide the text that should be used for screen readers.
   */
  accessibleText: PropTypes.string,
}
const propKeysToRemove = ['accessibleText'] as const
export type PriceBarPriceInfoProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent PriceBar
 */
export const PriceBarPriceInfo = renderWithRef<MinNativeRef, PriceBarPriceInfoProps>('PriceBarPriceInfo', propTypes, (props, ref) => {
  const classNames = new Set(['swan-price-bar-price-info'])

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
      <Button skin="unstyled">
        <Icon alt={props.accessibleText || ''} iconType="info" size="standard" />
      </Button>
    </RenderComp>
  )
})
