import PropTypes, { InferProps } from 'prop-types'
import warning from 'tiny-warning'

import { swanStyleObjectFit, swanStyleObjectFitValues } from '~/core/utilities'
import { getSwanIconUrl, SWAN_DEPRECATED_ICONS, SWAN_ICON_KEY_MAP, SwanIconKey } from '~/core/utilities/manifest-icons.utils'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedPropValues, RenderComp, renderWithRef } from '~/react/components/core'
import { DEPRECATED_ICON_SIZES, DEPRECATED_ICON_SKINS } from '~/react/components/icon/icon.constants'

import { useSwanContext } from '~/react/contexts/swan-provider/swan-provider.context'

const propTypes = {
  /**
   * The type of icon to show.
   */
  iconType: deprecatedPropValues(PropTypes.oneOf(Object.keys(SWAN_ICON_KEY_MAP) as SwanIconKey[]), SWAN_DEPRECATED_ICONS, 'See alternatives on the SWAN docsite.'),
  /**
   * The size of the icon.
   * One of: "standard", "10p", "16p", "20p", "24p", "28p", "32p", "40p", "48p", "60p"
   * Size "10p" is deprecated. Use "16p" size instead.
   * Size "28p", "32p", "40p", "48p" and "60p" are deprecated. Use "standard" size instead.
   */
  size: deprecatedPropValues(
    PropTypes.oneOf(['standard', 'small', '10p', '16p', '20p', '24p', '28p', '32p', '40p', '48p', '60p'] as const),
    DEPRECATED_ICON_SIZES,
    'Use 16p instead of 10p and use standard instead of 28p, 32p, 40p, 48p and 60p.',
  ),
  /**
   * The visual style of the icon.
   * One of: "white", "standard", "error", "warning", "success".
   * 'white' option is deprecated - use 'standard' instead in dark mode
   *
   * @default standard
   */
  skin: deprecatedPropValues(
    PropTypes.oneOf(['standard', 'subtle', 'white', 'error', 'warning', 'success', 'accent', 'help', 'info'] as const),
    DEPRECATED_ICON_SKINS,
    'Use "standard" instead in dark mode',
  ),
  /**
   * Sets the resizing behaviour of the image.
   * One of: "cover", "contain", "none", "unset", "fill"
   */
  objectFit: PropTypes.oneOf(swanStyleObjectFitValues),
}

const propKeysToRemove = Object.keys(propTypes)

export type IconProps = CoreProps<JSX.IntrinsicElements['img'], HTMLImageElement, InferProps<typeof propTypes>, JSX.IntrinsicElements['img']>

export const Icon = renderWithRef<MinNativeRef, IconProps>('Icon', propTypes, (props, ref) => {
  const { children, src, size, skin = 'standard', iconType, objectFit, alt } = props

  const { swanPathType, swanBaseUrl } = useSwanContext()
  const isCustom = !!src

  const validIconTypes = Object.keys(SWAN_ICON_KEY_MAP)
  const hasValidIconType = Boolean(iconType && validIconTypes.includes(iconType))

  warning(!(!src && !hasValidIconType), 'Icon: Either iconType is missing or invalid or if you are trying to use it as custom icon the `src` has not been passed')

  let iconUrl: string | undefined | null = ''
  if (iconType && hasValidIconType) {
    iconUrl = getSwanIconUrl(iconType, swanPathType, swanBaseUrl)
  }

  const maskImage = iconUrl ? `url(${iconUrl})` : undefined

  const classNames = new Set<string>(['swan-icon', 'swan-icon-mask'])
  const objectFitClassName = swanStyleObjectFit(objectFit)
  if (objectFitClassName) classNames.add(objectFitClassName)
  if (size) classNames.add(`swan-icon-size-${size}`)
  if (skin) classNames.add(`swan-icon-skin-${skin}`)
  if (isCustom) {
    classNames.add('swan-icon-custom')
  } else {
    hasValidIconType && classNames.add(`swan-icon-type-${iconType}`)
  }

  return (
    <RenderComp
      root="img"
      forwardedRef={ref}
      classNames={classNames}
      props={{
        ...props,
        style: {
          ...props.style,
          maskImage,
          WebkitMaskImage: maskImage,
        },
        // use a blank img for the src since we're using maskImage instead
        src: src ? src : maskImage ? 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' : undefined,
        // role: props.role || !props.alt ? 'presentation' : undefined,
        alt: alt || '',
        draggable: false,
      }}
      propKeysToRemove={propKeysToRemove}
    >
      {children}
    </RenderComp>
  )
})
