import { useOverlayTrigger } from '@react-aria/overlays'
import { useOverlayTriggerState } from '@react-stately/overlays'
import PropTypes, { InferProps } from 'prop-types'
import { useMemo, useRef } from 'react'

import { assignRefs } from '~/core/utilities'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'
import { SwanPopoverContextProvider, SwanPopoverContextValue } from '~/react/components/popover/popover.context'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'
import { useModeExtractor } from '~/react/hooks/use-mode-extractor'

const propTypes = {
  /**
   * Position in which should the popover be shown
   *
   * @default top
   */
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left'] as const),
}

const propKeysToRemove = Object.keys(propTypes)

export type PopoverProps = CoreProps<JSX.IntrinsicElements['div'], HTMLDivElement, InferProps<typeof propTypes>>

export const Popover = renderWithRef<HTMLDivElement, PopoverProps>('Popover', propTypes, (props, ref) => {
  useComponentStylesLoaded('Popover', SWAN_STYLE_KEY_MAP.popover)
  const { position = 'top', children } = props

  const popoverRef = useRef<HTMLDivElement>(null)
  const combinedRef = assignRefs(ref, popoverRef)

  const triggerRef = useRef<HTMLElement>(null)
  const overlayState = useOverlayTriggerState({})
  const modes = useModeExtractor(popoverRef, overlayState.isOpen)
  const { overlayProps, triggerProps } = useOverlayTrigger({ type: 'dialog' }, overlayState, triggerRef)

  const contextValue = useMemo<SwanPopoverContextValue>(
    () => ({
      modes,
      popoverRef,
      triggerRef,
      overlayState,
      overlayProps: overlayProps,
      triggerProps: triggerProps,
      popoverPlacement: position ?? 'top',
    }),
    [triggerRef, popoverRef, overlayState, overlayProps, triggerProps, position, modes],
  )

  const classNames = ['swan-popover-container']

  return (
    <SwanPopoverContextProvider value={contextValue}>
      <RenderComp root="div" forwardedRef={combinedRef} classNames={classNames} props={props} propKeysToRemove={propKeysToRemove}>
        {children}
      </RenderComp>
    </SwanPopoverContextProvider>
  )
})
