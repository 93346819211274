import { createContext } from 'react'

import { useNonNullishContext } from '~/react/hooks'

export type CollapsibleContextType = {
  expanded: boolean
  onRequestExpandedChange: (expanded: boolean) => void
  contentId: string | undefined | null
  setContentId: (id: string | undefined | null) => void
  disabled: boolean | undefined | null
}

export const CollapsibleContext = createContext<CollapsibleContextType | null | undefined>(undefined)
CollapsibleContext.displayName = 'CollapsibleContext'

export function useCollapsibleContext() {
  return useNonNullishContext(CollapsibleContext)
}
