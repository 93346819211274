import PropTypes, { InferProps } from 'prop-types'
import { createContext, ElementType, useContext } from 'react'

import { SWAN_BASE_CDN_URL } from '~/core/constants'
import { SwanPathTypeEnum } from '~/core/types'

export const swanLoaderConfigProps = {
  swanBaseUrl: PropTypes.string,
  swanTenant: PropTypes.string,
  swanLocale: PropTypes.string,
  swanPathType: PropTypes.oneOf([SwanPathTypeEnum.hashed, SwanPathTypeEnum.versioned] as const),
}

export type SwanLoaderConfigProps = InferProps<typeof swanLoaderConfigProps>

export type SwanContextValue = {
  swanBaseUrl: string
  swanPathType: SwanPathTypeEnum
  globalLinkComponent?: ElementType
} & Omit<SwanLoaderConfigProps, 'swanBaseUrl' | 'swanPathType'>

const defaultCtxValue: SwanContextValue = {
  swanBaseUrl: SWAN_BASE_CDN_URL,
  swanPathType: SwanPathTypeEnum.hashed,
}

export const SwanContext = createContext<SwanContextValue>(defaultCtxValue)
SwanContext.displayName = 'SwanContext'

export function useSwanContext() {
  return useContext(SwanContext)
}
