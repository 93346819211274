import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ModalDialogButtonsTextProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Dialog
 */
export const ModalDialogButtonsText = renderWithRef<MinNativeRef, ModalDialogButtonsTextProps>('ModalDialogButtonsText', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" forwardedRef={ref} props={props} classNames={['swan-modal-dialog-buttons-text']}>
    {children}
  </RenderComp>
))
