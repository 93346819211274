import { getSwanIllustrationUrl, SWAN_ILLUSTRATION_KEY_MAP } from '~/core/utilities/manifest-illustrations.utils'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useSwanContext } from '~/react/contexts'

type EmptyStateIllustrationTypes = {
  /**
   * Type of illustration to display
   *
   */
  illustrationType: keyof typeof SWAN_ILLUSTRATION_KEY_MAP
}

export type EmptyStateIllustrationProps = CoreProps<JSX.IntrinsicElements['img'], MinNativeRef, EmptyStateIllustrationTypes>

/**
 * @subcomponent EmptyState
 */
export const EmptyStateIllustration = renderWithRef<MinNativeRef, EmptyStateIllustrationProps>('EmptyStateIllustration', null, (props, ref) => {
  const { alt = '', illustrationType, ...rest } = props

  const { swanPathType, swanBaseUrl } = useSwanContext()

  const illustrationUrl = illustrationType && illustrationType in SWAN_ILLUSTRATION_KEY_MAP ? getSwanIllustrationUrl(illustrationType, swanPathType, swanBaseUrl) : undefined

  return <RenderComp root="img" forwardedRef={ref} classNames={['swan-empty-state-illustration']} props={{ ...rest, alt, src: illustrationUrl }} />
})
