import { useMenuItem } from '@react-aria/menu'
import { TreeState } from '@react-stately/tree'
import type { Node } from '@react-types/shared'
import PropTypes, { InferProps } from 'prop-types'
import { useEffect, useRef } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp } from '~/react/components/core'

import { useTrackDisabledState } from '~/react/contexts/internal/collection'

export const propTypes = {
  /**
   * Whether or not the option is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * The option's human-readable string. Required if using JSX inside of an option
   */
  textValue: PropTypes.string,
}
const propKeysToRemove = Object.keys(propTypes)

export type MenuItemCustomProps = CoreProps<HTMLLIElement, MinNativeRef, InferProps<typeof propTypes>>

export type MenuItemInternalProps = {
  item: Node<object>
  state: TreeState<unknown>
}

/**
 * MenuItemInternal is the internal component for rendering a menu item
 *
 * The public MenuItem is only used for the MenuList to parse the children and store in state
 */
export const MenuItemInternal = ({ item, state }: MenuItemInternalProps) => {
  const ref = useRef<HTMLElement>(null)

  // get props from MenuItem, an item can optionally be a link
  const { disabled, href } = (item.props ?? {}) as { disabled?: boolean } & JSX.IntrinsicElements['a']

  useTrackDisabledState(item.key, disabled)
  const { menuItemProps, isFocused } = useMenuItem({ key: item.key }, state, ref)

  // DSYS-2715 workaround for react-aria but where clicks are passing through to below the popover on mobile
  // https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1257931321
  useEffect(() => {
    ref.current?.addEventListener(
      'touchend',
      e => {
        e.preventDefault()
      },
      { passive: false, once: true },
    )
  }, [])

  const classes = new Set(['swan-menu-item', 'swan-listbox-option'])

  if (isFocused) {
    classes.add('swan-menu-item-focused swan-listbox-option-focused')
  }

  return (
    <RenderComp root={href ? 'a' : 'li'} forwardedRef={ref} propKeysToRemove={propKeysToRemove} props={{ ...item.props, ...menuItemProps }} classNames={classes}>
      {item.rendered}
    </RenderComp>
  )
}
