import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'

export const buttonPropTypes = {
  /**
   * The visual style of the Button
   *
   * @default secondary
   */
  skin: PropTypes.oneOf(['unstyled', 'link', 'primary', 'secondary', 'tertiary', 'clear-selection'] as const),
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * The size variation
   *
   * @default standard
   */
  size: deprecatedProp(PropTypes.oneOf(['standard', 'mini'] as const), 'Sizing is now handled automatically by standardMode/compactMode'),
  /**
   * The width variation
   *
   * @default standard
   */
  width: PropTypes.oneOf(['standard', 'full-width', 'wide'] as const),
  /**
   * Variations for special cases
   *
   * @default standard
   */
  specialVariant: PropTypes.oneOf(['standard', 'design-path'] as const),
  /**
   * The position of the icon in the Button
   */
  iconPosition: PropTypes.oneOf(['right', 'left'] as const),
  /**
   * The shape of the Button
   *
   * @default standard
   */
  buttonShape: PropTypes.oneOf(['standard', 'round'] as const),
}

const propKeysToRemove = Object.keys(buttonPropTypes)

export type ButtonProps = CoreProps<JSX.IntrinsicElements['button'], MinNativeRef, InferProps<typeof buttonPropTypes>>

export const Button = renderWithRef<MinNativeRef, ButtonProps>('Button', buttonPropTypes, (props, ref) => {
  const buttonSkinsWithoutBaseClass = ['unstyled', 'clear-selection', 'link']
  const { as, component, skin = 'secondary', size = 'standard', width = 'standard', specialVariant = 'standard', iconPosition, buttonShape = 'standard', children, type } = props
  const Comp = as ?? component ?? 'button'
  const buttonType = Comp === 'button' || Comp === 'input' ? (type ?? 'button') : type

  const applyBaseClass = !buttonSkinsWithoutBaseClass.includes(skin ?? 'secondary')
  const classNames = new Set<string>([])
  if (applyBaseClass) {
    classNames.add('swan-button')
    classNames.add(`swan-button-skin-${skin}`)
  }

  if (!applyBaseClass) classNames.add(`swan-button-skin-${skin}`)
  if (buttonShape === 'round') classNames.add('swan-button-round')
  if (iconPosition === 'left') classNames.add('swan-button-with-icon-left')
  if ((specialVariant === 'design-path' && !iconPosition) || iconPosition === 'right') classNames.add('swan-button-with-icon-right')
  if (size !== 'standard' && applyBaseClass) classNames.add(`swan-button-${size}`)
  if (size !== 'standard' && !applyBaseClass) classNames.add(`swan-button-${size}`)
  if (width !== 'standard') classNames.add(`swan-button-${width}`)
  if (specialVariant === 'design-path') classNames.add(`swan-button-design-path`)

  return (
    <RenderComp
      root="button"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      classNames={classNames}
      props={Comp === 'button' || Comp === 'input' ? { ...props, type: buttonType } : props}
    >
      {children}
    </RenderComp>
  )
})
