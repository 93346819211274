import { useDialog } from '@react-aria/dialog'
import { ReactNode, useRef } from 'react'

/**
 * @subcomponent Popover
 */
export const PopoverDialog = ({ children }: { children: ReactNode }) => {
  const ref = useRef(null)
  const { dialogProps } = useDialog({}, ref)

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  )
}
