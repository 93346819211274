import { KeyboardEventHandler, MutableRefObject, RefObject } from 'react'

export function createKeyPressHandlers<T = Element>(handler: KeyboardEventHandler<T>, keyCode: number, ...keys: Array<KeyboardEvent['key']>): KeyboardEventHandler<T> {
  return event => {
    // keyCode and which are deprecated, we have it for legacy reason
    if (keys.includes(event.key) || event.keyCode === keyCode || event.which === keyCode) {
      handler(event)
    }
  }
}

function emulateClick<T extends HTMLElement>(elementRef: MutableRefObject<T> | RefObject<T>) {
  if (elementRef && elementRef.current && typeof elementRef.current.click === 'function') {
    // elementRef.current.click()
    elementRef.current.dispatchEvent(new Event('click'))
  }
}

export function createClickOnEnterHandler<T extends HTMLElement>(elementRef: MutableRefObject<T> | RefObject<T>): KeyboardEventHandler<T> {
  return createKeyPressHandlers(() => emulateClick(elementRef), 13, 'Enter')
}

export function createClickOnSpaceHandler<T extends HTMLElement>(elementRef: MutableRefObject<T> | RefObject<T>): KeyboardEventHandler<T> {
  return createKeyPressHandlers(() => emulateClick(elementRef), 32, ' ', 'Spacebar')
}
