import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ModalDialogBodyProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Dialog
 */
export const ModalDialogBody = renderWithRef<MinNativeRef, ModalDialogBodyProps>('ModalDialogBody', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" forwardedRef={ref} props={props} classNames={['swan-modal-dialog-body']}>
    {children}
  </RenderComp>
))
