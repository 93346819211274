import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * Whether or not the content should be visible on extra-small screens
   * @default false
   */
  xs: PropTypes.bool,
  /**
   * Whether or not the content should be visible on small screens
   * @default false
   */
  sm: PropTypes.bool,
  /**
   * Whether or not the content should be visible on medium screens
   * @default false
   */
  md: PropTypes.bool,
  /**
   * Whether or not the content should be visible on large screens
   * @default false
   */
  lg: PropTypes.bool,
  /**
   * Whether or not the content should be visible on extra-large screens
   * @default false
   */
  xl: PropTypes.bool,
}

export type VisibleProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>
export const Visible = renderWithRef<MinNativeRef, VisibleProps>('Visible', propTypes, (props, ref) => {
  useComponentStylesLoaded('Visible', SWAN_STYLE_KEY_MAP.visible)

  const { xs = false, sm = false, md = false, lg = false, xl = false, children, ...rest } = props

  const classNames = new Set<string>()

  if (xs) classNames.add('swan-visible-xs')
  if (sm) classNames.add('swan-visible-sm')
  if (md) classNames.add('swan-visible-md')
  if (lg) classNames.add('swan-visible-lg')
  if (xl) classNames.add('swan-visible-xl')

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={rest}>
      {children}
    </RenderComp>
  )
})
