import PropTypes, { InferProps } from 'prop-types'
import { useEffect } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

import { useModalDialogContext } from './modal-dialog.context'

const propTypes = {
  /**
   * To pin footers to the bottom of the dialog
   *
   * @default false
   */
  pinned: PropTypes.bool,
}

export type ModalDialogFooterProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Dialog
 */
export const ModalDialogFooter = renderWithRef<MinNativeRef, ModalDialogFooterProps>('ModalDialogFooter', null, ({ children, pinned = false, ...props }, ref) => {
  const { setFooterPinned } = useModalDialogContext()
  useEffect(() => {
    // communicate this value up to the parent so that it can add a class
    setFooterPinned(!!pinned)

    // when the component unmounts, tell the parent that it no longer needs the class
    return () => setFooterPinned(undefined)
  }, [pinned, setFooterPinned])

  return (
    <RenderComp root="div" forwardedRef={ref} props={props} classNames={['swan-modal-dialog-footer']}>
      {children}
    </RenderComp>
  )
})
