import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type PProps = CoreProps<JSX.IntrinsicElements['p'], MinNativeRef>

export const P = renderWithRef<MinNativeRef, PProps>('Paragraph', null, (props, ref) => {
  const { children } = props

  return (
    <RenderComp root="p" forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
