export const MANIFEST_FONTS = {
  tiemposRegular2: {
    hashed: 'tiempos-regular2.e4e5aee576677174d2fbcddb1caa0fd3.woff2',
    versioned: 'tiempos-regular2.woff2',
  },
  graphicRegular2: {
    hashed: 'graphic_regular2.7c96db81b23a97fd67cbeb7e7efad583.woff2',
    versioned: 'graphic_regular2.woff2',
  },
  graphicRegular: {
    hashed: 'graphic_regular.e694f50e9c3a4ee999da756a90b0e872.woff',
    versioned: 'graphic_regular.woff',
  },
  graphicMedium2: {
    hashed: 'graphic_medium2.3829398551b96ac319a48122465462c2.woff2',
    versioned: 'graphic_medium2.woff2',
  },
  graphicMedium: {
    hashed: 'graphic_medium.1e5761591bb4bdd7e1d6ef96bb7cef90.woff',
    versioned: 'graphic_medium.woff',
  },
} as const;
