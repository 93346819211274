import { useListBox } from '@react-aria/listbox'
import { ListProps } from '@react-stately/list'
import { useEffect } from 'react'

import { assignRefs } from '~/core/utilities'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, renderWithRef } from '~/react/components/core'
import { useSwanPopover } from '~/react/components/popover'

import { useCollectionContext } from '~/react/contexts/internal/collection'

import { useSwanListboxListContext } from './listbox-list.context'
import { ListboxOptionInternal } from './listbox-option-internal.component'
import { ListboxSectionInternal } from './listbox-section-internal.component'

type ListboxListPropsGeneric<T> = CoreProps<Omit<JSX.IntrinsicElements['ul'], 'children'>, MinNativeRef, Partial<Pick<ListProps<T>, 'children'>>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ListboxListProps = ListboxListPropsGeneric<any>

/**
 * @subcomponent Listbox
 */
export const ListboxList = renderWithRef<HTMLUListElement, ListboxListProps>('ListboxList', null, (props, ref) => {
  const { children, className, 'aria-label': ariaLabel } = props
  const { setChildItems } = useCollectionContext()
  const { overlayState } = useSwanPopover()
  const { listProps, listState, listboxRef } = useSwanListboxListContext()

  useEffect(() => {
    setChildItems(() => children)
  }, [setChildItems, children])

  const { listBoxProps } = useListBox(
    {
      ...listProps,
      'aria-label': ariaLabel,
    },
    listState,
    listboxRef,
  )

  const combinedRef = assignRefs(ref, listboxRef)

  const classes = new Set(['swan-listbox-list'])
  if (className) classes.add(className)

  return (
    <ul {...props} {...listBoxProps} ref={combinedRef} className={[...classes].join(' ')}>
      {overlayState.isOpen
        ? [...listState.collection].map(item => {
            return item.type === 'section' ? (
              <ListboxSectionInternal key={item.key} section={item} state={listState} />
            ) : (
              <ListboxOptionInternal key={item.key} item={item} state={listState} />
            )
          })
        : null}
    </ul>
  )
})
