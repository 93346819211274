import PropTypes, { InferProps } from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'

import { assignRefs } from '~/core/utilities'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

import { useAnchorBarIsPinned, useAnchorBarScroll } from './anchor-bar.hooks'

const propTypes = {
  /**
   * Whether or not the element should stick to the top of the screen when scrolling past it.
   * Sticky also enables the scroll behavior to update the active anchor on scroll
   *
   * @default false
   */
  sticky: PropTypes.bool,
  /**
   * When sticky, the function is called when an element with an id that matches one
   *  of the AnchorBar a element's href attribute scrolls into view on the screen
   */
  onActiveAnchorChanged: PropTypes.func,
}

const propKeysToRemove = Object.keys(propTypes)

export type AnchorBarProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const AnchorBar = renderWithRef<MinNativeRef, AnchorBarProps>('AnchorBar', propTypes, (props, ref) => {
  useComponentStylesLoaded('AnchorBar', SWAN_STYLE_KEY_MAP.anchorBar)
  const { children, sticky = false, onActiveAnchorChanged } = props
  const anchorBarRef = useRef<HTMLDivElement | null>(null)
  const [isPinned] = useAnchorBarIsPinned(anchorBarRef, sticky ?? false)

  const setActiveAnchor = useCallback(
    (anchor: string) => {
      if (onActiveAnchorChanged) {
        onActiveAnchorChanged(anchor)
      }
    },
    [onActiveAnchorChanged],
  )

  useAnchorBarScroll(anchorBarRef, {
    sticky: sticky ?? false,
    pinned: isPinned,
    setActiveAnchor,
  })

  const classNames = new Set(['swan-anchor-bar'])
  if (sticky) classNames.add('swan-anchor-bar-sticky')
  if (isPinned) classNames.add('swan-anchor-bar-sticky-pinned')

  const combinedRef = assignRefs(ref, anchorBarRef)

  useEffect(() => {
    if (!anchorBarRef.current) return

    const varName = '--swan-internal-anchor-bar-el-height'
    if (isPinned) {
      const height = anchorBarRef.current.offsetHeight
      document.documentElement.style.setProperty(varName, `${height}px`)
    } else {
      document.documentElement.style.removeProperty(varName)
    }
  }, [isPinned])

  return (
    <RenderComp
      root="div"
      forwardedRef={combinedRef}
      propKeysToRemove={propKeysToRemove}
      classNames={classNames}
      props={{ ...props, darkMode: props.darkMode, role: 'navigation' }}
    >
      {children}
    </RenderComp>
  )
})
