import { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {}

const propKeysToRemove = Object.keys(propTypes)

export type AnchorBarListProps = CoreProps<JSX.IntrinsicElements['ul'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * List of navigation items to render inside of the anchor bar
 *
 * @subcomponent AnchorBar
 */
export const AnchorBarList = renderWithRef<MinNativeRef, AnchorBarListProps>('SubNavList', propTypes, (props, ref) => {
  const classNames = new Set(['swan-anchor-bar-list'])
  const { children } = props

  return (
    <RenderComp root="ul" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
      {children}
    </RenderComp>
  )
})
