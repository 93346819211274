import PropTypes, { InferProps } from 'prop-types'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * Whether or not the card should have a border
   * @default false
   */
  bordered: PropTypes.bool,
  /**
   * Whether or not this Card should stretch its height to fill its parent GridContainer Row
   *
   * This is useful if you have a Row of Cards and you want them to all be the same height
   * @default false
   */
  evenHeight: PropTypes.bool,
  /**
   * Whether or not the content should expand to the edges of the Card
   * @default false
   */
  fullBleed: PropTypes.bool,
  /**
   * Use "link" if you want to render card as a link or <Link> as a Card covering
   */
  skin: PropTypes.oneOf(['link'] as const),
}

export type CardProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const Card = renderWithRef<MinNativeRef, CardProps>('Card', propTypes, (props, ref) => {
  const { bordered = false, evenHeight = false, fullBleed = false, skin, children, ...rest } = props

  const classNames = new Set<string>(['swan-card'])
  if (skin === 'link') classNames.add('swan-card-skin-link')
  if (bordered) classNames.add('swan-card-bordered')
  if (evenHeight) classNames.add('swan-card-even-height')
  if (fullBleed) classNames.add('swan-card-full-bleed')

  return (
    <RenderComp root="div" forwardedRef={ref} classNames={classNames} props={rest}>
      {children}
    </RenderComp>
  )
})
