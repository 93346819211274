import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type StandardTilePromoCodeLabelProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent StandardTile
 */

export const StandardTilePromoCodeLabel = renderWithRef<MinNativeRef, StandardTilePromoCodeLabelProps>('StandardTilePromoCodeLabel', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-standard-tile-promo-code-label']} props={props}>
    {props.children}
  </RenderComp>
))
