import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { useId } from '~/react/hooks'

type ProgressBarContextValue = {
  id?: string
  setId: (id?: string) => void
}

type ProgressBarContextProps = {
  children: ReactNode
}

const ProgressBarContext = createContext<ProgressBarContextValue | undefined>(undefined)

export const ProgressBarContextProvider = ({ children }: ProgressBarContextProps) => {
  const [id, setId] = useState<string | undefined>(undefined)
  const value = useMemo(() => ({ id, setId }), [id])
  return <ProgressBarContext.Provider value={value}>{children}</ProgressBarContext.Provider>
}

export const useProgressBarContext = () => useContext(ProgressBarContext)

export const useProgressBarId = (id?: string) => {
  const ctx = useProgressBarContext()
  const resultId = useId(id)
  useEffect(() => {
    ctx?.setId(resultId)
  }, [ctx, resultId])
  return ctx?.id
}
