import { Validator } from 'prop-types'
import warning from 'tiny-warning'

function deprecatedPropValues<T>(validator: Validator<T>, values: Array<unknown>, instructions: string): Validator<T | null | undefined> {
  let warned = false
  return function validate(props, propName, componentName, location, propFullName, ...args) {
    const componentNameSafe = componentName || '<<anonymous>>'
    const propFullNameSafe = propFullName || propName

    if (props[propName] != null && !props.__dangerouslySuppressWarning && values.includes(props[propName])) {
      warning(warned, `The \`${propFullNameSafe}\`=\`${props[propName]}\` value of ` + `\`${componentNameSafe}\` is deprecated. ${instructions}.`)
      if (!warned) console.warn(`${componentNameSafe}: props received =======>`, props)

      warned = true
    }

    return validator(props, propName, componentName, location, propFullName, ...args)
  }
}

export { deprecatedPropValues }
