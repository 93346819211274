import { Component, ErrorInfo, ReactNode } from 'react'

type ErrorBoundaryProps = {
  /**
   * A React node that serves as a fallback UI when an error occurs.
   */
  fallback?: ReactNode | null
  /**
   * A boolean indicating whether to force showing the error details
   *
   * @default false
   */
  forceShowError?: boolean
  /**
   * Context or a message related to the error.
   */
  errorDetailMsg?: string | null
  children?: ReactNode
}
type ErrorBoundaryState = {
  error: Error | null
  errorInfo: ErrorInfo | null
}

type ErrorDetailsProps = {
  open?: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
  msg?: string | null
}

const ErrorDetails = ({ open, error, errorInfo, msg = 'Something went wrong!' }: ErrorDetailsProps) => (
  <div>
    {typeof msg === 'string' ? <h2>{msg}</h2> : msg}
    <details style={{ whiteSpace: 'pre-wrap' }} open={open}>
      <summary>Error details</summary>
      <p>{error && error.toString()}</p>
      <h4 className="swan-mt-4">Stack trace:</h4>
      <div>{errorInfo && errorInfo.componentStack}</div>
    </details>
  </div>
)

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    const { children, fallback, forceShowError = false, errorDetailMsg } = this.props
    if (!this.state.error) {
      return children
    }
    return (
      <>
        {fallback}
        {forceShowError || !fallback ? <ErrorDetails error={this.state.error} errorInfo={this.state.errorInfo} open={!fallback} msg={errorDetailMsg} /> : null}
      </>
    )
  }
}
