import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'
import { Icon } from '~/react/components/icon'

const propTypes = {
  /**
   * Should it be disabled or not
   *
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * Button type
   */
  variant: PropTypes.oneOf(['previous', 'next'] as const),
  /**
   * Provide the text that should be used for screen readers.
   */
  accessibleText: PropTypes.string,
}

export type PaginationButtonProps = CoreProps<JSX.IntrinsicElements['a'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent Pagination
 */
export const PaginationButton = renderWithRef<MinNativeRef, PaginationButtonProps>(
  'PaginationButton',
  null,
  ({ children, disabled = false, variant, accessibleText, as, component, ...props }, ref) => {
    const renderAsSpan = disabled && as !== 'button' && component !== 'button'

    const processedProps = {
      'aria-label': accessibleText ?? undefined,
      ...props,
      disabled: (as === 'button' || component === 'button') && disabled,
      as: (renderAsSpan && 'span') || as || component || 'a',
      role: 'button',
    }

    const classNames = [`swan-pagination-button-${variant}`]
    if (disabled) classNames.push('swan-pagination-button-disabled')

    return (
      <li>
        <RenderComp root="a" classNames={classNames} forwardedRef={ref} props={processedProps}>
          {variant === 'next' && <Icon iconType="chevronRight" />}
          {variant === 'previous' && <Icon iconType="chevronLeft" />}
          {children}
        </RenderComp>
      </li>
    )
  },
)
