import PropTypes, { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * Visual type of banner
   * Available options: "card", "full-width-image"
   *
   * @default card
   */
  variant: PropTypes.oneOf(['card', 'full-width-image'] as const),
  /**
   * Whether the Banner has two images
   *
   * @default false
   */
  twoImages: PropTypes.bool,
  /**
   * Whether the Banner has multiple discounts
   *
   * @default false
   */
  multiColumnOffer: PropTypes.bool,
  /**
   * Height of the Banner
   * Available options: "standard", "short"
   *
   * @default standard
   */
  height: PropTypes.oneOf(['standard', 'short'] as const),
  /**
   * @deprecated
   * The text will be by default center aligned on extra small screen size devices and to maintain the consistent user experience, we are deprecating this prop.
   *
   * How the text itself should be aligned horizontally within the Banner, when on mobile.
   * Available options: "left", "center"
   */
  textHorizontalAlignOnExtraSmall: deprecatedProp(
    PropTypes.oneOf(['left', 'center'] as const),
    'The text will be by default center aligned on extra small screen size devices and to maintain the consistent user experience, we are deprecating this prop.',
  ),
  /**
   * How the text box should be aligned horizontally within the Banner.
   * Available options: "left", "center", "right"
   *
   * @default left
   */
  textBoxHorizontalAlign: PropTypes.oneOf(['left', 'center', 'right'] as const),
  /**
   * Specifies if the Banner's layout should adjust to fit a left hand navigation menu
   *
   * @default false
   */
  withLeftHandNavigation: PropTypes.bool,
  /**
   * Specifies if the Banner's secondary image should be shown if we are only showing one image
   *
   * @default false
   */
  preferSecondaryImage: PropTypes.bool,
  /**
   * Hides all image(s) on Extra-Small screens
   *
   * @default false
   */
  hideImageOnExtraSmall: PropTypes.bool,
  /**
   * Styles the appropriate Banner subcomponents with different font styling
   *
   * @default false
   */
  editorial: PropTypes.bool,
  /**
   * Sets the focal point if the image gets clipped. (X-axis first, then Y-axis)
   *
   * @deprecated
   * Set `imageFocalPoint` on `BannerImage` or `BannerSecondaryImage` instead.
   */
  imageFocalPoint: deprecatedProp(
    PropTypes.oneOf(['left top', 'left center', 'left bottom', 'center top', 'center center', 'center bottom', 'right top', 'right center', 'right bottom'] as const),
    "This prop is deprecated and will be removed in a future release. Please set 'imageFocalPoint' on 'BannerImage' or 'BannerSecondaryImage' instead.",
  ),
}
const propKeysToRemove = Object.keys(propTypes)

export type BannerProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const Banner = renderWithRef<MinNativeRef, BannerProps>('Banner', propTypes, (props, ref) => {
  useComponentStylesLoaded('Banner', SWAN_STYLE_KEY_MAP.banner)

  const {
    children,
    height,
    textHorizontalAlignOnExtraSmall,
    textBoxHorizontalAlign = 'left',
    variant = 'card',
    twoImages = false,
    multiColumnOffer = false,
    withLeftHandNavigation = false,
    preferSecondaryImage = false,
    hideImageOnExtraSmall = false,
    editorial = false,
    imageFocalPoint,
  } = props

  const classNameSet = new Set<string>(['swan-banner'])
  classNameSet.add(`swan-banner-${variant}`)
  if (textHorizontalAlignOnExtraSmall) classNameSet.add(`swan-banner-text-horizontal-align-on-extra-small-${textHorizontalAlignOnExtraSmall}`)
  if (textBoxHorizontalAlign) classNameSet.add(`swan-banner-text-box-horizontal-align-${textBoxHorizontalAlign}`)
  if (height === 'short') classNameSet.add('swan-banner-short')
  if (twoImages && variant === 'card') classNameSet.add('swan-banner-two-images')
  if (multiColumnOffer) classNameSet.add('swan-banner-multi-column-offer')
  if (withLeftHandNavigation && variant === 'card' && textBoxHorizontalAlign === 'left') classNameSet.add('swan-banner-with-left-hand-navigation')
  if (preferSecondaryImage) classNameSet.add('swan-banner-prefer-secondary-image')
  if (hideImageOnExtraSmall) classNameSet.add('swan-banner-hide-image-on-extra-small')
  if (editorial) classNameSet.add(`swan-banner-editorial`)
  if (imageFocalPoint) classNameSet.add(`swan-banner-image-focal-point-${imageFocalPoint.replace(/ +/g, '-')}`)

  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNameSet} props={props}>
      {children}
    </RenderComp>
  )
})
