import { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { Button, buttonPropTypes } from '~/react/components/button'
import { RenderComp, renderWithRef } from '~/react/components/core'
import { useFileInputContext } from '~/react/components/file-input/file-input.context'

const propTypes = {
  ...buttonPropTypes,
}

const propKeysToRemove = Object.keys(propTypes)

export type FileInputLabelProps = CoreProps<JSX.IntrinsicElements['label'], MinNativeRef, InferProps<typeof propTypes>>

/**
 * @subcomponent FileInput
 */
export const FileInputLabel = renderWithRef<MinNativeRef, FileInputLabelProps>('FileInputLabel', propTypes, (props, ref) => {
  const { children, skin, size, width, specialVariant, iconPosition, buttonShape } = props

  const { inputId, disabled } = useFileInputContext()
  return (
    <Button
      skin={skin}
      size={size}
      width={width}
      specialVariant={specialVariant}
      iconPosition={iconPosition}
      buttonShape={buttonShape}
      render={({ className }) => (
        <RenderComp
          root="label"
          forwardedRef={ref}
          propKeysToRemove={propKeysToRemove}
          classNames={['swan-file-input-label', className ?? '']}
          props={{ ...props, htmlFor: inputId, 'aria-disabled': disabled }}
        >
          {children}
        </RenderComp>
      )}
    />
  )
})
