import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type ModalDialogNavProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent Dialog
 */
export const ModalDialogNav = renderWithRef<MinNativeRef, ModalDialogNavProps>('ModalDialogNav', null, ({ children, ...props }, ref) => (
  <RenderComp root="div" forwardedRef={ref} props={props} classNames={['swan-modal-dialog-nav']}>
    {children}
  </RenderComp>
))
