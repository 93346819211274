import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type BulletListProps = CoreProps<JSX.IntrinsicElements['ul'], MinNativeRef>
/**
 * @subcomponent List
 */

export const BulletList = renderWithRef<MinNativeRef, BulletListProps>('BulletList', null, (props, ref) => (
  <RenderComp root="ul" forwardedRef={ref} classNames={['swan-bullet-list']} props={props}>
    {props.children}
  </RenderComp>
))
