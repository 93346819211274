import { useCallback, useState } from 'react'

/**
 * useMap is a utility for working with a map-like (key-value pair) object stored in state. It returns `set`, `remove`, and `has` functions that will store a new object in state after applying the update. (Well, `has` won't update state, but `set` and `remove` do.)
 *
 * `useDidImmutableValueChange` stores a reference to the initial value passed in and will return true if the value has changed.
 *
 * @param {Context | undefined | null} context
 */

export function useMap<T>(initialValue = {}): [
  { [key: string]: T },
  {
    set: (key: string, val: T) => void
    remove: (key: string) => void
    has: (key: string) => boolean
  },
] {
  const [map, setMap] = useState<{ [key: string]: T }>(initialValue)

  const set = useCallback((key: string, val: T) => {
    setMap(prevMap => ({ ...prevMap, [key]: val }))
  }, [])

  const remove = useCallback((key: string) => {
    setMap(prevMap => {
      const clonedMap = { ...prevMap }

      delete clonedMap[key]

      return clonedMap
    })
  }, [])

  const has = useCallback((key: string) => map[key] !== undefined, [map])

  return [map, { set, remove, has }]
}
