import { StyleBreakpoints, StylePropsValues, SwanCoreStyleProps, SwanStyleProps } from '~/core/types/swan-style.types'

import { getKey, isResponsiveModifier, isValidStyleProperty, responsiveModifierSet } from './swan-style.utils'
import { iterToSet } from './transform.utils'

function getClassName(key: keyof SwanStyleProps, value: StylePropsValues, modifier: StyleBreakpoints = 'xs'): string | null {
  if (value === undefined || value === null) {
    return null
  }
  const effectiveKey = getKey(key)
  if (!effectiveKey) return null
  const prefix = `swan-${effectiveKey}`
  const suffix = modifier === 'xs' ? '' : `-${modifier}`

  switch (typeof value) {
    case 'boolean':
      return value ? prefix + suffix : null
    default:
      return prefix + `-${value}` + suffix
  }
}

function updateClassNameSet(key: keyof SwanStyleProps, value: StylePropsValues, modifier: StyleBreakpoints, classNamesSet: Set<string>): Set<string> {
  const cname = getClassName(key, value, modifier)
  if (cname) {
    classNamesSet.add(cname)
  }
  return classNamesSet
}

function processResponsiveStyleProps<T extends SwanStyleProps>(props: T, defaultResponsiveModifier: StyleBreakpoints, classNameSet: Set<string> = new Set()): Set<string> {
  Object.keys(props).forEach(propKey => {
    if (!isValidStyleProperty(propKey)) {
      return null // exit iteration
    }
    if (typeof props[propKey] === 'object') {
      // Key must be a modifier
      const responsiveValue = props[propKey] as Record<StyleBreakpoints, StylePropsValues> // eg: {xs: 1, md: 2}
      Object.keys(responsiveValue).forEach(modifier => {
        if (isResponsiveModifier(modifier)) {
          updateClassNameSet(propKey, responsiveValue[modifier], modifier, classNameSet)
        }
      })
    } else {
      updateClassNameSet(propKey, props[propKey], defaultResponsiveModifier, classNameSet)
    }
  })
  return classNameSet
}

export function processStyleProps(props: SwanCoreStyleProps, defaultClassNameSet?: Iterable<string> | null): Iterable<string> {
  let classNameSet = iterToSet(defaultClassNameSet)
  if (props.swanStyle) {
    const swanStyle = props.swanStyle
    responsiveModifierSet.forEach(modifier => {
      const modifierValue = swanStyle[modifier]
      if (modifierValue) {
        classNameSet = processResponsiveStyleProps(modifierValue, modifier, classNameSet)
      }
    })
    // This only process top level ResponsiveProps, inside Swan Style
    classNameSet = processResponsiveStyleProps(props.swanStyle, 'xs', classNameSet)
  }
  // This only process top level ResponsiveProps
  processResponsiveStyleProps(props, 'xs', classNameSet)
  return classNameSet
}
