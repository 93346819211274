import { useOption } from '@react-aria/listbox'
import { ListState } from '@react-stately/list'
import type { Node } from '@react-types/shared'
import PropTypes, { InferProps } from 'prop-types'
import { useEffect, useMemo, useRef } from 'react'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp } from '~/react/components/core'

import { useTrackDisabledState } from '~/react/contexts/internal/collection'

import { ListboxOptionProps } from './listbox-option.component'

export const propTypes = {
  /**
   * Whether or not the option is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Whether or not the option has a thumbnail.
   */
  withThumbnail: PropTypes.bool,
  /**
   * The contents to render inside the ListboxButton. Can include JSX
   */
  label: PropTypes.node,

  /**
   * The option's human-readable string. Required if using JSX inside of an option
   */
  textValue: PropTypes.string,
}
const propKeysToRemove = Object.keys(propTypes)

export type ListboxOptionCustomProps = CoreProps<HTMLLIElement, MinNativeRef, InferProps<typeof propTypes>>

export type ListboxOptionInternalProps = {
  item: Node<object>
  state: ListState<unknown>
}

/**
 * ListboxOptionInternal is the internal component for rendering an item
 *
 * The public ListboxOption is only used for the ListboxList to parse the children and store in state
 *
 * @subcomponent Listbox
 */
export const ListboxOptionInternal = ({ item, state }: ListboxOptionInternalProps) => {
  const ref = useRef<HTMLLIElement>(null)

  // get props from ListboxOption
  // if disabled is not given here, it can also be supplied as part of `disabledKeys` on the root component
  const { disabled } = (item.props ?? {}) as ListboxOptionProps

  const { optionProps, isFocused, isFocusVisible } = useOption(item, state, ref)
  useTrackDisabledState(item.key, disabled)

  const browserFocusVisible = useMemo(() => window?.CSS?.supports?.('selector(:focus-visible)'), [])
  const focused = browserFocusVisible ? isFocusVisible : isFocused

  // DSYS-2715 workaround for react-aria but where clicks are passing through to below the popover on mobile
  // https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1257931321
  useEffect(() => {
    ref.current?.addEventListener(
      'touchend',
      e => {
        e.preventDefault()
      },
      { passive: false, once: true },
    )
  }, [])

  const classes = new Set(['swan-listbox-option'])
  if (item.props.withThumbnail) classes.add('swan-listbox-option-with-thumbnail')
  if (focused) {
    classes.add('swan-listbox-option-focused')
  }

  return (
    <RenderComp root="li" forwardedRef={ref} propKeysToRemove={propKeysToRemove} props={{ ...item.props, ...optionProps }} classNames={classes}>
      {item.rendered}
    </RenderComp>
  )
}
