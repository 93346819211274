import PropTypes, { InferProps } from 'prop-types'

import type { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

export const avatarPropTypes = {
  /**
   * The size variation
   *
   * @default standard
   */
  size: PropTypes.oneOf(['standard', 'mini'] as const),
  /**
   * URL of the avatar image
   */
  src: PropTypes.string,
  /**
   * A label to use to describe the avatar to assistive devices.
   */
  alt: PropTypes.string,
}

const propKeysToRemove = Object.keys(avatarPropTypes)

export type AvatarProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof avatarPropTypes>>

export const Avatar = renderWithRef<MinNativeRef, AvatarProps>('Avatar', avatarPropTypes, (props, ref) => {
  useComponentStylesLoaded('Avatar', SWAN_STYLE_KEY_MAP.avatar)

  const { src, alt, size = 'standard' } = props

  const classNames = new Set(['swan-avatar'])
  if (size === 'mini') classNames.add(`swan-avatar-${size}`)

  const avatarProps = {
    ...props,
    style: { '--swan-internal-avatar-image': src ? `url(${src})` : null },
  }
  if (alt) {
    avatarProps['role'] = 'img'
    avatarProps['aria-label'] = alt
  }

  return (
    <RenderComp
      root="div"
      forwardedRef={ref}
      propKeysToRemove={propKeysToRemove}
      classNames={classNames}
      props={{
        ...avatarProps,
      }}
    />
  )
})
