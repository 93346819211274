import PropTypes, { InferProps } from 'prop-types'

import { TabHeaderInput, TabHeaderInputProps } from './tab-header-input.component'
import { TabHeaderLabel, TabHeaderLabelProps } from './tab-header-label.component'

const propTypes = {
  /**
   * The id of the matching tab
   */
  tabId: PropTypes.string.isRequired,
  /**
   * Props passed to the TabHeaderInput
   */
  inputProps: PropTypes.object as PropTypes.Requireable<Omit<TabHeaderInputProps, 'tabId' | 'ref'>>,
  /**
   * Whether or not the header is disabled
   *
   * @default false
   */
  disabled: PropTypes.bool,
}
export type TabHeaderProps = InferProps<typeof propTypes> & Omit<TabHeaderLabelProps, 'tabId' | 'ref'>

/**
 * @subcomponent Tabs
 */
export const TabHeader = (props: TabHeaderProps) => {
  const { children, inputProps = {}, tabId, disabled = false, ...labelProps } = props

  return (
    <>
      <TabHeaderInput tabId={tabId} disabled={!!disabled} {...inputProps} />
      <TabHeaderLabel tabId={tabId} {...labelProps}>
        {children}
      </TabHeaderLabel>
    </>
  )
}
