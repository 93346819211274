import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'

export type SelectionSetTilePriceProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef>

/**
 * @subcomponent SelectionSet
 */
export const SelectionSetTilePrice = renderWithRef<MinNativeRef, SelectionSetTilePriceProps>('SelectionSetTilePrice', null, (props, ref) => (
  <RenderComp root="div" forwardedRef={ref} classNames={['swan-selection-set-tile-price']} props={props}>
    {props.children}
  </RenderComp>
))
