import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  'aria-hidden': PropTypes.bool,
}
export type PaginationEllipsesProps = CoreProps<JSX.IntrinsicElements['div'], HTMLDivElement, InferProps<typeof propTypes>>

/**
 * @subcomponent Pagination
 */
export const PaginationEllipses = renderWithRef<MinNativeRef, PaginationEllipsesProps>('PaginationEllipses', null, ({ children, ...props }, ref) => (
  <li>
    <RenderComp root="div" classNames={['swan-pagination-step-ellipses']} forwardedRef={ref} props={{ ...props, 'aria-hidden': true }}>
      {children}
    </RenderComp>
  </li>
))
