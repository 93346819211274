import PropTypes, { InferProps } from 'prop-types'

import { MinNativeRef } from '~/react/components/core/core.types'

import { CoreProps, deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'
import { SWAN_STYLE_KEY_MAP } from '~/react/components/head'

import { useComponentStylesLoaded } from '~/react/hooks/use-component-styles-loaded'

const propTypes = {
  /**
   * By default, the banner segments will be hidden on Small and Extra-Small screens.
   * However, an adaptive design might require that for these screens, the banner be shown elsewhere on the page, separate from the regular Price Bar.
   * In this case, you can create a second Price Bar in a second location on the page, set this option to true, which will force that PriceBar to show its banner (and only its banner) on all screens.
   *
   * @deprecated
   * This is no longer supported for consistent user experience
   *
   * @default false
   */
  showBannerOnly: deprecatedProp(
    PropTypes.bool,
    'To maintain a high-quality user experience, we are no longer supporting this feature, and are not providing a direct replacement.',
  ),
}
const propKeysToRemove = Object.keys(propTypes)

export type PriceBarProps = CoreProps<JSX.IntrinsicElements['div'], MinNativeRef, InferProps<typeof propTypes>>

export const PriceBar = renderWithRef<MinNativeRef, PriceBarProps>('PriceBar', propTypes, (props, ref) => {
  useComponentStylesLoaded('PriceBar', SWAN_STYLE_KEY_MAP.priceBar)
  const { showBannerOnly = false, children } = props
  const classNames = new Set(['swan-price-bar'])
  if (showBannerOnly) {
    classNames.add(`swan-price-bar-show-banner`)
  }
  return (
    <RenderComp root="div" forwardedRef={ref} propKeysToRemove={propKeysToRemove} classNames={classNames} props={props}>
      <div className="swan-price-bar-inner">{children}</div>
    </RenderComp>
  )
})
