import { createContext, useContext } from 'react'

export type AccordionPurpose = undefined | 'list' | null
export type ExpandedCollapsibleMap = Record<string, boolean>

export const AccordionContext = createContext<
  | undefined
  | {
      expandedCollapsibleMap: ExpandedCollapsibleMap
      requestExpandedChange: (collapsibleId: string, expanded: boolean) => void
      purpose: AccordionPurpose
    }
>(undefined)

AccordionContext.displayName = 'AccordionContext'
// we don't warn if you try to use this context outside an Accordion
// because Collapsible may be used outside of Accordions

// Collapsible will need to check if this is undefined before using it
export function useAccordionContext() {
  return useContext(AccordionContext)
}
