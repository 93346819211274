import PropTypes, { InferProps } from 'prop-types'
import { FormEvent } from 'react'

import { CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { deprecatedProp, RenderComp, renderWithRef } from '~/react/components/core'

const propTypes = {
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * The layout of the form. Use horizontal when your form uses the label to the left of the input, instead of above it
   * @default vertical
   */
  variant: deprecatedProp(
    PropTypes.oneOf(['vertical', 'horizontal'] as const),
    'As this variant is rarely used, it has been deprecated to improve consistency for customers, better align with post-elev8 design standards, and reduce UX debt.',
  ),
  /**
   * @deprecated
   * This is deprecated without a replacement.
   *
   * The skin for the form. Tight will reduce margin between inputs
   * @default standard
   */
  skin: deprecatedProp(PropTypes.oneOf(['standard', 'tight'] as const), 'With the new visual language, this prop has become redundant.'),
}

const propKeysToRemove = Object.keys(propTypes)

export type StandardFormProps = CoreProps<JSX.IntrinsicElements['form'], MinNativeRef, InferProps<typeof propTypes>>

export const StandardForm = renderWithRef<MinNativeRef, StandardFormProps>('StandardForm', null, ({ children, variant, skin, ...props }, ref) => {
  const classNames = ['swan-form']
  if (variant === 'horizontal') classNames.push('swan-form-horizontal')
  if (skin === 'tight') classNames.push('swan-form-skin-tight')

  const finalProps = {
    ...props,
    onSubmit: !props.onSubmit && !props.method && !props.action ? (e: FormEvent<HTMLFormElement>) => e.preventDefault() : props.onSubmit, // if there is no method or action or onSubmit, prevent the submission
  }

  return (
    <RenderComp root="form" forwardedRef={ref} props={finalProps} propKeysToRemove={propKeysToRemove} classNames={classNames}>
      {children}
    </RenderComp>
  )
})
