import { useListBoxSection } from '@react-aria/listbox'
import { useSeparator } from '@react-aria/separator'
import { ListState } from '@react-stately/list'
import type { Node } from '@react-types/shared'
import { ReactNode } from 'react'

import { ListboxOptionInternal } from './listbox-option-internal.component'

export type ListboxSectionInternalProps = {
  section: {
    key: string | number
    // props?: CustomListboxProps
    'aria-label'?: string
    rendered: ReactNode
    childNodes: Iterable<Node<object>>
  }
  state: ListState<unknown>
}

/**
 * @subcomponent Listbox
 */
export function ListboxSectionInternal({ section, state }: ListboxSectionInternalProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  const { separatorProps } = useSeparator({
    elementType: 'li',
  })

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && <li className="swan-listbox-section-separator" {...separatorProps} />}
      <li {...itemProps}>
        {section.rendered && (
          <span {...headingProps} className="swan-listbox-section-title">
            {section.rendered}
          </span>
        )}
        <ul {...groupProps} className="swan-listbox-section">
          {[...section.childNodes].map(node => (
            <ListboxOptionInternal key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  )
}
