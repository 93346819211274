import { MinNativeRef } from '~/react/components/core/core.types'

import { CarouselSlide, CarouselSlideProps } from '~/react/components/carousel'
import { CoreProps, renderWithRef } from '~/react/components/core'

export type ThumbnailsHeroVideoThumbnailSlideProps = CoreProps<CarouselSlideProps, MinNativeRef>

/**
 * @subcomponent ThumbnailsHero
 */
export const ThumbnailsHeroVideoThumbnailSlide = renderWithRef<MinNativeRef, ThumbnailsHeroVideoThumbnailSlideProps>('ThumbnailsHeroVideoThumbnailSlide', null, (props, ref) => {
  return (
    <CarouselSlide ref={ref} {...props}>
      <span className="swan-thumbnails-hero-thumbnail-play-icon" />
      {props.children}
    </CarouselSlide>
  )
})
