import { CoreProps, RenderComp, renderWithRef } from '~/react/components/core/'
import { MinNativeRef } from '~/react/components/core/core.types'

export type SpanProps = CoreProps<JSX.IntrinsicElements['span'], MinNativeRef>

export const Span = renderWithRef<MinNativeRef, SpanProps>('Span', null, (props, ref) => {
  const { children } = props

  return (
    <RenderComp root="span" forwardedRef={ref} props={props}>
      {children}
    </RenderComp>
  )
})
