import { Requireable, Validator } from 'prop-types'

export function conditionalPropType<T, F>(
  predicateFun: (props: { [p: string]: unknown }, propName: string, componentName: string, location: string, propFullName: string) => boolean,
  truthyValidator: Validator<T> | Requireable<T>,
  falsyValidator: Validator<F> | Requireable<F>,
): Validator<T | F> {
  return function conditionalValidator(props, propName, componentName, location, propFullName, ...args) {
    const conditionValue = predicateFun(props, propName, componentName, location, propFullName)
    if (conditionValue) {
      return truthyValidator(props, propName, componentName, location, propFullName, ...args)
    }
    return falsyValidator(props, propName, componentName, location, propFullName, ...args)
  }
}
