import type { CoreInputProps, CoreProps, MinNativeRef } from '~/react/components/core/core.types'

import { RenderComp, renderWithRef } from '~/react/components/core'
import { useFormFieldProps } from '~/react/components/form'

import { useId } from '~/react/hooks'

import { FileInputContext } from './file-input.context'

export type FileInputProps = CoreProps<Omit<CoreInputProps, 'value'>, MinNativeRef>

export const FileInput = renderWithRef<MinNativeRef, FileInputProps>('FileInput', null, (props, ref) => {
  const { children, id: customId, disabled, ...rest } = props
  const id = useId(customId)
  const formProps = useFormFieldProps(id)

  const classNames = ['swan-file-input', 'swan-visually-hidden']

  return (
    <>
      <RenderComp classNames={classNames} root="input" forwardedRef={ref} props={{ ...rest, ...formProps, disabled, type: 'file' }} />
      <FileInputContext.Provider value={{ inputId: id, disabled }}>{children}</FileInputContext.Provider>
    </>
  )
})
